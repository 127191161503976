import styled from 'styled-components';

import { LoadingSpinner, SegmentTitle } from 'components';
import { NEUTRAL_5_COLOUR } from 'theme';

import { OnboardingStages, OnboardingSteps } from './components';
import { useOnboardingChecklistContext } from './providers/OnboardingChecklistProvider';

const Container = styled.div`
  display: flex;
  height: 584px;

  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 8px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const OnboardingChecklist = () => {
  const { isLoading } = useOnboardingChecklistContext();

  return (
    <>
      <SegmentTitle title="Launch Checklist" />
      <Container>
        {isLoading ? (
          <LoadingWrapper>
            <LoadingSpinner />
          </LoadingWrapper>
        ) : (
          <>
            <OnboardingStages />
            <OnboardingSteps />
          </>
        )}
      </Container>
    </>
  );
};
