import { AxiosInstance } from 'axios';

export interface MFFModule {
  ID: string;
  Name: string;
  Description: string;
  MinNative: string;
  MinReact: string;
}

export const getMFFModules = (client: AxiosInstance) => {
  return client.get<{ Modules: MFFModule[] }>(`/misc/modules`).then(({ data }) => data.Modules);
};
