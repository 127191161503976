import { useCallback, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { AppBasicInfo } from 'api';
import { CustomCell, CustomTable, FilterDropdown, SearchBar, TableColumn, Tabs } from 'components';
import { FONT_12PX_MEDIUM, FONT_14PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { useAvailableApps } from 'hooks';
import { NEUTRAL_8_COLOUR } from 'theme';
import { getSortedArr, getSortedNumberArr } from '../util';

const PAGE_SIZE = 50;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SearchBarWrapper = styled.div`
  margin-right: 12px;
`;

const Total = styled.div`
  ${FONT_14PX_MEDIUM};
  color: ${NEUTRAL_8_COLOUR};
`;

const StyledCell = styled(CustomCell)`
  height: 42px;
  ${OVERFLOW_ELLIPSIS};
  ${FONT_12PX_MEDIUM};
`;

const Ellipsis = styled.div`
  ${OVERFLOW_ELLIPSIS};
`;

const JSONWrapper = styled.div`
  height: 100%;
  padding: 5px;

  .react-json-view {
    height: 100%;
  }
`;

export const DebugApps = () => {
  const { data: availableApps } = useAvailableApps();
  const [page, setPage] = useState(1);
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get('search') ?? undefined;
  const [filters, setFilters] = useState<Record<string, string[] | undefined>>({});

  const apps = useMemo(() => {
    const arr: Record<string, string>[] = [];

    if (!availableApps) {
      return arr;
    }

    availableApps.forEach((app) => {
      const obj: Record<string, string> = {};
      Object.entries(app).forEach(([key, value]) => {
        if (key !== 'Id') {
          obj[key] = value ? value.toString() : value;
        }
      });
      arr.push(obj);
    });

    return arr.sort((a, b) => a.AppleTitle.localeCompare(b.AppleTitle));
  }, [availableApps]);

  const appFilters = useMemo(() => {
    const DataSource = new Set<string>();
    const PaymentStatus = new Set<number>();

    apps.forEach((a) => {
      if (a.DataSource) {
        DataSource.add(a.DataSource);
      }
      if (a.PaymentStatus) {
        PaymentStatus.add(parseInt(a.PaymentStatus));
      }
    });
    return {
      DataSource: getSortedArr(DataSource),
      PaymentStatus: getSortedNumberArr(PaymentStatus),
    };
  }, [apps]);

  const appliedFilters = useMemo(() => {
    const obj: Record<string, (string | number)[]> = {};
    for (const [key, value] of Object.entries(filters)) {
      if (value) {
        obj[key] = value;
      }
    }
    return obj;
  }, [filters]);

  const filteredData = useMemo(() => {
    const filteredByQuery = !query
      ? apps
      : apps?.filter(({ AppId, AppleTitle }) => {
          if (AppId.toLowerCase().includes(query)) {
            return true;
          }
          if (AppleTitle && AppleTitle.toLowerCase().includes(query)) {
            return true;
          }
          return false;
        });

    if (Object.keys(appliedFilters).length === 0) {
      return filteredByQuery;
    }

    const filtered = filteredByQuery.filter((row) => {
      let match = true;
      Object.keys(appliedFilters).forEach((key) => {
        const fieldValue = row[key as keyof AppBasicInfo]?.toString();
        if (!fieldValue || !appliedFilters[key]?.includes(fieldValue)) {
          match = false;
        }
      });
      return match;
    });

    return filtered;
  }, [apps, query, appliedFilters]);

  const columns: TableColumn<Record<string, string> & { id: string }>[] = [
    {
      heading: 'AppId',
      width: 300,
      render: ({ AppId }) => (
        <StyledCell>
          <Ellipsis>{AppId}</Ellipsis>
        </StyledCell>
      ),
      sorter: (a, b) => a.AppId.localeCompare(b.AppId),
      sorterKey: 'AppId',
      initialSortOrder: 'ascend',
    },
    {
      heading: 'AppleTitle',
      width: 300,
      render: ({ AppleTitle }) => (
        <StyledCell>
          <Ellipsis>{AppleTitle}</Ellipsis>
        </StyledCell>
      ),
      sorter: (a, b) => a.AppleTitle.localeCompare(b.AppleTitle),
      sorterKey: 'AppleTitle',
      initialSortOrder: 'ascend',
    },
    {
      heading: 'App Basic Info',
      width: 'grow',
      render: function LogDetails(app) {
        return (
          <JSONWrapper>
            <ReactJson
              name=""
              collapsed
              src={app}
              theme="paraiso"
              indentWidth={2}
              iconStyle="square"
              style={{ padding: '6px', fontSize: '12px', lineHeight: '14px' }}
              displayDataTypes={false}
              quotesOnKeys={false}
              displayObjectSize={false}
            />
          </JSONWrapper>
        );
      },
    },
  ];

  const availableFilters = useMemo(
    () =>
      Object.entries(appFilters).map(([key, value]) => ({
        key,
        title: key,
        options: value.map((name) => ({ name: name.toString() })),
        multiSelect: true,
      })),
    [appFilters],
  );

  const handleSearch = useCallback(
    (value: string) => {
      const searchInput = value.toLowerCase();
      const searchParam = searchInput !== '' ? `&search=${searchInput}` : '';

      history.replace({
        search: searchParam,
      });

      setPage(1);
    },
    [history],
  );

  return (
    <Tabs.Content value="apps">
      <TableHeader>
        <SearchWrapper>
          <SearchBarWrapper>
            <SearchBar value={query} onSearch={handleSearch} width="293px" />
          </SearchBarWrapper>
          <FilterDropdown
            availableFilters={availableFilters}
            handleChange={(value) => {
              setFilters(value);
              setPage(1);
            }}
            total={Object.keys(appliedFilters).length}
          />
        </SearchWrapper>
        <Total>{`${filteredData.length} Apps`}</Total>
      </TableHeader>
      <CustomTable
        isLoading={!apps}
        pageSize={PAGE_SIZE}
        columns={columns}
        data={filteredData.map((a) => ({ ...a, id: a.AppId }))}
        emptyTitle=""
        emptyDescription="No results"
        overridePage={page}
        onPageChange={setPage}
        defaultSortKey="AppleTitle"
        defaultSortOrder="ascend"
      />
    </Tabs.Content>
  );
};
