import {
  METRIC_KEY_AVERAGE_WATCHED,
  METRIC_KEY_COMMENTS,
  METRIC_KEY_COMPLETIONS,
  METRIC_KEY_ENGAGED_USERS,
  METRIC_KEY_MINUTES,
  METRIC_KEY_SAVED_PLAYLIST,
  METRIC_KEY_SCHEDULED,
  METRIC_KEY_TOTAL_PLAYS,
  METRIC_KEY_UNIQUE_COMPLETIONS,
  METRIC_KEY_UNIQUE_PLAYS,
  MetricKey,
  VideoMetricKey,
} from 'api';

export const METRIC_PARAM = 'metric'; // Query param used in analytics path, e.g. /analytics/viewership?metric=total-plays

export const METRIC_LABELS: Record<VideoMetricKey | MetricKey, string> = {
  [METRIC_KEY_TOTAL_PLAYS]: 'Total Plays',
  [METRIC_KEY_COMPLETIONS]: 'Completions',
  [METRIC_KEY_AVERAGE_WATCHED]: 'Average Watched',
  [METRIC_KEY_UNIQUE_PLAYS]: 'Unique Plays',
  [METRIC_KEY_UNIQUE_COMPLETIONS]: 'Unique Completions',
  [METRIC_KEY_MINUTES]: 'Total Minutes Watched',
  [METRIC_KEY_SCHEDULED]: 'Scheduled To Calendar',
  [METRIC_KEY_COMMENTS]: 'Comments',
  [METRIC_KEY_SAVED_PLAYLIST]: 'Saved To Playlists',
  [METRIC_KEY_ENGAGED_USERS]: 'Engaged Users',
};

export const METRIC_TOOLTIPS: Record<MetricKey, string> = {
  [METRIC_KEY_TOTAL_PLAYS]: 'Number of times your videos have been played.',
  [METRIC_KEY_COMPLETIONS]:
    'Number of times your videos have been completed (over 90% progress or marked as complete).',
  [METRIC_KEY_AVERAGE_WATCHED]: 'The average watched percentage of your videos.',
  [METRIC_KEY_MINUTES]: 'The total number of minutes watched.',
  [METRIC_KEY_SCHEDULED]: 'Total number of times a video has been scheduled in the Calendar.',
  [METRIC_KEY_COMMENTS]: 'Total number of comments made in your app.',
  [METRIC_KEY_SAVED_PLAYLIST]: 'Total number of videos saved to a playlist i.e. Favorites, Watch Later, etc.',
  [METRIC_KEY_ENGAGED_USERS]: 'Total number of engaged users that have taken an action.',
};
