import { useQuery } from 'react-query';
import { AnalyticsTotals, getAnalyticsTotals, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useAnalyticsTotals = (
  type: 'keyMetrics' | 'viewershipMetrics' | 'engagementMetrics' | 'otherMetrics',
  startDate: Date,
  endDate: Date,
) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  return useQuery<AnalyticsTotals>(
    ['analyticsTotals', appId, type, startDate, endDate],
    () => getAnalyticsTotals(type, client, appId, startDate, endDate),
    {
      enabled: !!appId, // Don't call fetcher with empty AppId
    },
  );
};
