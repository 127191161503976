import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { deleteBuilderEvent, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

export const useDeleteBuilderEvent = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, { type: string; id: number }>(
    (event) => {
      return deleteBuilderEvent(client, appId, event.id);
    },
    {
      onSuccess: async (_response, payload) => {
        queryClient.invalidateQueries(['builderEvents', appId]);
        recordAction.mutate({ action: 'Delete Builder Event', detail: { event: payload.type, id: payload.id } });
      },
    },
  );
};
