import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { saveBuilderEvent, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

export const useSaveBuilderEvent = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, string>(
    (eventType) => {
      return saveBuilderEvent(client, appId, eventType);
    },
    {
      onSuccess: async (_response, payload) => {
        queryClient.invalidateQueries(['builderEvents', appId]);
        recordAction.mutate({ action: 'Save Builder Event', detail: { event: payload } });
      },
    },
  );
};
