import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { createVideo, useAxiosInstance, VideoToSave } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

interface CreateVideoProps {
  invalidateQuery: boolean;
  refresh: boolean;
  saveActionLog: boolean;
}

export const useCreateVideo = ({ invalidateQuery = true, refresh = true, saveActionLog = true }: CreateVideoProps) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse<{ Video: number }>, AxiosError, VideoToSave>(
    (videoToCreate) => {
      return createVideo(client, appId, videoToCreate, refresh);
    },
    {
      onSuccess: async (_response, payload) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['videos', appId]);
        }
        if (saveActionLog) {
          recordAction.mutate({ action: 'Create Video', detail: { createVideo: payload } });
        }
      },
    },
  );
};
