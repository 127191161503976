import { useCallback, useMemo, useState } from 'react';

import { InfoModal } from 'components';
import { BuildOrSubmitOptions, refreshContentCache, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { usePublishContext, useUnsavedChanges } from 'providers';
import {
  useAppBasicInfo,
  useAppProperties,
  useBuildOrSubmitApp,
  useSaveAppProperties,
  useUpdateAppleIAPs,
} from 'hooks';

import { useMandatoryFields } from './useMandatoryFields';

export const useAdminActions = () => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const { data: appProperties } = useAppProperties({ staleTime: Infinity });
  const { data: appBasicInfo } = useAppBasicInfo(Infinity);
  const saveAppProperties = useSaveAppProperties();
  const buildOrSubmitApp = useBuildOrSubmitApp();
  const updateAppleIAPs = useUpdateAppleIAPs();
  const { handlePublishApp } = usePublishContext();
  const { unsavedChanges } = useUnsavedChanges();
  const { validateMandatoryFields } = useMandatoryFields(appBasicInfo?.DataSource);
  const [branchModalAction, setBranchModalAction] = useState<
    { options: BuildOrSubmitOptions; successMessage: string } | undefined
  >(undefined);

  const ADMIN_ACTIONS = useMemo(
    () => [
      { value: 'publish-to-preview', label: 'Publish to preview' },
      { value: 'publish-to-live-apps', label: 'Publish to live apps' },
      { value: 'refresh-content-cache', label: 'Refresh content cache for Builder' },
      {
        value: 'disable-publish-to-live-apps',
        label: `${appProperties?.LivePublishingDisabled === '1' ? 'Enable' : 'Disable'} 'Publish to Live Apps' button`,
      },
      { value: 'ios-add-to-publish-queue', label: 'iOS - Update app in TestFlight' },
      { value: 'ios-add-to-submit-queue', label: 'iOS - Submit to app store' },
      { value: 'ios-update-iap', label: 'iOS - Update in-app purchases' },
      { value: 'ios-add-to-publish-queue-on-branch', label: 'iOS - Update app in TestFlight (special release branch)' },
      { value: 'ios-update-push-notification-certificates', label: 'iOS - Update push notification certificates' },
      { value: 'tvos-add-to-publish-queue', label: 'tvOS - Update app in TestFlight' },
      { value: 'tvos-add-to-submit-queue', label: 'tvOS - Submit to app store' },
      { value: 'android-create-apk', label: 'Android - Create APK / update beta version' },
      { value: 'android-create-apk-on-branch', label: 'Android - Create APK from branch' },
      { value: 'android-update-app-store', label: 'Android - Update app store' },
      { value: 'android-add-to-submit-queue', label: 'Android - Submit to play store' },
      { value: 'android-tv-create-apk-on-branch', label: 'Android TV - Create from branch' },
      { value: 'roku-build-zip', label: 'Roku - Build ZIP' },
    ],
    [appProperties],
  );

  const handleBuildOrSubmit = useCallback(
    (options: BuildOrSubmitOptions, successMessage: string) => {
      if (validateMandatoryFields(options.platform, options.stage, options.repository)) {
        buildOrSubmitApp.mutate(options, {
          onError: (err) => {
            InfoModal(
              'Something went wrong',
              `${
                options.stage === 'Notification'
                  ? 'Updating push notification certificates'
                  : 'Preview'
                  ? 'App build'
                  : 'App submission'
              } failed, please try again or contact support`,
              'error',
            );
            console.error(err);
          },
        });
        InfoModal('Success', successMessage, 'success');
      }
    },
    [validateMandatoryFields, buildOrSubmitApp],
  );

  const toggleLiveAppPublishing = useCallback(() => {
    saveAppProperties.mutateAsync([
      { Name: 'LivePublishingDisabled', Value: appProperties?.LivePublishingDisabled === '1' ? '0' : '1' },
    ]);
  }, [appProperties, saveAppProperties]);

  const handleAdminAction = useCallback(
    (value: string) => {
      switch (value) {
        case 'ios-add-to-publish-queue':
          handleBuildOrSubmit(
            { platform: 'iOS', stage: 'Preview', repository: 'monorepo' },
            'A new version of your app will be uploaded to TestFlight shortly',
          );
          break;
        case 'ios-add-to-submit-queue':
          setBranchModalAction({
            options: { platform: 'iOS', stage: 'Live' },
            successMessage: 'Your app is being submitted to Apple for approval',
          });
          break;
        case 'ios-add-to-publish-queue-on-branch':
          setBranchModalAction({
            options: { platform: 'iOS', stage: 'Preview' },
            successMessage: 'A new version of your app will be uploaded to TestFlight shortly',
          });
          break;
        case 'ios-update-push-notification-certificates':
          setBranchModalAction({
            options: { platform: 'iOS', stage: 'Notification' },
            successMessage: 'The push notification certificates will be updated',
          });
          break;
        case 'tvos-add-to-publish-queue':
          handleBuildOrSubmit(
            { platform: 'tvOS', stage: 'Preview' },
            'A new version of your app will be uploaded to TestFlight shortly',
          );
          break;
        case 'tvos-add-to-submit-queue':
          handleBuildOrSubmit({ platform: 'tvOS', stage: 'Live' }, 'Your app is being submitted to Apple for approval');
          break;
        case 'android-create-apk':
          handleBuildOrSubmit(
            { platform: 'Android', stage: 'Preview', repository: 'monorepo' },
            "Your APK is being created. The APK will be sent to the 'app-builds' slack channel once it's ready",
          );
          break;
        case 'android-create-apk-on-branch':
          setBranchModalAction({
            options: { platform: 'Android', stage: 'Preview' },
            successMessage:
              "Your APK is being created. The APK will be sent to the 'app-builds' slack channel once it's ready",
          });
          break;
        case 'android-tv-create-apk-on-branch':
          setBranchModalAction({
            options: { platform: 'AndroidTV', stage: 'Preview', repository: 'monorepo' },
            successMessage:
              "Your APK is being created. The APK will be sent to the 'app-builds' slack channel once it's ready",
          });
          break;
        case 'android-update-app-store':
          handleBuildOrSubmit(
            { platform: 'Android', stage: 'Live' },
            "The Android app store is being updated! You'll receive an email once it's ready",
          );
          break;
        case 'android-add-to-submit-queue':
          handleBuildOrSubmit(
            { platform: 'Android', stage: 'Live', repository: 'monorepo' },
            'Your app is being submitted to Google for approval',
          );
          break;
        case 'roku-build-zip':
          handleBuildOrSubmit(
            { platform: 'Roku', stage: 'Live' },
            "The ZIP will be sent to the 'app-builds' slack channel in 1-2 minutes",
          );
          break;
        case 'refresh-content-cache':
          refreshContentCache(client, appId);
          break;
        case 'publish-to-preview':
          handlePublishApp('Preview', unsavedChanges);
          break;
        case 'publish-to-live-apps':
          handlePublishApp('Live');
          break;
        case 'disable-publish-to-live-apps':
          toggleLiveAppPublishing();
          break;
        case 'ios-update-iap':
          updateAppleIAPs.mutate(null, {
            onSuccess: async () => {
              InfoModal('Success', 'Apple in-app purchases are being updated', 'success');
            },
            onError: (err) => {
              InfoModal(
                'Something went wrong updating Apple in-app purchases',
                'Please try again or contact support for help',
                'error',
              );
              console.error(err);
            },
          });
          break;
      }
    },
    [
      client,
      appId,
      setBranchModalAction,
      handleBuildOrSubmit,
      handlePublishApp,
      toggleLiveAppPublishing,
      updateAppleIAPs,
    ],
  );

  return {
    ADMIN_ACTIONS,
    livePublishingDisabled: appProperties?.LivePublishingDisabled === '1',
    branchModalAction,
    setBranchModalAction,
    handleAdminAction,
    handlePublishApp,
    handleBuildOrSubmit,
  };
};
