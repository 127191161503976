import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  METRIC_KEY_AVERAGE_WATCHED,
  METRIC_KEY_COMPLETIONS,
  METRIC_KEY_TOTAL_PLAYS,
  METRIC_KEY_UNIQUE_COMPLETIONS,
  METRIC_KEY_UNIQUE_PLAYS,
  Video,
} from 'api';
import { CustomTableFixedColumn, TableColumn } from 'components';
import { secondsToTime } from 'utils';
import { NEUTRAL_9_COLOUR } from 'theme';
import { OVERFLOW_ELLIPSIS } from 'font';

import { METRIC_LABELS } from '../../const';

const Cell = styled.div`
  padding: 5px 2px 5px 12px;
  height: 55px;
  display: flex;
  align-items: center;
  width: 100%;
  color: ${NEUTRAL_9_COLOUR};
`;

const Ellipsis = styled.span`
  ${OVERFLOW_ELLIPSIS};
`;

export interface VideoWithAnalytics extends Video {
  Duration?: string;
  [METRIC_KEY_AVERAGE_WATCHED]?: string;
  [METRIC_KEY_UNIQUE_PLAYS]?: number;
  [METRIC_KEY_UNIQUE_COMPLETIONS]?: number;
  [METRIC_KEY_TOTAL_PLAYS]?: number;
  [METRIC_KEY_COMPLETIONS]?: number;
  TotalWatched?: number;
}

export interface VideoAnalyticsTableProps {
  dataSource?: Partial<VideoWithAnalytics>[];
  isLoading?: boolean;
}

type TableData = Pick<
  VideoWithAnalytics,
  | 'Title'
  | 'SourceId'
  | 'Duration'
  | 'plays'
  | 'unique_plays'
  | 'completions'
  | 'unique_completions'
  | 'average_completion'
  | 'TotalWatched'
>;

export const VideoAnalyticsTable = ({ dataSource, isLoading }: VideoAnalyticsTableProps) => {
  const [data, setData] = useState<TableData[]>();

  useEffect(() => {
    if (dataSource) {
      setData(
        dataSource
          .map(
            ({
              Title,
              SourceId,
              Duration,
              plays,
              unique_plays,
              completions,
              unique_completions,
              average_completion,
              TotalWatched,
            }) => ({
              Title: Title ?? '',
              SourceId: SourceId ?? '',
              Duration,
              plays,
              unique_plays,
              completions,
              unique_completions,
              average_completion,
              TotalWatched,
            }),
          )
          .sort((a, b) => (b[METRIC_KEY_TOTAL_PLAYS] ?? 0) - (a[METRIC_KEY_TOTAL_PLAYS] ?? 0)),
      );
    }
  }, [dataSource]);

  const columns: TableColumn<TableData & { id: string }>[] = [
    {
      heading: 'Title',
      width: 300,
      sorter: (a, b) => a.Title.localeCompare(b.Title),
      sorterKey: 'Title',
      render: ({ Title }) => <Cell>{Title}</Cell>,
    },
    {
      heading: 'Source ID',
      width: 112,
      sorter: (a, b) => (a.SourceId ?? '').localeCompare(b.SourceId ?? ''),
      sorterKey: 'SourceId',
      render: ({ SourceId }) => (
        <Cell>
          <Ellipsis>{SourceId}</Ellipsis>
        </Cell>
      ),
    },
    {
      heading: 'Video Duration',
      width: 147,
      sorter: (a, b) =>
        (a.Duration ? parseInt(a.Duration.replaceAll(':', '')) : 0) -
        (b.Duration ? parseInt(b.Duration.replaceAll(':', '')) : 0),
      sorterKey: 'Duration',
      initialSortOrder: 'descend',
      render: ({ Duration }) => (
        <Cell>
          <Ellipsis>{Duration}</Ellipsis>
        </Cell>
      ),
    },
    {
      heading: METRIC_LABELS[METRIC_KEY_TOTAL_PLAYS],
      width: 120,
      sorter: (a, b) => (a[METRIC_KEY_TOTAL_PLAYS] ?? 0) - (b[METRIC_KEY_TOTAL_PLAYS] ?? 0),
      sorterKey: METRIC_KEY_TOTAL_PLAYS,
      initialSortOrder: 'descend',
      render: ({ plays }) => (
        <Cell>
          <Ellipsis>{plays}</Ellipsis>
        </Cell>
      ),
    },
    {
      heading: METRIC_LABELS[METRIC_KEY_UNIQUE_PLAYS],
      width: 133,
      sorter: (a, b) => (a[METRIC_KEY_UNIQUE_PLAYS] ?? 0) - (b[METRIC_KEY_UNIQUE_PLAYS] ?? 0),
      sorterKey: METRIC_KEY_UNIQUE_PLAYS,
      initialSortOrder: 'descend',
      render: ({ unique_plays }) => (
        <Cell>
          <Ellipsis>{unique_plays}</Ellipsis>
        </Cell>
      ),
    },
    {
      heading: METRIC_LABELS[METRIC_KEY_COMPLETIONS],
      width: 131,
      sorter: (a, b) => (a[METRIC_KEY_COMPLETIONS] ?? 0) - (b[METRIC_KEY_COMPLETIONS] ?? 0),
      sorterKey: METRIC_KEY_COMPLETIONS,
      initialSortOrder: 'descend',
      render: ({ completions }) => (
        <Cell>
          <Ellipsis>{completions}</Ellipsis>
        </Cell>
      ),
    },
    {
      heading: METRIC_LABELS[METRIC_KEY_UNIQUE_COMPLETIONS],
      width: 182,
      sorter: (a, b) => (a[METRIC_KEY_UNIQUE_COMPLETIONS] ?? 0) - (b[METRIC_KEY_UNIQUE_COMPLETIONS] ?? 0),
      sorterKey: METRIC_KEY_UNIQUE_COMPLETIONS,
      initialSortOrder: 'descend',
      render: ({ unique_completions }) => (
        <Cell>
          <Ellipsis>{unique_completions}</Ellipsis>
        </Cell>
      ),
    },
    {
      heading: 'Ave. Watched',
      width: 139,
      sorter: (a, b) =>
        parseFloat(a[METRIC_KEY_AVERAGE_WATCHED] ?? '0') - parseFloat(b[METRIC_KEY_AVERAGE_WATCHED] ?? '0'),
      sorterKey: METRIC_KEY_AVERAGE_WATCHED,
      initialSortOrder: 'descend',
      render: ({ average_completion }) => (
        <Cell>
          <Ellipsis>{average_completion}</Ellipsis>
        </Cell>
      ),
    },
    {
      heading: 'Est. Total Watched',
      width: 172,
      sorter: (a, b) => (a.TotalWatched ?? 0) - (b.TotalWatched ?? 0),
      sorterKey: 'TotalWatched',
      initialSortOrder: 'descend',
      render: ({ TotalWatched }) => (
        <Cell>
          <Ellipsis>{TotalWatched ? secondsToTime(TotalWatched, { format: 'h_min' }) : ''}</Ellipsis>
        </Cell>
      ),
    },
  ];

  return (
    <CustomTableFixedColumn
      data={data?.map((item) => ({ ...item, id: item.SourceId }))}
      columns={columns}
      pageSize={20}
      emptyTitle=""
      emptyDescription="No content"
      defaultSortKey={METRIC_KEY_TOTAL_PLAYS}
      defaultSortOrder="descend"
      isLoading={isLoading}
    />
  );
};
