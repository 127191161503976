import { useQuery } from 'react-query';

import { DateIncrement, getAnalyticsMetricByDate, MetricKey, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useAnalyticsMetricByDate = (metric: MetricKey, startDate: Date, endDate: Date, byDate: DateIncrement) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  return useQuery(
    ['videoAnalyticsMetricByDate', appId, metric, startDate, endDate, byDate],
    () => getAnalyticsMetricByDate(client, appId, metric, startDate, endDate, byDate),
    {
      enabled: !!appId, // Don't call fetcher with empty AppId
    },
  );
};
