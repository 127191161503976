import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useContent } from 'app/modules/build-dragdrop/Builder/providers';

import { useUnsavedChanges } from 'providers/index';
import { AppBasicInfoToSave, AppPropertyToSave, CollectionItem, Resource } from 'api';

import {
  useCreateCollection,
  useCreateVideo,
  useDeleteCollection,
  useDeleteProduct,
  useRecordAction,
  useSaveAppBasicInfo,
  useSaveAppProperties,
  useSaveCollection,
  useSaveCollectionItems,
  useSaveCollectionProperties,
  useSaveProduct,
  useSaveVideo,
  useSaveVideoProperties,
} from 'hooks';
import { useLocalProducts } from '../local-products-provider';
import { useQueryClient } from 'react-query';
import { useAppBeingEdited } from 'app-context';
import { useRefreshContent } from 'hooks/useRefreshContent';
import { useLocation } from 'react-router-dom';
import {
  ChangeToSave,
  CollectionValue,
  LogDetails,
  ProductValue,
  SaveOutput,
  SetAppBasicInfoValueToSave,
  SetAppPropertyToSave,
  SetCollectionItemsToSave,
  SetCollectionPropertyToSave,
  SetCollectionToDelete,
  SetCollectionToSave,
  SetCollectionValueToSave,
  SetProductToDelete,
  SetProductToSave,
  SetProductValueToSave,
  SetVideoPropertyToSave,
  SetVideoToSave,
  SetVideoValueToSave,
  VideoValue,
} from './save-types';
import {
  addAppPropertiesLogDetails,
  addCollectionLogDetails,
  addProductLogDetails,
  addVideoLogDetails,
  getSaveLogName,
} from './save-util';

export interface ContextValue {
  getTempId: () => number;
  entitiesToSave: (ChangeToSave | AppPropertyToSave)[];
  setCollectionToSave: SetCollectionToSave; // Save a new collection
  setCollectionValueToSave: SetCollectionValueToSave; // Save a single value on a collection
  setCollectionItemsToSave: SetCollectionItemsToSave;
  setCollectionPropertyToSave: SetCollectionPropertyToSave;
  setCollectionToDelete: SetCollectionToDelete;
  setAppPropertyToSave: SetAppPropertyToSave;
  setAppBasicInfoValueToSave: SetAppBasicInfoValueToSave;
  setVideoToSave: SetVideoToSave;
  setVideoValueToSave: SetVideoValueToSave;
  setVideoPropertyToSave: SetVideoPropertyToSave;
  setProductToSave: SetProductToSave; // Save a new product
  setProductValueToSave: SetProductValueToSave;
  setProductToDelete: SetProductToDelete;
  discardChangesToSave: () => void;
  isSaving: boolean;
  saveChanges: () => Promise<SaveOutput>;
}

export interface ProviderProps {
  children: ReactNode[] | ReactNode;

  // After saving force subsequent pages to reload Content API
  // This is the preferred behaviour but some pages do not behave correctly after this happens
  resetCacheOnSave?: boolean;
}

// These properties of a collection can go through on a new collection

const SAVEABLE_THUMBNAIL_PROPERTIES = [
  'SourceThumbnailSource',
  'ThumbnailSource',
  'SourcePortraitThumbnailSource',
  'PortraitThumbnailSource',
  'SourceSquareThumbnailSource',
  'SquareThumbnailSource',
];

const SAVEABLE_COLLECTION_PROPERTIES = [
  ...SAVEABLE_THUMBNAIL_PROPERTIES,
  'Name',
  'TemplateId',
  'IsMainTab',
  'Position',
  'Description',
  'PreviewVideoId',
  'InstructionalVideoId',
  'Type',
  'SourceType',
  'DataSource',
  'SourceId',
  'SourceName',
  'SourceDescription',
  'ItemCount',
  'ModularViewDisplayLimit',
  'DisplayInTVApp',
  'IncludedInAppData',
  'HideFromSearchResults',
  'Published',
  'Tag',
  'Resources',
  'AppId',
  'NavBarTitleText',
  'Icon',
];

// These properties of a video can go through on a new video
const SAVEABLE_VIDEO_PROPERTIES = [
  ...SAVEABLE_THUMBNAIL_PROPERTIES,
  'Title',
  'Description',
  'PreviewVideoId',
  'Type',
  'SourceType',
  'DataSource',
  'SourceId',
  'SourceName',
  'SourceDescription',
  'IncludedInAppData',
  'HideFromSearchResults',
  'Published',
  'ProductId',
  'Tag',
  'Resources',
  'OriginalFilename',
  'DurationSeconds',
];

// These properties of a product can go through on a new product
const SAVEABLE_PRODUCT_PROPERTIES = [
  'AppId',
  'IsIndividualPurchase',
  'ProductType',
  'ProductId',
  'DataSource',
  'CustomDisplayName',
  'SourceProductId',
  'SubscriptionGroup',
  'Items',
  'DisplayName',
  'ReferenceName',
  'Description',
  'ReadyForSale',
  'AvailableForLogin',
  'PriceTier',
  'SubscriptionDuration',
  'SubscriptionTrial',
];

const isTempId = (id: number | string) => {
  return isNaN(id as number);
};

// Reuse the code for setting a property for a collection vs setting a tab property
const setPropertyToSave = (
  setChanges: Dispatch<SetStateAction<ChangeToSave[]>>,
  type: ChangeToSave['type'],
  {
    id,
    name,
    value,
  }: {
    id: string | number;
    name: string;
    value: CollectionValue['value'] | VideoValue['value'] | ProductValue['value'];
  },
) => {
  setChanges((prev) => {
    const idx = prev.findIndex((ets) => ets.type === type && ets.id === id.toString());
    if (idx > -1) {
      // Manipulate existing record
      const newEntities = [...prev];
      const entity = newEntities[idx];
      newEntities[idx] = { ...entity, properties: { ...entity.properties, [name]: value } };
      return newEntities;
    } else {
      // Append new record
      return [
        ...prev,
        {
          id: id.toString(),
          type,
          properties: { [name]: value },
        },
      ];
    }
  });
};

const defaultFunction = () => {
  console.warn('Unexpected function call save-provider');
};

const SaveContext = createContext<ContextValue>({
  entitiesToSave: [],
  setCollectionToSave: defaultFunction,
  setCollectionValueToSave: defaultFunction,
  setCollectionItemsToSave: defaultFunction,
  setCollectionPropertyToSave: defaultFunction,
  setCollectionToDelete: defaultFunction,
  setAppPropertyToSave: defaultFunction,
  setAppBasicInfoValueToSave: defaultFunction,
  setVideoToSave: defaultFunction,
  setVideoValueToSave: defaultFunction,
  setVideoPropertyToSave: defaultFunction,
  setProductToSave: defaultFunction,
  setProductValueToSave: defaultFunction,
  setProductToDelete: defaultFunction,
  discardChangesToSave: defaultFunction,
  getTempId: () => -1,
  isSaving: false,
  saveChanges: defaultFunction as () => Promise<SaveOutput>,
});

const SAVE_HOOK_OPTIONS = { invalidateQuery: false, refresh: false, saveActionLog: false };

const SaveProvider = ({ children, resetCacheOnSave }: ProviderProps) => {
  const location = useLocation();
  const recordAction = useRecordAction();
  // Hooks for updating/saving changes
  const saveCollectionItems = useSaveCollectionItems(SAVE_HOOK_OPTIONS);
  const saveCollection = useSaveCollection(SAVE_HOOK_OPTIONS);
  const createCollection = useCreateCollection(SAVE_HOOK_OPTIONS);
  const saveCollectionProperties = useSaveCollectionProperties(SAVE_HOOK_OPTIONS);
  const deleteCollection = useDeleteCollection(SAVE_HOOK_OPTIONS);

  const createVideo = useCreateVideo(SAVE_HOOK_OPTIONS);
  const saveVideo = useSaveVideo(SAVE_HOOK_OPTIONS);
  const saveVideoProperties = useSaveVideoProperties(SAVE_HOOK_OPTIONS);

  const saveAppProperties = useSaveAppProperties({ saveActionLog: false });
  const saveAppBasicInfo = useSaveAppBasicInfo({ saveActionLog: false });
  const saveProduct = useSaveProduct({ saveActionLog: false });
  const deleteProduct = useDeleteProduct({ saveActionLog: false });
  const refreshContent = useRefreshContent();
  const queryClient = useQueryClient();
  const appId = useAppBeingEdited();

  // Make changes according to the local state
  const { collections, setCollections, setVideos } = useContent();
  const { products, setProducts } = useLocalProducts();

  // Tracking of changes to be saved
  const [collectionChangesToSave, setCollectionChangesToSave] = useState<ChangeToSave[]>([]);
  const [appPropertiesToSave, setAppPropertiesToSave] = useState<AppPropertyToSave[]>([]);
  const [appBasicInfoToSave, setAppBasicInfoToSave] = useState<AppBasicInfoToSave>({});
  const [videoChangesToSave, setVideoChangesToSave] = useState<ChangeToSave[]>([]);
  const [productChangesToSave, setProductChangesToSave] = useState<ChangeToSave[]>([]);

  const [saveInProgress, setSaveInProgress] = useState(false);

  // Generate unique temporary IDs for unsaved content
  const tempCounter = useRef(0);

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    setUnsavedChanges(
      collectionChangesToSave.length > 0 ||
        appPropertiesToSave.length > 0 ||
        Object.entries(appBasicInfoToSave).length > 0 ||
        videoChangesToSave.length > 0 ||
        productChangesToSave.length > 0,
    );
  }, [collectionChangesToSave, appPropertiesToSave, appBasicInfoToSave, videoChangesToSave, productChangesToSave]);

  useEffect(() => {
    // All unsaved changes should be abandoned when SaveProvider is unmounted
    // This occurs on navigation
    return discardChangesToSave;
  }, []);

  /* Expose utility functions for marking things that are ready to be saved */
  // Collection
  const setCollectionValueToSave: SetCollectionValueToSave = (collectionId, name, value) => {
    console.debug(`Collection with id:${collectionId} will save ${name} as "${value}"`);
    setPropertyToSave(setCollectionChangesToSave, 'collection', { name, value, id: collectionId });
  };

  const setCollectionToSave: SetCollectionToSave = (collectionId, values) => {
    const saveValues = values.filter((v) => SAVEABLE_COLLECTION_PROPERTIES.includes(v.name));
    console.debug(`Collection with id:${collectionId} will save ${JSON.stringify(saveValues)}"`);
    for (const v of saveValues) {
      setPropertyToSave(setCollectionChangesToSave, 'collection', { ...v, id: collectionId });
    }
  };

  const setCollectionPropertyToSave: SetCollectionPropertyToSave = (collectionId, name, value) => {
    console.debug(`Collection with id:${collectionId} will save property ${name} as "${value}"`);
    setPropertyToSave(setCollectionChangesToSave, 'collection-properties', { name, value, id: collectionId });
  };

  const setCollectionItemsToSave: SetCollectionItemsToSave = (collectionId) => {
    console.debug(`Collection Items will be saved for collection:${collectionId}`);
    setCollectionChangesToSave((e) => {
      if (e) {
        const idx = e.findIndex((ets) => ets.type === 'collection-items' && ets.id === collectionId.toString());
        if (idx > -1) {
          return e; // Collection items already being saved
        }
        return [
          ...e,
          {
            type: 'collection-items',
            id: collectionId.toString(),
          },
        ];
      }
      return [{ type: 'collection-items', id: collectionId.toString() }];
    });
  };

  const setCollectionToDelete: SetCollectionToDelete = (collectionId) => {
    console.debug(`Collection with id:${collectionId} will be deleted"`);

    setCollectionChangesToSave((e) => {
      if (e) {
        // Remove any other saves for this collection
        const newEntities = e.filter((entity) => {
          return !(entity.id === collectionId.toString());
        });

        if (!isTempId(collectionId)) {
          // Insert the 'delete-collection' entity if it doesnt already exist
          const idx = newEntities.findIndex(
            (ets) => ets.type === 'delete-collection' && ets.id === collectionId.toString(),
          );
          if (idx == -1) {
            newEntities.push({
              type: 'delete-collection',
              id: collectionId.toString(),
            });
          }
        }
        return newEntities;
      }
      return isTempId(collectionId) ? [] : [{ type: 'delete-collection', id: collectionId.toString() }];
    });
  };

  // Video
  const setVideoToSave: SetVideoToSave = (videoId, values) => {
    const saveValues = values.filter((v) => SAVEABLE_VIDEO_PROPERTIES.includes(v.name));
    console.debug(`Video with id:${videoId} will save ${JSON.stringify(saveValues)}"`);
    for (const v of saveValues) {
      setPropertyToSave(setVideoChangesToSave, 'video', { ...v, id: videoId });
    }
  };

  const setVideoValueToSave: SetVideoValueToSave = (videoId, name, value) => {
    console.debug(`Video with id:${videoId} will save ${name} as "${value}"`);
    setPropertyToSave(setVideoChangesToSave, 'video', { name, value, id: videoId });
  };

  const setVideoPropertyToSave: SetVideoPropertyToSave = (videoId, name, value) => {
    console.debug(`Video with id:${videoId} will save property ${name} as "${value}"`);
    setPropertyToSave(setVideoChangesToSave, 'video-properties', { name, value, id: videoId });
  };

  // AppProperty
  const setAppPropertyToSave: SetAppPropertyToSave = (name, value) => {
    console.debug(`App will save property ${name} as "${value}"`);
    setAppPropertiesToSave((prev) => {
      const idx = prev.findIndex((p) => p.Name === name);
      if (idx > -1) {
        // Overwrite existing property to be saved
        const newProperties = [...prev];
        newProperties[idx] = { Name: name, Value: value };
        return newProperties;
      } else {
        // Append new record
        return [...prev, { Name: name, Value: value }];
      }
    });
  };

  // AppBasicInfo
  const setAppBasicInfoValueToSave: SetAppBasicInfoValueToSave = (name, value) => {
    console.debug(`App will save appBasicInfo ${name} as "${value}"`);
    setAppBasicInfoToSave((prev) => {
      const newObj = { ...prev };
      newObj[name] = value;
      return newObj;
    });
  };

  // Product
  const setProductToSave: SetProductToSave = (productId, values) => {
    const saveValues = values.filter((v) => SAVEABLE_PRODUCT_PROPERTIES.includes(v.name));
    console.debug(`Product with id:${productId} will save ${JSON.stringify(saveValues)}"`);
    for (const v of saveValues) {
      setPropertyToSave(setProductChangesToSave, 'product', { ...v, id: productId });
    }
  };

  const setProductValueToSave: SetProductValueToSave = (productId, name, value) => {
    console.debug(`Product with id:${productId} will save ${name} as "${value}"`);
    setPropertyToSave(setProductChangesToSave, 'product', { name, value, id: productId });
  };

  const setProductToDelete: SetProductToDelete = (productId) => {
    console.debug(`Product with id:${productId} will be deleted"`);

    setProductChangesToSave((e) => {
      if (e) {
        // Remove any other saves for this product
        const newEntities = e.filter((entity) => {
          return !(entity.id === productId.toString());
        });

        if (!isTempId(productId)) {
          // Insert the 'delete-product' entity if it doesnt already exist
          const idx = newEntities.findIndex((ets) => ets.type === 'delete-product' && ets.id === productId.toString());
          if (idx == -1) {
            newEntities.push({
              type: 'delete-product',
              id: productId.toString(),
            });
          }
        }
        return newEntities;
      }
      return isTempId(productId) ? [] : [{ type: 'delete-product', id: productId.toString() }];
    });
  };

  const getTempId = () => {
    const returnValue = tempCounter.current;
    tempCounter.current = returnValue + 1;
    return returnValue;
  };

  /*
   * The main save function that will save all pending changes
   */
  const saveChanges = async () => {
    setSaveInProgress(true);
    // Build up the action log details
    const logMessage = getSaveLogName(location.pathname);
    const logDetails: LogDetails = {};

    /* VIDEO CHANGES */
    // Map of temporary video ids to the newly created VideoId from the database
    // e.g { 'TempVideo0' : '123456' }
    const newVideos: Record<string, number> = {};
    const videosToRefresh = new Set<number>();

    const getPermanentVideoId = (id: string) => {
      if (id.startsWith('Temp')) {
        return newVideos[id];
      }
      return parseInt(id);
    };

    const isSavingVideos = videoChangesToSave.length > 0;
    const isSavingCollections = collectionChangesToSave.length > 0;

    const videoChanges: Record<ChangeToSave['type'], ChangeToSave[]> = {
      video: [],
      'video-properties': [],
    };
    videoChangesToSave.forEach((change) => {
      videoChanges[change.type].push(change);
    });

    // Handle new videos and videos updates first
    // Wait for new videos and updated videos to finish
    await Promise.all(
      videoChanges.video.map(async (change) => {
        if (change.properties) {
          if (change.id.startsWith('Temp')) {
            console.debug('Saving new video', change.properties);
            const resources = change.properties['Resources'] ? (change.properties['Resources'] as Resource[]) : [];
            // For new videos, only Title, Url and Type should be included in Resources
            const newVideo = {
              ...change.properties,
              Resources: resources.map((r) => ({
                Title: r.Title,
                Url: r.Url,
                Type: r.Type,
              })),
            };
            const response = await createVideo.mutateAsync(newVideo);
            if (response.data.Video) {
              videosToRefresh.add(response.data.Video);
            }
            newVideos[change.id] = response.data.Video; // Other saves for this video should use this id

            // Local state should reference the permanent id
            setVideos((prevVideos) => {
              const newVideos = { ...prevVideos };
              if (!newVideos[response.data.Video]) {
                newVideos[response.data.Video] = {
                  ...newVideos[change.id],
                  VideoId: response.data.Video,
                };
              }
              return newVideos;
            });
          } else {
            console.debug(`Saving video for video:${change.id}`, change.properties);
            const videoId = Number.parseInt(change.id);
            videosToRefresh.add(videoId);
            const updatedProperties = {
              ...change.properties,
            };

            if (change.properties['Resources']) {
              // Resources have been modified
              // Have to cast because resources have some fields unpopulated until created in backend
              updatedProperties['Resources'] = (change.properties['Resources'] as Resource[]).map((r) => {
                const updatedResource: Partial<Resource> = { ...r };
                // New resources will have a temporary ResourceId, this should be removed before saving as it will be assigned by BE
                if (typeof updatedResource.ResourceId === 'string' && updatedResource.ResourceId.startsWith('Temp')) {
                  delete updatedResource.ResourceId;
                }
                return updatedResource;
              }) as Resource[];
            }

            await saveVideo.mutateAsync({ videoId, properties: updatedProperties });
          }
        }
      }),
    );

    // Update video properties
    // Ensuring that video properties are saved against the permanent video id
    await Promise.all(
      videoChanges['video-properties'].map(async (change) => {
        const videoId = getPermanentVideoId(change.id);
        videosToRefresh.add(videoId);
        if (!videoId) {
          console.error('Unable to save video properties for', change.id);
          return;
        }
        if (change.properties) {
          console.debug(`Saving video properties for video:${change.id}`, change.properties);
          const propertiesToSave = Object.entries(change.properties).map(([key, value]) => {
            return { Name: key, Value: value.toString() };
          });
          await saveVideoProperties.mutateAsync({ videoId, properties: propertiesToSave });
        }
      }),
    );

    if (videoChangesToSave.length > 0) {
      addVideoLogDetails(logDetails, videoChangesToSave);
      setVideoChangesToSave([]);
      if (videosToRefresh) {
        await refreshContent.mutateAsync({ ids: Array.from(videosToRefresh), content: 'videos' });
      }
      if (resetCacheOnSave) {
        queryClient.setQueryData(['videos', appId], undefined);
      }
    }

    /* COLLECTION CHANGES  */
    // Map of temporary tab ids to the newly created TabId from the database
    // e.g { 'TempTabId0' : '123456' }
    const newCollections: Record<string, number> = {};
    const collectionsToRefresh = new Set<number>();

    const getPermanentCollectionId = (id: string) => {
      if (id.startsWith('Temp')) {
        return newCollections[id];
      }
      return parseInt(id);
    };

    const collectionChanges: Record<ChangeToSave['type'], ChangeToSave[]> = {
      collection: [],
      'collection-items': [],
      'collection-properties': [],
      'delete-collection': [],
    };
    collectionChangesToSave.forEach((change) => {
      collectionChanges[change.type].push(change);
    });

    // Handle new collections and collection updates first
    // Wait for new collections and updated collections to finish
    await Promise.all(
      collectionChanges.collection.map(async (change) => {
        // These occur first before items/properties
        if (change.properties) {
          if (change.id.startsWith('Temp')) {
            console.debug('Saving new collection', change.properties);
            const resources = change.properties['Resources'] ? (change.properties['Resources'] as Resource[]) : [];
            // For new collections, only Title, Url and Type should be included in Resources
            const newCollection = {
              ...change.properties,
              Resources: resources.map((r) => ({
                Title: r.Title,
                Url: r.Url,
                Type: r.Type,
              })),
            };
            const response = await createCollection.mutateAsync(newCollection);
            newCollections[change.id] = response.data.TabId; // Other saves for this collection should use this id
            if (response.data.TabId) {
              collectionsToRefresh.add(response.data.TabId);
            }

            // Local state should reference the permanent id
            setCollections((prevCollections) => {
              const newCollections = { ...prevCollections };
              if (!newCollections[response.data.TabId]) {
                newCollections[response.data.TabId] = {
                  ...newCollections[change.id],
                  TabId: response.data.TabId,
                };
              }
              return newCollections;
            });
          } else {
            console.debug(`Saving collection for collection:${change.id}`, change.properties);
            const collectionId = Number.parseInt(change.id);
            collectionsToRefresh.add(collectionId);
            const updatedProperties = {
              ...change.properties,
            };
            if (change.properties['Resources']) {
              // Resources have been modified
              // Have to cast because resources have some fields unpopulated until created in backend
              updatedProperties['Resources'] = (change.properties['Resources'] as Resource[]).map((r) => {
                const updatedResource: Partial<Resource> = { ...r };
                // New resources will have a temporary ResourceId, this should be removed before saving as it will be assigned by BE
                if (typeof updatedResource.ResourceId === 'string' && updatedResource.ResourceId.startsWith('Temp')) {
                  delete updatedResource.ResourceId;
                }
                return updatedResource;
              }) as Resource[];
            }

            await saveCollection.mutateAsync({
              collectionId,
              properties: updatedProperties as Record<string, CollectionValue['value']>,
            });
          }
        }
      }),
    );

    // Do collection item changes
    // We ensure all items in local state reflect the permanent collection id of new collections
    await Promise.all(
      collectionChanges['collection-items'].map(async (change) => {
        const collectionId = getPermanentCollectionId(change.id);
        collectionsToRefresh.add(collectionId);
        const localId = change.id.startsWith('Temp') ? change.id : parseInt(change.id);
        if (!collectionId) {
          console.error('Unable to save collection items for', change.id);
          return;
        }

        // Ensure the items being sent to the API do not refer to Temporary IDs
        const items = collections[localId].Items?.map((i) => {
          const newItem = { ...i };

          // Convert any new tab items to refer to the permanent TabId of the newly created collection
          // TabItem -> TabId
          if (isNaN(newItem.TabId as number)) {
            const newId = newCollections[newItem.TabId];
            if (newId) {
              newItem.TabId = newId;
            } else {
              console.warn(
                `Update items for collection ${localId}`,
                `Unable to find permanent id for ${newItem.TabId}`,
              );
            }
          }
          // TabItem -> ChildId
          if (isNaN(newItem.ChildId as number)) {
            const newId = newCollections[newItem.ChildId] ?? newVideos[newItem.ChildId];
            if (newId) {
              newItem.ChildId = newId;
            } else {
              console.warn(
                `Update items for collection ${localId}`,
                `Unable to find permanent id for ${newItem.ChildId}`,
              );
            }
          }
          return newItem;
        });

        // API doesnt need TabItemId to save collection items, better to strip them
        const strippedItems = items.map((item) => {
          const newItem = { ...item };
          if (newItem.TabItemId) {
            delete newItem.TabItemId;
          }
          return newItem;
        });

        // Update the local state to match the items sent to the API
        setCollections((prevCollections) => {
          const cols = { ...prevCollections };
          const oldCol = cols[collectionId];
          if (oldCol) {
            cols[collectionId] = { ...oldCol, Items: items };
          }
          return cols;
        });

        console.debug(`Saving collection items for collection:${change.id}`);
        await saveCollectionItems.mutateAsync({ collectionId, items: strippedItems as CollectionItem[] });
      }),
    );

    // Update collection properties
    // Ensuring that collection properties are saved against the permanent collection id
    await Promise.all(
      collectionChanges['collection-properties'].map(async (change) => {
        const collectionId = getPermanentCollectionId(change.id);
        collectionsToRefresh.add(collectionId);
        if (!collectionId) {
          console.error('Unable to save collection properties for', change.id);
          return;
        }
        if (change.properties) {
          console.debug(`Saving collection properties for collection:${change.id}`, change.properties);
          const propertiesToSave = Object.entries(change.properties).map(([key, value]) => {
            return { Name: key, Value: value.toString() };
          });
          await saveCollectionProperties.mutateAsync({ collectionId, properties: propertiesToSave });
        }
      }),
    );

    // Delete collections
    // Temporary collections are simply ignored as they do not exist on the server
    await Promise.all(
      collectionChanges['delete-collection'].map(async (change) => {
        if (!change.id.startsWith('Temp')) {
          console.debug('Deleting collection', change.id);
          const _id = parseInt(change.id);
          collectionsToRefresh.add(_id);
          await deleteCollection.mutateAsync(_id);
        }
      }),
    );
    if (collectionChangesToSave.length > 0) {
      addCollectionLogDetails(logDetails, collectionChangesToSave, collections);
      setCollectionChangesToSave([]);
      await refreshContent.mutateAsync({ ids: Array.from(collectionsToRefresh), content: 'collections' });
      if (resetCacheOnSave) {
        queryClient.setQueryData(['collections', appId], undefined);
      }
    }

    /* APP PROPERTY CHANGES */
    if (appPropertiesToSave.length > 0) {
      saveAppProperties.mutate(appPropertiesToSave);
      addAppPropertiesLogDetails(logDetails, appPropertiesToSave);
      setAppPropertiesToSave([]);
    }

    /* APP BASIC INFO CHANGES */
    if (Object.entries(appBasicInfoToSave).length > 0) {
      saveAppBasicInfo.mutate(appBasicInfoToSave);
      logDetails.appBasicInfo = appBasicInfoToSave;
      setAppBasicInfoToSave({});
    }

    /* PRODUCT CHANGES  */
    const productChanges: Record<ChangeToSave['type'], ChangeToSave[]> = {
      product: [],
      'delete-product': [],
    };
    productChangesToSave.forEach((change) => {
      productChanges[change.type].push(change);
    });

    // Handle new products and product updates first
    // Wait for new products and updated products to finish
    await Promise.all(
      productChanges.product.map(async (change) => {
        if (change.properties) {
          if (change.id.startsWith('Temp')) {
            console.debug('Saving new product', change.properties);
            const response = await saveProduct.mutateAsync(change.properties);

            // Local state should reference the permanent id
            setProducts((prevProducts) => {
              const newProducts = new Map(prevProducts);
              const oldProduct = newProducts.get(change.id);
              if (oldProduct) {
                newProducts.set(response.data.Id, {
                  ...oldProduct,
                  Id: response.data.Id,
                });
              }

              newProducts.delete(change.id);
              return newProducts;
            });
          } else {
            console.debug(`Saving product for product:${change.id}`, change.properties);
            const id = parseInt(change.id);
            saveProduct.mutate({
              ...change.properties,
              Id: id,
              ProductId: products?.get(id)?.ProductId,
            });
          }
        }
      }),
    );

    // Delete products
    // Temporary products are simply ignored as they do not exist on the server
    productChanges['delete-product'].forEach((change) => {
      if (!change.id.startsWith('Temp')) {
        console.debug('Deleting product', change.id);
        deleteProduct.mutate(parseInt(change.id));
      }
    });

    if (productChangesToSave.length > 0) {
      addProductLogDetails(logDetails, productChangesToSave);
      setProductChangesToSave([]);
    }

    if (isSavingVideos) {
      queryClient.invalidateQueries(['videos', appId]);
    }
    if (isSavingCollections) {
      queryClient.invalidateQueries(['collections', appId]);
    }

    // Save one log message for this save
    if (Object.keys(logDetails).length) {
      recordAction.mutate({ action: logMessage, detail: logDetails });
    }

    setSaveInProgress(false);

    return { getPermanentCollectionId };
  };

  const isSaving = useMemo(
    () =>
      saveInProgress ||
      saveCollectionItems.isLoading ||
      saveCollection.isLoading ||
      createCollection.isLoading ||
      createVideo.isLoading ||
      deleteCollection.isLoading ||
      saveCollectionProperties.isLoading ||
      saveAppProperties.isLoading ||
      saveAppBasicInfo.isLoading ||
      saveVideo.isLoading ||
      saveVideoProperties.isLoading ||
      saveProduct.isLoading ||
      deleteProduct.isLoading,
    [
      saveInProgress,
      saveCollectionItems.isLoading,
      saveCollection.isLoading,
      createCollection.isLoading,
      createVideo.isLoading,
      deleteCollection.isLoading,
      saveCollectionProperties.isLoading,
      saveAppProperties.isLoading,
      saveAppBasicInfo.isLoading,
      saveVideo.isLoading,
      saveVideoProperties.isLoading,
      saveProduct.isLoading,
      deleteProduct.isLoading,
    ],
  );

  const discardChangesToSave = () => {
    setCollectionChangesToSave([]);
    setVideoChangesToSave([]);
    setAppPropertiesToSave([]);
    setAppBasicInfoToSave({});
    setProductChangesToSave([]);
    setUnsavedChanges(false);
  };

  return (
    <SaveContext.Provider
      value={{
        entitiesToSave: [
          ...collectionChangesToSave,
          ...appPropertiesToSave,
          ...videoChangesToSave,
          ...productChangesToSave,
        ],
        setCollectionToSave,
        setCollectionValueToSave,
        setCollectionItemsToSave,
        setCollectionPropertyToSave,
        setCollectionToDelete,
        setAppPropertyToSave,
        setAppBasicInfoValueToSave,
        setVideoToSave,
        setVideoValueToSave,
        setVideoPropertyToSave,
        setProductToSave,
        setProductValueToSave,
        setProductToDelete,
        discardChangesToSave,
        getTempId,
        saveChanges,
        isSaving,
      }}
    >
      {children}
    </SaveContext.Provider>
  );
};

const useSaveContext = () => {
  const context = useContext(SaveContext);
  if (context === undefined) {
    throw new Error('useSaveContext must be used within a SaveContextProvider');
  }
  return context;
};

export { SaveProvider, useSaveContext };
