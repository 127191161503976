import {
  AnalyticsTotals,
  EngagementMetricKey,
  METRIC_KEY_AVERAGE_WATCHED,
  METRIC_KEY_COMMENTS,
  METRIC_KEY_COMPLETIONS,
  METRIC_KEY_ENGAGED_USERS,
  METRIC_KEY_MINUTES,
  METRIC_KEY_SAVED_PLAYLIST,
  METRIC_KEY_SCHEDULED,
  METRIC_KEY_TOTAL_PLAYS,
  OtherMetricKey,
  ViewershipMetricKey,
} from 'api';

const NA_LABEL = 'N/A';

export const getKeyAnalytics = (data?: AnalyticsTotals, disabledKeys?: (ViewershipMetricKey | EngagementMetricKey)[]) =>
  data
    ? {
        [METRIC_KEY_TOTAL_PLAYS]: data[METRIC_KEY_TOTAL_PLAYS]?.Plays,
        [METRIC_KEY_COMPLETIONS]: data[METRIC_KEY_COMPLETIONS]?.Completions,
        [METRIC_KEY_AVERAGE_WATCHED]: data[METRIC_KEY_AVERAGE_WATCHED]
          ? parseInt(data[METRIC_KEY_AVERAGE_WATCHED].AverageCompletion)
          : undefined,
        [METRIC_KEY_MINUTES]: data[METRIC_KEY_MINUTES]?.Minutes,
        [METRIC_KEY_SCHEDULED]: disabledKeys?.includes(METRIC_KEY_SCHEDULED)
          ? NA_LABEL
          : data[METRIC_KEY_SCHEDULED]?.Count,
        [METRIC_KEY_COMMENTS]: disabledKeys?.includes(METRIC_KEY_COMMENTS)
          ? NA_LABEL
          : data[METRIC_KEY_COMMENTS]?.Count,
        [METRIC_KEY_SAVED_PLAYLIST]: data[METRIC_KEY_SAVED_PLAYLIST]?.Count,
        [METRIC_KEY_ENGAGED_USERS]: data[METRIC_KEY_ENGAGED_USERS]?.Count,
      }
    : undefined;

export const getMetricType = (metricKey: ViewershipMetricKey | EngagementMetricKey | OtherMetricKey) => {
  if ([METRIC_KEY_SCHEDULED, METRIC_KEY_COMMENTS, METRIC_KEY_SAVED_PLAYLIST].includes(metricKey)) {
    return 'engagement';
  } else if (metricKey === METRIC_KEY_ENGAGED_USERS) {
    return 'engaged-users';
  }
  return 'viewership';
};

export const getMetricUrl = (
  appId: string,
  type: 'engagement' | 'engaged-users' | 'viewership',
  metric: string,
  params: string,
) => {
  const metricKey = metric === METRIC_KEY_SAVED_PLAYLIST ? 'add_watchlist,add_favourite,add_playlist_item' : metric;

  if (type === 'engagement') {
    return `/analytics/${appId}/events?event_type=${metricKey}&${params}`;
  } else if (type === 'engaged-users') {
    return `/analytics/${metricKey}?app_id=${appId}&${params}`;
  }
  return `/analytics/${appId}/${metric}?${params}`;
};
