import { useCallback } from 'react';
import styled from 'styled-components';

import { CustomButton, Dropdown, DropdownOptionInterface } from 'components';
import { FONT_10PX_SEMIBOLD, FONT_14PX_SEMIBOLD } from 'font';
import { useBuilderEvents, useDeleteBuilderEvent, useSaveBuilderEvent } from 'hooks';
import { ChevronDownIcon } from 'icons';
import { NEUTRAL_5_COLOUR, NEUTRAL_7_COLOUR, SUCCESS_COLOUR, WARNING_COLOUR } from 'theme';
import { isUserAdmin } from 'utils';

import {
  STEP_KEY_ANDROID_REVIEW_SUCCESSFUL,
  STEP_KEY_ANDROID_SUBMITTED,
  STEP_KEY_IOS_REVIEW_SUCCESSFUL,
  STEP_KEY_IOS_SUBMITTED,
} from '../const';
import {
  STATUS_IN_REVIEW,
  STATUS_PREPARING,
  STATUS_READY_TO_RELEASE,
  STATUS_REVIEW_SUCCESSFUL,
  STATUS_SUBMITTED,
  useOnboardingChecklistContext,
} from '../providers/OnboardingChecklistProvider';

const Container = styled.div<{ $marginBottom?: string }>`
  display: flex;
  flex-direction: column;

  width: 100%;
  ${({ $marginBottom }) => $marginBottom && `margin-bottom: ${$marginBottom}`};
`;

const Title = styled.div`
  ${FONT_14PX_SEMIBOLD};
  margin-bottom: 10px;
`;

const StatusRow = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
`;

const Status = styled.div`
  width: 32.75%;
`;

const StatusIndicator = styled.div<{ $isFirst?: boolean; $isLast?: boolean; $backgroundColor: string }>`
  width: 100%;
  height: 5px;
  margin-bottom: 10px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ $isFirst, $isLast }) => ($isFirst ? '5px 0 0 5px' : $isLast ? '0px 5px 5px 0px' : 0)};
`;

const DropdownButton = styled(CustomButton)<{ $isDisabled?: boolean }>`
  &&&& {
    ${FONT_10PX_SEMIBOLD};
    padding: 0;
    ${({ $isDisabled }) => $isDisabled && `color: ${NEUTRAL_7_COLOUR}`};
  }
`;

const DropdownLabel = styled.div<{ $isDisabled?: boolean }>`
  &&&& {
    display: flex;
    align-items: center;

    height: 22px;
    ${FONT_10PX_SEMIBOLD};
    ${({ $isDisabled }) => $isDisabled && `color: ${NEUTRAL_7_COLOUR}`};
  }
`;

const StyledChevron = styled(ChevronDownIcon)`
  &&&& {
    font-size: 16px;
  }
`;

const StatusDropdown = ({
  value,
  disabled,
  options,
}: {
  value: string;
  disabled?: boolean;
  options?: DropdownOptionInterface[];
}) => {
  if (!options || !isUserAdmin()) {
    return <DropdownLabel $isDisabled={disabled}>{value}</DropdownLabel>;
  }

  return (
    <Dropdown
      disabled={disabled}
      trigger={
        <DropdownButton
          small
          tertiary
          iconPosition="right"
          icon={<StyledChevron />}
          disabled={disabled}
          $isDisabled={disabled}
        >
          {value}
        </DropdownButton>
      }
      align="start"
      options={options}
      isOpen={disabled ? false : undefined}
    />
  );
};

export const SubmissionStatus = ({
  platform,
  title,
  marginBottom,
  isLocked,
}: {
  platform: 'iOS' | 'android';
  title: string;
  marginBottom?: string;
  isLocked: boolean;
}) => {
  const { submissionStatuses } = useOnboardingChecklistContext();

  const activeStatus = submissionStatuses[platform];

  const { data: builderEvents } = useBuilderEvents();
  const saveBuilderEvent = useSaveBuilderEvent();
  const deleteBuilderEvent = useDeleteBuilderEvent();

  const addStatus = useCallback(
    (key: string) => {
      const existingEvent = builderEvents?.find((e) => e.Type === key && !e.DeleteTs);

      if (!existingEvent) {
        saveBuilderEvent.mutate(key);
      }
    },
    [builderEvents],
  );

  const removeStatus = useCallback(
    (key: string) => {
      const existingEvent = builderEvents?.find((e) => e.Type === key && !e.DeleteTs);

      if (existingEvent) {
        deleteBuilderEvent.mutate({ type: key, id: existingEvent.ID });
      }
    },
    [builderEvents],
  );

  const updateStatus = useCallback(
    (status: string) => {
      const submitted = platform === 'iOS' ? STEP_KEY_IOS_SUBMITTED : STEP_KEY_ANDROID_SUBMITTED;
      const reviewSuccessful = platform === 'iOS' ? STEP_KEY_IOS_REVIEW_SUCCESSFUL : STEP_KEY_ANDROID_REVIEW_SUCCESSFUL;

      if (status === STATUS_REVIEW_SUCCESSFUL) {
        addStatus(reviewSuccessful);
      } else {
        removeStatus(reviewSuccessful);
        if (status === STATUS_PREPARING) {
          removeStatus(submitted);
        } else if (status === STATUS_SUBMITTED) {
          addStatus(submitted);
        }
      }
    },
    [platform, addStatus, removeStatus],
  );

  const getIndicatorColor = useCallback(
    (indicatorType: 'submit' | 'review' | 'release') => {
      switch (indicatorType) {
        case 'submit':
          return activeStatus === STATUS_PREPARING ? WARNING_COLOUR : SUCCESS_COLOUR;
        case 'review':
          return activeStatus === STATUS_IN_REVIEW
            ? WARNING_COLOUR
            : activeStatus === STATUS_READY_TO_RELEASE
            ? SUCCESS_COLOUR
            : NEUTRAL_5_COLOUR;
        case 'release':
          return activeStatus === STATUS_READY_TO_RELEASE ? SUCCESS_COLOUR : NEUTRAL_5_COLOUR;
      }
    },
    [activeStatus],
  );

  const getOptions = (options: string[]) =>
    options.map((o) => ({
      label: o,
      onClick: () => updateStatus(o),
    }));

  return (
    <Container $marginBottom={marginBottom}>
      <Title>{title}</Title>
      <StatusRow>
        <Status>
          <StatusIndicator $backgroundColor={getIndicatorColor('submit')} $isFirst />
          <StatusDropdown
            value={activeStatus === STATUS_PREPARING ? STATUS_PREPARING : STATUS_SUBMITTED}
            options={getOptions([STATUS_PREPARING, STATUS_SUBMITTED])}
            disabled={isLocked}
          />
        </Status>
        <Status>
          <StatusIndicator $backgroundColor={getIndicatorColor('review')} />
          <StatusDropdown
            value={activeStatus === STATUS_READY_TO_RELEASE ? STATUS_REVIEW_SUCCESSFUL : STATUS_IN_REVIEW}
            disabled={isLocked || activeStatus === STATUS_PREPARING}
            options={getOptions([STATUS_IN_REVIEW, STATUS_REVIEW_SUCCESSFUL])}
          />
        </Status>
        <Status>
          <StatusIndicator $backgroundColor={getIndicatorColor('release')} $isLast />
          <StatusDropdown
            value={STATUS_READY_TO_RELEASE}
            disabled={isLocked || activeStatus !== STATUS_READY_TO_RELEASE}
          />
        </Status>
      </StatusRow>
    </Container>
  );
};
