import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import {
  DEFAULT_COLLECTION,
  DEFAULT_COLLECTION_ITEM,
  GROUP_LABEL,
  ITEM_TYPE_COLLECTION,
  SOURCE_TYPE_COLLECTION,
} from 'api';
import { useAppBeingEdited } from 'app-context';
import { CustomButton, Dropdown } from 'components';
import { CollectionIcon, PlusIcon } from 'icons';
import { useContent, useSaveContext } from 'providers';
import { NEUTRAL_5_COLOUR, NEUTRAL_6_COLOUR } from 'theme';
import { getCollectionValuesFromNewCollection } from 'utils';

import { useModalContext } from '../../cms-modal/providers/modal-provider';

const StyledCollectionIcon = styled(CollectionIcon)`
  svg {
    fill: none;
  }
`;

export const TreeRowButton = styled(CustomButton)`
  &&&& {
    margin-left: 4px;

    :hover {
      background-color: ${NEUTRAL_5_COLOUR};
    }

    :active {
      background-color: ${NEUTRAL_6_COLOUR};
    }
  }
`;

const HeaderButton = styled(CustomButton)`
  &&&& {
    margin-left: 4px;
  }
`;

interface AddContentButtonProps {
  collectionId: string | number;
  isTreeRow?: boolean;
  onAction?: () => void;
  onOpenChange?: (open: boolean) => void;
}

export const AddContentButton = ({ collectionId, isTreeRow, onAction, onOpenChange }: AddContentButtonProps) => {
  const { setNewCollection, setCollectionItems, collections } = useContent();
  const { getTempId, setCollectionToSave, setCollectionItemsToSave } = useSaveContext();
  const appId = useAppBeingEdited();
  const { setModalCollectionId } = useModalContext();

  const collection = useMemo(() => collections[collectionId], [collections, collectionId]);

  const createNewCollection = useCallback(
    (sourceType: string) => {
      onAction && onAction();
      const tempId = `TempTab${getTempId()}`;
      const newCollection = {
        AppId: appId,
        ...DEFAULT_COLLECTION,
        TabId: tempId,
        TemplateId: 10,
        Position: 0,
        Name: `New ${sourceType === SOURCE_TYPE_COLLECTION ? GROUP_LABEL : sourceType}`,
        Thumbnails: {},
        SourceType: sourceType,
        Items: [],
        Resources: [],
        DisplayInTVApp: 1 as const,
        IncludedInAppData: 0 as const,
      };
      setNewCollection(tempId, newCollection);
      setCollectionToSave(tempId, getCollectionValuesFromNewCollection(newCollection));
      setCollectionItems(collection.TabId, [
        ...collection.Items,
        {
          ...DEFAULT_COLLECTION_ITEM,
          AppId: appId,
          TabId: collection.TabId,
          ChildId: tempId,
          Type: ITEM_TYPE_COLLECTION,
          SubType: ITEM_TYPE_COLLECTION,
          Position: collection.Items.length + 1,
          TabItemId: `TempTabItem${getTempId()}`,
        },
      ]);
      setCollectionItemsToSave(collection.TabId); // Set to be saved
    },
    [
      collection,
      appId,
      onAction,
      getTempId,
      setNewCollection,
      setCollectionToSave,
      setCollectionItems,
      setCollectionItemsToSave,
    ],
  );

  const isCollection = collection.SourceType === SOURCE_TYPE_COLLECTION;

  const dropdownTrigger = isTreeRow ? (
    <TreeRowButton icon={<PlusIcon />} small tertiaryHighlight onClick={(e) => e.stopPropagation()}>
      Add
    </TreeRowButton>
  ) : (
    <HeaderButton icon={<PlusIcon />} medium tertiaryHighlight>
      Add
    </HeaderButton>
  );

  return (
    <>
      {isCollection ? (
        <Dropdown
          trigger={dropdownTrigger}
          options={[
            {
              label: `New ${GROUP_LABEL}`,
              icon: <StyledCollectionIcon />,
              onClick: () => createNewCollection(SOURCE_TYPE_COLLECTION),
              keepMenuOpen: true,
            },
            {
              label: 'Content',
              icon: <PlusIcon />,
              onClick: () => {
                onAction && onAction();
                onOpenChange && onOpenChange(false);
                setModalCollectionId(collection.TabId);
              },
            },
          ]}
          menuMinWidth="198px"
          onOpenChange={onOpenChange}
        />
      ) : isTreeRow ? (
        <TreeRowButton
          icon={<PlusIcon />}
          small
          tertiaryHighlight
          onClick={(e) => {
            e.stopPropagation();
            onAction && onAction();
            setModalCollectionId(collection.TabId);
          }}
        >
          Add
        </TreeRowButton>
      ) : (
        <HeaderButton
          icon={<PlusIcon />}
          medium
          tertiaryHighlight
          onClick={() => setModalCollectionId(collection.TabId)}
        >
          Add
        </HeaderButton>
      )}
    </>
  );
};
