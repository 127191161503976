import { useQuery } from 'react-query';

import { ProgressTracking, getProgressTrackingAnalytics, useAxiosInstance } from 'api';
import { useAppBeingEdited } from '../../../../../app-context';

export const useProgressTracking = (startDate: Date, endDate: Date) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  return useQuery<ProgressTracking[]>(['progresstracking', appId, startDate, endDate], () => {
    return getProgressTrackingAnalytics(client, appId, startDate, endDate);
  });
};
