import styled, { css } from 'styled-components';

import { FONT_12PX_SEMIBOLD, FONT_14PX_MEDIUM } from 'font';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_1_COLOUR,
  NEUTRAL_2_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_5_COLOUR,
} from 'theme';
import { OnClick } from 'utils';
import { TableColumn } from '../CustomTable';
import { SettingsSelectInput } from 'components/Settings/SettingsSelectInput';
import { ChevronDownIcon, ChevronUpIcon } from 'icons';

export const StyledTable = styled.div<{ $width?: string; $minWidth?: string; $borderRadius?: string }>`
  background-color: ${NEUTRAL_1_COLOUR};
  border-radius: 8px 8px 0 0;
  border-radius: ${({ $borderRadius }) => $borderRadius || '8px 8px 0 0'};
  overflow: hidden;
  width: ${({ $width }) => $width || '100%'};
  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth}`};
`;

export const StyledTableHeader = styled.div`
  background-color: ${NEUTRAL_3_COLOUR};
  height: 40px;
`;
export const TableRow = styled.div`
  display: flex;
`;
export const StyledTableRow = styled(TableRow)<OnClick>`
  border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};

  :hover {
    background-color: ${({ onClick }) => !!onClick && NEUTRAL_2_COLOUR};
  }
`;

const getCellWidth = (width: string | number) => {
  if (width === 'grow') {
    return css`
      width: 100%;
      flex-grow: 1;
    `;
  }
  return css`
    min-width: ${width}px;
    width: ${width}px;
    max-width: ${width}px;
    flex-shrink: 0;
    flex-grow: 0;
  `;
};

export const TableCell = styled.div<{
  $width: TableColumn<unknown>['width'];
  $minWidth?: TableColumn<unknown>['minWidth'];
  $showColumnBorders?: boolean;
}>`
  &&&& {
    overflow: hidden;
    color: ${NEUTRAL_10_COLOUR};
    ${FONT_14PX_MEDIUM};
    ${({ $width }) => getCellWidth($width)};
    ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth}px`};
    ${({ $showColumnBorders }) => $showColumnBorders && `border-right: 1px solid ${NEUTRAL_5_COLOUR}`};
  }
`;
export const TableHeadCell = styled(TableCell)<{ $centered?: TableColumn<unknown>['centered']; $cursor?: string }>`
  &&&& {
    ${FONT_12PX_SEMIBOLD};
    padding: 12px;
    ${({ $centered }) => $centered && 'text-align: center'};
    display: flex;
    justify-content: space-between;
    cursor: ${({ $cursor }) => $cursor};
    user-select: none;
  }
`;
export const TableCheckboxCell = styled(TableCell)`
  display: flex;
  justify-content: center;
  padding-top: 11px;
`;

export const PaginationRow = styled.div`
  &&&& {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 54px;
    display: flex;
    justify-content: flex-end;
  }
`;
export const PaginationButton = styled.div<{ $selected: boolean; $disabled: boolean }>`
  height: 29px;
  width: 30px;
  margin-left: 4px;
  margin-right: 4px;

  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
    color: ${HIGHLIGHT_PRIMARY_COLOUR};
  }

  cursor: pointer;

  ${({ $selected }) =>
    $selected &&
    css`
      border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
      color: ${HIGHLIGHT_PRIMARY_COLOUR};
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      border-color: ${NEUTRAL_5_COLOUR};
      color: ${NEUTRAL_5_COLOUR};
      cursor: not-allowed;
    `}
`;

export const PageSizeSelector = styled(SettingsSelectInput)`
  &&&& {
    margin-left: 15px;
  }
`;

export const LoadingContainer = styled.div`
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableSortIconWrapper = styled.span`
  font-size: 18px;
  width: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TableSortIcon = ({ active, order }: { active: boolean; order?: 'ascend' | 'descend' }) => {
  const sortIcon = order === 'descend' ? <ChevronDownIcon /> : <ChevronUpIcon />;
  return <TableSortIconWrapper>{active && sortIcon}</TableSortIconWrapper>;
};
