import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  DateIncrement,
  METRIC_KEY_AVERAGE_WATCHED,
  METRIC_KEY_COMMENTS,
  METRIC_KEY_COMPLETIONS,
  METRIC_KEY_ENGAGED_USERS,
  METRIC_KEY_MINUTES,
  METRIC_KEY_SAVED_PLAYLIST,
  METRIC_KEY_SCHEDULED,
  METRIC_KEY_TOTAL_PLAYS,
  MetricGroup,
  MetricKey,
} from 'api';
import { CustomButton, Dropdown, SegmentTitle } from 'components';
import { ChevronDownIcon } from 'icons';

import { Card } from 'app/modules/analytics/components/';
import { METRIC_LABELS, METRIC_TOOLTIPS } from '../const';
import { useAnalyticsContext } from '../providers/AnalyticsProvider';
import { getScreenFromPathname } from 'utils';

interface MetricDetails {
  key: MetricKey;
  tooltip: string;
  isPercentage?: boolean;
}

const VISIBLE_METRICS: Record<MetricGroup, MetricDetails[]> = {
  keyMetrics: [
    { key: METRIC_KEY_TOTAL_PLAYS, tooltip: METRIC_TOOLTIPS[METRIC_KEY_TOTAL_PLAYS] },
    {
      key: METRIC_KEY_MINUTES,
      tooltip: METRIC_TOOLTIPS[METRIC_KEY_MINUTES],
    },
    {
      key: METRIC_KEY_SAVED_PLAYLIST,
      tooltip: METRIC_TOOLTIPS[METRIC_KEY_SAVED_PLAYLIST],
    },
    {
      key: METRIC_KEY_COMMENTS,
      tooltip: METRIC_TOOLTIPS[METRIC_KEY_COMMENTS],
    },
  ],
  viewershipMetrics: [
    { key: METRIC_KEY_TOTAL_PLAYS, tooltip: METRIC_TOOLTIPS[METRIC_KEY_TOTAL_PLAYS] },
    {
      key: METRIC_KEY_COMPLETIONS,
      tooltip: METRIC_TOOLTIPS[METRIC_KEY_COMPLETIONS],
    },
    {
      key: METRIC_KEY_AVERAGE_WATCHED,
      tooltip: METRIC_TOOLTIPS[METRIC_KEY_AVERAGE_WATCHED],
      isPercentage: true,
    },
    {
      key: METRIC_KEY_MINUTES,
      tooltip: METRIC_TOOLTIPS[METRIC_KEY_MINUTES],
    },
  ],
  engagementMetrics: [
    {
      key: METRIC_KEY_SAVED_PLAYLIST,
      tooltip: METRIC_TOOLTIPS[METRIC_KEY_SAVED_PLAYLIST],
    },
    {
      key: METRIC_KEY_COMMENTS,
      tooltip: METRIC_TOOLTIPS[METRIC_KEY_COMMENTS],
    },
    { key: METRIC_KEY_SCHEDULED, tooltip: METRIC_TOOLTIPS[METRIC_KEY_SCHEDULED] },
  ],
  otherMetrics: [
    {
      key: METRIC_KEY_ENGAGED_USERS,
      tooltip: METRIC_TOOLTIPS[METRIC_KEY_ENGAGED_USERS],
    },
  ],
};

const getRangeLabel = (range: number) => `Last ${range} Days`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const HeaderRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  div {
    width: auto;
  }
`;

const CardContainer = styled(Row)<{ $isDashboard?: boolean }>`
  margin-bottom: 24px;
  justify-content: space-between;
  flex-wrap: ${({ $isDashboard }) => ($isDashboard ? 'wrap' : 'nowrap')};
`;

const DropdownWrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(CustomButton)`
  #react-app && {
    margin-left: 8px;
    text-transform: capitalize;
  }
`;

export const KeyAnalytics = () => {
  const {
    activeMetric,
    activeMetricGroup,
    activeRange,
    keyAnalytics,
    setActiveRange,
    setStartDate,
    setEndDate,
    hideIncrementSelect,
    activeIncrement,
    setActiveIncrement,
  } = useAnalyticsContext();
  const today: Date = new Date();
  const { pathname } = useLocation();
  const screen = getScreenFromPathname(pathname) as string;
  const isDashboard = screen === 'dashboard';

  const handleRangeChange = useCallback(
    (value: number) => {
      // Make sure a valid increment is selected when range changes
      if (value === 7 && activeIncrement !== 'daily') {
        setActiveIncrement('daily');
      } else if (value === 30 && activeIncrement === 'monthly') {
        setActiveIncrement('weekly');
      }

      setActiveRange(value);

      const startDate = subDays(today, value);
      const endDate = subDays(today, 1);

      setStartDate(startOfDay(startDate));
      setEndDate(endOfDay(endDate));
    },
    [setStartDate, setEndDate, setActiveRange, activeIncrement, setActiveIncrement],
  );

  const incrementOptions = useMemo(() => {
    const options = [{ label: 'Daily', value: 'daily' }];

    if (activeRange > 7) {
      options.push({ label: 'Weekly', value: 'weekly' });
      if (activeRange > 30) {
        options.push({ label: 'Monthly', value: 'monthly' });
      }
    }

    return options;
  }, [activeRange]);

  // Comment/Playlist data only goes back to Sep 2023, so for now limit date range selection to 90 days for these metrics (and dashboard)
  const limitTo90Days = useMemo(
    () => !activeMetric || [METRIC_KEY_COMMENTS, METRIC_KEY_SAVED_PLAYLIST].includes(activeMetric),
    [activeMetric],
  );

  const rangeOptions = limitTo90Days ? [7, 30, 90] : [7, 30, 90, 365];

  useEffect(() => {
    if (limitTo90Days && activeRange > 90) {
      setActiveRange(90);
    }
  }, [activeMetric]);

  return (
    <>
      <HeaderRow>
        <SegmentTitle title="Key Analytics" $marginBottom="0" />
        <DropdownWrapper>
          {!hideIncrementSelect && (
            <Dropdown
              trigger={
                <StyledButton small secondary iconPosition="right" icon={<ChevronDownIcon />}>
                  {activeIncrement}
                </StyledButton>
              }
              options={incrementOptions.map(({ label, value }) => ({
                label,
                onClick: () => setActiveIncrement(value as DateIncrement),
                isActive: value === activeIncrement,
              }))}
            />
          )}
          <Dropdown
            trigger={
              <StyledButton small secondary iconPosition="right" icon={<ChevronDownIcon />}>
                {getRangeLabel(activeRange)}
              </StyledButton>
            }
            options={rangeOptions.map((range) => ({
              label: getRangeLabel(range),
              onClick: () => handleRangeChange(range),
              isActive: range === activeRange,
            }))}
          />
        </DropdownWrapper>
      </HeaderRow>
      <CardContainer $isDashboard={isDashboard}>
        {VISIBLE_METRICS[activeMetricGroup].map(({ key, tooltip, isPercentage }) => (
          <Card
            key={key}
            metric={key}
            title={METRIC_LABELS[key]}
            active={activeMetric === key}
            value={keyAnalytics && keyAnalytics[key]}
            isPercentage={isPercentage}
            tooltip={tooltip}
            isLoading={!keyAnalytics}
          />
        ))}
      </CardContainer>
    </>
  );
};
