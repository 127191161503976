import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { CustomButton, ConfirmModal } from 'components';
import { useDeleteCollection } from 'hooks';
import { DANGER_COLOUR, NEUTRAL_5_COLOUR } from 'theme';
import { TrashIcon, XIcon } from 'icons';
import { DataSource, SOURCE_VIDAPP } from 'api';

import { useContentNavigationContext } from '../../providers';

interface DeleteCollectionButtonProps {
  tabId: number;
  dataSource: DataSource;
  sourceType?: string;
  tableStyle?: boolean;
  onClick?: () => void;
}

const DeleteButton = styled(CustomButton)`
  &&&& {
    color: ${DANGER_COLOUR};
    width: 100%;
    justify-content: flex-start;
    margin: 1px 0;
  }
`;

const TableDeleteButton = styled(CustomButton)`
  &&&& {
    height: 20px;
    padding: 2px;
    margin-right: 10px;

    :hover {
      background-color: ${NEUTRAL_5_COLOUR};
    }
  }
`;

export const DeleteCollectionButton = ({
  tabId,
  dataSource,
  sourceType,
  tableStyle,
  onClick,
}: DeleteCollectionButtonProps) => {
  const deleteCollection = useDeleteCollection({});
  const [visible, setVisible] = useState(false);
  const { navigate } = useContentNavigationContext();

  const openModal = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const handleDelete = useCallback(() => {
    !tableStyle && navigate(undefined);
    deleteCollection.mutate(tabId, {
      onSuccess: () => {
        setVisible(false);
      },
    });
  }, [tabId, deleteCollection, setVisible, navigate]);

  return (
    <>
      {tableStyle ? (
        <TableDeleteButton
          small
          icon={<TrashIcon />}
          onClick={(e) => {
            e.stopPropagation();
            openModal();
          }}
          tertiary
          loading={deleteCollection.isLoading}
        />
      ) : (
        <DeleteButton
          icon={dataSource === SOURCE_VIDAPP ? <TrashIcon /> : <XIcon />}
          onClick={(e) => {
            onClick && onClick();
            e.stopPropagation();
            openModal();
          }}
          tertiary
          medium
          loading={deleteCollection.isLoading}
        >
          {dataSource === SOURCE_VIDAPP ? 'Delete' : 'Remove'}
        </DeleteButton>
      )}
      {createPortal(
        <ConfirmModal
          heading={sourceType === 'Product' ? 'Remove this Course?' : 'Delete this content?'}
          type="warning"
          content={
            sourceType === 'Product'
              ? 'This will remove it from VidApp. It will be removed from your app when you next publish.'
              : 'This will permanently delete it. This action can not be undone.'
          }
          danger
          primaryText="Delete"
          onConfirm={handleDelete}
          loading={deleteCollection.isLoading}
          open={visible}
          setOpen={setVisible}
        />,
        document.getElementById('modal-portal') as HTMLElement,
      )}
    </>
  );
};
