import { ReactNode, useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { FileUploader, ImageUploader, SegmentTitle } from 'components';
import { downloadFile } from 'utils';
import { useAppBeingEdited } from 'app-context';
import { DownloadIcon, TrashIcon, UploadIcon } from 'icons';
import { HIGHLIGHT_PRIMARY_COLOUR, DANGER_COLOUR, NEUTRAL_6_COLOUR, NEUTRAL_8_COLOUR } from 'theme';
import { FONT_10PX_REGULAR } from 'font';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

const HeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

interface SubheadingProps {
  minHeight?: string;
}

const Subheading = styled.div<SubheadingProps>`
  margin-bottom: 5px;
  color: ${NEUTRAL_8_COLOUR};
  ${FONT_10PX_REGULAR};
  min-height: ${(props) => props.minHeight || 'unset'};
`;

const UploaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ImageUploaderWrapperProps {
  borderRadius?: string;
}

const ImageUploaderWrapper = styled(UploaderWrapper)<ImageUploaderWrapperProps>`
  border: 1px solid ${NEUTRAL_6_COLOUR};
  border-radius: ${(props) => props.borderRadius || '6px'};
  margin-right: 14px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
`;

interface VerticalGuideProps {
  width: string;
}

const VerticalGuide = styled.div<VerticalGuideProps>`
  width: ${({ width }) => width};
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-left: 1px dashed #ccc;
  border-right: 1px dashed #ccc;
`;

interface HorizontalGuideProps {
  height: string;
}

const HorizontalGuide = styled.div<HorizontalGuideProps>`
  width: 100%;
  height: ${({ height }) => height};
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`;

const Button = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
`;

const UploadButton = styled(Button)`
  color: ${HIGHLIGHT_PRIMARY_COLOUR};
  margin-right: 10px;
`;

const DownloadButton = styled(Button)`
  color: ${NEUTRAL_8_COLOUR};
  margin-right: 10px;
`;

const DeleteButton = styled(Button)`
  color: #ff4d4f;
`;

interface ButtonProps {
  size?: 'small';
}

const getWrapperStyles = (props: ButtonProps) => {
  if (props.size === 'small') {
    return css`
      bottom: 1px;
      left: 5px;
    `;
  }
  return css`
    bottom: 6px;
    left: 8px;
  `;
};

const getButtonStyles = (props: ButtonProps) => {
  if (props.size === 'small') {
    return css`
      width: 16px;
      height: 16px;
      margin-right: 5px;
      font-size: 12px;
    `;
  }
  return css`
    width: 30px;
    height: 30px;
    margin-right: 8px;
    font-size: 22px;
  `;
};

const OverlayButtonWrapper = styled.div<ButtonProps>`
  position: absolute;
  ${(props) => getWrapperStyles(props)};
`;

const RemoveButton = styled(DeleteButton)<ButtonProps>`
  cursor: pointer;
  background-color: #ffffff;
  ${(props) => getButtonStyles(props)};
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease opacity;

  :hover {
    opacity: 0.7;
  }
`;

const DownloadOverlayButton = styled(RemoveButton)<ButtonProps>`
  ${(props) => getButtonStyles(props)};
  color: ${HIGHLIGHT_PRIMARY_COLOUR};
`;

const StyledUploadIcon = styled(UploadIcon)`
  margin-right: 5px;
`;

const StyledTrashIcon = styled(TrashIcon)`
  margin-right: 5px;
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  margin-right: 5px;
`;

const Requirements = styled.div`
  color: ${NEUTRAL_8_COLOUR};
  ${FONT_10PX_REGULAR};
  margin-top: 8px;
`;

const Error = styled(Requirements)`
  color: ${DANGER_COLOUR};
  max-width: 120px;
`;

interface SettingsImageInputProps {
  heading?: string;
  subheading?: string;
  subheadingMinHeight?: string;
  tooltip?: string;
  filename: string | undefined;
  backgroundColor?: string;
  requirementsLabel?: string | ReactNode;
  requirementsLabelPosition?: 'bottom' | undefined;
  hideUpload?: boolean;
  hideDelete?: boolean;
  downloadable?: boolean;
  overlayButtonSize?: 'small';
  handleRemove?: () => void;
  verticalGuide?: string;
  horizontalGuide?: string;
  filePath?: string;
  width: string;
  height: string;
  borderRadius?: string;
  scale?: number;
  scaleSpinner?: number;
  requiredDimensions?: { requiredWidth: number; requiredHeight: number };
  maxDimensions?: { maxWidth: number; maxHeight: number };
  maxFileSize?: number;
  acceptedFileTypes?: string;
  onFilenameChange: (filename: string) => void;
  backgroundImage?: string;
}

export const SettingsImageInput = ({
  heading,
  subheading,
  subheadingMinHeight,
  tooltip,
  filename,
  requirementsLabel,
  requirementsLabelPosition,
  hideUpload,
  hideDelete,
  downloadable,
  overlayButtonSize,
  handleRemove,
  verticalGuide,
  horizontalGuide,
  filePath,
  onFilenameChange,
  borderRadius,
  ...props
}: SettingsImageInputProps) => {
  const appId = useAppBeingEdited();
  const overrideRef = useRef<HTMLInputElement>(null);

  const [isError, setIsError] = useState(false);

  const handleUploadClick = useCallback(() => {
    setIsError(false);
    overrideRef.current?.click(); //Forward click to the file input
  }, [overrideRef, setIsError]);

  const handleDeleteClick = useCallback(() => {
    onFilenameChange('');
  }, [onFilenameChange]);

  return (
    <Container>
      {heading && (
        <HeadingWrapper>
          <SegmentTitle title={heading} tooltip={tooltip} />
        </HeadingWrapper>
      )}
      {subheading && <Subheading minHeight={subheadingMinHeight}>{subheading}</Subheading>}
      <ImageUploaderWrapper onClick={handleUploadClick} borderRadius={borderRadius}>
        <ImageUploader
          overrideRef={overrideRef}
          filename={filename !== '' ? filename : undefined}
          filePath={filePath}
          onFilenameChange={onFilenameChange}
          hideUploadButton
          hideGuide
          handleError={() => setIsError(true)}
          {...props}
        />
        {verticalGuide && <VerticalGuide width={verticalGuide} />}
        {horizontalGuide && <HorizontalGuide height={horizontalGuide} />}
        <OverlayButtonWrapper size={overlayButtonSize}>
          {downloadable && filename && (
            <DownloadOverlayButton
              size={overlayButtonSize}
              onClick={(e) => {
                e.stopPropagation();
                downloadFile(filePath ?? `${appId}/images/`, filename);
              }}
            >
              <DownloadIcon />
            </DownloadOverlayButton>
          )}
          {handleRemove && (
            <RemoveButton
              size={overlayButtonSize}
              onClick={(e) => {
                e.stopPropagation();
                handleRemove();
              }}
            >
              <TrashIcon />
            </RemoveButton>
          )}
        </OverlayButtonWrapper>
      </ImageUploaderWrapper>
      <ButtonContainer>
        <Buttons>
          {!hideUpload && (
            <UploadButton onClick={handleUploadClick}>
              <StyledUploadIcon />
              {filename ? 'Replace' : 'Upload'}
            </UploadButton>
          )}
          {!hideDelete && filename && (
            <DeleteButton onClick={handleDeleteClick}>
              <StyledTrashIcon />
              Delete
            </DeleteButton>
          )}
        </Buttons>
        {requirementsLabel && requirementsLabelPosition !== 'bottom' && (
          <>
            <Requirements>{requirementsLabel}</Requirements>
            {isError && <Error>Failed to upload. Please try again.</Error>}
          </>
        )}
      </ButtonContainer>
      {requirementsLabel && requirementsLabelPosition === 'bottom' && (
        <Requirements style={{ marginTop: '8px' }}>{requirementsLabel}</Requirements>
      )}
    </Container>
  );
};

interface SettingsFileInputProps {
  heading?: string;
  filename: string | undefined;
  useOriginalFilename?: boolean;
  filePath?: string;
  acceptedFileTypes?: string;
  downloadable?: boolean;
  onFilenameChange: (filename: string) => void;
}

export const SettingsFileInput = ({
  heading,
  filename,
  filePath,
  downloadable,
  onFilenameChange,
  ...props
}: SettingsFileInputProps) => {
  const appId = useAppBeingEdited();
  const overrideRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = useCallback(() => {
    overrideRef.current?.click(); //Forward click to the file input
  }, [overrideRef]);

  const handleDeleteClick = useCallback(() => {
    onFilenameChange('');
  }, [onFilenameChange]);

  return (
    <Container>
      {heading && (
        <HeadingWrapper>
          <SegmentTitle title={heading} />
        </HeadingWrapper>
      )}
      <ButtonContainer>
        <Buttons>
          <UploadButton onClick={handleUploadClick}>
            <StyledUploadIcon />
            {filename ? 'Replace' : 'Upload'}
          </UploadButton>
          {downloadable && filename && (
            <DownloadButton onClick={() => downloadFile(filePath ?? `${appId}/`, filename)}>
              <StyledDownloadIcon />
              Download
            </DownloadButton>
          )}
          {filename && (
            <DeleteButton onClick={handleDeleteClick}>
              <StyledTrashIcon />
              Delete
            </DeleteButton>
          )}
        </Buttons>
      </ButtonContainer>
      <UploaderWrapper>
        <FileUploader
          overrideRef={overrideRef}
          filename={filename !== '' ? filename : undefined}
          onFilenameChange={onFilenameChange}
          filePath={filePath}
          {...props}
        />
      </UploaderWrapper>
    </Container>
  );
};
