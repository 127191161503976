import { BuilderCollection } from 'providers';
import { Video } from 'api';

export const getFilteredCollections = (collections: BuilderCollection[], searchValue: string) => {
  if (searchValue !== '') {
    return collections.filter(
      (item) =>
        item.Name?.toLowerCase().includes(searchValue) ||
        item.SourceName?.toLowerCase().includes(searchValue) ||
        item.SourceId?.toLowerCase().includes(searchValue) ||
        item.TabId?.toString().toLowerCase().includes(searchValue),
    );
  }
  return collections;
};

export const getFilteredVideos = (videos: Video[], searchValue: string) => {
  if (searchValue !== '') {
    return videos.filter(
      (item) =>
        item.Title?.toLowerCase().includes(searchValue) ||
        item.SourceTitle?.toLowerCase().includes(searchValue) ||
        item.SourceId?.toLowerCase().includes(searchValue) ||
        item.VideoId?.toString().toLowerCase().includes(searchValue),
    );
  }
  return videos;
};
