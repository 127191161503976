import React, { createContext, useContext } from 'react';
import { useAppBasicInfo } from 'hooks';
import { DataSource } from 'api';
import { ErrorPage } from 'components';

const DataSourceContext = createContext<string | undefined>(undefined);

interface ProviderProps {
  children: React.ReactNode | React.ReactNode[];
  dataSource?: DataSource; // Supersedes AppBasicInfo, useful for storybook
}

const DataSourceProvider = ({ children, dataSource }: ProviderProps) => {
  const { data, isLoading, isSuccess } = useAppBasicInfo(Infinity, 'always');

  if (isLoading) {
    return null;
  }

  if (isSuccess && !data) {
    return <ErrorPage code={404} heading="Invalid App ID" showAppSelector hideDashboardButton />;
  }

  return <DataSourceContext.Provider value={dataSource ?? data?.DataSource}>{children}</DataSourceContext.Provider>;
};
const useDataSource = () => {
  const context = useContext(DataSourceContext);
  if (context === undefined) {
    throw new Error('useDataSource must be used within an DataSourceProvider');
  }
  return context;
};

export { DataSourceProvider, useDataSource };
