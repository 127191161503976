import { ReactNode, useCallback, useEffect, useState } from 'react';

import { CustomCheckbox, LoadingSpinner } from 'components';
import { ChevronLeftIcon, ChevronRightIcon } from 'icons';

import { Empty } from 'app/modules/community/Shared/Empty';
import {
  LoadingContainer,
  PageSizeSelector,
  PaginationButton,
  PaginationRow,
  StyledTable,
  StyledTableHeader,
  StyledTableRow,
  TableCell,
  TableCheckboxCell,
  TableHeadCell,
  TableRow,
  TableSortIcon,
} from './components/CustomTableComponents';

type CompareFn<T> = (a: T, b: T) => number;

export interface TableColumn<T> {
  render: (data: T) => ReactNode | string;
  id?: number | string;
  heading: string;
  headingElement?: ReactNode;
  width: number | 'grow';
  minWidth?: number;
  centered?: boolean;
  sorter?: CompareFn<T>;
  sorterKey?: string;
  initialSortOrder?: 'descend' | 'ascend';
}

interface TableProps<T> {
  data?: T[];
  columns: TableColumn<T>[];
  pageSize?: number;
  onPageSizeChange?: (value: number) => void;
  query?: string;
  emptyTitle?: string;
  emptyDescription?: string;
  onRowClick?: (data: T) => void;
  overridePage?: number;
  onPageChange?: (page: number) => void;
  tableWidth?: string;
  tableMinWidth?: string;
  rowSelection?: RowSelection;
  defaultSortKey?: string;
  defaultSortOrder?: 'ascend' | 'descend';
  isLoading?: boolean;
}

interface RowSelection {
  selectedRowKeys: string[];
  onChange: (selectedKeys: string[]) => void;
}

// Data can be anything, but must have an id value
interface RowData {
  id: string;
}

const MAX_VISIBLE_PAGES = 10;

/**
 * This is the DIY Table
 * Supports custom column widths
 * Custom cell rendering
 * Pagination
 */

export const CustomTable = <T extends RowData>({
  data,
  columns,
  pageSize = 15,
  onPageSizeChange,
  query,
  emptyTitle,
  emptyDescription,
  onRowClick,
  overridePage,
  onPageChange,
  tableWidth,
  tableMinWidth,
  rowSelection,
  defaultSortKey,
  defaultSortOrder,
  isLoading,
}: TableProps<T>) => {
  const [page, setPage] = useState(1);
  const [headerCheckboxValue, setHeaderCheckboxValue] = useState<boolean | 'indeterminate'>(false);
  const [sortedData, setSortedData] = useState<T[] | undefined>(data);
  const [sortOrder, setSortOrder] = useState<{ key: string; order: 'ascend' | 'descend' } | undefined>(
    defaultSortKey ? { key: defaultSortKey, order: defaultSortOrder ?? 'ascend' } : undefined,
  );

  const activePageNumber = overridePage ?? page;

  const totalPages = sortedData ? Math.ceil(sortedData.length / pageSize) : 0;
  const startIndex = (activePageNumber - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const [lastCheckedIdx, setLastCheckedIdx] = useState(-1);
  const [shifPressed, setShiftPressed] = useState(false);

  const currentPage = sortedData?.slice(startIndex, endIndex);

  const clearSelections = () => {
    if (rowSelection) {
      rowSelection.onChange([]);
      setLastCheckedIdx(-1);
    }
  };

  useEffect(() => {
    if (data) {
      if (sortOrder) {
        const column = columns.find((col) => col.sorterKey === sortOrder.key);
        if (column && column.sorter) {
          const sorted = sortOrder.order === 'ascend' ? data.sort(column.sorter) : data.sort(column.sorter).reverse();
          setSortedData(sorted);
        } else {
          setSortedData(data);
        }
      }
      setSortedData(data);
    }
  }, [data]);

  useEffect(() => {
    clearSelections();
  }, [activePageNumber, query]);

  useEffect(() => {
    setPage(1);
  }, [query]);

  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        clearSelections();
      } else if (e.shiftKey) {
        setShiftPressed(true);
      }
    },
    [clearSelections, setShiftPressed],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    document.addEventListener('keyup', () => {
      setShiftPressed(false);
    });

    return () => {
      document.removeEventListener('keyup', keyDownHandler);
      document.removeEventListener('keyup', () => {
        setShiftPressed(false);
      });
    };
  }, []);

  useEffect(() => {
    if (rowSelection) {
      if (!currentPage) {
        setHeaderCheckboxValue(false);
      } else {
        if (rowSelection.selectedRowKeys.length === 0) {
          setHeaderCheckboxValue(false);
        } else if (rowSelection.selectedRowKeys.length === currentPage.length) {
          setHeaderCheckboxValue(true);
        } else {
          setHeaderCheckboxValue('indeterminate');
        }
      }
    }
  }, [rowSelection, currentPage]);

  const generatePageNumbers = () => {
    const visiblePages = [];
    const halfMaxVisiblePages = Math.floor(MAX_VISIBLE_PAGES / 2);

    let startPage = activePageNumber - halfMaxVisiblePages;
    if (startPage < 1) {
      startPage = 1;
    }

    let endPage = startPage + MAX_VISIBLE_PAGES - 1;
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - MAX_VISIBLE_PAGES + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(i);
    }

    return visiblePages;
  };

  const handlePageChange = (pageNumber: number) => {
    onPageChange ? onPageChange(pageNumber) : setPage(pageNumber);
  };

  const handleSort = useCallback(
    (column: TableColumn<T>) => {
      const { sorter, sorterKey, initialSortOrder } = column;
      if (data && sorter && sorterKey) {
        if (sortOrder?.key === sorterKey) {
          const newOrder = sortOrder.order === 'ascend' ? 'descend' : 'ascend';
          setSortedData(newOrder === 'descend' ? data.sort(sorter).reverse() : data.sort(sorter));
          setSortOrder({
            key: sorterKey,
            order: newOrder,
          });
        } else {
          const newOrder = initialSortOrder ?? 'ascend';
          setSortedData(newOrder === 'descend' ? data.sort(sorter).reverse() : data.sort(sorter));
          setSortOrder({ key: sorterKey, order: newOrder });
        }
      }
    },
    [data, sortOrder],
  );

  return (
    <div>
      <StyledTable $width={tableWidth} $minWidth={tableMinWidth}>
        <StyledTableHeader>
          <TableRow>
            {rowSelection && (
              <TableCheckboxCell key={`checkbox-cell-header`} $width={32}>
                <CustomCheckbox
                  name="checkbox-header"
                  checked={headerCheckboxValue}
                  onCheckedChange={(checked) => {
                    setHeaderCheckboxValue(checked);
                    const keys = !checked || !currentPage ? [] : currentPage.map((item) => item.id);
                    rowSelection.onChange(keys);
                  }}
                />
              </TableCheckboxCell>
            )}
            {columns.map((column) => (
              <TableHeadCell
                key={column.id ?? column.heading}
                $width={column.width}
                $minWidth={column.minWidth}
                $centered={column.centered}
                $cursor={column.sorter ? 'pointer' : 'unset'}
                onClick={() => handleSort(column)}
              >
                {column.headingElement ?? column.heading}
                {column.sorterKey && sortOrder && (
                  <TableSortIcon active={sortOrder.key === column.sorterKey} order={sortOrder.order} />
                )}
              </TableHeadCell>
            ))}
          </TableRow>
        </StyledTableHeader>
        {!isLoading && sortedData ? (
          <div>
            {(!currentPage || currentPage?.length === 0) && (
              <Empty
                title={!!query ? `No results found for "${query}"` : emptyTitle}
                description={!!query ? 'Check the spelling or try a different search term.' : emptyDescription}
                $width="50%!"
              />
            )}
            {currentPage?.map((row, idx) => (
              <StyledTableRow
                key={row.id}
                onClick={onRowClick ? () => onRowClick(row) : undefined}
                className="StyledRow"
              >
                {rowSelection && (
                  <TableCheckboxCell key={`checkbox-cell-${row.id}`} $width={32}>
                    <CustomCheckbox
                      name={`checkbox-${row.id}`}
                      checked={rowSelection.selectedRowKeys.includes(row.id)}
                      onCheckedChange={(checked) => {
                        const newState = [...rowSelection.selectedRowKeys];
                        if (checked) {
                          if (shifPressed && lastCheckedIdx !== -1) {
                            const startIdx = lastCheckedIdx > idx - 1 ? idx : lastCheckedIdx;
                            const endIdx = lastCheckedIdx > idx - 1 ? lastCheckedIdx : idx;

                            for (let i = startIdx; i <= endIdx; i++) {
                              const id = currentPage[i].id;
                              if (!newState.includes(id)) {
                                newState.push(id);
                              }
                            }
                          } else {
                            newState.push(row.id);
                          }
                          rowSelection.onChange(newState);
                          setLastCheckedIdx(idx);
                        } else {
                          const keys = newState.filter((key) => key !== row.id);
                          rowSelection.onChange(keys);
                          setLastCheckedIdx(-1);
                        }
                      }}
                    />
                  </TableCheckboxCell>
                )}
                {columns.map((column) => (
                  <TableCell
                    key={row.id + (column.id ?? column.heading)}
                    $width={column.width}
                    $minWidth={column.minWidth}
                  >
                    {column.render(row)}
                  </TableCell>
                ))}
              </StyledTableRow>
            ))}
          </div>
        ) : (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        )}
      </StyledTable>
      {(totalPages > 1 || onPageSizeChange) && (
        <PaginationRow>
          {totalPages > 1 && (
            <>
              <PaginationButton
                $selected={false}
                $disabled={activePageNumber === 1}
                onClick={() => activePageNumber > 1 && handlePageChange(activePageNumber - 1)}
              >
                <ChevronLeftIcon />
              </PaginationButton>
              {generatePageNumbers().map((pageNumber) => (
                <PaginationButton
                  key={pageNumber}
                  $disabled={false}
                  $selected={pageNumber === activePageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </PaginationButton>
              ))}
              <PaginationButton
                $selected={false}
                $disabled={activePageNumber === totalPages}
                onClick={() => activePageNumber < totalPages && handlePageChange(activePageNumber + 1)}
              >
                <ChevronRightIcon />
              </PaginationButton>
            </>
          )}
          {onPageSizeChange && (
            <PageSizeSelector
              width="100px"
              wrapperWidth="fit-content"
              size="small"
              options={[
                { name: '25 / page', value: 25 },
                { name: '50 / page', value: 50 },
                { name: '100 / page', value: 100 },
              ]}
              defaultVal={25}
              onChange={(value) => onPageSizeChange(value as number)}
            />
          )}
        </PaginationRow>
      )}
    </div>
  );
};
