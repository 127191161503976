import { ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';

import { CustomButton, Dropdown } from 'components';
import { FONT_16PX_SEMIBOLD } from 'font';
import { FilterAltIcon } from 'icons';
import { NEUTRAL_10_COLOUR } from 'theme';

import { AddFilter } from './AddFilter';
import { FilterInput } from './FilterInput';

const DropdownContent = styled.div`
  color: ${NEUTRAL_10_COLOUR};
`;

const Header = styled.div`
  ${FONT_16PX_SEMIBOLD};
  padding: 9px 10px 20px 10px;
`;

const Footer = styled.div`
  padding: 24px 8px 13px 8px;
  display: flex;
  justify-content: flex-end;
`;

const ApplyButton = styled(CustomButton)`
  margin-left: 12px;
`;

interface FilterOption {
  name: string;
  label?: string;
  danger?: boolean;
  neutral?: boolean;
}

export interface AvailableFilter {
  title: string;
  key: string;
  icon?: ReactNode;
  options: FilterOption[];
  useTypeLabel?: boolean;
  multiSelect?: boolean;
}

interface FilterDropdownProps {
  availableFilters: AvailableFilter[];
  handleChange: (value: Record<string, string[] | undefined>) => void;
  total: number;
}

export const FilterDropdown = ({ availableFilters, handleChange, total }: FilterDropdownProps) => {
  const [open, setOpen] = useState(false);
  const [pendingFilters, setPendingFilters] = useState<Record<string, string[] | undefined>>({});
  const [visibleFilters, setVisibleFilters] = useState<Record<string, boolean>>({});

  const handleApply = useCallback(() => {
    handleChange(pendingFilters);
    setOpen(false);
  }, [pendingFilters, handleChange, setOpen]);

  const handleClearAll = useCallback(() => {
    setVisibleFilters({});
    setPendingFilters({});
    handleChange({});
    setOpen(false);
  }, [setVisibleFilters, setPendingFilters, handleChange, setOpen]);

  return (
    <Dropdown
      trigger={
        <CustomButton tertiary medium icon={<FilterAltIcon />}>
          {`Filter${total > 0 ? ` (${total})` : ''}`}
        </CustomButton>
      }
      isOpen={open}
      onOpenChange={setOpen}
      content={
        <DropdownContent>
          <Header>Filter</Header>
          <>
            {availableFilters.map(({ title, key, options, useTypeLabel, multiSelect }) =>
              visibleFilters[key] ? (
                <FilterInput
                  key={key}
                  title={title}
                  multiSelect={multiSelect}
                  options={options.map(({ name, label, danger, neutral }) => ({
                    name,
                    label,
                    checked: pendingFilters[key]?.includes(name),
                    danger,
                    neutral,
                  }))}
                  onChange={(value) => setPendingFilters((prevState) => ({ ...prevState, [key]: value }))}
                  useTypeLabel={useTypeLabel}
                  onRemove={() => {
                    setPendingFilters((prevState) => ({ ...prevState, [key]: undefined }));
                    setVisibleFilters((prevState) => ({ ...prevState, [key]: false }));
                  }}
                />
              ) : null,
            )}
          </>
          <AddFilter
            availableFilters={availableFilters}
            visibleFilters={visibleFilters}
            setVisibleFilters={setVisibleFilters}
          />
          <Footer>
            <CustomButton secondary medium onClick={handleClearAll}>
              Clear All
            </CustomButton>
            <ApplyButton medium onClick={handleApply}>
              Apply
            </ApplyButton>
          </Footer>
        </DropdownContent>
      }
      align="start"
      menuMinWidth="376px"
    />
  );
};
