import { useState } from 'react';
import styled from 'styled-components';

import { CustomButton, CustomCheckbox, Dropdown, SegmentTitle, TypeLabel } from 'components';
import { FONT_12PX_REGULAR, OVERFLOW_ELLIPSIS } from 'font';
import { ChevronDownBIcon, XIcon } from 'icons';
import { NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR } from 'theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 28px 8px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  ${OVERFLOW_ELLIPSIS};
`;

const StyledTypeLabel = styled(TypeLabel)`
  margin-right: 4px;
`;
const StyledTypeLabel2 = styled(TypeLabel)`
  margin-left: 8px;
  cursor: pointer;
`;

const DropdownContent = styled.div`
  color: ${NEUTRAL_10_COLOUR};
`;

const OptionLabel = styled.div`
  max-width: 100%;
  ${OVERFLOW_ELLIPSIS};
`;

const StyledButton = styled(CustomButton)`
  &&&& {
    width: 304px;
    flex-grow: 1;
    justify-content: space-between;
    ${FONT_12PX_REGULAR};
    border: 1px solid ${NEUTRAL_5_COLOUR};
  }
`;

interface Option {
  name: string;
  label?: string;
  danger?: boolean;
  neutral?: boolean;
  checked?: boolean;
}

interface FilterInputProps {
  title: string;
  options: Option[];
  useTypeLabel?: boolean;
  multiSelect?: boolean;
  onChange: (value: string[] | undefined) => void;
  onRemove: () => void;
}

export const FilterInput = ({ title, options, useTypeLabel, multiSelect, onChange, onRemove }: FilterInputProps) => {
  const [open, setOpen] = useState(false);

  const handleCheckedChange = (name: string, checked: boolean) => {
    const newArr: Option[] = [];
    options.forEach((option) => {
      if (option.name === name) {
        newArr.push({ ...option, checked });
      } else {
        newArr.push(option);
      }
    });

    const checkedOptions = newArr.filter((o) => o.checked);
    if (checkedOptions.length === 0) {
      onChange(undefined);
    } else {
      const checkedValues = checkedOptions.map((o) => o.name);
      onChange(checkedValues);
    }
  };

  const checkedOptions = options.filter(({ checked }) => checked);

  return (
    <Wrapper>
      <SegmentTitle title={title} />
      <DropdownWrapper>
        <Dropdown
          trigger={
            <StyledButton tertiary medium icon={<ChevronDownBIcon />} iconPosition="right">
              <LabelWrapper>
                {checkedOptions.length === 0
                  ? 'None selected'
                  : checkedOptions.map(({ name, label, danger, neutral }, idx) => {
                      const displayName = label ?? name;
                      if (useTypeLabel) {
                        return (
                          <StyledTypeLabel key={name} $danger={danger} $neutral={neutral}>
                            {displayName}
                          </StyledTypeLabel>
                        );
                      }
                      return idx !== 0 ? `, ${displayName}` : displayName;
                    })}
              </LabelWrapper>
            </StyledButton>
          }
          isOpen={open}
          onOpenChange={setOpen}
          options={
            !multiSelect
              ? options.map(({ name, label, checked }) => ({
                  label: label ?? name,
                  labelElement: <OptionLabel>{label ?? name}</OptionLabel>,
                  onClick: () => {
                    onChange([name]);
                    setOpen(false);
                  },
                  isActive: checked,
                }))
              : undefined
          }
          content={
            multiSelect && (
              <DropdownContent>
                {options.map(({ name, label, checked, danger, neutral }, idx) => (
                  <CustomCheckbox
                    small
                    key={name}
                    name={name}
                    label={label ?? name}
                    labelElement={
                      useTypeLabel && (
                        <StyledTypeLabel2
                          $danger={danger}
                          $neutral={neutral}
                          onClick={() => handleCheckedChange(name, !checked)}
                        >
                          {label ?? name}
                        </StyledTypeLabel2>
                      )
                    }
                    checked={!!checked}
                    onCheckedChange={(checked) => handleCheckedChange(name, !!checked)}
                    $marginTop={idx === 0 ? '0px' : '12px'}
                  />
                ))}
              </DropdownContent>
            )
          }
          menuMinWidth="312px"
          menuMaxWidth="432px"
          menuMaxHeight="330px"
          align="start"
          padding="12px"
          small
        />
        <CustomButton tertiary medium icon={<XIcon />} onClick={onRemove} />
      </DropdownWrapper>
    </Wrapper>
  );
};
