import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { InfoModal, SettingsGroup, SettingsSegment, SettingTextDisplay } from 'components';
import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_9_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { AccountDetails, PricingPlan, StripeCustomer, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { getUserEmail } from 'utils';
import { ForwardIcon } from 'icons';

const Caption = styled.div`
  margin-top: 4px;
  margin-bottom: 12px;
  color: ${NEUTRAL_7_COLOUR};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  width: 100%;
`;
const Underline = styled.span`
  text-decoration: underline;
`;

const getPricingPlanTitle = (plan: PricingPlan) => {
  const billingCycle = plan.BillingCycle;
  let frequency = '';
  if (billingCycle === 'monthly') {
    frequency = '(MONTHLY)';
  } else if (billingCycle === 'annual') {
    frequency = '(ANNUAL)';
  }

  return `${plan.PaymentPlan?.Name} ${frequency}`.toUpperCase();
};

const BlueLink = styled.div`
  color: ${HIGHLIGHT_PRIMARY_COLOUR};
  font-size: 12px;
  font-weight: 500;

  position: absolute;
  top: 0;
  right: 5px;
  padding-right: 12px;

  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

const StyledRightIcon = styled(ForwardIcon)`
  font-size: 18px;
`;

interface PlanDetailsProps {
  customer?: StripeCustomer;
  plan?: PricingPlan;
  account?: AccountDetails;
}

export const PlanDetails = ({ customer, plan, account }: PlanDetailsProps) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();

  const queryClient = useQueryClient();

  const handleUpdatePayment = useCallback(() => {
    if (!account) {
      return;
    }
    const userEmail = getUserEmail();

    // Existing stripe logic from the legacy builder
    // Long term solution is to completely rewrite our stripe logic
    const handler = window.StripeCheckout.configure({
      key: 'pk_live_T7VjlKnnsaysD3LmEGwJEIGI', // 'pk_test_BQJxz7JT41w1QPftMEomQ0Q4'
      image: '',
      locale: 'auto',
      token: (token: { id: string }) => {
        client
          .put(`misc/stripe`, { StripeCustomerId: account.StripeId, StripeToken: token.id })
          .then(() => {
            InfoModal('Success!', 'Your payment method has been updated!', 'success');
            queryClient.invalidateQueries(['user', userEmail]);
            queryClient.invalidateQueries(['stripe', userEmail]);
          })
          .catch(() => {
            InfoModal('Sorry, your card was declined!', 'Please check your details or try another card.', 'error');
          });
      },
    });
    handler.open();
  }, [account, client, queryClient]);

  return (
    <SettingsGroup title="Plan & Billing">
      <SettingsSegment>
        <SettingTextDisplay
          label="Current Plan"
          value={plan ? getPricingPlanTitle(plan) : 'You do not have an active plan'}
          color={plan ? NEUTRAL_9_COLOUR : NEUTRAL_7_COLOUR}
          size="large"
        >
          {plan && (
            <BlueLink
              onClick={() => {
                window.open(`/php/payment.php?app_id=${appId}`, '_blank', 'noopener,noreferrer');
              }}
            >
              See Details <StyledRightIcon />
            </BlueLink>
          )}
        </SettingTextDisplay>
        {plan && (
          <Caption>
            To change plan please get <Underline>in contact</Underline> with your account manager
          </Caption>
        )}
      </SettingsSegment>
      {customer?.Last4Digits && (
        <SettingTextDisplay label="Payment Method" value={`xxxx-xxx-xxxx-${customer?.Last4Digits}`}>
          <BlueLink onClick={handleUpdatePayment}>Update</BlueLink>
        </SettingTextDisplay>
      )}
    </SettingsGroup>
  );
};
