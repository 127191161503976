import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { saveVideoProperties, useAxiosInstance, VideoPropertiesToSave } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

interface SaveVideoPropertiesProps {
  invalidateQuery: boolean;
  refresh: boolean;
  saveActionLog: boolean;
}

export const useSaveVideoProperties = ({
  invalidateQuery = true,
  refresh = true,
  saveActionLog = true,
}: SaveVideoPropertiesProps) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, VideoPropertiesToSave>(
    (videoPropertiesToSave) => {
      return saveVideoProperties(client, appId, videoPropertiesToSave, refresh);
    },
    {
      onSuccess: async (_response, payload) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['videos', appId]);
        }
        if (saveActionLog) {
          recordAction.mutate({ action: 'Save Video Properties', detail: { videoPropertiesToSave: payload } });
        }
      },
    },
  );
};
