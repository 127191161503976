import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { ColumnModal, SettingsSelectInput } from 'components';
import { useSyncPurchases } from '../hooks/useSyncPurchases';
import styled from 'styled-components';
import { FONT_12PX_REGULAR, FONT_20PX_MEDIUM } from '../../../../font';
import { useOffers } from '../../offers/hooks/useOffers';

export interface PurchaseDetails {
  userID: string;
  email: string;
  purchaseName: string;
  purchaseId: number;
  productId: string;
}

interface SyncPurchaseModalProps {
  purchase?: PurchaseDetails;
  setPurchase: Dispatch<SetStateAction<PurchaseDetails | undefined>>;
}

const Content = styled.div`
  padding-bottom: 16px;
`;
const MarginBottom = styled.div`
  margin-bottom: 20px;
`;
const Header = styled.div`
  ${FONT_20PX_MEDIUM};
  margin-bottom: 16px;
`;
const Subtitle = styled.div`
  ${FONT_12PX_REGULAR};
`;

export const SyncPurchaseModal = ({ purchase, setPurchase }: SyncPurchaseModalProps) => {
  const { products } = useOffers();
  const syncPurchase = useSyncPurchases();
  const [productID, setProductID] = useState<string>(purchase?.productId ?? '');
  const closeModal = useCallback(() => {
    setProductID('');
    setPurchase(undefined);
  }, []);
  if (!products) {
    return null;
  }

  return (
    <ColumnModal
      open={!!purchase}
      hideTrigger
      onCancel={closeModal}
      onConfirm={() => {
        if (purchase) {
          syncPurchase.mutate(
            {
              Email: purchase.email,
              StoreProductID: productID,
              UserID: purchase.userID,
              PurchaseID: purchase.purchaseId,
            },
            {
              onSuccess: () => closeModal(),
            },
          );
        }
      }}
      confirmIsLoading={syncPurchase.isLoading}
      confirmDisabled={syncPurchase.isLoading}
      primaryText="Sync Purchase"
      cols={3}
    >
      <Content>
        <MarginBottom>
          <Header>Sync Purchase</Header>
          <Subtitle>
            <b>User:</b> {purchase?.userID}
          </Subtitle>
          <Subtitle>
            <b>Current Product:</b> {purchase?.purchaseName}.
          </Subtitle>
        </MarginBottom>
        <SettingsSelectInput
          value={productID}
          //eslint-disable-next-line
          options={Array.from(products)?.map(([_, product]) => {
            return { name: product.DisplayName, value: product.ProductId };
          })}
          onChange={(value) => setProductID(value)}
        />
        <Subtitle> Change this product if the user cross-graded this purchase.</Subtitle>
      </Content>
    </ColumnModal>
  );
};
