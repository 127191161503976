import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import {
  GROUP_LABEL,
  ITEM_TYPE_COLLECTION,
  ITEM_TYPE_VIDEO,
  SOURCE_KAJABI,
  SOURCE_TYPE_COLLECTION,
  SOURCE_VIDAPP,
  Video,
} from 'api';
import { BreadcrumbItem, BreadcrumbProps, CustomButton, Dropdown, InfoModal, PageContainer } from 'components';
import { useSyncKajabiProduct } from 'hooks';
import { EllipsisIcon, UISyncIcon } from 'icons';
import { BuilderCollection, useSaveContext, useUnsavedChanges } from 'providers';
import { NEUTRAL_9_COLOUR } from 'theme';
import { capitalizeString, collectionIsProtected, getCollectionSourceName, getVideoSourceTitle } from 'utils';

import { useContentScreen } from 'app/modules/content/hooks/useContentScreen';
import { CONTENT_ROOT_PATH, useContentNavigationContext } from 'app/modules/content/providers';
import { CMSMockup } from '../../cms-mockup/CMSMockup';
import { DeleteCollectionButton } from '../../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SaveWrapper = styled.div`
  display: flex;
`;

const SaveButton = styled(CustomButton)`
  &&&& {
    margin-left: 8px;
  }
`;

const DropdownButton = styled(CustomButton)`
  &&&& {
    color: ${NEUTRAL_9_COLOUR};
  }
`;

interface ContentViewContainerProps {
  collection?: BuilderCollection;
  video?: Video;
  isLoading?: boolean;
  children: React.ReactNode;
}

export const ContentViewContainer = ({ collection, video, isLoading, children }: ContentViewContainerProps) => {
  const { breadcrumbs, blockTreeNavigation, tempTabId, navigate, goToNewCollection } = useContentNavigationContext();
  const { collections, videos } = useContentScreen();
  const { isSaving, saveChanges } = useSaveContext();
  const { unsavedChanges } = useUnsavedChanges();
  const syncKajabiProduct = useSyncKajabiProduct({ isOnboarding: false, syncToApp: true });

  const showSync = useMemo(() => {
    if (collection) {
      return collection.DataSource === SOURCE_KAJABI && collection.SourceType === 'Product';
    }
    return false;
  }, [collection]);

  const showDelete = useMemo(() => {
    if (!collection) {
      return false;
    }

    const isTempId = typeof collection.TabId === 'string' && collection.TabId.startsWith('Temp');

    if (isTempId || blockTreeNavigation) {
      return false;
    }

    return !collectionIsProtected(collection.SourceId) && (showSync || collection.DataSource === SOURCE_VIDAPP);
  }, [collection, blockTreeNavigation, showSync]);

  const showDropdown = (showSync || showDelete) && collection;

  const handleSync = useCallback(() => {
    syncKajabiProduct.mutate({ productId: collection?.SourceId, themeId: collection?.KajabiDataThemeId });

    InfoModal(
      `Your ${SOURCE_KAJABI} course is being synced`,
      "You'll receive an email once this is complete",
      'success',
    );
  }, [syncKajabiProduct]);

  const handleSave = async () => {
    const { getPermanentCollectionId } = await saveChanges();

    // If a new collection has been created, navigate to the newly created permanent TabId
    if (collection?.TabId === tempTabId) {
      const newTabId = getPermanentCollectionId(tempTabId);
      if (newTabId && collection) {
        goToNewCollection(newTabId, collection.Name);
      }
    }
  };

  const getBreadcrumbLabel = useCallback(
    (item: string) => {
      if (item.startsWith('new_')) {
        const type = capitalizeString(item.toString().replace('new_', ''));
        const label = type === SOURCE_TYPE_COLLECTION ? GROUP_LABEL : type;
        return `New ${label}`;
      } else if (!!collections && collections[item]) {
        return collections[item].Name;
      } else if (!!videos && videos[item]) {
        return videos[item].Title;
      }

      return 'Unknown';
    },
    [collections, videos],
  );

  const breadcrumbProps: BreadcrumbProps | undefined = useMemo(() => {
    if (breadcrumbs && !!collections && !!videos) {
      const items: BreadcrumbItem[] = breadcrumbs.map((item) => {
        if (item === 'content') {
          return {
            label: 'Content',
            handleClick: () => {
              navigate(CONTENT_ROOT_PATH, true);
            },
          };
        } else {
          return {
            label: getBreadcrumbLabel(item),
            handleClick: () => {
              const newPath = breadcrumbs.slice(0, breadcrumbs.lastIndexOf(item) + 1);
              navigate(`/${newPath.join('/')}`, true);
            },
          };
        }
      });

      const currentScreen = items.pop()?.label ?? '';

      return { items, currentScreen };
    }
    return {
      items: [
        {
          label: 'Content',
          handleClick: () => {
            navigate(CONTENT_ROOT_PATH, true);
          },
        },
      ],
      currentScreen: '',
    };
  }, [breadcrumbs, getBreadcrumbLabel, navigate]);

  if (!collection && !video) {
    return <PageContainer isLoading mockup={<CMSMockup isLoading />} />;
  }

  return (
    <PageContainer
      heading={collection ? getCollectionSourceName(collection) || ' ' : getVideoSourceTitle(video as Video) || ' '}
      isLoading={isLoading}
      breadcrumbs={breadcrumbProps}
      headingButton={
        !isLoading && (
          <SaveWrapper>
            {showDropdown && (
              <Dropdown
                trigger={<DropdownButton medium icon={<EllipsisIcon />} tertiary />}
                content={
                  <>
                    {showSync && (
                      <CustomButton icon={<UISyncIcon />} onClick={handleSync} tertiary medium>
                        Sync
                      </CustomButton>
                    )}
                    {showDelete && (
                      <DeleteCollectionButton
                        tabId={collection.TabId as number}
                        sourceType={collection.SourceType}
                        dataSource={collection.DataSource}
                      />
                    )}
                  </>
                }
                menuMinWidth="130px"
              />
            )}
            <SaveButton medium onClick={handleSave} loading={isSaving} disabled={!unsavedChanges || isSaving}>
              Save
            </SaveButton>
          </SaveWrapper>
        )
      }
      mockup={
        (collection || video) && (
          <CMSMockup
            currentPage={
              collection
                ? { itemId: collection.TabId, itemType: ITEM_TYPE_COLLECTION }
                : { itemId: video?.VideoId, itemType: ITEM_TYPE_VIDEO }
            }
          />
        )
      }
    >
      <Container key={collection ? collection.SourceId : video?.SourceId}>{children}</Container>
    </PageContainer>
  );
};
