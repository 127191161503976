import { useLocation } from 'react-router-dom';
import { BuildNavProvider } from '../providers';
import { BuildMyAppInternal } from './BuildMyAppInternal';
import { getScreenFromPathname } from 'utils';

export const BuildMyAppInternalWrapper = () => {
  const { pathname } = useLocation();
  const screen = getScreenFromPathname(pathname);

  return (
    <BuildNavProvider key={screen}>
      <BuildMyAppInternal />
    </BuildNavProvider>
  );
};
