import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

import { BuilderVideo, useContent, useSaveContext } from 'providers';
import { getVideoHTMLDescription } from 'utils';
import { ColumnModal, CustomButton, VideoDescription } from 'components';
import { FONT_14PX_SEMIBOLD, FONT_16PX_REGULAR } from 'font';
import { NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { PenIcon, RevertIcon } from 'icons';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const Header = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_14PX_SEMIBOLD};
`;

const Buttons = styled.div`
  display: flex;
`;

const EditButton = styled(CustomButton)`
  &&&& {
    margin-left: 4px;
  }
`;

const PreviewContainer = styled.div<{ isPlaceholder: boolean }>`
  color: ${({ isPlaceholder }) => (isPlaceholder ? NEUTRAL_7_COLOUR : 'unset')};
  width: 100%;
  height: 200px;
  padding: 8px 12px;
  ${FONT_16PX_REGULAR};
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 6px;
  overflow-y: auto;
`;

const DescriptionWrapper = styled.div`
  .mce-container {
    border-radius: 8px;
  }
`;

interface VideoDescriptionPreviewProps {
  video: BuilderVideo;
  revertText?: string;
  disabled?: boolean;
  title?: string;
  placeholder?: string;
}

export const VideoDescriptionPreview = ({
  video,
  revertText,
  disabled,
  title = 'Description',
  placeholder = 'No description',
}: VideoDescriptionPreviewProps) => {
  const { setVideoValue } = useContent();
  const { setVideoValueToSave } = useSaveContext();

  const [open, setOpen] = useState(false); // Modal is open
  const [useSourceDescription, setUseSourceDescription] = useState(video.SourceDescription === video.Description);

  const description = getVideoHTMLDescription({ ...video, SourceDescription: '' });
  const processedSourceDesc = video.SourceDescription
    ? getVideoHTMLDescription({ ...video, Description: '' })
    : undefined;

  const purifiedDesc = useMemo(() => {
    return DOMPurify.sanitize(description);
  }, [description]);

  const isPlaceholder = description === '';

  const handleRevert = () => {
    setVideoValue(video.VideoId, 'Description', video.SourceDescription);
    setVideoValueToSave(video.VideoId, 'Description', video.SourceDescription);
    setUseSourceDescription(true);
  };

  useEffect(() => {
    if (open === false) {
      setUseSourceDescription(video.SourceDescription === video.Description);
    }
  }, [open]);

  return (
    <Wrapper id="description-preview-wrapper">
      <Header>
        <Title>{title}</Title>
        {!disabled && (
          <Buttons>
            {!!processedSourceDesc && description != processedSourceDesc && (
              <CustomButton medium tertiary icon={<RevertIcon />} onClick={handleRevert}>
                {revertText ?? 'Revert to Source'}
              </CustomButton>
            )}
            <ColumnModal
              title="Description"
              cols={4}
              align="right"
              onConfirm={() => setOpen(false)}
              open={open}
              onOpenChange={setOpen}
              primaryText="Done"
              hideClose
              hideSecondary
              hideDialogActionsBorder
              hasTinyMCE
              triggerButton={
                <EditButton medium tertiaryHighlight icon={<PenIcon />}>
                  Edit
                </EditButton>
              }
            >
              <DescriptionWrapper>
                <VideoDescription
                  VideoId={video.VideoId}
                  Description={video.Description}
                  SourceDescription={video.SourceDescription}
                  useSourceDescription={useSourceDescription}
                  revertText={revertText}
                  height={305}
                  maxHeight={470}
                  hideTitle
                  bottomRevertButton
                />
              </DescriptionWrapper>
            </ColumnModal>
          </Buttons>
        )}
      </Header>
      <PreviewContainer
        isPlaceholder={isPlaceholder}
        dangerouslySetInnerHTML={{ __html: isPlaceholder ? placeholder : purifiedDesc }}
      />
    </Wrapper>
  );
};
