import {
  CollectionItemSubType,
  DEFAULT_COLLECTION,
  DEFAULT_COLLECTION_ITEM,
  ITEM_TYPE_COLLECTION,
  SOURCE_TYPE_BUILDMYAPP,
} from 'api';

export const BUILDER_DRAWER_WIDTH = 360;
export const UNTITLED_BLOCK_NAME = 'Untitled';
export const UNTITLED_CONTENT_NAME = 'Heading';
export const THUMBNAIL_RADIUS = '7px';

export const TOOLBOX_PAGE_ADD = 'toolbox_add';
export const TOOLBOX_PAGE_TREE = 'toolbox_tree';

// These aren't real templates but we want to trigger certain behaviour as if they were
// Avoid clashing with real template ids
export const FEATURE_TEMPLATE_MYCOURSES = 901;
export const FEATURE_TEMPLATE_TVOD = 902;
export const FEATURE_TEMPLATE_ALLCOURSES = 903;

export const DRAWER_TYPE_LINKED = 'linkSubTab';
export const DRAWER_TYPE_SECTION = 'section';
export const DRAWER_TYPE_ADD = 'add';
export const DRAWER_TYPE_BMA = 'build-my-app';
export const DRAWER_TYPE_SECTION_HEADER = 'sectionHeader';

export const STANDALONE_PROPERTY = 'StandaloneEnabled';
export const CMS_PROPERTY = 'DisplaySimpleCMS';
export const WORKOUT_PROPERTY = 'DisplayCircuitWorkouts';

// When dropping new blocks/content into the mockup these are the default values
export const BUILDMYAPP_COLLECTION_ITEM = {
  ...DEFAULT_COLLECTION_ITEM,
  // Currently we only add collection sections
  Type: ITEM_TYPE_COLLECTION,
  SubType: ITEM_TYPE_COLLECTION as CollectionItemSubType,
  // Updated during processing
  ChildId: 0,
  Position: 99,
};

export const BUILDMYAPP_COLLECTION = {
  ...DEFAULT_COLLECTION,
  Name: 'Untitled',
  SourceType: SOURCE_TYPE_BUILDMYAPP,
  Items: [],
  HideFromSearchResults: 1 as const,
  Thumbnails: {},
  Position: 0,
};
