import { useEffect } from 'react';
import styled from 'styled-components';

import { Tabs } from 'components';
import { FONT_14PX_MEDIUM, FONT_14PX_REGULAR, FONT_14PX_SEMIBOLD } from 'font';
import { AndroidIcon, AppStoreIcon, BuildIcon, ChanelIcon, DollarSignIcon, FileCheckIcon, RocketIcon } from 'icons';
import { CUSTOM_SCROLLBAR, HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_10_COLOUR } from 'theme';
import { isUserAdmin } from 'utils';

import { SalesCall } from 'app/modules/call-scheduling';
import { useTourPrefetch } from 'app/modules/dashboard/Dashboard/hooks/useTourPrefetch';
import { OnboardingStep, ProgressBar, SubmissionStatuses } from '.';
import {
  OnboardingStageKey,
  OnboardingStepKey,
  STAGE_KEY_APP_STORE,
  STAGE_KEY_APP_STORE_ANDROID,
  STAGE_KEY_APP_STORE_APPLE,
  STAGE_KEY_BUILD,
  STAGE_KEY_GET_STARTED,
  STAGE_KEY_IAP,
  STAGE_KEY_LAUNCH,
  STAGE_KEY_PLATFORM,
  STAGE_KEY_REVIEW_AND_SUBMISSION,
} from '../const';
import { useOnboardingChecklistContext } from '../providers/OnboardingChecklistProvider';
import { getNumberOfCompletedSteps, getNumberOfSteps } from '../util';

const ICONS: Record<OnboardingStageKey, JSX.Element> = {
  [STAGE_KEY_GET_STARTED]: <RocketIcon />,
  [STAGE_KEY_APP_STORE]: <AppStoreIcon />,
  [STAGE_KEY_APP_STORE_APPLE]: <AppStoreIcon />,
  [STAGE_KEY_APP_STORE_ANDROID]: <AndroidIcon />,
  [STAGE_KEY_PLATFORM]: <ChanelIcon />,
  [STAGE_KEY_IAP]: <DollarSignIcon />,
  [STAGE_KEY_BUILD]: <BuildIcon />,
  [STAGE_KEY_REVIEW_AND_SUBMISSION]: <FileCheckIcon />,
  [STAGE_KEY_LAUNCH]: <RocketIcon />,
};
const DESCRIPTIONS: Record<OnboardingStageKey, string | JSX.Element> = {
  [STAGE_KEY_GET_STARTED]:
    "Let's get started! Start by completing a few quick steps to discover how VidApp can be of benefit and the best plan for your business.",
  [STAGE_KEY_APP_STORE]: (
    <>
      <b>Apple Integration</b>
      <br />
      Get your app publishable on the App Store.
    </>
  ),
  [STAGE_KEY_APP_STORE_APPLE]: (
    <>
      <b>Apple Integration</b>
      <br />
      Get your app publishable on the App Store.
    </>
  ),
  [STAGE_KEY_APP_STORE_ANDROID]: (
    <>
      <b>Android Integration</b>
      <br />
      Get your app publishable on the Google Play Store.
    </>
  ),
  [STAGE_KEY_PLATFORM]: (
    <>
      <b>Platform Integration</b>
      <br />
      Link your membership platform with VidApp to sync your content and member access.
    </>
  ),
  [STAGE_KEY_IAP]: (
    <>
      <b>In-App Purchases</b>
      <br />
      Let members buy subscriptions directly in the mobile app, with payment and subscription management handled by the
      app stores. Skip this step if you are not planning to have In-App Purchases.
    </>
  ),
  [STAGE_KEY_BUILD]: (
    <>
      <b>Build</b>
      <br />
      Complete the design and presentation of your app for the app stores to accurately reflect your brand. These
      details can be updated later. Let's prepare for submission!
    </>
  ),
  [STAGE_KEY_REVIEW_AND_SUBMISSION]: (
    <>
      <b>Review and Submission</b>
      <br />
      After completing the previous steps, you can track your app submission status here. If delays or rejections occur,
      which does happen at times, don't worry—our team will handle it for you.
    </>
  ),
  [STAGE_KEY_LAUNCH]: (
    <>
      <b>Launch</b>
      <br />
      Congratulations on reaching this stage! Your app is ready to launch. When you're ready, release your approved app
      through each platform's console. Once released, it may take up to 48 hours for the apps to become available in all
      app stores worldwide.
    </>
  ),
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100% - 8px);
  margin: 4px 4px 0 0;
  ${FONT_14PX_REGULAR};
  color: ${NEUTRAL_10_COLOUR};
  overflow-x: hidden;
  overflow-y: auto;
  ${CUSTOM_SCROLLBAR};
`;

const ListWrapper = styled.div`
  padding: 28px 26px 32px 32px;
`;

const Spacer = styled.div`
  height: 28px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 36px;
  height: 36px;
  margin-bottom: 20px;
  font-size: 22px;
  color: ${HIGHLIGHT_PRIMARY_COLOUR};
  border: 2px solid ${HIGHLIGHT_PRIMARY_COLOUR};
  border-radius: 50%;
`;

const StageDescription = styled.div`
  margin-bottom: 16px;
`;

const StyledList = styled(Tabs.List)`
  &&& {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0;
    height: 72px;
    border-bottom-width: 1px;
  }
`;
const StyledTrigger = styled(Tabs.Trigger)`
  &&& {
    ${FONT_14PX_MEDIUM};
    flex: 1 1 0;
    text-align: center;
    margin-right: 0;
    &[data-state='active'] {
      ${FONT_14PX_SEMIBOLD};
      padding-top: 1px;
      :after {
        border-bottom-width: 1px;
        transform: translateY(30px);
      }
    }
  }
`;

const mapSteps = (steps: OnboardingStepKey[], options: { isDisabled?: boolean; isAdmin?: boolean }) => {
  const { isDisabled, isAdmin } = options;
  if (isAdmin && !isUserAdmin()) {
    return null;
  }

  return steps.map((step) => <OnboardingStep key={step} id={step} isDisabled={isDisabled} isAdmin={isAdmin} />);
};

export const OnboardingSteps = () => {
  const { activeStage, onboardingStages, salesCallModalVisible, setSalesCallModalVisible } =
    useOnboardingChecklistContext();
  useTourPrefetch();

  useEffect(() => {
    document.getElementById('OnboardingStepsContainer')?.scrollTo(0, 0);
  }, [activeStage]);

  if (!onboardingStages) {
    return null;
  }

  const { steps, adminSteps, dependency } = onboardingStages[activeStage];

  const numberOfSteps = getNumberOfSteps(onboardingStages[activeStage]);
  const numberOfCompletedSteps = getNumberOfCompletedSteps(onboardingStages[activeStage]);

  const tabs =
    activeStage === STAGE_KEY_APP_STORE
      ? [
          {
            key: STAGE_KEY_APP_STORE_APPLE,
            numberOfSteps: getNumberOfSteps(onboardingStages[STAGE_KEY_APP_STORE_APPLE]),
            numberOfCompletedSteps: getNumberOfCompletedSteps(onboardingStages[STAGE_KEY_APP_STORE_APPLE]),
            ...onboardingStages[STAGE_KEY_APP_STORE_APPLE],
          },
          {
            key: STAGE_KEY_APP_STORE_ANDROID,
            numberOfSteps: getNumberOfSteps(onboardingStages[STAGE_KEY_APP_STORE_ANDROID]),
            numberOfCompletedSteps: getNumberOfCompletedSteps(onboardingStages[STAGE_KEY_APP_STORE_ANDROID]),
            ...onboardingStages[STAGE_KEY_APP_STORE_ANDROID],
          },
        ]
      : undefined;

  const isDisabled =
    isUserAdmin() || !dependency
      ? false
      : onboardingStages[dependency].completedSteps < onboardingStages[dependency].steps.length;

  return (
    <>
      <Container id="OnboardingStepsContainer">
        {tabs ? (
          // App Store is split into 2 tabs, Apple and Android
          <Tabs.Root defaultValue={tabs[0].key}>
            <StyledList>
              {tabs.map(({ key, title, numberOfSteps, numberOfCompletedSteps }) => (
                <StyledTrigger value={key} key={key}>
                  {`${title} • ${numberOfCompletedSteps}/${numberOfSteps}`}
                </StyledTrigger>
              ))}
            </StyledList>
            {tabs.map(({ key, steps, adminSteps, numberOfSteps, numberOfCompletedSteps }) => (
              <Tabs.Content value={key} key={key}>
                <ListWrapper>
                  <IconWrapper>{ICONS[key as OnboardingStageKey]}</IconWrapper>
                  <StageDescription>{DESCRIPTIONS[key as OnboardingStageKey]}</StageDescription>
                  {numberOfSteps > 0 && <ProgressBar completed={numberOfCompletedSteps} total={numberOfSteps} />}
                  <Spacer />
                  {mapSteps(steps, { isDisabled })}
                  {mapSteps(adminSteps, { isAdmin: true })}
                </ListWrapper>
              </Tabs.Content>
            ))}
          </Tabs.Root>
        ) : (
          <ListWrapper>
            <IconWrapper>{ICONS[activeStage]}</IconWrapper>
            <StageDescription>{DESCRIPTIONS[activeStage]}</StageDescription>
            {numberOfSteps > 0 && <ProgressBar completed={numberOfCompletedSteps} total={numberOfSteps} />}
            <Spacer />
            {activeStage === STAGE_KEY_REVIEW_AND_SUBMISSION && (
              <SubmissionStatuses isLocked={numberOfCompletedSteps < numberOfSteps} />
            )}
            {mapSteps(steps, { isDisabled })}
            {mapSteps(adminSteps, { isAdmin: true })}
          </ListWrapper>
        )}
      </Container>
      <SalesCall modalVisible={salesCallModalVisible} setModalVisible={setSalesCallModalVisible} />
    </>
  );
};
