// Filter down the Datasources and their respective Collection/Videos available to be added into a specific collection

import { BuilderCollection, BuilderVideo, useContent, useDataSource } from 'providers';
import { useCallback, useMemo } from 'react';
import {
  CATALOG_SOURCE_ID,
  FEATURED_SOURCE_ID,
  GROUP_LABEL,
  MY_PRODUCTS_SOURCE_ID,
  SOURCE_CUSTOMAPI,
  SOURCE_JWPLAYER,
  SOURCE_KAJABI,
  SOURCE_TYPE_COLLECTION,
  SOURCE_TYPE_KAJABI_CAT,
  SOURCE_TYPE_KAJABI_PRODUCT,
  SOURCE_TYPE_KAJABI_SUBCAT,
  SOURCE_TYPE_USCREEN_CAT,
  SOURCE_TYPE_VHX_CAT,
  SOURCE_TYPE_VHX_SEAS,
  SOURCE_TYPE_WORKOUT,
  SOURCE_USCREEN,
  SOURCE_VHX,
  SOURCE_VIDAPP,
  SOURCE_VIMEO,
  SOURCE_WORDPRESS,
  SOURCE_YOUTUBE,
} from 'api';
import {
  CollectionIcon,
  CubeIcon,
  DumbbellIcon,
  FileBlankIcon,
  FolderIcon,
  GlobeIcon,
  PostIcon,
  TextImageIcon,
  VideoIcon,
} from 'icons';
import { NavigationOption } from 'app/modules/build-dragdrop/Builder/customise-items/CustomiseContentV2';
import { checkVideoType } from 'utils';
import { useAppBasicInfo, useAppProperties } from 'hooks';
import { CMS_PROPERTY, WORKOUT_PROPERTY } from 'app/modules/build-dragdrop/Builder/const';
import styled from 'styled-components';

const StyledCollectionIcon = styled(CollectionIcon)`
  svg {
    fill: none;
  }
`;
const DEFAULT_VIDEOS_VIEW = {
  Title: 'Videos',
  ViewType: 'videos' as const,
  Icon: <VideoIcon />,
};
const EXCLUDED_CIRCUIT_TEMPLATES = [24, 25, 32, 35];

interface UseModalOptionsProps {
  collectionId: BuilderCollection['TabId'];
}

export const useModalOptions = ({ collectionId }: UseModalOptionsProps) => {
  const datasource = useDataSource();
  const { videos, collections } = useContent();
  const { data } = useAppProperties();
  const { isMigratedLegacy } = useAppBasicInfo();

  const showVidAppCollections = data?.[CMS_PROPERTY] === '1' || isMigratedLegacy;
  const showWorkouts = data?.[WORKOUT_PROPERTY] === '1';

  // Applied to all datasources before they get grouped
  const isAddableCollection = useCallback(
    (c: BuilderCollection) => {
      if (c.SourceType === SOURCE_TYPE_WORKOUT && data?.DisplayCircuitWorkouts !== '1') {
        return false;
      }
      if ([...EXCLUDED_CIRCUIT_TEMPLATES].includes(c.TemplateId)) {
        return false;
      }
      return true;
    },
    [data],
  );

  const videosBySource = useMemo(() => {
    const obj: Map<string, BuilderVideo[]> = new Map(
      Object.entries({
        [datasource]: [],
        [SOURCE_WORDPRESS]: [],
        [SOURCE_JWPLAYER]: [],
        [SOURCE_VIMEO]: [],
        [SOURCE_YOUTUBE]: [],
      }),
    );
    obj.set(SOURCE_CUSTOMAPI, []); // Sometimes its also the app datasource

    Object.values(videos).forEach((video) => {
      if (obj.has(video.DataSource) && checkVideoType(video, { audio: true, text: true })) {
        obj.get(video.DataSource)?.push(video);
      }
    });
    return obj;
  }, [videos, datasource]);

  const collectionsBySource = useMemo(() => {
    const obj: Map<string, BuilderCollection[]> = new Map(
      Object.entries({
        [datasource]: [],
        [SOURCE_WORDPRESS]: [],
        [SOURCE_JWPLAYER]: [],
        [SOURCE_VIMEO]: [],
        [SOURCE_YOUTUBE]: [],
      }),
    );
    obj.set(SOURCE_CUSTOMAPI, []); // Sometimes its also the app datasource

    if (showVidAppCollections || showWorkouts) {
      obj.set(SOURCE_VIDAPP, []);
    }
    Object.values(collections).forEach((c) => {
      if (!isAddableCollection(c)) {
        return; // skip some collections
      }

      if (obj.has(c.DataSource) && c.TabId !== collectionId) {
        obj?.get(c.DataSource)?.push(c);
      }
    });
    return obj;
  }, [collections, datasource, showVidAppCollections, showWorkouts, isAddableCollection]);

  // The following determines the full list of navigation options in the Navigation Panel
  // All conditional logic on what collections/videos are available is contained here
  const navigationOptions = useMemo(() => {
    if (!collectionsBySource || !videosBySource) {
      return {};
    }

    const outputOptions: Record<string, NavigationOption[]> = {
      [datasource]: [],
      [SOURCE_WORDPRESS]: [
        {
          ...DEFAULT_VIDEOS_VIEW,
          Title: 'Posts',
          VideoOptions: videosBySource.get(SOURCE_WORDPRESS),
        },
        {
          Title: 'Playlists',
          ViewType: 'collections',
          Icon: <FolderIcon />,
          CollectionOptions: collectionsBySource.get(SOURCE_WORDPRESS),
        },
      ],
      [SOURCE_JWPLAYER]: [
        { ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(SOURCE_JWPLAYER) },
        {
          Title: 'Playlists',
          ViewType: 'collections',
          Icon: <FolderIcon />,
          CollectionOptions: collectionsBySource.get(SOURCE_JWPLAYER),
        },
      ],
      [SOURCE_VIMEO]: [
        { ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(SOURCE_VIMEO) },
        {
          Title: 'Albums',
          ViewType: 'collections',
          Icon: <FolderIcon />,
          CollectionOptions: collectionsBySource.get(SOURCE_VIMEO),
        },
      ],
      [SOURCE_YOUTUBE]: [
        { ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(SOURCE_YOUTUBE) },
        {
          Title: 'Playlists',
          ViewType: 'collections',
          Icon: <FolderIcon />,
          CollectionOptions: collectionsBySource.get(SOURCE_YOUTUBE),
        },
      ],
      [SOURCE_CUSTOMAPI]: [
        { ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(SOURCE_CUSTOMAPI) },
        {
          Title: 'Groups',
          ViewType: 'collections',
          Icon: <FolderIcon />,
          CollectionOptions: collectionsBySource.get(SOURCE_CUSTOMAPI),
        },
      ],
      [SOURCE_VIDAPP]: [],
    };
    if (showVidAppCollections) {
      outputOptions[SOURCE_VIDAPP] = [
        {
          Title: `${GROUP_LABEL}s`,
          ViewType: 'collections',
          Icon: <StyledCollectionIcon />,
          CollectionOptions: collectionsBySource
            .get(SOURCE_VIDAPP)
            ?.filter(({ SourceType }) => [SOURCE_TYPE_COLLECTION].includes(SourceType)),
        },
      ];
    }
    if (showWorkouts) {
      outputOptions[SOURCE_VIDAPP].push({
        ViewType: 'collections',
        Title: 'Workouts',
        Icon: <DumbbellIcon />,
        CollectionOptions: collectionsBySource
          .get(SOURCE_VIDAPP)
          ?.filter(({ SourceType }) => [SOURCE_TYPE_WORKOUT].includes(SourceType)),
      });
    }
    // Add VidApp content options
    outputOptions[SOURCE_VIDAPP].push(
      ...[
        { ViewType: 'website' as const, Title: 'Website', Icon: <GlobeIcon /> },
        { ViewType: 'text' as const, Title: 'Page (HTML)', Icon: <TextImageIcon /> },
        { ViewType: 'file' as const, Title: 'File', Icon: <FileBlankIcon /> },
        { ViewType: 'post' as const, Title: 'Post', Icon: <PostIcon /> },
      ],
    );

    if (datasource == SOURCE_KAJABI) {
      /**** KAJABI SPECIFIC LOGIC ****/
      outputOptions[datasource] = [
        {
          ...DEFAULT_VIDEOS_VIEW,
          Title: 'Lessons',
          VideoOptions: videosBySource.get(datasource),
        },
        {
          Title: 'Modules',
          ViewType: 'collections',
          Icon: <FolderIcon />,
          CollectionOptions: collectionsBySource
            ?.get(datasource)
            ?.filter(
              ({ SourceType }) => SourceType === SOURCE_TYPE_KAJABI_CAT || SourceType === SOURCE_TYPE_KAJABI_SUBCAT,
            ),
        },
        {
          Title: 'Courses',
          ViewType: 'collections',
          Icon: <CubeIcon />,
          CollectionOptions: collectionsBySource
            ?.get(datasource)
            ?.filter(({ SourceType }) => SourceType === SOURCE_TYPE_KAJABI_PRODUCT),
        },
      ];
    } else if (datasource === SOURCE_VHX) {
      /**** VHX SPECIFIC LOGIC ****/
      outputOptions[datasource] = [
        { ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(datasource) },
        {
          Title: 'Collections',
          ViewType: 'collections',
          Icon: <StyledCollectionIcon />,
          CollectionOptions: collectionsBySource
            ?.get(datasource)
            ?.filter(
              ({ SourceType, SourceId }) =>
                SourceType !== SOURCE_TYPE_VHX_CAT &&
                SourceType !== SOURCE_TYPE_VHX_SEAS &&
                SourceId !== CATALOG_SOURCE_ID &&
                SourceId !== FEATURED_SOURCE_ID &&
                SourceId !== MY_PRODUCTS_SOURCE_ID,
            ),
        },
        {
          Title: 'Categories',
          ViewType: 'collections',
          Icon: <FolderIcon />,
          CollectionOptions: collectionsBySource
            ?.get(datasource)
            ?.filter(({ SourceType }) => SourceType === SOURCE_TYPE_VHX_CAT),
        },
      ];
    } else if (datasource == SOURCE_USCREEN) {
      /**** USCREEN SPECIFIC LOGIC ***/
      outputOptions[datasource] = [
        { ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(datasource) },
        {
          Title: 'Collections',
          ViewType: 'collections',
          Icon: <FolderIcon />,
          CollectionOptions: collectionsBySource
            ?.get(datasource)
            ?.filter(({ SourceType }) => SourceType !== SOURCE_TYPE_USCREEN_CAT),
        },
        {
          Title: 'Categories',
          ViewType: 'collections',
          Icon: <CubeIcon />,
          CollectionOptions: collectionsBySource
            ?.get(datasource)
            ?.filter(({ SourceType }) => SourceType === SOURCE_TYPE_USCREEN_CAT),
        },
      ];
    } else if (datasource !== SOURCE_VIDAPP) {
      /*** DEFAULT LOGIC (WordPress, Shopify) ***/
      outputOptions[datasource] = [
        { ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(datasource) },
        {
          Title: 'Collections',
          ViewType: 'collections',
          Icon: <StyledCollectionIcon />,
          CollectionOptions: collectionsBySource
            ?.get(datasource)
            ?.filter(
              ({ SourceId }) => ![CATALOG_SOURCE_ID, FEATURED_SOURCE_ID, MY_PRODUCTS_SOURCE_ID].includes(SourceId),
            ),
        },
      ];
    }

    // Exclude any video/collections page with no items
    for (const key of Object.keys(outputOptions)) {
      outputOptions[key] = outputOptions[key].filter((opt) => {
        if (opt.ViewType === 'collections') {
          return opt.CollectionOptions && opt.CollectionOptions.length > 0;
        } else if (opt.ViewType === 'videos') {
          return opt.VideoOptions && opt.VideoOptions.length > 0;
        }
        return true;
      });
      if (outputOptions[key]?.length === 0) {
        delete outputOptions[key];
      }
    }

    return outputOptions;
  }, [collectionsBySource, videosBySource, datasource]);

  return { navigationOptions };
};
