import {
  BuilderIndexedCollections,
  Collection,
  CollectionKey,
  CONTENT_1_SOURCE_ID,
  DataSource,
  FEATURED_SOURCE_ID,
  GROUP_LABEL,
  ITEM_TYPE_SECTIONHEADER,
  KAJABI_LIBRARY_SOURCE_ID,
  SOURCE_JWPLAYER,
  SOURCE_KAJABI,
  SOURCE_TYPE_CIRCUIT,
  SOURCE_TYPE_COLLECTION,
  SOURCE_TYPE_COURSE,
  SOURCE_TYPE_KAJABI_CAT,
  SOURCE_TYPE_KAJABI_PRODUCT,
  SOURCE_TYPE_KAJABI_SUBCAT,
  SOURCE_TYPE_PLAYLIST,
  SOURCE_TYPE_TAG,
  SOURCE_TYPE_USCREEN_CAT,
  SOURCE_TYPE_USCREEN_COL,
  SOURCE_TYPE_VHX_CAT,
  SOURCE_TYPE_VHX_PLAYLIST,
  SOURCE_TYPE_VHX_SEAS,
  SOURCE_TYPE_VHX_SERIES,
  SOURCE_TYPE_WORKOUT,
  SOURCE_USCREEN,
  SOURCE_VHX,
  SOURCE_VIDAPP,
  SOURCE_VIMEO,
  SOURCE_WORDPRESS,
  SOURCE_YOUTUBE,
  SUBNAV_TEMPLATE,
} from 'api';
import { BuilderCollection, BuilderCollectionItem } from 'providers';
import { capitalizeString } from 'utils';

type GetCollectionBySourceId = (id: string, source: string) => BuilderCollection | undefined;

export type CollectionNameProps = Pick<Collection, 'Name' | 'SourceName'>;
export const getCollectionName = (collection: Collection | CollectionNameProps) => {
  return collection.Name ?? collection.SourceName;
};

export const getCollectionSourceName = (collection: Collection | BuilderCollection) =>
  collection.SourceName && collection.SourceName !== '' ? collection.SourceName : collection.Name;

export type CollectionDescriptionProps = Pick<Collection, 'SourceDescription' | 'Description'>;

export const getCollectionHTMLDescription = (collection: Collection | CollectionDescriptionProps) => {
  return (collection.Description || collection.SourceDescription || '').replaceAll('\n', '<br/>');
};

export const isWorkout = (collection: Collection | { SourceType: Collection['SourceType'] }) => {
  return collection?.SourceType === SOURCE_TYPE_WORKOUT;
};

export const isSubNav = (collection: Collection | { TemplateId: Collection['TemplateId'] }) => {
  return collection?.TemplateId === SUBNAV_TEMPLATE;
};

export const collectionIsProtected = (sourceId: string) =>
  [
    'Content1',
    'Content2',
    'Content3',
    'KajabiLibrary',
    'Search',
    'Calendar',
    'MyLists',
    'Profile',
    'Website1',
    'Website2',
    'Catalog',
    'MyProducts',
    'Featured',
    'InProgress',
    'MyPlaylists',
  ].includes(sourceId);

export const getFeaturedCollection = (getCollectionBySourceId: GetCollectionBySourceId) => {
  const vidappFeatured = getCollectionBySourceId(FEATURED_SOURCE_ID, SOURCE_VIDAPP);
  if (vidappFeatured) {
    return vidappFeatured; // If the collection has been edited, a VidApp version will exist, so return this
  } else {
    return getCollectionBySourceId(FEATURED_SOURCE_ID, SOURCE_KAJABI);
  }
};

export const getContent1Collection = (getCollectionBySourceId: GetCollectionBySourceId) => {
  return (
    getCollectionBySourceId(CONTENT_1_SOURCE_ID, SOURCE_VIDAPP) ??
    getCollectionBySourceId(KAJABI_LIBRARY_SOURCE_ID, SOURCE_VIDAPP)
  );
};

export const isContent1Tab = (tab: BuilderCollection) =>
  [CONTENT_1_SOURCE_ID, KAJABI_LIBRARY_SOURCE_ID].includes(tab.SourceId);

export const getContent1SourceId = (getCollectionBySourceId: GetCollectionBySourceId) => {
  if (getCollectionBySourceId(CONTENT_1_SOURCE_ID, SOURCE_VIDAPP)) {
    return CONTENT_1_SOURCE_ID;
  } else if (getCollectionBySourceId(KAJABI_LIBRARY_SOURCE_ID, SOURCE_VIDAPP)) {
    return KAJABI_LIBRARY_SOURCE_ID;
  }
  return undefined;
};

export const getMyPlaylistsTab = (collections: BuilderIndexedCollections) => {
  const collectionArray = Object.values(collections);
  return collectionArray.find((collection) => collection.IsMainTab === 1 && collection.TemplateId === 33);
};

// Updates the positions of a set of collection items to match the order of the array
export const calculateCollectionItemPositions = (items: BuilderCollectionItem[]) => {
  return items.forEach((item, idx) => (item.Position = idx + 1));
};

export const getCollectionValuesFromNewCollection = (collection: BuilderCollection) => {
  const newCollection = { ...collection };

  // None of these properties should be saved for new collections
  const propertiesToDelete: CollectionKey[] = [
    'Checksum',
    'ItemsChecksum',
    'CreatedAt',
    'UpdatedAt',
    'SourceProductIDs',
    'Resources',
  ];
  propertiesToDelete.forEach((property) => {
    delete newCollection[property];
  });

  return Object.entries(newCollection)
    .map(([key, value]) => {
      return { name: key as keyof Collection, value };
    })
    .filter((val) => {
      // Only number and string properties are saved for new collections
      // Other properties are typically calculated and aren't stored values
      const type = typeof val.value;
      return type === 'number' || type === 'string';
    }) as { name: keyof Collection; value: number | string }[];
};

export const isSectionHeaderItem = (item: BuilderCollectionItem) =>
  item.Type.toLowerCase() === ITEM_TYPE_SECTIONHEADER.toLowerCase();

interface SourceTypeMap {
  value: string;
  heading: string;
  pluralHeading?: string;
}

const KAJABI_SOURCE_TYPE_MAP: SourceTypeMap[] = [
  { value: 'category', heading: 'Module' },
  { value: 'sub-category', heading: 'Submodule' },
  { value: 'product', heading: 'Course' },
  { value: 'catalog', heading: 'All Courses', pluralHeading: 'All Courses' },
  { value: 'myproducts', heading: 'My Courses', pluralHeading: 'My Courses' },
];

const USCREEN_SOURCE_TYPE_MAP: SourceTypeMap[] = [
  { value: 'uscreencollection', heading: 'Collection' },
  { value: 'tag', heading: 'Playlist (Tags)', pluralHeading: 'Playlists (Tags)' },
  { value: 'catalog', heading: 'Catalog' },
];

const SOURCE_TYPE_MAP: SourceTypeMap[] = [
  { value: 'album', heading: 'Album' },
  { value: 'bundle', heading: 'Bundle' },
  { value: 'catalog', heading: 'All Products', pluralHeading: 'All Products' },
  { value: 'category', heading: 'Category', pluralHeading: 'Categories' },
  { value: 'chapter', heading: 'Chapter' },
  { value: 'course', heading: 'Course' },
  { value: 'featured', heading: 'Featured', pluralHeading: 'Featured' },
  { value: 'movie', heading: 'Movie' },
  { value: 'myproducts', heading: 'My Products', pluralHeading: 'My Products' },
  { value: 'other', heading: 'Other' },
  { value: 'playlist', heading: 'Playlist' },
  { value: 'product', heading: 'Product' },
  { value: 'program', heading: 'Program' },
  { value: 'season', heading: 'Season' },
  { value: 'series', heading: 'Series', pluralHeading: 'Series' },
  { value: 'tag', heading: 'Playlist (Tags)', pluralHeading: 'Playlists (Tags)' },
  { value: 'text', heading: 'Page (HTML)', pluralHeading: 'Pages (HTML)' },
  { value: 'collection', heading: GROUP_LABEL },
  { value: 'website', heading: 'Website' },
  { value: 'subtab', heading: 'SubTab' },
  { value: 'tab', heading: 'Tab' },
  { value: 'workout', heading: 'Workout' },
  { value: 'circuit', heading: 'Circuit' },
  { value: 'sectionheader', heading: 'Text' },
];

interface MappingOptions {
  pluralize?: boolean;
  defaultName?: string;
}

export const getSourceTypeDisplayName = (sourceType: string, dataSource?: DataSource, options?: MappingOptions) => {
  if (sourceType !== '') {
    if (dataSource === SOURCE_KAJABI) {
      for (const type of KAJABI_SOURCE_TYPE_MAP) {
        if (sourceType?.toLowerCase().startsWith(type.value)) {
          if (options?.pluralize) {
            const displayName = type.pluralHeading ?? `${type.heading}s`;
            return capitalizeString(displayName);
          }

          return capitalizeString(type.heading);
        }
      }
    }

    if (dataSource === SOURCE_USCREEN) {
      for (const type of USCREEN_SOURCE_TYPE_MAP) {
        if (sourceType?.toLowerCase().startsWith(type.value)) {
          if (options?.pluralize) {
            const displayName = type.pluralHeading ?? `${type.heading}s`;
            return capitalizeString(displayName);
          }

          return capitalizeString(type.heading);
        }
      }
    }

    for (const type of SOURCE_TYPE_MAP) {
      if (sourceType?.toLowerCase().startsWith(type.value)) {
        if (options?.pluralize) {
          const displayName = type.pluralHeading ?? `${type.heading}s`;
          return capitalizeString(displayName);
        }

        return capitalizeString(type.heading);
      }
    }
  }

  if (options?.defaultName) {
    return options.defaultName;
  }

  return options?.pluralize ? `${GROUP_LABEL}s` : GROUP_LABEL;
};

// Map of the VidApp equivalent of a collection's SourceType (either SOURCE_TYPE_COURSE, SOURCE_TYPE_COLLECTION or SOURCE_TYPE_PLAYLIST)
export const SOURCE_TYPE_EQUIVALENT_MAP: Record<string, Record<string, string>> = {
  [SOURCE_VIDAPP]: {
    [SOURCE_TYPE_COLLECTION]: SOURCE_TYPE_COLLECTION,
    [SOURCE_TYPE_TAG]: SOURCE_TYPE_PLAYLIST,
    [SOURCE_TYPE_WORKOUT]: SOURCE_TYPE_WORKOUT,
    [SOURCE_TYPE_CIRCUIT]: SOURCE_TYPE_CIRCUIT,
  },
  [SOURCE_KAJABI]: {
    [SOURCE_TYPE_KAJABI_PRODUCT]: SOURCE_TYPE_COURSE,
    [SOURCE_TYPE_KAJABI_CAT]: SOURCE_TYPE_PLAYLIST,
    [SOURCE_TYPE_KAJABI_SUBCAT]: SOURCE_TYPE_PLAYLIST,
    [SOURCE_TYPE_TAG]: SOURCE_TYPE_PLAYLIST,
  },
  [SOURCE_VHX]: {
    [SOURCE_TYPE_VHX_CAT]: SOURCE_TYPE_COURSE,
    [SOURCE_TYPE_VHX_SERIES]: SOURCE_TYPE_COLLECTION,
    [SOURCE_TYPE_VHX_SEAS]: SOURCE_TYPE_PLAYLIST,
    [SOURCE_TYPE_VHX_PLAYLIST]: SOURCE_TYPE_PLAYLIST,
    [SOURCE_TYPE_TAG]: SOURCE_TYPE_PLAYLIST,
  },
  [SOURCE_USCREEN]: {
    [SOURCE_TYPE_USCREEN_CAT]: SOURCE_TYPE_COURSE,
    [SOURCE_TYPE_USCREEN_COL]: SOURCE_TYPE_COLLECTION,
    [SOURCE_TYPE_TAG]: SOURCE_TYPE_PLAYLIST,
  },
};

export const getCollectionSourceTypeEquivalent = (dataSource?: string, sourceType?: string) => {
  if (!dataSource || !sourceType) {
    return SOURCE_TYPE_COLLECTION;
  }
  if ([SOURCE_JWPLAYER, SOURCE_YOUTUBE, SOURCE_VIMEO, SOURCE_WORDPRESS].includes(dataSource)) {
    return SOURCE_TYPE_PLAYLIST;
  }
  if (SOURCE_TYPE_EQUIVALENT_MAP[dataSource] && SOURCE_TYPE_EQUIVALENT_MAP[dataSource][sourceType]) {
    return SOURCE_TYPE_EQUIVALENT_MAP[dataSource][sourceType];
  }
  return SOURCE_TYPE_COLLECTION;
};
