import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { S3_BUCKET } from 'api';
import { APP_FONT_10PX_MEDIUM, APP_FONT_12PX_REGULAR, APP_FONT_22PX_BOLD, APP_FONT_8PX_REGULAR } from 'mockup-font';
import { useLocalAppBasicInfo, useLocalAppProperties } from 'providers';
import { DEFAULT_THUMBNAIL } from 'utils';

import { BuilderProductWithPrice } from './ContentGateView';
import { ProductList } from './ProductList';
import { SUBSCRIPTION_TERMS } from './const';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FeatureImageWrapper = styled.div`
  background-image: url(${DEFAULT_THUMBNAIL});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  flex-grow: 1;
`;

const FeatureImage = styled(FeatureImageWrapper)<{ $backgroundImage: string }>`
  background-image: ${({ $backgroundImage }) => `url(${$backgroundImage})`};
  height: 100%;
  width: 100%;
`;

const Spacer = styled.div`
  height: 84px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  flex-shrink: 1;
  padding: 28px 16px 0;
  overflow-y: auto;
`;

const Heading = styled.div`
  ${APP_FONT_22PX_BOLD};
  margin-bottom: 8px;
  white-space: pre-wrap;
`;

const Subheading = styled(Heading)`
  ${APP_FONT_12PX_REGULAR};
`;

const Terms = styled.div`
  ${APP_FONT_8PX_REGULAR};
  line-height: 11px;
  margin-top: 16px;
`;

const Footer = styled.div<{ $flexGrow: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 28px 16px 34px;
  ${({ $flexGrow }) => $flexGrow && 'flex-grow: 1'};
`;

const SubscribeButton = styled.div<{ $highlightColor: string }>`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: ${({ $highlightColor }) => `#${$highlightColor}`};
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  ${APP_FONT_10PX_MEDIUM};
`;

interface PurchaseScreenProps {
  heading: string;
  subheading: string;
  products: BuilderProductWithPrice[];
  isBto?: boolean;
}

export const PurchaseScreen = ({ heading, subheading, products, isBto }: PurchaseScreenProps) => {
  const { properties } = useLocalAppProperties();
  const { basicInfo } = useLocalAppBasicInfo();

  const [displayImage, setDisplayImage] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const { HighlightHEX, PurchaseScreenImage } = properties;
  const { AppId } = basicInfo;

  const imageUrl = PurchaseScreenImage ? `${S3_BUCKET}${AppId}/images/${PurchaseScreenImage}` : undefined;

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.target === contentRef.current) {
          const height = entry.contentRect.height;
          setDisplayImage(height < 480);
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    // Cleanup on unmount
    return () => {
      if (contentRef.current) {
        resizeObserver.unobserve(contentRef.current);
      }
    };
  }, []);

  return (
    <Container>
      {displayImage && imageUrl ? (
        <FeatureImageWrapper>{<FeatureImage $backgroundImage={imageUrl} />}</FeatureImageWrapper>
      ) : (
        <Spacer />
      )}
      <ContentWrapper ref={contentRef}>
        <Heading>{heading}</Heading>
        <Subheading>{subheading}</Subheading>
        <ProductList key={heading} products={products} isBto={isBto} />
        {!isBto && <Terms>{SUBSCRIPTION_TERMS}</Terms>}
      </ContentWrapper>
      <Footer $flexGrow={!displayImage || !imageUrl}>
        <SubscribeButton $highlightColor={HighlightHEX}>Subscribe</SubscribeButton>
        <FooterLinks>
          <div>Terms & Conditions</div>
          <div>Privacy Policy</div>
          <div>Restore Purchases</div>
        </FooterLinks>
      </Footer>
    </Container>
  );
};
