import { useQuery } from 'react-query';
import { searchAppUsers } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useAppUsers = (userIds?: string[]) => {
  const appId = useAppBeingEdited();
  return useQuery(
    ['app_user', userIds],
    () => {
      return searchAppUsers(appId, userIds!);
    },
    { enabled: !!userIds },
  );
};
