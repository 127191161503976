import styled from 'styled-components';

import { SOURCE_VIDAPP } from 'api';
import { CustomButton, CustomCheckbox, Dropdown } from 'components';
import { CogIcon } from 'icons';
import { NEUTRAL_8_COLOUR } from 'theme';
import { getDataSourceDisplayName } from 'utils';

import { useContentNavigationContext } from 'app/modules/content/providers';

import { FONT_12PX_MEDIUM } from 'font';

const DropdownWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 1px;
  right: 0;
`;

const CogButton = styled(CustomButton)`
  &&&& {
    padding: 0;
    height: 20px;
    svg {
      color: ${NEUTRAL_8_COLOUR};
    }
  }
`;

const DropdownContent = styled.div`
  padding: 5px 0;
`;

const DropdownHeader = styled.div`
  ${FONT_12PX_MEDIUM};
  color: ${NEUTRAL_8_COLOUR};
`;

export const ColumnControls = () => {
  const { activeTab, visibleColumns, handleColumnCheckbox } = useContentNavigationContext();

  if (!activeTab) {
    return null;
  }

  return (
    <DropdownWrapper>
      <Dropdown
        trigger={<CogButton tertiary large icon={<CogIcon />} />}
        content={
          <DropdownContent>
            <DropdownHeader>Display Settings</DropdownHeader>
            <CustomCheckbox
              name="DisplaySourceTitle"
              label={`Display ${getDataSourceDisplayName(activeTab)} Title Column`}
              $marginTop="12px"
              checked
              disabled
            />
            {activeTab !== SOURCE_VIDAPP && (
              <CustomCheckbox
                name="DisplayVidAppTitle"
                label="Display VidApp Title Column"
                $marginTop="12px"
                checked={visibleColumns.vidAppTitle}
                onCheckedChange={(checked: boolean) => handleColumnCheckbox('vidAppTitle', checked)}
              />
            )}
            <CustomCheckbox
              name="DisplayCreatedAt"
              label={`Display ${activeTab === SOURCE_VIDAPP ? 'Created At' : 'First Synced'} Column`}
              $marginTop="12px"
              checked={visibleColumns.createdAt}
              onCheckedChange={(checked: boolean) => handleColumnCheckbox('createdAt', checked)}
            />
            <CustomCheckbox
              name="DisplaySourceId"
              label="Display Source ID Column"
              $marginTop="12px"
              checked={visibleColumns.sourceId}
              onCheckedChange={(checked: boolean) => handleColumnCheckbox('sourceId', checked)}
            />
            {activeTab !== SOURCE_VIDAPP && (
              <CustomCheckbox
                name="DisplayVidAppId"
                label="Display VidApp ID Column"
                $marginTop="12px"
                checked={visibleColumns.vidAppId}
                onCheckedChange={(checked: boolean) => handleColumnCheckbox('vidAppId', checked)}
              />
            )}
          </DropdownContent>
        }
      />
    </DropdownWrapper>
  );
};
