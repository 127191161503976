import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';

import { CalendarType, PublishedType } from 'api';
import { useCalendar } from '../../CalendarPage/hooks/useCalendar';

export const DISPLAY_OPTIONS = ['Videos / Posts', 'Workouts', 'Quotes'];

interface PublishedStatus {
  [key: string]: PublishedType;
}

interface ContextValue {
  calendarData: CalendarType | undefined;
  activeDate: Date;
  setActiveDate: Dispatch<SetStateAction<Date>>;
  displayedItems: CheckboxValueType[];
  setDisplayedItems: Dispatch<SetStateAction<CheckboxValueType[]>>;
  publishedStatus: PublishedStatus;
  setPublishedStatus: Dispatch<SetStateAction<PublishedStatus>>;
  startDate: Date;
  endDate: Date;
}

const CalendarPageContext = createContext<ContextValue | undefined>(undefined);

interface ProviderProps {
  children?: ReactNode;
}

const CalendarPageProvider = ({ children }: ProviderProps) => {
  const { data: calendarData, getCalendarMonthPublished } = useCalendar();
  const [activeDate, setActiveDate] = useState<Date>(new Date());
  const [displayedItems, setDisplayedItems] = useState<CheckboxValueType[]>(DISPLAY_OPTIONS);
  const [publishedStatus, setPublishedStatus] = useState<PublishedStatus>({});

  const dateNow: Date = new Date();
  const startDate: Date = startOfMonth(subMonths(dateNow, 12));
  const endDate: Date = endOfMonth(addMonths(dateNow, 3));

  useEffect(() => {
    const statusFromApi: PublishedStatus = {};
    if (calendarData) {
      Object.keys(calendarData).forEach((month) => {
        statusFromApi[month] = getCalendarMonthPublished(month) ? 1 : 0;
      });
    }
    setPublishedStatus(statusFromApi);
  }, [calendarData]);

  return (
    <CalendarPageContext.Provider
      value={{
        calendarData,
        activeDate,
        setActiveDate,
        displayedItems,
        setDisplayedItems,
        publishedStatus,
        setPublishedStatus,
        startDate,
        endDate,
      }}
    >
      {children}
    </CalendarPageContext.Provider>
  );
};

const useCalendarPageContext = () => {
  const context = useContext(CalendarPageContext);
  if (context === undefined) {
    throw new Error('useCalendarPageContext must be used within a CalendarPageProvider');
  }
  return context;
};

export { CalendarPageProvider, useCalendarPageContext };
