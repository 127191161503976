import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ShepherdTourContext } from 'react-shepherd';
import styled from 'styled-components';

import { ListContainer, SegmentTitle } from 'components';
import { useDataSource } from 'providers';
import { PAYMENT_STATUS_DETAILS_SAVED, SOURCE_KAJABI, SOURCE_VHX, VIDAPP_HELP_URL } from 'api';
import { FONT_12PX_MEDIUM } from 'font';

import { useAppBasicInfo, useAppProperties, usePricingPlan } from 'hooks';
import { useAppBeingEdited } from 'app-context';
import { SalesCall } from 'app/modules/call-scheduling';

import { ChecklistItem } from '.';
import { useTourPrefetch } from 'app/modules/dashboard/Dashboard/hooks/useTourPrefetch';

const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HelpLink = styled.a`
  ${FONT_12PX_MEDIUM};
  margin-bottom: 12px;
`;

export const OnboardingChecklistSimple = () => {
  const [salesCallModalVisible, setSalesCallModalVisible] = useState<boolean>(false);
  const { data: pricingPlan } = usePricingPlan();
  const { data: appProperties } = useAppProperties();
  const appId = useAppBeingEdited();
  const { data } = useAppBasicInfo();
  const tour = useContext(ShepherdTourContext);
  const history = useHistory();
  const dataSource = useDataSource();
  useTourPrefetch();

  return (
    <>
      <HeadingRow>
        <SegmentTitle title="Onboarding Checklist - Stage 1" $marginBottom="12px" />
        <HelpLink target="_blank" rel="noreferrer" href={VIDAPP_HELP_URL}>
          Need Help?
        </HelpLink>
      </HeadingRow>
      <ListContainer $padding="8px 0">
        {[SOURCE_VHX, SOURCE_KAJABI].includes(dataSource) && (
          <ChecklistItem
            title="Complete Wizard"
            description="Complete the wizard to begin syncing your products and customizing your app."
            property="OnboardingWizardCompleted"
            locked
          />
        )}
        <ChecklistItem
          title="Explore the VidApp Builder"
          description="Take a quick tour of our Builder, where you will build, launch and manage your app."
          property="OnboardingChecklistExplore"
          handleClick={() => {
            tour?.start();
          }}
        />
        <ChecklistItem
          title="Preview your app"
          description="Customize your app in the Builder and then preview the changes in the VidApp preview app."
          property="OnboardingChecklistPreview"
          handleClick={() =>
            window.open('https://apps.apple.com/nz/app/vidapp/id1605665168', '_blank', 'noopener,noreferrer')
          }
        />
        <ChecklistItem
          id="Checklist__Item--call"
          title="Book in a call"
          description="To finish onboarding, book in a call with a member of our team."
          property="OnboardingChecklistSalesCall"
          handleClick={() => setSalesCallModalVisible(true)}
        />
        <SalesCall modalVisible={salesCallModalVisible} setModalVisible={setSalesCallModalVisible} />
        <ChecklistItem
          title="Payment"
          description="Before we begin launching your app, please confirm your payment plan."
          property="PaymentDetailsSaved"
          value={(data?.PaymentStatus || 0) >= PAYMENT_STATUS_DETAILS_SAVED}
          isDisabled={!pricingPlan}
          locked
          handleClick={() => window.open(`php/payment.php?app_id=${appId}`, '_blank', 'noopener,noreferrer')}
        />
      </ListContainer>
      <SegmentTitle title="Onboarding Checklist - Stage 2" $marginBottom="12px" />
      <ListContainer $padding="8px 0">
        <ChecklistItem
          title="Set up your Developer Titles"
          description="Follow the instructions here to set up your Developer Titles."
          property="OnboardingChecklistDeveloper"
          isDisabled={appProperties?.PaymentDetailsSaved !== '1'}
          handleClick={() =>
            window.open(
              `https://wearevidapp.zendesk.com/hc/en-nz/sections/4407786120217-Setting-up-your-Apple-and-Google-Developer-accounts`,
              '_blank',
              'noopener,noreferrer',
            )
          }
        />
        <ChecklistItem
          title="Confirm your in-app purchases"
          description="Confirm the in-app purchases that you would like us to set up for you."
          property="OnboardingChecklistIAP"
          isDisabled={appProperties?.PaymentDetailsSaved !== '1'}
        />
        <ChecklistItem
          title="Complete the App Store section"
          description="Complete the App Store Description, Title, and other details in the App Store section."
          property="OnboardingChecklistAppStore"
          isDisabled={appProperties?.PaymentDetailsSaved !== '1'}
          handleClick={() => history.push('/app-store/ios')}
        />
      </ListContainer>
    </>
  );
};
