import styled from 'styled-components';

import { NEUTRAL_5_COLOUR } from 'theme';
import { isUserAdmin } from 'utils';

import { OnboardingStage } from '.';
import { OnboardingStageKey, STAGE_KEY_BUILD, STAGE_KEY_LAUNCH, STAGE_KEY_REVIEW_AND_SUBMISSION } from '../const';
import { useOnboardingChecklistContext } from '../providers/OnboardingChecklistProvider';
import { getNumberOfCompletedSteps, getNumberOfSteps } from '../util';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: 238px;
  padding: 36px 0 7px 32px;
  border-right: 1px solid ${NEUTRAL_5_COLOUR};
`;

export const OnboardingStages = () => {
  const { onboardingStages, activeStage, setActiveStage, currentStageKey, isReadyToRelease } =
    useOnboardingChecklistContext();

  if (!onboardingStages) {
    return null;
  }

  return (
    <Container>
      {Object.entries(onboardingStages).map(([key, stage], idx) => {
        const { title, steps, adminSteps, completedSteps, completedAdminSteps, position } = stage;

        if (!position) {
          return null;
        }

        const isCompleted =
          key === STAGE_KEY_REVIEW_AND_SUBMISSION
            ? isReadyToRelease
            : getNumberOfSteps(stage) === getNumberOfCompletedSteps(stage);

        // For non-admin users, Review and Launch stages will be locked until Build Stage has been completed
        const isLocked =
          !isUserAdmin() && [STAGE_KEY_REVIEW_AND_SUBMISSION, STAGE_KEY_LAUNCH].includes(key)
            ? onboardingStages[STAGE_KEY_BUILD].completedSteps < onboardingStages[STAGE_KEY_BUILD].steps.length
            : false;

        return (
          <OnboardingStage
            key={key}
            title={title}
            position={position}
            isActive={key === activeStage}
            isCurrent={!isUserAdmin() && currentStageKey === key}
            showDivider={idx + 1 < Object.keys(onboardingStages).length}
            handleClick={() => setActiveStage(key as OnboardingStageKey)}
            isCompleted={isCompleted}
            isLocked={isLocked}
            clientTotal={steps.length}
            clientCompleted={completedSteps}
            adminTotal={adminSteps.length}
            adminCompleted={completedAdminSteps}
          />
        );
      })}
    </Container>
  );
};
