import { useState } from 'react';
import styled from 'styled-components';

import { CustomButton } from 'components/Buttons/CustomButton/CustomButton';
import { FONT_14PX_REGULAR } from 'font';
import { SearchIcon, XIcon } from 'icons';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  INPUT_BORDER_FOCUS,
  NEUTRAL_2_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_7_COLOUR,
  NEUTRAL_9_COLOUR,
} from 'theme';

const Wrapper = styled.form<{ $width?: string; $marginBottom?: string }>`
  width: ${({ $width }) => $width || '400px'};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '0px'};
  height: 36px;
  position: relative;
`;

const StyledSearchBar = styled.input`
  &&&& {
    color: ${NEUTRAL_9_COLOUR};
    ${FONT_14PX_REGULAR};
    border: 1px solid ${NEUTRAL_5_COLOUR};
    border-radius: 6px;
    width: 100%;
    height: 100%;
    padding: 4px 84px 4px 38px;

    ::placeholder {
      color: ${NEUTRAL_7_COLOUR};
    }

    :hover {
      background-color: ${NEUTRAL_2_COLOUR};
    }

    :focus,
    :hover {
      box-shadow: none;
      outline: none;
      border: ${INPUT_BORDER_FOCUS};
    }

    transition-property: background-color, border-color;
    transition-duration: 0.3s;
    transition-timing-function: linear;
  }
`;

const StyledButton = styled(CustomButton)`
  &&&& {
    height: 100%;
    top: 0;
    position: absolute;

    :hover {
      color: ${HIGHLIGHT_PRIMARY_COLOUR};
      background: none;
    }
  }
`;

const SearchButton = styled(StyledButton)`
  &&&& {
    right: 9px;
    color: ${NEUTRAL_9_COLOUR};
  }
`;

const XButton = styled(StyledButton)`
  &&&& {
    right: 54px;
    color: ${NEUTRAL_7_COLOUR};
    svg {
      font-size: 19px;
    }
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  &&&& {
    font-size: 19px;
    position: absolute;
    top: 9px;
    left: 12px;
    color: ${NEUTRAL_7_COLOUR};
  }
`;

interface SearchBarProps {
  value?: string;
  onSearch: (value: string) => void;
  placeholder?: string;
  width?: string;
  marginBottom?: string;
}

export const SearchBar = ({ value, onSearch, placeholder, width, marginBottom }: SearchBarProps) => {
  const [val, setVal] = useState<string>(value ?? '');
  return (
    <Wrapper
      onSubmit={(e) => {
        e.preventDefault();
        onSearch(val);
      }}
      $width={width}
      $marginBottom={marginBottom}
    >
      <StyledSearchBar value={val} placeholder={placeholder ?? 'Search...'} onChange={(e) => setVal(e.target.value)} />
      <StyledSearchIcon />
      {val !== '' && (
        <XButton
          medium
          tertiary
          onClick={() => {
            setVal('');
            onSearch('');
          }}
          icon={<XIcon />}
        />
      )}
      <SearchButton small tertiary type="submit">
        Search
      </SearchButton>
    </Wrapper>
  );
};
