import { createContext, useState, useContext, useEffect } from 'react';

import { usePublishContext } from 'providers/index';

interface ContextProps {
  unsavedChanges: boolean;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ProviderProps {
  children: React.ReactNode;
}

const UnsavedChangesContext = createContext<ContextProps>({
  unsavedChanges: false,
  setUnsavedChanges: () => {
    null;
  },
});

const UnsavedChangedProvider = ({ children }: ProviderProps) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const { publishAvailable, setPublishAvailable } = usePublishContext();

  useEffect(() => {
    if (!unsavedChanges && publishAvailable === 'live') {
      setPublishAvailable('preview');
    }
  }, [unsavedChanges]);

  return (
    <UnsavedChangesContext.Provider value={{ unsavedChanges, setUnsavedChanges }}>
      {children}
    </UnsavedChangesContext.Provider>
  );
};

const useUnsavedChanges = () => {
  const context = useContext(UnsavedChangesContext);
  if (context === undefined) {
    throw new Error('useUnsavedChanges must be used within an UnsavedChangesProvider');
  }
  return context;
};

export { UnsavedChangedProvider, useUnsavedChanges };
