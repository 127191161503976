import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Draggable, DraggableProvidedDragHandleProps, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import * as amplitude from '@amplitude/analytics-browser';

import { Collapse, ContentIcon, CustomButton, Tooltip } from 'components';
import {
  DragIcon,
  EyeIcon,
  FileBlankIcon,
  FileLinkedIcon,
  FilledRightArrow,
  InfoCircleIcon,
  PlusIcon,
  SectionIcon,
} from 'icons';
import { OVERFLOW_ELLIPSIS } from 'font';
import {
  HIGHLIGHT_SECONDARY_ORANGE_HOVER_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_4_COLOUR,
  NEUTRAL_7_COLOUR,
  NEUTRAL_8_COLOUR,
  PRIMARY_ORANGE_COLOUR,
} from 'theme';
import { getContentName, isSectionHeaderItem, isSubNav } from 'utils';
import { BuilderCollection, BuilderCollectionItem, BuilderVideo } from 'providers';
import {
  DataSource,
  ITEM_TYPE_COLLECTION,
  ITEM_TYPE_TAB,
  ITEM_TYPE_VIDEO,
  MIXED_TEMPLATE,
  SOURCE_TYPE_BUILDMYAPP,
  SOURCE_TYPE_WORKOUT,
  SOURCE_VIDAPP,
} from 'api';
import { useAppBeingEdited } from 'app-context';

import { useBuilderContext, useContent } from 'app/modules/build-dragdrop/Builder/providers';
import { PageContext, useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup';
import { DRAWER_TYPE_ADD } from 'app/modules/build-dragdrop/Builder/const';
import { TreeVideo } from './TreeVideo';
import { TreeRowContent } from './TreeRowContent';

const StyledCollapse = styled(Collapse)`
  --radix-collapsible-content-height: 100px;

  .radix-collapse-content {
    padding-left: 22px;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 36px;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
`;

interface StyledArrowProps {
  rotate?: boolean;
  $selected?: boolean;
  $hover?: boolean;
  $disabled?: boolean;
}

const StyledArrow = styled(FilledRightArrow)<StyledArrowProps>`
  &&&& {
    font-size: 16px;
    line-height: 16px;
    transform: ${({ rotate }) => (rotate ? 'rotate(90deg) translate(1px, 0px)' : 'rotate(0deg) translate(1px, 2px)')};
    transition: transform 0.1s ease;
    color: ${({ $selected, $hover, $disabled }) =>
      $selected && !$disabled ? PRIMARY_ORANGE_COLOUR : $hover ? NEUTRAL_10_COLOUR : NEUTRAL_7_COLOUR};
  }
`;
const Spacer = styled.div`
  width: 26px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const Wrapper = styled.div`
  margin-top: 8px;
`;

export const Row = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  cursor: pointer;
`;

export const Icon = styled.div<{ $color?: string }>`
  color: ${({ $color }) => $color};
  font-size: 16px;
  margin-right: 4px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const ContentTitle = styled.div<{ $fontWeight?: number }>`
  ${OVERFLOW_ELLIPSIS};
  font-weight: ${({ $fontWeight }) => $fontWeight};
`;

const AddButton = styled(CustomButton)`
  &&&& {
    margin-top: 8px;
    margin-left: 16px;
  }
`;

export const HoverIconContainer = styled.div<{ $width?: string }>`
  width: ${({ $width }) => $width ?? '16px'};
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;

  min-height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
`;
export const StyledDraggableIcon = styled(DragIcon)`
  &&&& {
    font-size: 16px;
    color: ${NEUTRAL_7_COLOUR};
    cursor: grab;
  }
`;
export const StyledEyeIcon = styled(EyeIcon)<{ $selected: boolean }>`
  &&&& {
    margin-left: 4px;
    font-size: 16px;
    color: ${({ $selected }) => ($selected ? PRIMARY_ORANGE_COLOUR : NEUTRAL_10_COLOUR)};
    padding: 2px;

    :hover {
      background-color: ${({ $selected }) => ($selected ? HIGHLIGHT_SECONDARY_ORANGE_HOVER_COLOUR : NEUTRAL_4_COLOUR)};
      border-radius: 6px;
    }
  }
`;

const StyledInfoCircleIcon = styled(InfoCircleIcon)`
  font-size: 16px;
`;
const StyledSectionIcon = styled(SectionIcon)`
  // Required to force the svg fill to not be current color
  svg {
    fill: none;
  }
`;

const getIcon = (
  type: TreeCollectionType,
  sourceType: BuilderCollection['SourceType'],
  dataSource: DataSource,
  linked?: boolean,
) => {
  switch (type) {
    case 'subtab':
      return linked ? <FileLinkedIcon /> : <FileBlankIcon />;
    case 'section':
      if (sourceType === SOURCE_TYPE_BUILDMYAPP && !linked) {
        return <StyledSectionIcon />;
      }
  }
  return (
    <ContentIcon itemType={ITEM_TYPE_COLLECTION} contentType={sourceType} dataSource={dataSource} linked={linked} />
  );
};

type TreeCollectionType = 'subtab' | 'section' | 'collection' | 'subcollection';

interface TreeCollectionProps {
  collection?: BuilderCollection; // undefined when App has bad data
  item?: BuilderCollectionItem;
  parentItem?: BuilderCollectionItem;
  defaultActive?: boolean; // Is active/expanded on initial render
  type: TreeCollectionType;
  singleSubTab?: boolean; // Is the only subtab
  linked?: boolean; // Is a linked collection instead of a custom one
  isDragging?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const TreeCollection = ({
  collection,
  item,
  parentItem,
  defaultActive,
  type,
  singleSubTab,
  linked,
  isDragging,
  dragHandleProps,
}: TreeCollectionProps) => {
  const { getContentForItem, collections } = useContent();
  const [active, setActive] = useState(defaultActive ?? false);
  const [isHovering, setIsHovering] = useState(false);
  const { drawerContext, setDrawerContext, selectedItem, visiblePageId } = useBuilderContext();
  const { setCurrentPage, currentTabId, currentPage } = useMockupContext();
  const appId = useAppBeingEdited();

  useEffect(() => {
    if (currentPage?.subItemId === item?.ChildId) {
      setActive(true);
    }
  }, [currentPage, drawerContext]);

  // Match on TabItemId for sections
  // Match on ItemId for collection details
  // Match on VisiblePageId for subtabs
  const isSelected =
    (!!selectedItem?.tabItemId && selectedItem?.tabItemId === item?.TabItemId?.toString()) || // Dont let this equality check pass on undefined=undefined
    selectedItem?.itemId === collection?.TabId?.toString() ||
    (!selectedItem && visiblePageId === collection?.TabId);

  const isMixed = collection?.TemplateId === MIXED_TEMPLATE;

  const filteredItems = useMemo(() => {
    // Don't allow workouts to be expanded, they are edited in CMS
    if (collection?.SourceType === SOURCE_TYPE_WORKOUT) {
      return [];
    }
    return (
      collection?.Items.filter((i) => {
        if ([ITEM_TYPE_COLLECTION, ITEM_TYPE_TAB, ITEM_TYPE_VIDEO].includes(i.Type) || isSectionHeaderItem(i)) {
          return true;
        }
        return false;
      }) ?? []
    );
  }, [collection?.Items, getContentForItem]);

  const handleTreeMenuNavigation = useCallback(
    (newPage: PageContext | undefined) => {
      setCurrentPage(newPage, { skipStack: true, resetScrollPosition: true });
    },
    [setCurrentPage],
  );

  const handleSubTabClick = useCallback(() => {
    handleTreeMenuNavigation({ subItemId: collection?.TabId, subTabItemId: item?.TabItemId });
    setDrawerContext(undefined);
  }, [collection, item, handleTreeMenuNavigation, setDrawerContext]);

  const handleSectionClick = useCallback(() => {
    const newDrawerContext = {
      itemId: item?.ChildId.toString(),
      tabItemId: item?.TabItemId?.toString() ?? 'unknown',
      drawerType: 'section' as const,
    };
    if (visiblePageId !== item?.TabId && currentTabId) {
      // We need to change the simulator page to display the selected section
      const currentTab = collections[currentTabId];
      handleTreeMenuNavigation(
        isSubNav(currentTab) ? { subItemId: item?.TabId, subTabItemId: parentItem?.TabItemId ?? '' } : undefined,
      );
    }
    // Give the new page a chance to load before updating the selection and expecting it to scroll
    const timeout = setTimeout(() => {
      setDrawerContext(newDrawerContext);
    }, 200);

    return () => clearTimeout(timeout);
  }, [parentItem, item, collections, visiblePageId, currentTabId, handleTreeMenuNavigation, setDrawerContext]);

  const handleCollectionClick = useCallback(() => {
    handleTreeMenuNavigation({
      itemId: collection?.TabId,
      itemType: ITEM_TYPE_COLLECTION,
    });
    setDrawerContext(undefined);
  }, [collection, handleTreeMenuNavigation, setDrawerContext]);

  const handleItemClick = useCallback(() => {
    if (isDragging) {
      return;
    }
    if (type === 'subtab') {
      handleSubTabClick();
    } else if (type === 'section') {
      handleSectionClick();
    } else {
      handleCollectionClick();
    }
  }, [isDragging, handleSubTabClick, handleSectionClick, handleCollectionClick]);

  const handleExpandPanel = () => {
    if (!isDragging) {
      setActive((current) => !current);
    }
  };
  const handleEyeClick = (e: MouseEvent) => {
    e.stopPropagation();
    handleTreeMenuNavigation({
      itemId: collection?.TabId,
      tabItemId: item?.TabItemId,
      itemType: ITEM_TYPE_COLLECTION,
    });
  };

  if (!collection) {
    return null;
  }

  return (
    <StyledCollapse
      isOpen={active}
      hideTrigger
      rowContent={
        <Row
          onClick={handleItemClick}
          onMouseOver={() => {
            setIsHovering(true);
          }}
          onMouseLeave={() => {
            setIsHovering(false);
          }}
          {...dragHandleProps}
        >
          <HoverIconContainer>
            {!!dragHandleProps && (
              <>
                {isHovering && singleSubTab && (
                  <Tooltip
                    title="Create an additional layer of navigation at the top of your tab by  clicking + Add Sub-Tab"
                    icon={<StyledInfoCircleIcon />}
                    align={{ offset: [0, 10] }}
                  />
                )}

                {(isHovering || isDragging) && !singleSubTab && <StyledDraggableIcon />}
              </>
            )}
          </HoverIconContainer>
          <TreeRowContent $selected={isSelected} $hover={isHovering || !!isDragging}>
            <>
              {filteredItems.length > 0 || type === 'subtab' ? (
                <ArrowWrapper
                  onClick={(e) => {
                    e.stopPropagation();
                    handleExpandPanel();
                  }}
                >
                  <StyledArrow $selected={isSelected} $hover={isHovering} rotate={active} />
                </ArrowWrapper>
              ) : (
                <Spacer />
              )}
            </>
            <Icon
              $color={isSelected ? PRIMARY_ORANGE_COLOUR : type === 'subtab' ? NEUTRAL_10_COLOUR : NEUTRAL_8_COLOUR}
            >
              {getIcon(type, collection.SourceType, collection.DataSource, linked)}
            </Icon>
            <ContentTitle $fontWeight={type === 'subtab' ? 600 : 500}>
              {getContentName(collection, ITEM_TYPE_COLLECTION)}
            </ContentTitle>
            <div style={{ flexShrink: 0 }}>
              <StyledEyeIcon
                $selected={isSelected}
                onClick={handleEyeClick}
                style={{
                  visibility:
                    isHovering && type === 'section' && collection.HideFromSearchResults !== 1 ? 'visible' : 'hidden',
                }}
              />
            </div>
          </TreeRowContent>
        </Row>
      }
    >
      {/* A Custom Subtab is rendered with draggable sections*/}
      {!linked ? (
        <Droppable droppableId={`${collection.TabId.toString()}|section`} type={`section_${collection.TabId}`}>
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {collection.Items.map((i, idx) => {
                  const draggableId = `${i.ChildId}|${i.TabItemId}|treesection`;

                  if ([ITEM_TYPE_COLLECTION, ITEM_TYPE_TAB].includes(i.Type)) {
                    const collection = getContentForItem(i) as BuilderCollection;
                    if (!collection) {
                      return null;
                    }
                    return (
                      <Draggable draggableId={draggableId} index={idx} key={draggableId}>
                        {(provided, snapshot) => {
                          return (
                            <Wrapper ref={provided.innerRef} {...provided.draggableProps}>
                              <TreeCollection
                                collection={collection}
                                item={i}
                                parentItem={item}
                                isDragging={snapshot.isDragging}
                                linked={collection.DataSource !== SOURCE_VIDAPP}
                                type={type === 'subtab' && isMixed ? 'section' : 'collection'} // Only Mixed Subtabs can have sections
                                dragHandleProps={provided.dragHandleProps}
                              />
                            </Wrapper>
                          );
                        }}
                      </Draggable>
                    );
                  } else if (i.Type === ITEM_TYPE_VIDEO || isSectionHeaderItem(i)) {
                    const video = getContentForItem(i) as BuilderVideo;
                    if (!video) {
                      return null;
                    }
                    return (
                      <Draggable draggableId={draggableId} index={idx} key={draggableId}>
                        {(provided, snapshot) => {
                          return (
                            <Wrapper ref={provided.innerRef} {...provided.draggableProps}>
                              <TreeVideo
                                video={video}
                                item={i}
                                isDragging={snapshot.isDragging}
                                dragHandleProps={provided.dragHandleProps}
                                parentItem={item}
                              />
                            </Wrapper>
                          );
                        }}
                      </Draggable>
                    );
                  }
                  return null;
                })}
                {provided.placeholder}
                {type === 'subtab' && !linked && (
                  <AddButton
                    medium
                    tertiaryHighlight
                    icon={<PlusIcon />}
                    onClick={() => {
                      amplitude.track('Add section button clicked (side menu)', { appId });
                      if (visiblePageId == collection.TabId) {
                        setDrawerContext({
                          drawerType: DRAWER_TYPE_ADD,
                          tabItemId: selectedItem?.tabItemId?.toString() ?? 'unknown',
                        });
                      } else {
                        handleTreeMenuNavigation({ subItemId: collection.TabId, subTabItemId: item?.TabItemId });
                        setTimeout(() => {
                          setDrawerContext({
                            drawerType: DRAWER_TYPE_ADD,
                            index: collection.Items?.length || 0,
                          });
                        }, 100);
                      }
                    }}
                  >
                    Add
                  </AddButton>
                )}
              </div>
            );
          }}
        </Droppable>
      ) : (
        <>
          {/* Any other collection is rendered with its items (Collections/Videos) */}
          {filteredItems.map((i) => {
            const content = getContentForItem(i);
            return i.Type === ITEM_TYPE_VIDEO || isSectionHeaderItem(i) ? (
              <Wrapper key={`Video_${i.TabItemId}`}>
                <TreeVideo video={content as BuilderVideo} item={i} parentItem={item} />
              </Wrapper>
            ) : (
              <Wrapper key={`Collection__${i.TabItemId}`}>
                <TreeCollection
                  collection={content as BuilderCollection}
                  item={i}
                  linked={linked}
                  type={type === 'section' ? 'collection' : 'subcollection'}
                />
              </Wrapper>
            );
          })}
        </>
      )}
    </StyledCollapse>
  );
};
