import styled from 'styled-components';

import { CALENDAR_TEMPLATE, Collection } from 'api';
import { BellFilledIcon, HamburgerIcon, ListViewIcon, SearchIcon } from 'icons';
import { CustomColor, getImageUrl, getTabNavBarTitleFromCollection, useCustomColor } from 'utils';
import { useAppBeingEdited } from 'app-context';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { BuilderCollection } from 'app/modules/build-dragdrop/Builder/providers';
import { APP_FONT_26PX_BOLD } from 'mockup-font';
import { OVERFLOW_ELLIPSIS } from 'font';

const NavBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
  height: 30px;
`;

const HeaderWrapper = styled.div`
  flex-grow: 1;
`;

/* Logo Version */
const StyledHamburgerIcon = styled(HamburgerIcon)<CustomColor>`
  font-size: 20px;
  stroke: ${useCustomColor};
  width: 30px;
  display: flex !important;
`;
const HeaderLogoWrapper = styled.div<{ $paddingLeft: string }>`
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: ${({ $paddingLeft }) => $paddingLeft};
`;
const Logo = styled.img`
  height: 100%;
`;

/* Text Version */
const NavigationHeader = styled.div<{ $maxWidth: string }>`
  height: 31px;
  max-width: ${({ $maxWidth }) => $maxWidth};
  ${APP_FONT_26PX_BOLD};
  cursor: default;
  ${OVERFLOW_ELLIPSIS};
`;

/* Right Side Icons */
const HeaderIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: fit-content;
  width: 50px;
`;

const StyledBellIcon = styled(BellFilledIcon)<CustomColor>`
  && {
    font-size: 20px;
    stroke: ${useCustomColor};
    stroke-width: 0.2;
  }
`;

const StyledSearchIcon = styled(SearchIcon)<CustomColor>`
  && {
    font-size: 20px;
    stroke: ${useCustomColor};
    margin-left: 8px;
  }
`;

const StyledListIcon = styled(ListViewIcon)<CustomColor>`
  && {
    font-size: 22px;
    color: ${useCustomColor};
  }
`;

export const TabNavBar = ({ tab }: { tab: Collection | BuilderCollection }) => {
  const { getDesignProperty } = useAppTheme();
  const appBeingEdited = useAppBeingEdited();
  const NavBarTitleHEX = getDesignProperty('navBarTitleHex');

  const headerLogoUrl = getImageUrl(appBeingEdited, tab?.Properties?.NavBarTitleImage);
  const navBarTitle = getTabNavBarTitleFromCollection(tab);
  const showHamburgerIcon = tab?.Properties?.ShowSideMenu === '1';
  const showBellIcon = tab?.Properties?.NavBarNotificationsView === '1';
  const showSearchIcon = tab?.Properties?.NavBarFilterView === '1' || tab?.Properties?.NavBarSearchView === '1';
  const showListIcon = tab?.TemplateId === CALENDAR_TEMPLATE;

  return (
    <NavBarWrapper>
      {showHamburgerIcon && <StyledHamburgerIcon $color={NavBarTitleHEX as string} />}
      {(headerLogoUrl || navBarTitle) && (
        <HeaderWrapper>
          {headerLogoUrl ? (
            <HeaderLogoWrapper $paddingLeft={showHamburgerIcon ? '20px' : '50px'}>
              <Logo src={headerLogoUrl} alt="app header logo" />
            </HeaderLogoWrapper>
          ) : (
            <NavigationHeader $maxWidth={showHamburgerIcon ? '296px' : '330px'}>{navBarTitle}</NavigationHeader>
          )}
        </HeaderWrapper>
      )}
      <HeaderIcons>
        {showBellIcon && <StyledBellIcon $color={NavBarTitleHEX as string} />}
        {showSearchIcon && <StyledSearchIcon $color={NavBarTitleHEX as string} />}
        {showListIcon && <StyledListIcon $color={NavBarTitleHEX as string} />}
      </HeaderIcons>
    </NavBarWrapper>
  );
};
