import { useCallback } from 'react';

import { SettingsCheckboxInput } from 'components';
import { useLocalAppProperties, useSaveContext } from 'providers';

const getValueToSave = (checked: boolean, inverseValue?: boolean) => {
  if (inverseValue) {
    return checked ? '0' : '1';
  }
  return checked ? '1' : '0';
};

export const AppPropertyCheckbox = ({
  label,
  property,
  disabled,
  inverseValue,
}: {
  label: string;
  property: string;
  disabled?: boolean;
  inverseValue?: boolean;
}) => {
  const { properties, setAppProperty } = useLocalAppProperties();
  const { setAppPropertyToSave } = useSaveContext();

  const handlePropertyChange = useCallback(
    (property: string, value: string) => {
      setAppProperty(property, value);
      setAppPropertyToSave(property, value);
    },
    [property, setAppProperty, setAppPropertyToSave],
  );

  return (
    <SettingsCheckboxInput
      label={label}
      checked={inverseValue ? properties[property] !== '1' : properties[property] === '1'}
      onChange={(e) => handlePropertyChange(property, getValueToSave(e.target.checked, inverseValue))}
      disabled={disabled}
    />
  );
};
