import { Form } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  ClickToCopy,
  CustomButton,
  PageContainer,
  SettingsCheckboxInput,
  SettingsFileInput,
  SettingsFormItem,
  SettingsGroup,
  SettingsSegmentDivider,
  SettingsSelectInput,
  SettingsTextArea,
  SettingsTextInput,
  Tabs,
} from 'components';
import { useDataSource } from 'providers';

import { FONT_12PX_MEDIUM } from 'font';
import { NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR, SETTINGS_ITEM_MARGIN_BOTTOM } from 'theme';

import {
  CodePushDeployment,
  PAYMENT_STATUS_CHURNED,
  PAYMENT_STATUS_DNE,
  PAYMENT_STATUS_INVOICED,
  PAYMENT_STATUS_TEST,
} from 'api';
import { useSaveAppBasicInfo } from 'hooks';
import { usePaymentStatuses } from 'hooks/usePaymentStatus';
import { useMemo, useState } from 'react';
import { useAdminSettings } from '../hooks/useAdminSettings';
import { useCodePushKeys } from '../hooks/useCodePushKeys';
import { FontInput } from './components/FontInput';
import {
  ANDROID_CUSTOM_FIELDS,
  ANDROID_FIELDS,
  CODE_PUSH_FIELDS,
  Field,
  FORCED_UPDATES_FIELDS,
  GENERAL_FIELDS,
  IOS_FIELDS,
  PROPERTIES,
  ROKU_FIELDS,
  SIGN_UP_FIELDS,
} from './const';

const { Root, List, Trigger, Content } = Tabs;

const TAB_PARAM = 'tab';

const StyledRoot = styled(Root)`
  margin-top: 24px;
`;

const Info = styled.div`
  ${FONT_12PX_MEDIUM};
  color: ${NEUTRAL_9_COLOUR};

  span {
    font-weight: 400;
    color: ${NEUTRAL_7_COLOUR};
  }
`;

const ContainerRow = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
const SpacedButton = styled(CustomButton)`
  &&&& {
    margin-right: 8px;
  }
`;
export const getCodePushFields = (keys?: CodePushDeployment[]) => {
  if (!keys) {
    return [];
  }
  const appleKeys = keys.map((key) => ({ name: key.Version, value: key.AppleKey }));
  const androidKeys = keys.map((key) => ({ name: key.Version, value: key.AndroidKey }));
  const [appleKeyField, androidKeyField] = CODE_PUSH_FIELDS;

  return [
    { ...appleKeyField, options: appleKeys },
    { ...androidKeyField, options: androidKeys },
  ];
};

const getFields = (fieldArr: Field[]) =>
  fieldArr.map(({ type, name, width, label, placeholder, tooltip, options, defaultVal }) => {
    if (type === 'text') {
      return (
        <SettingsFormItem key={name} name={name} width={width || '49%'} $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}>
          <SettingsTextInput label={label} tooltip={tooltip} placeholder={placeholder} autoComplete="off" />
        </SettingsFormItem>
      );
    } else if (type === 'checkbox') {
      return <SettingsCheckboxInput key={name} label={label} name={name} />;
    } else if (type === 'textarea') {
      return (
        <SettingsFormItem key={name} name={name} width={width || '49%'}>
          <SettingsTextArea label={label} rows={3} tooltip={tooltip} placeholder={placeholder} />
        </SettingsFormItem>
      );
    }
    return (
      <SettingsFormItem key={name} name={name} width={width || '49%'}>
        <SettingsSelectInput label={label} tooltip={tooltip} options={options ?? []} defaultVal={defaultVal} />
      </SettingsFormItem>
    );
  });

export const AdminSettings = () => {
  const dataSource = useDataSource();
  const {
    appBasicInfo,
    appProperties,
    form,
    unsavedChanges,
    isSaving,
    isLoading,
    isError,
    initialValues,
    filenames,
    TEMPLATE_FIELDS,
    onFilenameChange,
    onValuesChange,
    saveForm,
  } = useAdminSettings(PROPERTIES, dataSource);
  const { data: statuses } = usePaymentStatuses();
  const saveAppBasicInfo = useSaveAppBasicInfo();
  const { data: keys } = useCodePushKeys();
  const history = useHistory();
  const { search } = useLocation();

  const { CustomCompulsoryTermsLink, AppleAuthKey, AndroidJsonFilename, AndroidStoreFile } = filenames;
  const [confirm, setConfirm] = useState<'live' | 'churn'>();

  const query = new URLSearchParams(search);
  const tabParam = query.get(TAB_PARAM) ?? 'general';

  const pStatus = appBasicInfo?.PaymentStatus || 0;
  const pStatusText = useMemo(() => {
    return statuses?.find((status) => status?.Id === appBasicInfo?.PaymentStatus)?.Name || 'Unknown';
  }, [statuses, appBasicInfo]);

  const handleLive = () => {
    saveAppBasicInfo.mutate({ PaymentStatus: PAYMENT_STATUS_INVOICED });
    setConfirm(undefined);
  };

  const handleChurn = () => {
    if (pStatus < PAYMENT_STATUS_INVOICED) {
      saveAppBasicInfo.mutate({ PaymentStatus: PAYMENT_STATUS_DNE });
    } else {
      saveAppBasicInfo.mutate({ PaymentStatus: PAYMENT_STATUS_CHURNED });
    }
    setConfirm(undefined);
  };

  return (
    <PageContainer
      heading="Settings"
      subheading="Update settings and include API keys to integrate your app with other online platforms."
      isLoading={isLoading}
      isError={isError}
      headingButton={
        <CustomButton medium onClick={() => form?.submit()} loading={isSaving} disabled={!unsavedChanges}>
          Save
        </CustomButton>
      }
    >
      <>
        <ClickToCopy copyValue={appBasicInfo?.AppId ?? ''}>
          <Info>
            App ID: <span>{appBasicInfo?.AppId}</span>
          </Info>
        </ClickToCopy>
        <Info>
          Apple Bundle ID: <span>{appBasicInfo?.AppleBundleId}</span>
        </Info>
        <Info>
          Android Package Name: <span>{appProperties?.AndroidPackageName}</span>
        </Info>
        <ContainerRow>
          <Info>
            Payment Status: <span>{pStatusText}</span>
          </Info>
          <Buttons>
            {![PAYMENT_STATUS_INVOICED, PAYMENT_STATUS_TEST].includes(pStatus) && (
              <Row>
                {confirm === 'live' ? (
                  <>
                    <SpacedButton medium success onClick={handleLive}>
                      Confirm
                    </SpacedButton>
                    <CustomButton medium secondary onClick={() => setConfirm(undefined)}>
                      Cancel
                    </CustomButton>
                  </>
                ) : (
                  <CustomButton medium success onClick={() => setConfirm('live')}>
                    Mark as Live
                  </CustomButton>
                )}
              </Row>
            )}
            {pStatus < PAYMENT_STATUS_CHURNED && (
              <Row>
                {confirm === 'churn' ? (
                  <>
                    <SpacedButton medium danger onClick={handleChurn}>
                      Confirm
                    </SpacedButton>
                    <CustomButton medium secondary onClick={() => setConfirm(undefined)}>
                      Cancel
                    </CustomButton>
                  </>
                ) : (
                  <CustomButton medium danger onClick={() => setConfirm('churn')}>
                    Mark as Churned
                  </CustomButton>
                )}
              </Row>
            )}
          </Buttons>
        </ContainerRow>
        <Form
          name="settings-form"
          onValuesChange={onValuesChange}
          onFinish={saveForm}
          autoComplete="off"
          form={form}
          initialValues={initialValues}
        >
          <StyledRoot
            defaultValue="general"
            value={tabParam}
            onValueChange={(key) => {
              history.push({
                search: `?${TAB_PARAM}=${key}`,
              });
            }}
          >
            <List>
              <Trigger value="general">General</Trigger>
              <Trigger value="ios">iOS</Trigger>
              <Trigger value="android">Android</Trigger>
              {appProperties?.DisplayRoku === 'Yes' && <Trigger value="roku">Roku</Trigger>}
            </List>

            {/* General Tab */}
            <Content value="general">
              <SettingsGroup title="General Settings" wrapRow $marginBottom="32px">
                {getFields(GENERAL_FIELDS)}
              </SettingsGroup>
              <SettingsGroup title="Code Push Settings" wrapRow $marginBottom="32px">
                {getFields(getCodePushFields(keys))}
              </SettingsGroup>
              <SettingsGroup title="Sign Up Screens">{getFields(SIGN_UP_FIELDS)}</SettingsGroup>
              <SettingsSegmentDivider />
              {TEMPLATE_FIELDS[dataSource] && (
                <SettingsGroup title="Default Templates On Sync" wrapRow>
                  {getFields(TEMPLATE_FIELDS[dataSource])}
                </SettingsGroup>
              )}
              <SettingsSegmentDivider />
              <SettingsGroup title="Forced Updates" wrapRow $marginBottom="0">
                {getFields(FORCED_UPDATES_FIELDS)}
              </SettingsGroup>
              <SettingsSegmentDivider />
              <SettingsGroup title="Custom Fonts">
                <FontInput
                  heading="Header"
                  property="CustomFontHeaderApple"
                  filenames={filenames}
                  onFilenameChange={onFilenameChange}
                />
                <FontInput
                  heading="Sub Header"
                  property="CustomFontSubHeaderApple"
                  filenames={filenames}
                  onFilenameChange={onFilenameChange}
                />
                <FontInput
                  heading="Body Bold"
                  property="CustomFontBodyBoldApple"
                  filenames={filenames}
                  onFilenameChange={onFilenameChange}
                />
                <FontInput
                  heading="Body Medium"
                  property="CustomFontBodySemiboldApple"
                  filenames={filenames}
                  onFilenameChange={onFilenameChange}
                />
                <FontInput
                  heading="Body Regular"
                  property="CustomFontBodyApple"
                  filenames={filenames}
                  onFilenameChange={onFilenameChange}
                />
              </SettingsGroup>
              <SettingsSegmentDivider />
              <SettingsGroup title="Compulsory Terms">
                <SettingsFileInput
                  heading="Compulsory Terms Link"
                  filename={CustomCompulsoryTermsLink?.value}
                  acceptedFileTypes="application/pdf"
                  onFilenameChange={(filename) => onFilenameChange('CustomCompulsoryTermsLink', filename)}
                />
              </SettingsGroup>
            </Content>

            {/* iOS Tab */}
            <Content value="ios">
              <SettingsGroup title="iOS Settings" wrapRow $marginBottom="0">
                {getFields(IOS_FIELDS)}
              </SettingsGroup>
              <SettingsGroup>
                <SettingsFileInput
                  heading="Apple Auth Key"
                  filename={AppleAuthKey?.value}
                  useOriginalFilename
                  downloadable
                  filePath={`${appBasicInfo?.AppId}/certs/`}
                  acceptedFileTypes=".p8"
                  onFilenameChange={(filename) => onFilenameChange('AppleAuthKey', filename)}
                />
              </SettingsGroup>
            </Content>

            {/* Android Tab */}
            <Content value="android">
              <SettingsGroup title="Android Settings" wrapRow $marginBottom="0">
                {getFields(ANDROID_FIELDS)}
              </SettingsGroup>
              <SettingsGroup>
                <SettingsFileInput
                  heading="Android Developer Account JSON"
                  filename={AndroidJsonFilename?.value}
                  useOriginalFilename
                  downloadable
                  acceptedFileTypes="application/json"
                  onFilenameChange={(filename) => onFilenameChange('AndroidJsonFilename', filename)}
                />
              </SettingsGroup>
              <SettingsSegmentDivider />
              <SettingsGroup title="Custom Developer Credentials" wrapRow $marginBottom="0">
                {getFields(ANDROID_CUSTOM_FIELDS)}
              </SettingsGroup>
              <SettingsGroup>
                <SettingsFileInput
                  heading="Android Store File"
                  filename={AndroidStoreFile?.value}
                  useOriginalFilename
                  downloadable
                  filePath={`${appBasicInfo?.AppId}/certs/`}
                  acceptedFileTypes=".jks"
                  onFilenameChange={(filename) => onFilenameChange('AndroidStoreFile', filename)}
                />
              </SettingsGroup>
            </Content>

            {/* Roku Tab */}
            {appProperties?.DisplayRoku === 'Yes' && (
              <Content value="roku">
                <SettingsGroup title="Roku Settings" wrapRow>
                  {getFields(ROKU_FIELDS)}
                </SettingsGroup>
              </Content>
            )}
          </StyledRoot>
        </Form>
      </>
    </PageContainer>
  );
};
