import { useCallback, useEffect } from 'react';
import { Form, FormInstance } from 'antd';
import _ from 'lodash';

import { AppProperties, Collection, MIXED_TEMPLATE, SUBNAV_TEMPLATE } from 'api';
import { InfoModal } from 'components';
import { useContent, useLocalAppProperties, useSaveContext } from 'providers';

import { getAppPropertyValue, getCollectionValue, PropertyMap, setInitialFormField } from '../utils';
import { CONTENT_TEMPLATES } from 'app/modules/build-dragdrop/Builder/mockup/const';

const DEBOUNCED_PROPERTIES = ['TabBarTintHEX', 'TabIconNotSelectedHEX'];

/*
  This hook is for use with helping manage settings for a single Tab
  
  Responsibilities
  - Initialize the form with the current state of the settings
  - Provide an onChange function that will 
    - Update the local state
    - Keep the SaveProvider up to date with pending changes
  - Convert values from what the form input supports to what is saved in the DB
  - Debounce changes for specific properties
  
  Dependencies
  - Wrapped in ContentProvider, LocalAppPropertyProvider, SaveProvider
  
  Supported Settings
  - Tab
     - Collection Property
     - Collection Value
  - AppProperty
  
 */

export const useTabSettings = (
  propertyTypes: Record<string, PropertyMap>,
  tabId: Collection['TabId'], // The tab these settings are linked to
  customForm?: FormInstance, // FormInstance that already exists can be injected
) => {
  const { setAppPropertyToSave, setCollectionPropertyToSave, setCollectionValueToSave } = useSaveContext();
  const { setCollectionProperty, setCollectionValue, collections } = useContent();
  const { properties, setAppProperty } = useLocalAppProperties();

  const tab = collections[tabId];
  const isContentTab = [MIXED_TEMPLATE, SUBNAV_TEMPLATE, ...CONTENT_TEMPLATES].includes(tab.TemplateId);

  const [newForm] = Form.useForm();
  const form = customForm ?? newForm;

  // Update local state to match the form state
  // Mark the change as ready to be saved
  const onPropertyChange = useCallback(
    (key: string, value: string) => {
      const propertyType = propertyTypes[key];
      if (propertyType.type === 'app') {
        console.debug('Setting app property', key, value);
        setAppProperty(key, value);
        setAppPropertyToSave(key, value);
      } else if (propertyType.type === 'collection') {
        console.debug('Setting collection value', key, value);
        setCollectionValue(tabId, key as keyof Collection, value);
        setCollectionValueToSave(tabId, key as keyof Collection, value);
      } else if (propertyType.type === 'collectionProperty') {
        console.debug('Setting collection property', key, value);
        setCollectionProperty(tabId, key, value);
        setCollectionPropertyToSave(tabId, key, value);
      }
    },
    [setAppProperty, setCollectionValue, setCollectionProperty],
  );

  const debouncedPropertyChange = useCallback(
    _.debounce((key: string, value: string) => {
      onPropertyChange(key, value);
    }, 500),
    [onPropertyChange],
  );

  // Update the form to match local state
  useEffect(() => {
    form.resetFields();
    for (const property in propertyTypes) {
      const propertyMap = propertyTypes[property];
      // Set form initial values from app properties
      if (propertyMap.type === 'app' && properties) {
        setInitialFormField(form, property, getAppPropertyValue(propertyMap, properties as AppProperties, property));
      } else if ((propertyMap.type === 'collection' || propertyMap.type === 'collectionProperty') && tabId) {
        // Set form initial values from collections/collection properties
        setInitialFormField(form, property, getCollectionValue(propertyMap, tab as Collection, property));
      }
    }
  }, [tabId]);

  // Convert field values to match their format in the database
  const getValueToSave = useCallback(
    (field: string) => {
      const propertyType: PropertyMap['type'] = propertyTypes[field].type;
      const valueType: PropertyMap['valueType'] = propertyTypes[field].valueType;
      if (valueType === 'string') {
        return form.getFieldValue(field);
      } else if (valueType === 'binary') {
        if (propertyType === 'collection') {
          return form.getFieldValue(field) ? 1 : 0;
        } else {
          return form.getFieldValue(field) ? '1' : '0';
        }
      } else if (valueType === 'inverseBinary') {
        if (propertyType === 'collection') {
          return form.getFieldValue(field) ? 0 : 1;
        } else {
          return form.getFieldValue(field) ? '0' : '1';
        }
      } else if (valueType === 'fullHex') {
        return form.getFieldValue(field).hex;
      } else if (valueType === 'hex') {
        return form.getFieldValue(field).hex.substring(1);
      }
    },
    [form],
  );

  // When a form value changes, update the local state with a value ready to be sent to the DB
  const onValuesChange = useCallback(
    (changedValues, _values?, isFile?: boolean) => {
      const key = Object.keys(changedValues)[0];
      if (propertyTypes[key]) {
        const value = isFile ? changedValues[key] : getValueToSave(key);
        if (DEBOUNCED_PROPERTIES.includes(key)) {
          debouncedPropertyChange(key, value);
        } else {
          onPropertyChange(key, value.toString());
          // For Content tabs, set ShowSideMenu CollectionProperty based on NavBarTitle Text - This can be removed once ShowSideMenu toggle is introduced
          if (key === 'NavBarTitleText' && isContentTab) {
            onPropertyChange('ShowSideMenu', value === '' ? '1' : '0');
          }
        }
      }
    },
    [getValueToSave, onPropertyChange, debouncedPropertyChange, form, isContentTab],
  );

  const saveFailed = useCallback((error) => {
    console.error(error);
    InfoModal('Unable To Save', 'An error occurred while saving. Please try again.', 'error');
  }, []);

  return {
    onValuesChange,
    getValueToSave,
    saveFailed,
  };
};
