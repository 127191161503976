import { useState } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group.d';

import { FONT_12PX_MEDIUM, FONT_14PX_REGULAR } from 'font';
import { NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR } from 'theme';
import { DISPLAY_OPTIONS, useCalendarPageContext } from '../../CalendarModal/providers/calendar-page-provider';

const DisplayPanelContainer = styled.div`
  height: fit-content;
  margin-left: 20px;
  margin-top: 71px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${NEUTRAL_5_COLOUR};
`;

const Heading = styled.div`
  width: 163px;
  ${FONT_12PX_MEDIUM};
  color: ${NEUTRAL_10_COLOUR};
`;

const AllCheckbox = styled(Checkbox)`
  &&&& {
    ${FONT_14PX_REGULAR};
    margin-top: 12px;
    color: ${NEUTRAL_10_COLOUR};
  }
`;

const CheckboxGroup = styled(Checkbox.Group)`
  &&&& {
    .ant-checkbox-wrapper {
      ${FONT_14PX_REGULAR};
      margin-top: 12px;
      color: ${NEUTRAL_10_COLOUR};
    }
  }
`;

export const DisplayPanel = () => {
  const { displayedItems, setDisplayedItems } = useCalendarPageContext();
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(true);

  const onChange = (list: CheckboxValueType[]) => {
    setDisplayedItems(list);
    setIndeterminate(!!list.length && list.length < DISPLAY_OPTIONS.length);
    setCheckAll(list.length === DISPLAY_OPTIONS.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setDisplayedItems(e.target.checked ? DISPLAY_OPTIONS : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <DisplayPanelContainer>
      <Heading>Display Settings</Heading>
      <AllCheckbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        All
      </AllCheckbox>
      <CheckboxGroup options={DISPLAY_OPTIONS} value={displayedItems} onChange={onChange} />
    </DisplayPanelContainer>
  );
};
