import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { AppPropertyToSave, saveAppProperties, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks/useRecordAction';

interface SaveAppPropertiesProps {
  saveActionLog?: boolean;
}

export const useSaveAppProperties = (props?: SaveAppPropertiesProps) => {
  const { saveActionLog = true } = props ?? {};
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, AppPropertyToSave[]>(
    (appProperties) => {
      return saveAppProperties(client, appId, appProperties);
    },
    {
      onSuccess: async (response, payload) => {
        await queryClient.invalidateQueries(['appProperties', appId]);
        if (saveActionLog) {
          recordAction.mutate({ action: 'Save App Properties', detail: { propertiesToSave: payload } });
        }
      },
    },
  );
};
