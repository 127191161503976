import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Dispatch, SetStateAction, useCallback } from 'react';
import styled from 'styled-components';

import { NEUTRAL_10_COLOUR, NEUTRAL_3_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { FONT_14PX_REGULAR, FONT_14PX_SEMIBOLD, FONT_16PX_MEDIUM } from 'font';
import { ExclamationCircleIcon, XIcon } from 'icons';
import { useContent, useUnsavedChanges } from 'providers';
import { CalendarItem, ITEM_TYPE_COLLECTION } from 'api';

import { CollectionDetailedItem } from './CollectionDetailedItem';
import { useCalendarModalContext } from '../providers/calendar-modal-provider';
import { SegmentTitle, SettingsTextArea } from 'components';

const Container = styled.div`
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 0 0;
  overflow-y: auto;
  flex-grow: 1;
  background-color: ${NEUTRAL_3_COLOUR};
`;

const Heading = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_14PX_SEMIBOLD};
  margin-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
`;
const ItemsScrollView = styled.div`
  overflow-y: auto;
`;

const DeleteIcon = styled(XIcon)`
  color: ${NEUTRAL_10_COLOUR};
  font-size: 16px;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${NEUTRAL_7_COLOUR};
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 33px;
`;
const EmptyTitle = styled.div`
  ${FONT_16PX_MEDIUM};
  margin-top: 8px;
`;
const EmptySubtitle = styled.div`
  ${FONT_14PX_REGULAR};
  margin-top: 4px;
  max-width: 150px;
  overflow: hidden;
`;

const QuoteWrapper = styled.div`
  padding: 0 20px;
`;

const StyledTextArea = styled(SettingsTextArea)`
  &&&& {
    padding: 8px;
    margin: 5px 0 22px;
    font-size: 14px !important;
    line-height: 20px !important;
    background: none;
  }
`;

interface ContentPreviewProps {
  items: CalendarItem[];
  setItems: Dispatch<SetStateAction<ContentPreviewProps['items']>>;
}

export const ContentPreview = ({ items, setItems }: ContentPreviewProps) => {
  const { modalDate, removeItem, quote, setQuote } = useCalendarModalContext();
  const { collections, videos } = useContent();
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source, draggableId } = result;
      if (!destination || destination.index === source.index) {
        return;
      }
      if (!unsavedChanges) {
        setUnsavedChanges(true);
      }
      setItems((currentItems) => {
        const updatedItems = [...currentItems];
        const draggedItem = updatedItems.find((item) => item.Id.toString() === draggableId);
        updatedItems.splice(source.index, 1);
        if (draggedItem) {
          updatedItems.splice(destination.index, 0, draggedItem);
        }
        return updatedItems.map((item, idx) => ({ ...item, Position: idx + 1 }));
      });
    },
    [setItems],
  );

  return (
    <Container>
      <QuoteWrapper>
        <SegmentTitle title="Scheduled Quote (optional)" />
        <StyledTextArea
          value={quote}
          rows={4}
          placeholder="Write your quote here..."
          autoSize={{ minRows: 4, maxRows: 4 }}
          onChange={(e) => {
            if (!unsavedChanges) {
              setUnsavedChanges(true);
            }
            setQuote(e.target.value);
          }}
        />
      </QuoteWrapper>
      <Heading>Scheduled Content</Heading>
      {items?.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`${modalDate}|CustomiseContent`} type="details">
            {(provided) => {
              return (
                <ItemsScrollView ref={provided.innerRef} {...provided.droppableProps}>
                  {items
                    .map((calendarItem, idx) => {
                      const item =
                        calendarItem.Type === ITEM_TYPE_COLLECTION
                          ? collections[calendarItem.Id]
                          : videos[calendarItem.Id];

                      if (item) {
                        return (
                          <CollectionDetailedItem
                            context="CustomiseContent"
                            item={item}
                            type={calendarItem.Type}
                            idx={idx}
                            key={calendarItem.Id}
                          >
                            <DeleteIcon onClick={() => removeItem(idx)} />
                          </CollectionDetailedItem>
                        );
                      }
                      return null;
                    })
                    .filter((item) => item !== null)}
                  {provided.placeholder}
                </ItemsScrollView>
              );
            }}
          </Droppable>
        </DragDropContext>
      ) : (
        <EmptyContainer>
          <ExclamationCircleIcon />
          <EmptyTitle>No Content</EmptyTitle>
          <EmptySubtitle>You haven't scheduled any content yet</EmptySubtitle>
        </EmptyContainer>
      )}
    </Container>
  );
};
