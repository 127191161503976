import { useMemo } from 'react';
import { Datum } from '@nivo/line';
import styled from 'styled-components';

import { CustomTable, TableColumn } from 'components';
import { FONT_12PX_MEDIUM } from 'font';

const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  border-radius: 8px;
  margin-bottom: 40px;

  .StyledRow {
    border-bottom: none;
  }
`;

const Cell = styled.div`
  height: 56px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  ${FONT_12PX_MEDIUM};
`;

const LoadingContainer = styled.div`
  height: 96px;
`;

export interface AnalyticsTableProps {
  data?: Datum[];
  total?: number | string;
  isPercentage?: boolean;
  getFormattedDate: (value: string, fullDate?: boolean) => string;
}

export const AnalyticsTable = ({ data, total, isPercentage, getFormattedDate }: AnalyticsTableProps) => {
  const row = useMemo(() => {
    const obj: Record<string, number> = {};

    if (data) {
      data.forEach(({ x, y }) => {
        if (x) {
          obj[x as string] = y as number;
        }
      });
    }

    return obj;
  }, [data]);

  if (!data || !total) {
    return <LoadingContainer />;
  }

  const columns: TableColumn<Datum>[] = data.map(({ x }) => {
    const key = x as string;
    return {
      heading: getFormattedDate(key, true),
      width: 'grow',
      minWidth: 108,
      render: (row) => {
        const value = row[key];
        return <Cell>{!value ? '' : isPercentage ? `${value}%` : value.toLocaleString()}</Cell>;
      },
    };
  });

  return (
    <ScrollContainer>
      <CustomTable
        columns={[
          {
            heading: 'Total',
            width: 'grow',
            minWidth: 108,
            render: () => <Cell>{!total ? '' : isPercentage ? `${total}%` : total.toLocaleString()}</Cell>,
          },
          ...columns,
        ]}
        data={[{ ...row, id: 'viewershipData' }]}
        tableWidth="fit-content"
        tableMinWidth="100%"
      />
    </ScrollContainer>
  );
};
