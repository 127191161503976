import { format } from 'date-fns';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CustomButton, Modal, ModalBodyNavigationGroup, ModalCollectionOptions, ModalVideoOptions } from 'components';
import { FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';
import { getDataSourceDisplayName } from 'utils';
import { INPUT_BORDER_RADIUS, NEUTRAL_10_COLOUR, NEUTRAL_3_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { BuilderCollection, BuilderVideo, useUnsavedChanges } from 'providers';

import { useCalendarModalContext } from './providers/calendar-modal-provider';
import { useModalOptions } from './hooks/useModalOptions';
import { CalendarMonth, ITEM_TYPE_COLLECTION, ITEM_TYPE_VIDEO, saveCalendar, useAxiosInstance } from 'api';
import { useCalendar } from '../CalendarPage/hooks/useCalendar';
import { ContentPreview } from './components';
import { useAppBeingEdited } from 'app-context';
import { useQueryClient } from 'react-query';

export interface NavigationOption {
  Title: string;
  Icon: ReactNode;
  ViewType: 'collections' | 'videos'; // Which view to show
  /* Collections or Videos for collections/videos array */
  CollectionOptions?: BuilderCollection[];
  VideoOptions?: BuilderVideo[];
}

type ActiveContext = NavigationOption & { key: string };

const ModalHeading = styled.div`
  ${FONT_16PX_MEDIUM};
  color: ${NEUTRAL_9_COLOUR};
  padding: 20px;
  border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
`;
const ModalBody = styled.div`
  display: flex;
  color: ${NEUTRAL_10_COLOUR};
  height: 516px;
`;
const ModalActions = styled.div`
  display: flex;
  justify-content: right;
  padding: 20px 24px;
  border-top: 1px solid ${NEUTRAL_5_COLOUR};
`;

const CancelButton = styled(CustomButton)`
  &&&& {
    margin-right: 8px;
  }
`;

const SaveButton = styled(CustomButton)`
  &&&& {
    font-weight: 500;
    border-radius: ${INPUT_BORDER_RADIUS};
  }
`;

const Message = styled.div`
  margin: 20px 20px 3px;
  padding: 12px;
  ${FONT_14PX_REGULAR};
  color: ${NEUTRAL_10_COLOUR};
  background-color: ${NEUTRAL_3_COLOUR};
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 6px;
`;

const BodyNavigation = styled.div`
  padding: 20px;
  width: 186px;
  height: 100%;
  border-right: 1px solid ${NEUTRAL_5_COLOUR};
  overflow-y: auto;
`;

const LeftPanel = styled.div`
  width: 379px;
  height: 100%;
`;

export const CalendarModal = () => {
  const axiosInstance = useAxiosInstance();
  const appId = useAppBeingEdited();
  const queryClient = useQueryClient();
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();
  const {
    modalDate,
    setModalDate,
    isSaving,
    setIsSaving,
    addItem,
    editedItems,
    setEditedItems,
    quote,
    setQuote,
    modalPublishedStatus,
  } = useCalendarModalContext();
  const { getCalendarDay } = useCalendar();

  useEffect(() => {
    if (modalDate) {
      const day = getCalendarDay(modalDate);
      setEditedItems(day?.Items ?? []);
      setQuote(day?.Quote ?? '');
    }
  }, [modalDate]);

  // This determines which page is rendered, the key is the identifier that knows which page is highlighted
  const [activeContext, setActiveContext] = useState<ActiveContext>();
  // This determines which collections/videos are available to add in this modal
  const { navigationOptions } = useModalOptions();

  // The content that already exists in the current date
  const includedVideos = useMemo(() => {
    return editedItems.filter((item) => item.Type === ITEM_TYPE_VIDEO).map((item) => item.Id);
  }, [editedItems]);
  const includedCollections = useMemo(() => {
    return editedItems.filter((item) => item.Type === ITEM_TYPE_COLLECTION).map((item) => item.Id);
  }, [editedItems]);

  const handleSave = useCallback(async () => {
    if (modalDate) {
      setIsSaving(true);

      const dataToSave: CalendarMonth = {
        [format(modalDate, 'yyyy-MM-dd')]: {
          Published: modalPublishedStatus,
          Quote: quote,
          Items: editedItems,
        },
      };
      await saveCalendar(axiosInstance, appId, dataToSave).then((response) => {
        if (response.status === 201) {
          setModalDate(undefined);
          queryClient.invalidateQueries(['calendar', appId]);
        }
        setIsSaving(false);
        setUnsavedChanges(false);
      });
    }
  }, [
    modalDate,
    editedItems,
    quote,
    modalPublishedStatus,
    axiosInstance,
    appId,
    queryClient,
    setModalDate,
    saveCalendar,
    setIsSaving,
    setUnsavedChanges,
  ]);

  // Set the default selected page
  useEffect(() => {
    if (Object.entries(navigationOptions).length > 0 && !activeContext) {
      const entries = Object.entries(navigationOptions);
      const [heading, opts] = entries[0];
      setActiveContext({ ...opts[0], key: `${heading}_${0}` });
    }
  }, [navigationOptions, activeContext, setActiveContext]);

  const videoOptionsView = (availableVideos: BuilderVideo[]) => (
    <ModalVideoOptions
      height={281}
      availableVideos={availableVideos}
      includedVideos={includedVideos}
      onVideoClick={(id) => {
        if (!includedVideos.includes(id as number)) {
          addItem({ Type: ITEM_TYPE_VIDEO, Id: id as number });
        }
      }}
      heading="All In-App Content"
    />
  );

  const collectionOptionsView = (availableCollections: BuilderCollection[]) => (
    <ModalCollectionOptions
      height={281}
      availableCollections={availableCollections}
      includedCollections={includedCollections}
      onCollectionClick={(id) => {
        if (!includedCollections.includes(id as number)) {
          addItem({ Type: ITEM_TYPE_COLLECTION, Id: id as number });
        }
      }}
      heading="All In-App Content"
    />
  );

  const switchViews = (activeContext: ActiveContext) => {
    switch (activeContext.ViewType) {
      case 'collections':
        return collectionOptionsView(activeContext?.CollectionOptions ?? []);
      case 'videos':
        return videoOptionsView(activeContext?.VideoOptions ?? []);
    }
  };

  return (
    <Modal open={!!modalDate} $height="100%" $contentWidth="100%" $padding="0" forceRender>
      {modalDate && (
        <>
          <ModalHeading>{format(modalDate, 'do MMMM yyyy')}</ModalHeading>
          <ModalBody>
            <BodyNavigation>
              {Object.entries(navigationOptions).map(([heading, options]) => {
                const formattedHeading = getDataSourceDisplayName(heading);
                // Details of Nav
                const navOptions = options?.map((opt, idx) => ({
                  name: opt.Title,
                  icon: opt.Icon,
                  id: `${heading}_${idx}`,
                }));
                return (
                  <ModalBodyNavigationGroup
                    key={heading}
                    heading={formattedHeading}
                    options={navOptions}
                    activeKey={activeContext?.key}
                    onOptionClick={(key) => setActiveContext({ ...options[parseInt(key.split('_')[1])], key })}
                  />
                );
              })}
            </BodyNavigation>
            <LeftPanel>
              <Message>
                Content that has been synced to the Builder, but has not been Published into your Live app, can not be
                added to the Calendar Scheduler. Publish content to the app to view it here.
              </Message>
              {activeContext && switchViews(activeContext)}
            </LeftPanel>
            <ContentPreview items={editedItems} setItems={setEditedItems} />
          </ModalBody>
          <ModalActions>
            <CancelButton
              medium
              tertiary
              onClick={() => {
                setUnsavedChanges(false);
                setModalDate(undefined);
              }}
            >
              Cancel
            </CancelButton>
            <SaveButton onClick={handleSave} disabled={!unsavedChanges} loading={isSaving} medium>
              Confirm
            </SaveButton>
          </ModalActions>
        </>
      )}
    </Modal>
  );
};
