import { Dispatch, SetStateAction, useMemo } from 'react';
import styled from 'styled-components';

import { CustomButton, Dropdown, DropdownOptionInterface } from 'components';
import { PlusIcon } from 'icons';

import { AvailableFilter } from './FilterDropdown';

const StyledButton = styled(CustomButton)`
  margin-bottom: 28px;
`;

interface AddFilterProps {
  availableFilters: AvailableFilter[];
  visibleFilters: Record<string, boolean>;
  setVisibleFilters: Dispatch<SetStateAction<Record<string, boolean>>>;
}

export const AddFilter = ({ availableFilters, visibleFilters, setVisibleFilters }: AddFilterProps) => {
  const options: DropdownOptionInterface[] = useMemo(() => {
    const inactive = availableFilters.filter(({ key }) => !visibleFilters[key]);

    return inactive.map(({ key, title, icon }) => ({
      label: title,
      icon,
      onClick: () => setVisibleFilters((prevState) => ({ ...prevState, [key]: true })),
    }));
  }, [visibleFilters, availableFilters, setVisibleFilters]);

  if (options.length === 0) {
    return null;
  }

  return (
    <Dropdown
      trigger={
        <StyledButton tertiary medium icon={<PlusIcon />}>
          Add Filter
        </StyledButton>
      }
      options={options}
      align="start"
      padding="16px"
      menuMarginTop="-30px"
      menuMarginLeft="107px"
    />
  );
};
