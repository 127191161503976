import { Dispatch, SetStateAction, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { SOURCE_KAJABI } from 'api';
import { DragIcon } from 'icons';
import { NEUTRAL_10_COLOUR, NEUTRAL_3_COLOUR, NEUTRAL_7_COLOUR } from 'theme';

import { TreeRowControls, TreeRowDetails } from '.';
import { useContentNavigationContext } from '../../../providers';
import { TreeItem } from '../ContentCollectionTree';

const Row = styled.div<{ $clickable: boolean; $isDragging?: boolean; $isEditingTitle?: boolean }>`
  width: 100%;
  min-height: 42px;
  max-height: 70px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${NEUTRAL_10_COLOUR};
  border-radius: 8px;
  position: relative;
  background-color: ${({ $isDragging }) => ($isDragging ? NEUTRAL_3_COLOUR : '#fff')};
  cursor: ${({ $clickable }) => ($clickable ? 'pointer !important' : 'default !important')};

  :hover {
    background-color: ${NEUTRAL_3_COLOUR};

    .tree-row-controls {
      ${({ $isEditingTitle }) => !$isEditingTitle && 'display: flex'};
    }

    .circuit-row-buttons {
      opacity: 1;
    }
  }
`;

const DragHandle = styled.div`
  position: absolute;
  left: -18px;
  width: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
`;

const StyledDraggableIcon = styled(DragIcon)`
  &&&& {
    font-size: 16px;
    color: ${NEUTRAL_7_COLOUR};
  }
`;

interface TreeRowProps {
  item: TreeItem;
  parentTabId: string | number;
  parentDataSource: string;
  setActiveKeys: Dispatch<SetStateAction<(string | number)[]>>;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isEmpty?: boolean;
  isOpen?: boolean;
  isDragging?: boolean;
  handleTriggerClick?: () => void;
}

export const TreeRow = (props: TreeRowProps) => {
  const { item, dragHandleProps, isDragging } = props;
  const { navigate, blockTreeNavigation } = useContentNavigationContext();
  const [isHovering, setIsHovering] = useState(false);
  const { type, breadcrumbs, dataSource } = item;

  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const clickable =
    !isEditingTitle &&
    !blockTreeNavigation &&
    (((typeof item.itemId === 'number' || !item.itemId.includes('Temp')) &&
      ['collection', 'workout', 'video'].includes(type)) ||
      (type === 'post' && dataSource === SOURCE_KAJABI));

  const handleClick = () => {
    if (clickable) {
      navigate(breadcrumbs.join('/'));
    }
  };

  const onMouseOver = () => {
    setIsHovering(true);
  };
  const onMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <Row
      $clickable={clickable}
      $isDragging={isDragging}
      $isEditingTitle={isEditingTitle}
      onClick={handleClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      {...dragHandleProps}
    >
      {!!dragHandleProps && (
        <DragHandle onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
          {(isHovering || isDragging) && <StyledDraggableIcon />}
        </DragHandle>
      )}
      <TreeRowDetails isEditingTitle={isEditingTitle} setIsEditingTitle={setIsEditingTitle} {...props} />
      <TreeRowControls setIsEditingTitle={setIsEditingTitle} {...props} />
    </Row>
  );
};
