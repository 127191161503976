import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { SOURCE_TYPE_CIRCUIT, SOURCE_TYPE_WORKOUT, SOURCE_VIDAPP } from 'api';
import { useUnsavedChanges } from 'providers';
import { useAppBasicInfo, useAppProperties } from 'hooks';

export const CONTENT_ROOT_PATH = '/content';

type ActiveId = number | string;

interface ActiveItem {
  id: ActiveId;
  type: 'collection' | 'video' | 'workout';
}

type ColumnKey = 'vidAppTitle' | 'createdAt' | 'sourceId' | 'vidAppId';

const DEFAULT_COLUMN_STATE = { vidAppTitle: true, createdAt: true, sourceId: true, vidAppId: false };

interface ContextProps {
  activeItem: ActiveItem | undefined;
  activeTab: string | undefined;
  activeSubTab: string | undefined;
  page: number;
  pageSize: number;
  breadcrumbs: string[] | undefined;
  searchValue: string;
  tempTabId: string;
  blockTreeNavigation: boolean;
  CMSEnabled?: boolean;
  circuitsEnabled?: boolean;
  visibleColumns: Record<ColumnKey, boolean>;
  setActiveItem: Dispatch<SetStateAction<ActiveItem | undefined>>;
  collectionIsEditable: (dataSource?: string, sourceType?: string) => boolean;
  navigate: (id: ActiveId | undefined, replaceStack?: boolean) => void;
  handleSearch: (value: string) => void;
  changeTab: (type: 'datasource' | 'filter', key: string | undefined) => void;
  setPage: Dispatch<SetStateAction<number>>;
  setPageSize: Dispatch<SetStateAction<number>>;
  setTempTabId: Dispatch<SetStateAction<string>>;
  setBlockTreeNavigation: Dispatch<SetStateAction<boolean>>;
  goToNewCollection: (newTabId: number, newName: string) => void;
  handleColumnCheckbox: (key: ColumnKey, value: boolean) => void;
}

interface ProviderProps {
  children: React.ReactNode;
}

const defaultFunction = () => {
  console.warn('Unexpected function call content-navigation-provider');
};

const ContentNavigationContext = createContext<ContextProps>({
  activeItem: undefined,
  activeTab: undefined,
  activeSubTab: undefined,
  page: 1,
  pageSize: 25,
  breadcrumbs: [],
  searchValue: '',
  tempTabId: '',
  blockTreeNavigation: false,
  visibleColumns: DEFAULT_COLUMN_STATE,
  setActiveItem: defaultFunction,
  collectionIsEditable: () => false,
  navigate: defaultFunction,
  handleSearch: defaultFunction,
  changeTab: defaultFunction,
  setPage: defaultFunction,
  setPageSize: defaultFunction,
  setTempTabId: defaultFunction,
  setBlockTreeNavigation: defaultFunction,
  goToNewCollection: defaultFunction,
  handleColumnCheckbox: defaultFunction,
});

const ContentNavigationProvider = ({ children }: ProviderProps) => {
  const { unsavedChanges } = useUnsavedChanges();
  const { data: appProperties } = useAppProperties();
  const { isMigratedLegacy } = useAppBasicInfo();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);

  // Control which screen is displayed in ContentInternal
  const [activeItem, setActiveItem] = useState<ActiveItem>(); // If set, ContentCollectionView, ContentVideoView or ContentWorkoutView will be displayed based on type
  const [tempTabId, setTempTabId] = useState(''); // TempTabId being used while new collection is being drafted
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>(); // Breadcrumb navigation of content being edited
  const [blockTreeNavigation, setBlockTreeNavigation] = useState(false); // Block navigation within Content tree while collections are in draft state

  // If none of the above states are defined, ContentInternal will display tables of collections and videos
  const [activeTab, setActiveTab] = useState<string>(); // Controls which DataSource tab is selected
  const [activeSubTab, setActiveSubTab] = useState<string>(); // Controls which collection/video type tab is selected
  const [page, setPage] = useState(1); // Controls table pagination
  const [pageSize, setPageSize] = useState(25); // Controls table pagination

  // Control which table columns are shown in SyncedContent
  const [visibleColumns, setVisibleColumns] = useState<Record<ColumnKey, boolean>>(DEFAULT_COLUMN_STATE);

  const handleColumnCheckbox = (key: ColumnKey, value: boolean) => {
    setVisibleColumns((prevState) => ({ ...prevState, [key]: value }));
  };

  const CMSEnabled = appProperties?.DisplaySimpleCMS === '1' || isMigratedLegacy;

  // Update breadcrumb navigation when pathname changes
  useEffect(() => {
    const path = pathname.slice(-1) === '/' ? pathname.slice(0, -1) : pathname; // Remove trailing / if there is one
    const pathArr = path.slice(1).split('/');
    setBreadcrumbs(pathArr);
  }, [pathname]);

  // Unblock navigation after save/discard
  useEffect(() => {
    if (blockTreeNavigation && !unsavedChanges) {
      setBlockTreeNavigation(false);
    }
  }, [unsavedChanges, blockTreeNavigation]);

  const handleSearch = useCallback(
    (value: string) => {
      const searchInput = value.toLowerCase();
      const datasourceParam = `?datasource=${query.get('datasource') ?? activeTab ?? SOURCE_VIDAPP}`;
      const filterParam = query.get('filter') ? `&filter=${query.get('filter')}` : '';
      const searchParam = searchInput !== '' ? `&search=${searchInput}` : '';

      history.replace({
        search: `${datasourceParam}${filterParam}${searchParam}`,
      });
    },
    [history, query],
  );

  const navigate = useCallback(
    (id: ActiveId | undefined, replaceStack?: boolean) => {
      if (id) {
        if (replaceStack) {
          history.push(`${id}`, { invalidateQueries: unsavedChanges, showUnsavedChangesPrompt: true });
        } else if (pathname.slice(-1) === '/') {
          history.push(`${pathname}${id}`, { invalidateQueries: unsavedChanges, showUnsavedChangesPrompt: true });
        } else {
          history.push(`${pathname}/${id}`, { invalidateQueries: unsavedChanges, showUnsavedChangesPrompt: true });
        }
      } else {
        history.push(CONTENT_ROOT_PATH, { invalidateQueries: unsavedChanges, showUnsavedChangesPrompt: true });
      }
    },
    [unsavedChanges, history, pathname],
  );

  // After a new collection has been saved, navigate to the new collection and update the breadcrumb item to use the permanent tab ID
  const goToNewCollection = useCallback(
    (newTabId: number) => {
      const path = pathname.slice(0, pathname.lastIndexOf('/'));
      history.push(`${path}/${newTabId}`);
    },
    [history, pathname],
  );

  const changeTab = useCallback(
    (type: 'datasource' | 'filter', key: string | undefined) => {
      if (type === 'datasource') {
        setActiveTab(key);
        if (key) {
          history.replace({
            search: `?${type}=${key}`,
          });
        }
      } else {
        setActiveSubTab(key);
        const datasourceParam = `?datasource=${query.get('datasource') ?? activeTab ?? SOURCE_VIDAPP}`;
        if (key) {
          history.replace({
            search: `${datasourceParam}&${type}=${key}`,
          });
        }
      }
    },
    [history, query, activeTab, setActiveTab, setActiveSubTab],
  );

  useEffect(() => {
    const dataSourceParam = query.get('datasource');
    const filterParam = query.get('filter');

    if (dataSourceParam && dataSourceParam !== activeTab) {
      setActiveTab(dataSourceParam);
    }

    if (filterParam && filterParam !== activeSubTab) {
      setActiveSubTab(filterParam);
    }
  }, [query]);

  const collectionIsEditable = (dataSource?: string, sourceType?: string) =>
    (dataSource === SOURCE_VIDAPP && CMSEnabled) ||
    sourceType === SOURCE_TYPE_WORKOUT ||
    sourceType === SOURCE_TYPE_CIRCUIT;

  return (
    <ContentNavigationContext.Provider
      value={{
        activeItem,
        activeTab,
        activeSubTab,
        page,
        pageSize,
        breadcrumbs,
        searchValue: query.get('search') ?? '',
        tempTabId,
        blockTreeNavigation,
        CMSEnabled,
        circuitsEnabled: appProperties?.DisplayCircuitWorkouts === '1',
        visibleColumns,
        setActiveItem,
        collectionIsEditable,
        navigate,
        handleSearch,
        changeTab,
        setPage,
        setPageSize,
        setTempTabId,
        setBlockTreeNavigation,
        goToNewCollection,
        handleColumnCheckbox,
      }}
    >
      {children}
    </ContentNavigationContext.Provider>
  );
};

const useContentNavigationContext = () => {
  const context = useContext(ContentNavigationContext);
  if (context === undefined) {
    throw new Error('useContentNavigationContext must be used within a ContentNavigationProvider');
  }
  return context;
};

export { ContentNavigationProvider, useContentNavigationContext };
