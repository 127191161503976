import { ReactNode, useState } from 'react';
import { LinkProps } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Content, Portal, Root, Trigger } from '@radix-ui/react-dropdown-menu';

import { CustomButton } from 'components';
import { CUSTOM_SCROLLBAR, NEUTRAL_5_COLOUR } from 'theme';
import { CheckIcon } from 'icons';
import { FONT_12PX_REGULAR } from 'font';

const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const StyledTrigger = styled(Trigger)`
  /* Reset */
  all: unset;
  height: fit-content;
`;

interface StyledContentProps {
  $minWidth?: string;
  $maxWidth?: string;
  $marginTop?: string;
  $marginLeft?: string;
  $padding?: string;
}

const StyledContent = styled(Content)<StyledContentProps>`
  /* Reset */

  button {
    font-family: unset;
  }

  min-width: ${({ $minWidth }) => ($minWidth ? `calc(${$minWidth} - 34px)` : 'unset')};
  max-width: ${({ $maxWidth }) => ($maxWidth ? `calc(${$maxWidth} - 34px)` : 'unset')};
  margin-top: ${({ $marginTop }) => $marginTop || '4px'};
  margin-left: ${({ $marginLeft }) => $marginLeft || 'unset'};
  padding: ${({ $padding }) => $padding || '11px 8px 11px 16px'};
  background-color: #fff;
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 8px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.05);
  z-index: 10;

  /* Animation */
  transform-origin: var(--radix-dropdown-menu-content-transform-origin);
  animation: ${scaleIn} 0.2s ease-out;
`;

const ScrollContainer = styled.div<{ $maxHeight?: string }>`
  max-height: ${({ $maxHeight }) => $maxHeight || '60vh'};
  overflow-y: auto;
  ${CUSTOM_SCROLLBAR};
  padding-right: 8px;
`;

export const DropdownOption = styled(CustomButton)<{ $small?: boolean }>`
  &&&& {
    width: 100%;
    justify-content: flex-start;
    user-select: none;
    ${({ $small }) => $small && FONT_12PX_REGULAR};

    :not(:last-child) {
      margin-bottom: 2px;
    }
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  &&&& {
    margin-left: 4px !important;
    margin-right: 0 !important;
  }
`;

export interface DropdownOptionInterface {
  label: string;
  labelElement?: ReactNode;
  link?: LinkProps;
  icon?: ReactNode;
  onClick?: () => void;
  keepMenuOpen?: boolean; // Don't close the dropdown menu after clicking this option
  danger?: boolean;
  isActive?: boolean;
}

interface DropdownProps {
  trigger: ReactNode;
  options?: DropdownOptionInterface[]; // Either provide an array of options to be used in default button format
  content?: ReactNode; // or provide custom content
  menuMinWidth?: string;
  menuMaxWidth?: string;
  menuMaxHeight?: string;
  menuMarginTop?: string;
  menuMarginLeft?: string;
  onOpenChange?: (open: boolean) => void;
  disabled?: boolean;
  isOpen?: boolean; // Optionally override internal open state
  align?: 'start' | 'end';
  padding?: string;
  small?: boolean;
}

export const Dropdown = ({
  trigger,
  options,
  content,
  menuMinWidth,
  menuMaxWidth,
  menuMaxHeight,
  menuMarginTop,
  menuMarginLeft,
  onOpenChange,
  disabled,
  isOpen,
  align = 'end',
  padding,
  small,
}: DropdownProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Root
      open={isOpen ?? open}
      onOpenChange={(open) => {
        setOpen(open);
        onOpenChange && onOpenChange(open);
      }}
      modal={false}
    >
      <StyledTrigger asChild>
        <div>{trigger}</div>
      </StyledTrigger>
      <Portal>
        <StyledContent
          $minWidth={menuMinWidth}
          $maxWidth={menuMaxWidth}
          $marginTop={menuMarginTop}
          $marginLeft={menuMarginLeft}
          $padding={padding}
          align={align}
        >
          <ScrollContainer $maxHeight={menuMaxHeight}>
            {options?.map(({ label, labelElement, onClick, keepMenuOpen, isActive, ...props }) => (
              <DropdownOption
                key={label}
                tertiary
                medium
                disabled={disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  !keepMenuOpen && setOpen(false);
                  onClick && onClick();
                }}
                {...props}
                $small={small}
              >
                {labelElement ?? label}
                {isActive && <StyledCheckIcon />}
              </DropdownOption>
            ))}
            {content}
          </ScrollContainer>
        </StyledContent>
      </Portal>
    </Root>
  );
};
