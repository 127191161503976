import { MouseEvent, MutableRefObject, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useDraggable } from 'react-use-draggable-scroll';
import { Typography } from 'antd';

import {
  contentHasPreviewVideo,
  getCollectionName,
  getContentName,
  getContentOverlay,
  isSectionHeader,
  usePointerOnClick,
} from 'utils';
import { APP_FONT_16PX_MEDIUM } from 'mockup-font';
import { NEUTRAL_4_COLOUR } from 'theme';
import { Collection, CollectionItem, ThumbnailType, Video } from 'api';
import { useAppProperties } from 'hooks';
import { BuilderCollection, BuilderCollectionItem, BuilderVideo, useContent } from 'providers';

import { THUMBNAIL_RADIUS, UNTITLED_BLOCK_NAME } from 'app/modules/build-dragdrop/Builder/const';
import { ContentThumbnail } from 'app/modules/build-dragdrop/Builder/components';
import { isItemClickable } from 'app/modules/build-dragdrop/Builder/util';

import { useAppCollectionContext } from '../../providers/app-collection-provider';
import { useAppTheme } from '../../hooks';
import { CollectionTitle } from './CollectionTitle';

const Row = styled.div`
  display: flex;
  justify-content: left;
  overflow-x: auto;
  padding-left: 12px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

interface CollectionRowItemProps {
  content?: BuilderCollection | BuilderVideo | Video | Collection;
  item: BuilderCollectionItem | CollectionItem;
}

const ROW_ITEM_WIDTH = 'unset';

const getHeight = (type?: ThumbnailType) => {
  if (type === 'portrait') {
    return '226px';
  }
  if (type === 'square') {
    return '138px';
  }
  return '138px';
};
const getWidth = (type?: ThumbnailType) => {
  if (type === 'portrait') {
    return '150px';
  }
  if (type === 'square') {
    return '138px';
  }
  return '245px';
};

const RowItemWrapper = styled.div`
  margin-right: 16px;
  display: flex;
  flex-direction: column;
`;

interface TitleProps {
  $color: string;
}

const RowItemTitle = styled(Typography.Paragraph)<TitleProps>`
  &&&& {
    ${APP_FONT_16PX_MEDIUM};
    max-width: ${ROW_ITEM_WIDTH};
    color: ${({ $color }) => $color};
    margin-bottom: 0;
  }
`;
const getThumbnailCss = (type?: ThumbnailType) => {
  return css`
    width: ${getWidth(type)};
    height: ${getHeight(type)};
    margin-bottom: 8px;
  `;
};
const StyledThumbnail = styled(ContentThumbnail)`
  ${({ type }) => getThumbnailCss(type)};
  cursor: ${usePointerOnClick};
`;
const DummyThumbnail = styled.div<{ type: ThumbnailType }>`
  ${({ type }) => getThumbnailCss(type)};
  background-color: ${NEUTRAL_4_COLOUR};
  border-radius: ${THUMBNAIL_RADIUS};
`;

const getThumbnailType: (templateId: number) => ThumbnailType = (templateId) => {
  if ([46, 49].includes(templateId)) {
    return 'square';
  }
  if ([45, 48].includes(templateId)) {
    return 'portrait';
  }
  return 'landscape';
};

export const CollectionRowItem = ({ content, item }: CollectionRowItemProps) => {
  const { onItemClick } = useAppCollectionContext();
  const { getDesignProperty } = useAppTheme();
  const { data: appProperties } = useAppProperties();
  const { templateId } = useAppCollectionContext();

  if (!content || isSectionHeader(content)) {
    return null;
  }
  return (
    <RowItemWrapper>
      <StyledThumbnail
        thumbnail={content}
        size="medium"
        onClick={onItemClick && isItemClickable(content, item) ? () => onItemClick(item) : undefined}
        overlayText={getContentOverlay(content, appProperties)}
        showPreviewTag={contentHasPreviewVideo(content)}
        type={getThumbnailType(templateId)}
      />
      <RowItemTitle $color={getDesignProperty('primaryItemHEX') as string} ellipsis={{ rows: 2 }}>
        {getContentName(content, item.Type)}
      </RowItemTitle>
    </RowItemWrapper>
  );
};

export const DummyRowItem = () => {
  const { getDesignProperty } = useAppTheme();
  const { templateId } = useAppCollectionContext();

  return (
    <RowItemWrapper>
      <DummyThumbnail type={getThumbnailType(templateId)} />
      <RowItemTitle $color={getDesignProperty('primaryItemHEX') as string}>Heading</RowItemTitle>
    </RowItemWrapper>
  );
};

export const CollectionHorizontalRow = () => {
  const { getContentForItem } = useContent();
  const { collection } = useAppCollectionContext();
  const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);

  const handleMouseDown = (event: MouseEvent<HTMLElement>) => {
    events.onMouseDown(event);
    // Prevent page scrolling up and down while dragging the row
    event.stopPropagation();
  };

  return (
    <>
      <CollectionTitle title={collection ? getCollectionName(collection) : UNTITLED_BLOCK_NAME} padding="0px 14px" />
      <Row onMouseDown={handleMouseDown} ref={ref}>
        {collection && collection.Items && collection.Items.length > 0 ? (
          collection.Items.slice(0, 200).map((item) => (
            <CollectionRowItem
              content={getContentForItem(item)}
              item={item}
              key={`${item.TabItemId}|${item.Position}`}
            />
          ))
        ) : (
          <>
            <DummyRowItem />
            <DummyRowItem />
          </>
        )}
      </Row>
    </>
  );
};
