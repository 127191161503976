import { AxiosInstance } from 'axios';

import { getUserEmail } from 'utils';

import { ETL_URL, SOURCE_KAJABI } from './const';
import { builderAPI } from 'api/axios';

export interface GetProductsResponse {
  products: Product[];
  product_by_product_id: Map<string, Product>;
}

export interface Product {
  Id: number;
  AppId: string;
  ProductId: string;
  ProductIdAndroid?: string;
  ProductIdWeb?: string;
  ReferenceName: string;
  ProductType: string;
  ReadyForSale: string;
  ReadyForSaleAndroid?: string;
  ReadyForSaleWeb?: string;
  AvailableForLogin: string;
  PriceTier: string;
  DisplayName: string;
  Description: string;
  SubscriptionDuration: string;
  SubscriptionTrial: string;
  IsIndividualPurchase: number;
  DataSource: string;
  SourceProductId: string;
  CustomDisplayName: string;
  SourceProductType?: string;
  SubscriptionGroup: string;
  WebPriceUSD?: number;
  RentalDays?: number;
  Items: ProductItem[];
}

export interface ProductItem {
  AppId: string;
  ProductId: string;
  ChildId: number;
  Type: 'all' | 'tab' | 'collection' | 'video';
  AddedBy?: string;
  Id?: number;
}

export const getProducts = (client: AxiosInstance, appId: string) => {
  return client
    .get<Product[]>(`${builderAPI.contentApiURL}/data/${appId}/builder/products?update_cache=false&no_emoji=false`, {
      headers: {
        accept: 'application/json',
      },
    })
    .then(({ data }) => {
      const PRODUCT_BY_PRODUCT_ID = new Map(data.map((obj) => [obj.ProductId, obj]));

      return {
        products: data,
        product_by_product_id: PRODUCT_BY_PRODUCT_ID,
      };
    });
};

export const updateProduct = async (client: AxiosInstance, appId: string, productValues: Partial<Product>) => {
  return client.put(`/content/product/${appId}`, JSON.stringify(productValues));
};

export const deleteProduct = async (client: AxiosInstance, appId: string, productId: Product['Id']) => {
  return client.delete(`/content/product/${appId}/${productId}`);
};

export const syncProducts = (client: AxiosInstance, appId: string, dataSource: string) => {
  const payload: {
    AppId: string;
    DataSource: string;
    Initiator?: string;
  } = {
    AppId: appId,
    DataSource: dataSource,
  };

  if (dataSource === SOURCE_KAJABI) {
    payload.Initiator = getUserEmail();
  }

  return client.post(`${ETL_URL}/extract-products-for-app`, payload);
};
