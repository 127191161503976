import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useState } from 'react';

import { CalendarItem } from 'api';
import { useUnsavedChanges } from 'providers';

interface ContextValue {
  modalDate?: Date;
  setModalDate: Dispatch<SetStateAction<Date | undefined>>;
  modalPublishedStatus: 0 | 1;
  setModalPublishedStatus: Dispatch<SetStateAction<0 | 1>>;
  isSaving: boolean;
  setIsSaving: Dispatch<SetStateAction<boolean>>;
  editedItems: CalendarItem[];
  setEditedItems: Dispatch<SetStateAction<CalendarItem[]>>;
  quote: string;
  setQuote: Dispatch<SetStateAction<string>>;
  addItem: (item: CalendarItem) => void;
  removeItem: (idx: number) => void;
}

const CalendarModalContext = createContext<ContextValue | undefined>(undefined);

interface ProviderProps {
  children?: ReactNode;
}

const CalendarModalContextProvider = ({ children }: ProviderProps) => {
  const [modalDate, setModalDate] = useState<Date>();
  const [modalPublishedStatus, setModalPublishedStatus] = useState<0 | 1>(0);
  const [isSaving, setIsSaving] = useState(false);
  const [editedItems, setEditedItems] = useState<CalendarItem[]>([]);
  const [quote, setQuote] = useState('');
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();

  const addItem = useCallback(
    (item: CalendarItem) => {
      if (!unsavedChanges) {
        setUnsavedChanges(true);
      }
      setEditedItems((current) => {
        return [...current, item];
      });
    },
    [setEditedItems, unsavedChanges, setUnsavedChanges],
  );
  const removeItem = useCallback(
    (idx: number) => {
      if (!unsavedChanges) {
        setUnsavedChanges(true);
      }
      setEditedItems((currentItems) => {
        const updatedItems = [...currentItems];
        updatedItems.splice(idx, 1);
        return updatedItems;
      });
    },
    [setEditedItems, unsavedChanges, setUnsavedChanges],
  );

  return (
    <CalendarModalContext.Provider
      value={{
        modalDate,
        setModalDate,
        modalPublishedStatus,
        setModalPublishedStatus,
        isSaving,
        setIsSaving,
        editedItems,
        setEditedItems,
        quote,
        setQuote,
        addItem,
        removeItem,
      }}
    >
      {children}
    </CalendarModalContext.Provider>
  );
};

const useCalendarModalContext = () => {
  const context = useContext(CalendarModalContext);
  if (context === undefined) {
    throw new Error('useCalendarModalContext must be used within a CalendarModalContextProvider');
  }
  return context;
};

export { CalendarModalContextProvider, useCalendarModalContext };
