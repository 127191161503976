import { css } from 'styled-components';

import { FONT_12PX_REGULAR, FONT_14PX_REGULAR, FONT_16PX_REGULAR } from 'font';

// Brand Colours
export const VIDAPP_GRADIENT = 'linear-gradient(90deg, #F4845F 0%, #F16A75 100%)';
export const PRIMARY_ORANGE_COLOUR = '#F4845F';
export const PRIMARY_ORANGE_HOVER_COLOUR = '#F06A3D';
export const SECONDARY_ORANGE_COLOUR = '#FFF9F7';
export const SECONDARY_ORANGE_HOVER_COLOUR = '#FDEDE7';

// Neutral Colours
export const NEUTRAL_1_COLOUR = '#FFF';
export const NEUTRAL_2_COLOUR = '#FAFAFA';
export const NEUTRAL_3_COLOUR = '#F7F8F9';
export const NEUTRAL_4_COLOUR = '#EEF0F2';
export const NEUTRAL_5_COLOUR = '#E0E3E6';
export const NEUTRAL_6_COLOUR = '#BEC3C9'; // DISABLED
export const NEUTRAL_7_COLOUR = '#7B7F85';
export const NEUTRAL_8_COLOUR = '#56595D';
export const NEUTRAL_9_COLOUR = '#3D3F42';
export const NEUTRAL_10_COLOUR = '#242628';
export const NEUTRAL_11_COLOUR = '#1E1F20';
export const NEUTRAL_12_COLOUR = '#141415';
export const NEUTRAL_13_COLOUR = '#000';
export const NEUTRAL_TEXT_COLOUR = '#8c8c8c';

// Conditions
export const HIGHLIGHT_PRIMARY_COLOUR = '#1890FF';
export const HIGHLIGHT_PRIMARY_HOVER_COLOUR = '#0077E5';
export const HIGHLIGHT_PRIMARY_ACTIVE_COLOUR = '#065EAF';
export const HIGHLIGHT_SECONDARY_HOVER_COLOUR = '#E5F2FF';
export const HIGHLIGHT_SECONDARY_COLOUR = '#F0F7FF';
export const HIGHLIGHT_SECONDARY_ACTIVE_COLOUR = '#C6E2FF';
export const HIGHLIGHT_PRIMARY_ORANGE_COLOUR = 'rgba(244, 143, 69, 0.05)';
export const HIGHLIGHT_SECONDARY_ORANGE_HOVER_COLOUR = '#FDEDE7';
export const SUCCESS_COLOUR = '#1AC45B';
export const SUCCESS_HOVER_COLOUR = '#19AD5B';
export const SUCCESS_ACTIVE_COLOUR = '#168B4A';
export const SECONDARY_SUCCESS_COLOUR = '#E8F9EF';

export const WARNING_COLOUR = '#FAD414';
export const DANGER_COLOUR = '#FF4D4F';
export const SECONDARY_DANGER_COLOUR = '#FFEDED';
export const DANGER_HOVER_COLOUR = '#FF7875';

// Misc colors
export const MODAL_OVERLAY_COLOUR = 'rgba(2, 7, 20, 0.45)';

// Input styling
export type InputSize = 'small' | 'middle' | 'large';
export const INPUT_HEIGHT_LARGE = '42px';
export const INPUT_HEIGHT_MIDDLE = '36px';
export const INPUT_HEIGHT_SMALL = '30px';
export const INPUT_BORDER = `1px solid ${NEUTRAL_5_COLOUR}`;
export const INPUT_BORDER_FOCUS = `1px solid ${NEUTRAL_6_COLOUR}`;
export const INPUT_BORDER_RADIUS = '6px';

export const SHARED_INPUT_STYLES = css`
  border: ${INPUT_BORDER};
  border-radius: ${INPUT_BORDER_RADIUS};

  :hover {
    border: ${INPUT_BORDER} !important;
    background-color: ${NEUTRAL_2_COLOUR};

    input {
      background-color: ${NEUTRAL_2_COLOUR};
    }
  }
`;

export const INPUT_STYLES_SMALL = css`
  ${SHARED_INPUT_STYLES};
  height: ${INPUT_HEIGHT_SMALL};
  ${FONT_12PX_REGULAR};
`;
export const INPUT_STYLES_MIDDLE = css`
  ${SHARED_INPUT_STYLES};
  height: ${INPUT_HEIGHT_MIDDLE};
  ${FONT_14PX_REGULAR};
`;
export const INPUT_STYLES_LARGE = css`
  ${SHARED_INPUT_STYLES};
  height: ${INPUT_HEIGHT_LARGE};
  ${FONT_16PX_REGULAR};
`;

export const INPUT_STYLES = { small: INPUT_STYLES_SMALL, middle: INPUT_STYLES_MIDDLE, large: INPUT_STYLES_LARGE };

export const NAV_BAR_OFFSET = '48px';
export const SIDE_NAV_WIDTH = '244px';
export const DEFAULT_PAGE_PADDING = '32px';
export const DEFAULT_PAGE_PADDING_SMALL = '28px 24px';
export const SETTINGS_ITEM_MARGIN_BOTTOM = '40px';
export const PAGE_CONTAINER_NARROW_WIDTH = '831px';
export const PAGE_CONTAINER_MIDDLE_WIDTH = { small: '1052px', large: '1052px' };
export const PAGE_CONTAINER_WIDE_WIDTH = { small: '1484px', large: '1484px' };
export const BREAKPOINT_0 = { minWidth: '1280px', minHeight: '764px' };
export const BREAKPOINT_1 = { minWidth: '1400px', minHeight: '794px' };
export const BREAKPOINT_2 = { minWidth: '1512px', minHeight: '899px' };
export const BREAKPOINT_3 = { minWidth: '1621px', minHeight: '968px' };
export const BREAKPOINT_4 = { minWidth: '1728px', minHeight: '1034px' };

export const STYLING_ID = 'react-app';

// Scrollbar with small neutral coloured thumb and invisible track
export const CUSTOM_SCROLLBAR = css`
  ::-webkit-scrollbar {
    background: transparent;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${NEUTRAL_5_COLOUR};
    border-radius: 32px;
    margin-right: 8px;
  }
`;
