import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { deleteProduct, Product, useAxiosInstance } from 'api';
import { useRecordAction } from 'hooks';

interface DeleteProductProps {
  invalidateQuery?: boolean;
  saveActionLog?: boolean;
}

export const useDeleteProduct = (props?: DeleteProductProps) => {
  const { invalidateQuery = true, saveActionLog = true } = props ?? {};
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse, AxiosError, Product['Id']>(
    (productId) => {
      return deleteProduct(client, appId, productId);
    },
    {
      onSuccess: async (_response, productId) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['products', appId]);
        }
        if (saveActionLog) {
          recordAction.mutate({ action: 'Delete Product', detail: { ProductId: productId } });
        }
      },
    },
  );
};
