import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { ColumnModal } from 'components';
import { InfoCircleIcon } from 'icons';
import { useSecureAppProperties } from 'hooks';
import { NEUTRAL_10_COLOUR, NEUTRAL_8_COLOUR, WARNING_COLOUR } from 'theme';
import { FONT_14PX_REGULAR, FONT_16PX_SEMIBOLD } from 'font';

interface AddJWPlayerModalProps {
  jwModal: boolean;
  setJwModal: Dispatch<SetStateAction<boolean>>;
}

const StyledIcon = styled(InfoCircleIcon)`
  color: ${WARNING_COLOUR} !important;
  font-size: 35px;
`;

const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
`;
const Title = styled.div`
  padding-top: 16px;
  ${FONT_16PX_SEMIBOLD};
  color: ${NEUTRAL_10_COLOUR};
`;
const BodyText = styled.div`
  ${FONT_14PX_REGULAR};
  color: ${NEUTRAL_8_COLOUR};
`;

export const AddJWPlayerModal = ({ jwModal, setJwModal }: AddJWPlayerModalProps) => {
  const { data: secureAppProperties } = useSecureAppProperties();

  return (
    <ColumnModal
      cols={2}
      primaryText="Proceed"
      open={jwModal}
      onOpenChange={(o) => setJwModal(o)}
      onCancel={() => setJwModal(false)}
      onConfirm={() => window.open(`https://dashboard.jwplayer.com/p/${secureAppProperties?.JWPlayerSiteId}/media`)}
      align="full"
      hideTrigger
      hideDialogActionsBorder
    >
      <MainWrapper>
        <StyledIcon />
        <Title>Open JW Player in a new tab?</Title>
        <BodyText>
          To add videos to VidApp you will first need to add them in JW Player and then sync JW Player with VidApp.
        </BodyText>
      </MainWrapper>
    </ColumnModal>
  );
};
