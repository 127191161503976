import { Modal } from 'antd';
import { customAlphabet } from 'nanoid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Filter, SOURCE_KAJABI } from 'api';
import {
  CustomButton,
  CustomLink,
  CustomTableFixedColumn,
  HighlightText,
  ListContainer,
  PageContainer,
  SearchBar,
  TableColumn,
  Thumbnail,
} from 'components';
import { FONT_10PX_MEDIUM, FONT_12PX_MEDIUM, FONT_12PX_SEMIBOLD, FONT_16PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { useCollections, useVideos } from 'hooks';
import { FolderIcon, ForwardIcon, PenIcon, VideoIcon } from 'icons';
import { useDataSource } from 'providers';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_4_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_7_COLOUR,
  NEUTRAL_9_COLOUR,
  PAGE_CONTAINER_WIDE_WIDTH,
} from 'theme';

import { ContentTaggingDrawer, HoverCard, TagSelectInput } from './components';
import { useContentTagging } from './hooks/useContentTagging';

const nanoid = customAlphabet('123456789', 16);

const CategoryColumnHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_12PX_SEMIBOLD};
`;

const StyledPencilIcon = styled(PenIcon)<{ disabled: boolean }>`
  &&& {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px !important;
    color: ${({ disabled }) => (disabled ? `${NEUTRAL_7_COLOUR} !important` : 'inherit')};

    :hover {
      background-color: ${NEUTRAL_4_COLOUR};
    }
  }
`;

const Cell = styled.div`
  min-height: 39px;
  padding: 8px 2px 0 12px;
`;

const NameCell = styled(Cell)`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 1px;

  span {
    ${FONT_12PX_MEDIUM};
  }
`;

const NameWrapper = styled.span`
  ${OVERFLOW_ELLIPSIS};
`;

const HoverCardContent = styled.div`
  display: flex;
  align-items: center;
  ${FONT_12PX_MEDIUM}
`;

const TypeCell = styled.span`
  padding: 3px 4px;
  background-color: ${NEUTRAL_4_COLOUR};
  border-radius: 3px;
  color: ${NEUTRAL_7_COLOUR};
  ${FONT_10PX_MEDIUM};
  text-transform: uppercase;
  user-select: none;
  width: fit-content;
  margin-top: 5px;
`;

const IdCell = styled(Cell)`
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-left: 9px;

  span {
    color: ${NEUTRAL_9_COLOUR};
    ${FONT_12PX_MEDIUM};
    letter-spacing: 0.6px;
    ${OVERFLOW_ELLIPSIS};
  }
`;

const ItemThumbnail = styled(Thumbnail)`
  margin-right: 8px;
`;

const SegmentCard = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 20px 10px;
  cursor: pointer;
  transition: 0.25s ease background-color;

  :hover {
    background-color: ${NEUTRAL_3_COLOUR};
  }

  border-top: 1px solid ${NEUTRAL_5_COLOUR};

  :last-child {
    border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
  }
`;

const SegmentDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 48px;
  margin-left: 10px;
  overflow: hidden;
`;

const SegmentName = styled.div`
  ${FONT_16PX_MEDIUM};
  color: ${NEUTRAL_9_COLOUR};
  ${OVERFLOW_ELLIPSIS};
`;

const Chevron = styled(ForwardIcon)`
  #react-app && {
    font-size: 30px;
    color: ${HIGHLIGHT_PRIMARY_COLOUR};
    margin-left: auto;
    transform: translateX(9px);
  }
`;

export interface ContentItem {
  Name: string;
  Type: 'course' | 'module' | 'sub-module' | 'lesson';
  Id: number;
  SourceId: string;
  Tags: Record<string, string[]>;
  Children?: ContentItem[];
  key: string;
}

export interface ContentItemChild {
  ChildId: number;
  Type: 'course' | 'module' | 'sub-module' | 'lesson';
}

export const ContentTagging = () => {
  const {
    filters,
    products,
    collections,
    videos,
    isDataLoaded,
    isError,
    unsavedChanges,
    isSaving,
    isInitialized,
    filtersToSave,
    getTags,
    getChildren,
    setItemsToSave,
    setFiltersToSave,
    handleSave,
    setIsInitialized,
    reset,
  } = useContentTagging();
  const { getCollectionById, getCollectionThumbnailUrl } = useCollections(Infinity, 'always');
  const { getVideoThumbnailUrl } = useVideos(Infinity, 'always');
  const dataSource = useDataSource();

  const [activeSegment, setActiveSegment] = useState<ContentItem | undefined>(undefined);
  const [activeFilter, setActiveFilter] = useState<Filter | undefined>(undefined);
  const [content, setContent] = useState<ContentItem[]>([]);
  const [filteredContent, setFilteredContent] = useState<ContentItem[] | undefined>(undefined);
  const [tagSelectState, setTagSelectState] = useState<Record<string, string[]>>({});
  const [selectedRows, setSelectedRows] = useState<ContentItem[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [pageSize, setPageSize] = useState(25);

  const isCourseView = dataSource === SOURCE_KAJABI;
  // Kajabi apps will show a list of courses, when a course is clicked on, a table will display that course and all of its nested content
  // Other data sources will show options for 'Groups' or 'Videos' - Each displaying a table with all VidApp collections or videos listed alphabetically

  const columns = useMemo(() => {
    // Default columns
    const columnsArr: TableColumn<ContentItem & { id: string }>[] = [
      {
        heading: 'Title',
        width: 465,
        minWidth: 465,
        render: ({ Name, Type, Id }) => {
          const thumbnailUrl =
            Type === 'lesson' ? getVideoThumbnailUrl(Id, 'small') : getCollectionThumbnailUrl(Id, 'small');
          return (
            <HoverCard
              trigger={
                <NameCell>
                  <ItemThumbnail
                    url={thumbnailUrl}
                    width="43px"
                    height="24px"
                    border={thumbnailUrl ? 'none' : undefined}
                    borderRadius="2px"
                  />
                  <NameWrapper>
                    <HighlightText text={Name} highlight={searchValue} />
                  </NameWrapper>
                </NameCell>
              }
              content={
                <HoverCardContent>
                  <ItemThumbnail
                    url={thumbnailUrl}
                    width="43px"
                    height="24px"
                    border={thumbnailUrl ? 'none' : undefined}
                    borderRadius="2px"
                  />
                  {Name}
                </HoverCardContent>
              }
            />
          );
        },
      },
      {
        id: 'Content_Type',
        heading: 'Type',
        width: 100,
        render: ({ Type }) => (
          <Cell>
            <TypeCell>{isCourseView ? Type : Type === 'lesson' ? 'Video' : 'Group'}</TypeCell>
          </Cell>
        ),
      },
      {
        heading: 'ID',
        width: 100,
        render: ({ SourceId }) => (
          <IdCell>
            <HighlightText text={SourceId} highlight={searchValue} />
          </IdCell>
        ),
      },
    ];

    // Dynamic columns from each Filter
    filters?.forEach((filter) => {
      if (filter.Enabled) {
        columnsArr.push({
          heading: filter.Filter,
          headingElement: (
            <CategoryColumnHeader>
              {filter.Filter}
              <StyledPencilIcon
                disabled={unsavedChanges || isSaving || !!activeFilter}
                onClick={() => {
                  if (unsavedChanges || isSaving) {
                    Modal.confirm({
                      title: 'You Have Unsaved Changes',
                      content:
                        'Before you can edit a category or its tags, you need to save any changes you have in-progress.',
                      getContainer: '#react-app',
                      okText: 'Save Changes',
                      cancelText: 'Cancel',
                      onOk: () => {
                        handleSave();
                      },
                    });
                  } else {
                    setActiveFilter(filter);
                  }
                }}
              />
            </CategoryColumnHeader>
          ),
          width: 147,
          render: (data) => (
            // Render a Select input in the cells for Filter columns
            <TagSelectInput
              contentItem={data}
              filter={filter}
              existingTags={data.Tags[filter.Filter] ?? []}
              tagSelectState={tagSelectState}
              filterToSave={filtersToSave[filter.Id]}
              setTagSelectState={setTagSelectState}
              setItemsToSave={setItemsToSave}
              setFiltersToSave={setFiltersToSave}
              selectedRows={selectedRows}
              disabled={selectedRows.length > 0 && !selectedRowKeys.includes(data.id)}
            />
          ),
        });
      }
    });

    return columnsArr;
  }, [
    filters,
    filtersToSave,
    activeFilter,
    tagSelectState,
    selectedRows,
    selectedRowKeys,
    unsavedChanges,
    setItemsToSave,
    setFiltersToSave,
    getVideoThumbnailUrl,
    getCollectionThumbnailUrl,
    setTagSelectState,
    setActiveFilter,
    handleSave,
  ]);

  useEffect(() => {
    if (isDataLoaded && !isInitialized && collections && videos) {
      if (isCourseView) {
        const contentArr: ContentItem[] = [];

        products?.sort((a, b) => a.Name.localeCompare(b.Name));

        products?.forEach((product) => {
          const children: ContentItemChild[] = [{ ChildId: product.TabId, Type: 'course' }]; // Put product at the start of the children array as it will be displayed in the first row of the product view

          // For each product, push 3 levels of children into 'children' of ContentItem
          product.Items.forEach((item) => {
            children.push({ ChildId: item.ChildId, Type: 'module' });
            getCollectionById(item.ChildId)?.Items.forEach((item) => {
              children.push({ ChildId: item.ChildId, Type: 'sub-module' });
              getCollectionById(item.ChildId)?.Items.forEach((item) => {
                children.push({ ChildId: item.ChildId, Type: 'sub-module' });
              });
            });
          });

          const row: ContentItem = {
            Name: product.Name,
            Type: 'course',
            Id: product.TabId,
            SourceId: product.SourceId,
            Tags: getTags(product.TabId, 'collection'), // Return record of tags for this product, categorised by Filter containing tags
            Children: getChildren(children), // Return a new ContentItemChild for each item in children
            key: nanoid(),
          };

          contentArr.push(row);
        });

        setContent(contentArr);
      } else {
        const contentArr: ContentItem[] = [
          {
            Name: 'Groups',
            Type: 'course',
            Id: 1,
            SourceId: '1',
            Tags: {},
            Children: getChildren(collections.map((collection) => ({ ChildId: collection.TabId, Type: 'course' }))), // Return a new ContentItemChild for each item in children
            key: nanoid(),
          },
          {
            Name: 'Videos',
            Type: 'course',
            Id: 2,
            SourceId: '2',
            Tags: {},
            Children: getChildren(videos.map((video) => ({ ChildId: video.VideoId, Type: 'lesson' }))), // Return a new ContentItemChild for each item in children
            key: nanoid(),
          },
        ];

        setContent(contentArr);
      }

      setIsInitialized(true);
    }
  }, [
    isDataLoaded,
    isInitialized,
    filters,
    products,
    collections,
    videos,
    isCourseView,
    setIsInitialized,
    setContent,
    getChildren,
    getTags,
  ]);

  useEffect(() => {
    const data = filteredContent ?? activeSegment?.Children;

    if (!data || selectedRowKeys.length === 0) {
      setSelectedRows([]);
    } else {
      const rows: ContentItem[] = [];
      selectedRowKeys.forEach((key) => {
        const row = data.find((item) => item.Id.toString() === key);
        if (row) {
          rows.push(row);
        }
      });
      setSelectedRows(rows);
    }
  }, [selectedRowKeys]);

  const clearSelections = useCallback(() => {
    setSelectedRowKeys([]);
  }, [setSelectedRowKeys]);

  const clearSearch = useCallback(() => {
    setFilteredContent(undefined);
    setSearchValue(undefined);
  }, [setFilteredContent, setSearchValue]);

  const handleSearch = useCallback(
    (value: string) => {
      const searchInput = value.toLowerCase();

      if (searchInput === '') {
        // Search term has been cleared, show all content unfiltered and collapse all products
        clearSearch();
      } else {
        // Search term has been entered
        setSearchValue(searchInput);
        const matchingRows: ContentItem[] = [];
        activeSegment?.Children?.forEach((row) => {
          if (row.Name?.toLowerCase().includes(searchInput) || row.SourceId?.toLowerCase().includes(searchInput)) {
            matchingRows.push(row);
          }
        });
        setFilteredContent(matchingRows);
      }
    },
    [activeSegment, setFilteredContent, setSearchValue, clearSearch],
  );

  const openSegment = useCallback(
    (segment: ContentItem) => {
      clearSelections();
      clearSearch();
      setActiveSegment(segment);
    },
    [clearSelections, clearSearch, setActiveSegment],
  );

  const navigateBack = useCallback(() => {
    clearSelections();
    clearSearch();
    setActiveSegment(undefined);
    setTagSelectState({});
    reset();
  }, [clearSelections, clearSearch, setActiveSegment, setTagSelectState, reset]);

  useEffect(() => {
    // StyledTable uses activeSegment or filteredContent as a DataSource, so when content changes (after a save and refetch), these states need to be updated so the table remounts with the updated data
    if (activeSegment) {
      setTagSelectState({});
      clearSelections();
      const updatedSegment = content.find((segment) => segment.SourceId === activeSegment.SourceId);
      setActiveSegment(updatedSegment);
      if (searchValue) {
        handleSearch(searchValue);
      }
    }
  }, [content, activeSegment, searchValue, clearSelections, handleSearch, setTagSelectState, setActiveSegment]);

  const handleNavigateBack = useCallback(() => {
    if (unsavedChanges) {
      Modal.confirm({
        title: 'Unsaved Changes',
        content: 'You have unsaved changes. If you leave without saving your changes will be lost.',
        getContainer: '#react-app',
        okText: 'Continue Editing',
        cancelText: 'Discard Changes',
        onCancel: () => {
          navigateBack();
          Modal.destroyAll();
        },
        onOk: () => {
          return false;
        },
      });
    } else {
      navigateBack();
    }
  }, [unsavedChanges, navigateBack]);

  return (
    <PageContainer
      heading={activeSegment ? activeSegment.Name : 'Content Tagging'}
      subheading={
        activeSegment && (
          <>
            To add or delete a category go to the{' '}
            <CustomLink to={{ pathname: '/filters', state: { showUnsavedChangesPrompt: true } }}>
              Filters section.
            </CustomLink>
          </>
        )
      }
      thumbnailUrl={activeSegment && isCourseView ? getCollectionThumbnailUrl(activeSegment.Id, 'small') : undefined}
      headingButton={
        activeSegment && (
          <CustomButton primary onClick={handleSave} disabled={!unsavedChanges} loading={isSaving}>
            Save
          </CustomButton>
        )
      }
      isLoading={!isInitialized}
      isError={isError}
      breadcrumbs={
        activeSegment && {
          items: [
            {
              label: isCourseView ? 'All Courses' : 'Back',
              handleClick: handleNavigateBack,
            },
          ],
          currentScreen: activeSegment.Name,
        }
      }
      contentMaxWidth={activeSegment ? PAGE_CONTAINER_WIDE_WIDTH : undefined}
    >
      {activeSegment ? (
        <>
          <SearchBar value={searchValue} onSearch={handleSearch} marginBottom="20px" />
          <CustomTableFixedColumn
            data={
              filteredContent?.map((item) => ({ ...item, id: item.Id.toString() })) ||
              activeSegment.Children?.map((item) => ({ ...item, id: item.Id.toString() }))
            }
            columns={columns}
            query={searchValue}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            emptyTitle="No Results Found"
            emptyDescription=""
            showColumnBorders
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedKeys) => {
                setSelectedRowKeys(selectedKeys);
              },
            }}
          />
          {activeFilter && (
            <ContentTaggingDrawer activeFilter={activeFilter} setActiveFilter={setActiveFilter} resetTable={reset} />
          )}
        </>
      ) : (
        <ListContainer heading={isCourseView ? 'Courses' : 'Content'} $border="none">
          {content.map((segment) => {
            const thumbnail = isCourseView ? getCollectionThumbnailUrl(segment.Id, 'small') : null;
            return (
              <SegmentCard key={segment.key} onClick={() => openSegment(segment)}>
                {isCourseView ? (
                  <ItemThumbnail url={thumbnail} width="107px" height="60px" border={thumbnail ? 'none' : undefined} />
                ) : segment.Name === 'Videos' ? (
                  <VideoIcon />
                ) : (
                  <FolderIcon />
                )}
                <SegmentDetails>
                  <SegmentName>{segment.Name}</SegmentName>
                  {isCourseView && <TypeCell>{`COURSE - ${segment.SourceId}`}</TypeCell>}
                </SegmentDetails>
                <Chevron />
              </SegmentCard>
            );
          })}
        </ListContainer>
      )}
    </PageContainer>
  );
};
