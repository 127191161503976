import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { Product, updateProduct, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

interface SaveProductProps {
  invalidateQuery?: boolean;
  saveActionLog?: boolean;
}

export const useSaveProduct = (props?: SaveProductProps) => {
  const { invalidateQuery = true, saveActionLog = true } = props ?? {};
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse<{ Id: number }>, AxiosError, Partial<Product>>(
    (valuesToUpdate) => {
      return updateProduct(client, appId, valuesToUpdate);
    },
    {
      onSuccess: async (_response, payload) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['products', appId]);
        }
        if (saveActionLog) {
          recordAction.mutate({ action: 'Save Product', detail: { updatedValues: payload } });
        }
      },
    },
  );
};
