import { Serie } from '@nivo/line';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  DateIncrement,
  EngagementMetricKey,
  METRIC_KEY_AVERAGE_WATCHED,
  METRIC_KEY_COMMENTS,
  METRIC_KEY_COMPLETIONS,
  METRIC_KEY_ENGAGED_USERS,
  METRIC_KEY_MINUTES,
  METRIC_KEY_SAVED_PLAYLIST,
  METRIC_KEY_SCHEDULED,
  METRIC_KEY_TOTAL_PLAYS,
  MetricGroup,
  MetricKey,
} from 'api';
import { useAppProperties } from 'hooks';
import { getScreenFromPathname } from 'utils';

import { METRIC_LABELS, METRIC_PARAM } from '../const';
import { useAnalyticsMetricByDate } from '../hooks/useAnalyticsMetricByDate';
import { useAnalyticsTotals } from '../hooks/useAnalyticsTotals';
import { getKeyAnalytics } from '../util';

interface KeyAnalytics {
  [METRIC_KEY_TOTAL_PLAYS]: number | undefined;
  [METRIC_KEY_COMPLETIONS]: number | undefined;
  [METRIC_KEY_AVERAGE_WATCHED]: number | undefined;
  [METRIC_KEY_MINUTES]: number | undefined;
  [METRIC_KEY_SCHEDULED]: number | string | undefined;
  [METRIC_KEY_COMMENTS]: number | string | undefined;
  [METRIC_KEY_SAVED_PLAYLIST]: number | undefined;
  [METRIC_KEY_ENGAGED_USERS]: number | undefined;
}

interface ProviderProps {
  children: ReactNode;
}

interface ContextValue {
  activeMetric?: MetricKey;
  activeMetricGroup: MetricGroup;
  keyAnalytics?: KeyAnalytics;
  chartData?: Serie[];
  setStartDate: Dispatch<SetStateAction<Date>>;
  setEndDate: Dispatch<SetStateAction<Date>>;
  activeRange: number;
  setActiveRange: Dispatch<SetStateAction<number>>;
  activeIncrement: DateIncrement;
  setActiveIncrement: Dispatch<SetStateAction<DateIncrement>>;
  isError?: boolean;
  hideIncrementSelect: boolean;
  disabledKeys: MetricKey[];
}

const AnalyticsContext = createContext<ContextValue | undefined>(undefined);

interface ScreenMetrics {
  type: MetricGroup;
  default?: typeof METRIC_KEY_TOTAL_PLAYS | typeof METRIC_KEY_SAVED_PLAYLIST | typeof METRIC_KEY_ENGAGED_USERS;
  range?: number;
  increment?: DateIncrement;
}

const SCREEN_MAP: Record<string, ScreenMetrics> = {
  dashboard: {
    type: 'keyMetrics',
    default: undefined,
  },
  viewership: {
    type: 'viewershipMetrics',
    default: METRIC_KEY_TOTAL_PLAYS,
  },
  engagement: {
    type: 'engagementMetrics',
    default: METRIC_KEY_SAVED_PLAYLIST,
  },
  'engaged-users': {
    type: 'otherMetrics',
    default: METRIC_KEY_ENGAGED_USERS,
    range: 365,
    increment: 'monthly',
  },
};

const AnalyticsProvider = ({ children }: ProviderProps) => {
  const today: Date = new Date();
  const { pathname, search } = useLocation();
  const screen = getScreenFromPathname(pathname) as string;
  const defaultActiveRange = SCREEN_MAP[screen]?.range ?? 7;
  const [activeRange, setActiveRange] = useState(defaultActiveRange);
  const [startDate, setStartDate] = useState(startOfDay(subDays(today, defaultActiveRange)));
  const [endDate, setEndDate] = useState(endOfDay(subDays(today, 1)));
  const [activeIncrement, setActiveIncrement] = useState<DateIncrement>(SCREEN_MAP[screen]?.increment ?? 'daily');
  const { data: properties } = useAppProperties();

  const engagementEnabled = properties?.RolloutEngagement !== '0';

  const disabledKeys = useMemo(() => {
    const keys: EngagementMetricKey[] = [];
    if (properties?.DisplayCalendarScheduler !== '1') {
      keys.push(METRIC_KEY_SCHEDULED);
    }
    if (properties?.ShowCommentsForItems === '0' && properties?.ShowCommentsForCollections === '0') {
      keys.push(METRIC_KEY_COMMENTS);
    }
    return keys;
  }, [properties]);

  const activeMetricGroup =
    !engagementEnabled && screen === 'dashboard' // If Engagement isn't enabled for the app, display Viewership metrics on dashboard instead of Key Metrics
      ? 'viewershipMetrics'
      : SCREEN_MAP[screen]?.type ?? 'keyMetrics';

  const { data: analyticsTotalsData, isError: analyticsTotalsIsError } = useAnalyticsTotals(
    activeMetricGroup,
    startDate,
    endDate,
  );

  const defaultMetric = SCREEN_MAP[screen]?.default ?? undefined;
  const query = new URLSearchParams(search);
  const activeMetric = query.get(METRIC_PARAM) ? (query.get(METRIC_PARAM) as MetricKey) : defaultMetric;

  const keyAnalytics = useMemo(
    () => getKeyAnalytics(analyticsTotalsData, disabledKeys),
    [analyticsTotalsData, disabledKeys],
  );

  const { data: metricByDateData, isError: metricByDateDataIsError } = useAnalyticsMetricByDate(
    activeMetric ?? defaultMetric ?? METRIC_KEY_TOTAL_PLAYS,
    startDate,
    endDate,
    activeIncrement,
  );

  const chartData: Serie[] | undefined = metricByDateData
    ? [
        {
          id: METRIC_LABELS[activeMetric ?? defaultMetric ?? METRIC_KEY_TOTAL_PLAYS],
          data: metricByDateData.map(({ date, value }) => ({ x: date, y: value })),
        },
      ]
    : undefined;

  return (
    <AnalyticsContext.Provider
      value={{
        activeMetric,
        activeMetricGroup,
        keyAnalytics,
        chartData,
        setStartDate,
        setEndDate,
        activeRange,
        setActiveRange,
        activeIncrement,
        setActiveIncrement,
        isError: analyticsTotalsIsError || metricByDateDataIsError,
        hideIncrementSelect: pathname === '/',
        disabledKeys,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalyticsContext must be used with an AnalyticsProvider');
  }
  return context;
};

export { AnalyticsProvider, useAnalyticsContext };
