import styled from 'styled-components';
import { useForm } from 'antd/es/form/Form';
import { useCallback, useState } from 'react';

import '../../../App.less'; // Because this is mounted separately to the app
import { LoginForm } from './components/LoginForm';
import { LoginModal } from './components/LoginModal';
import { UnsupportedBrowser } from './components/UnsupportedBrowser';

import { NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR, PRIMARY_ORANGE_COLOUR } from 'theme';
import { useAxiosInstance } from 'api';
import { BUILDER_IMAGES_DOMAIN } from 'utils';

const FullPageRow = styled.div`
  font-family: Inter, serif;
  height: 100%;
  display: flex;
  min-height: 760px;
`;

const LoginWrapper = styled.div`
  padding: 40px 20% 80px 20%;
  margin: 0 auto;
  width: 100%;
  height: 100%;
`;
const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
`;
const LeftCol = styled.div`
  flex: auto;
  min-width: 500px;
`;
const RightCol = styled.div`
  height: 100%;
  width: 50%;
  @media (max-width: 750px) {
    display: none;
  }
`;
const LogoImage = styled.img`
  position: absolute;
  top: 40px;
`;
const MainHeader = styled.div`
  margin: 20px 0;
`;
const Title = styled.div`
  font-size: 45px;
  font-weight: 700;
  line-height: 54px;
  color: ${NEUTRAL_9_COLOUR};
  margin-bottom: 20px;
`;
const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: ${NEUTRAL_7_COLOUR};
`;

const StyledLink = styled.a`
  font-weight: 500;
  text-decoration: underline !important;
  color: inherit !important;
`;

const Footer = styled.div`
  margin: 31px auto;
  text-align: center;
  cursor: pointer;
`;
const FooterText = styled.span`
  color: ${NEUTRAL_7_COLOUR};
  line-height: 20px;
`;
const ColorFooterText = styled(FooterText)`
  color: ${PRIMARY_ORANGE_COLOUR};
`;

const SplashImage = styled.img`
  float: right;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
`;
const Block = styled.div`
  width: 100%;
`;

export const LoginPanel = () => {
  const axiosInstance = useAxiosInstance();
  const [form] = useForm<{ email: string; password: string }>();
  const [visible, setVisible] = useState(false);
  const [proceedWithUnsupportedBrowser, setProceedWithUnsupportedBrowser] = useState(
    !!sessionStorage.proceedWithUnsupportedBrowser,
  );

  const resetPassword = useCallback(async () => {
    const email = form.getFieldValue('email');
    return await axiosInstance.post('auth/forgot-password', { Email: email }).catch((err) => {
      form.setFields([
        {
          name: 'password',
          errors: [`Failed to reset password: ${err.response.data.detail[0].msg}`],
        },
      ]);
    });
  }, [form, axiosInstance]);

  const handleResetPassword = useCallback(async () => {
    const email = form.getFieldValue('email');
    if (email) {
      setVisible(true);
    } else {
      form.setFields([
        {
          name: 'email',
          errors: ['Please enter your email address to reset your password.'],
        },
      ]);
    }
  }, [form, setVisible]);

  return (
    <FullPageRow>
      {window.chrome || proceedWithUnsupportedBrowser ? (
        <>
          <LeftCol>
            <LoginWrapper>
              <LogoImage
                src={`${BUILDER_IMAGES_DOMAIN}/branding/vidapp_logo.webp`}
                width="150"
                height="40"
                alt="login logo"
              />
              <FormWrapper>
                <Block>
                  <MainHeader>
                    <Title>Welcome back!</Title>
                    <Subtitle>
                      {'Need an account? '}
                      <StyledLink href={`${window.location.origin}/sign-up/create-free-app`}>Get started.</StyledLink>
                    </Subtitle>
                  </MainHeader>
                  <LoginForm form={form} />
                  <Footer onClick={handleResetPassword}>
                    <FooterText>{'Forgot your password? '}</FooterText>
                    <ColorFooterText>{'Reset password'}</ColorFooterText>
                  </Footer>
                </Block>
              </FormWrapper>
            </LoginWrapper>
          </LeftCol>
          <RightCol>
            <SplashImage src={`${BUILDER_IMAGES_DOMAIN}/login/splash_2024.webp`} />
          </RightCol>
          <LoginModal
            visible={visible}
            onCancel={() => {
              setVisible(false);
            }}
            onSubmit={resetPassword}
          />
        </>
      ) : (
        <UnsupportedBrowser
          handleProceed={() => {
            sessionStorage.proceedWithUnsupportedBrowser = true;
            setProceedWithUnsupportedBrowser(true);
          }}
        />
      )}
    </FullPageRow>
  );
};
