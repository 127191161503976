import styled from 'styled-components';

import { CustomCell, CustomTable, PageContainer, TableColumn } from 'components';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';
import { FONT_14PX_MEDIUM } from 'font';

import { AccountInfo } from '../../../../api';
import { useAppAccounts } from './hooks/useAppAccounts';

import { Add } from './components/Add';
import { Delete } from './components/Delete';

const PAGE_NAME = 'Manage Team';

const Capitalize = styled.span`
  text-transform: capitalize;
  ${FONT_14PX_MEDIUM};
`;

export const TeamTable = () => {
  const { data } = useAppAccounts();

  const columns: TableColumn<AccountInfo & { id: string }>[] = [
    {
      heading: 'Name',
      width: 300,
      render: ({ FirstName, LastName }) => (
        <CustomCell>
          <Capitalize>{`${FirstName} ${LastName}`}</Capitalize>
        </CustomCell>
      ),
    },
    {
      heading: 'Email',
      width: 'grow',
      render: ({ Email }) => <CustomCell>{Email}</CustomCell>,
    },
    {
      heading: 'Role',
      width: 260,
      render: ({ Role }) => (
        <CustomCell>
          <Capitalize>{Role}</Capitalize>
        </CustomCell>
      ),
    },
    {
      heading: '',
      id: 'UserId',
      width: 82,
      render: ({ UserId, Role }) =>
        Role !== 'owner' ? (
          <CustomCell>
            <Delete userId={UserId} />
          </CustomCell>
        ) : null,
    },
  ];

  return (
    <PageContainer heading={PAGE_NAME} headingButton={<Add />} contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}>
      <CustomTable
        columns={columns}
        data={data?.Accounts.map((account) => ({ ...account, id: account.UserId.toString() }))}
        emptyTitle="No Team Members"
        emptyDescription=""
      />
    </PageContainer>
  );
};
