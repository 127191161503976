import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { PAYMENT_STATUS_INVOICED, SOURCE_KAJABI, SOURCE_VHX } from 'api';
import { Heading, PageContainer } from 'components';
import { useAppBasicInfo, useAppProperties } from 'hooks';
import { useDataSource } from 'providers';
import { BREAKPOINT_1, BREAKPOINT_2, BREAKPOINT_3, BREAKPOINT_4, PAGE_CONTAINER_NARROW_WIDTH } from 'theme';

import { useAccountDetails } from 'app/modules/account/AccountDetails/hooks/useAccountDetails';
import { KeyAnalytics } from 'app/modules/analytics';
import { OnboardingWizard } from 'app/modules/self-onboarding';
import { OnboardingChecklist } from 'app/modules/onboarding-checklist';

import { GuidedTour, OnboardingChecklistSimple, TabNavigation } from './components';
import { OnboardingChecklistProvider } from 'app/modules/onboarding-checklist/OnboardingChecklist/providers/OnboardingChecklistProvider';

const Header = styled.div`
  width: 100%;
  margin-bottom: 10px;

  transition: 0.2s linear margin-top;
  margin-top: 0px;
  @media (min-width: ${BREAKPOINT_1.minWidth}) and (min-height: ${BREAKPOINT_1.minHeight}) {
    margin-top: 25px;
  }
  @media (min-width: ${BREAKPOINT_2.minWidth}) and (min-height: ${BREAKPOINT_2.minHeight}) {
    margin-top: 40px;
  }
  @media (min-width: ${BREAKPOINT_3.minWidth}) and (min-height: ${BREAKPOINT_3.minHeight}) {
    margin-top: 55px;
  }
  @media (min-width: ${BREAKPOINT_4.minWidth}) and (min-height: ${BREAKPOINT_4.minHeight}) {
    margin-top: 75px;
  }
`;

export const Dashboard = ({ forceWizardOpen }: { forceWizardOpen?: boolean }) => {
  const {
    data: appBasicInfo,
    isMigratedLegacy,
    isLoading: basicInfoIsLoading,
    isError: basicInfoIsError,
  } = useAppBasicInfo();
  const { data: properties, isLoading: propertiesIsLoading, isError: propertiesIsError } = useAppProperties();
  const { data: account, isLoading: accountIsLoading, isError: accountIsError } = useAccountDetails();
  const dataSource = useDataSource();
  const [wizardOpen, setWizardOpen] = useState(forceWizardOpen ?? false);

  const showChecklist =
    [SOURCE_KAJABI, SOURCE_VHX].includes(dataSource) && properties?.DisplayFullOnboardingChecklist === '1';

  const showSimpleChecklist = useMemo(() => {
    if (properties?.DisplayFullOnboardingChecklist) {
      // If this property exists in either state, the simple checklist should never be shown
      return false;
    }

    if (appBasicInfo?.PaymentStatus === PAYMENT_STATUS_INVOICED) {
      return properties?.DisplayOnboardingChecklist === '1'; // For invoiced apps, only show the checklist if it has explicitly been turned on via Add-Ons
    } else {
      return properties?.DisplayOnboardingChecklist !== '0'; // Otherwise show it unless it has been explicitly turned off via Add-Ons
    }
  }, [properties, appBasicInfo]);

  const welcomeMessage = (!showChecklist && !showSimpleChecklist) || isMigratedLegacy ? 'Welcome back' : 'Welcome';

  useEffect(() => {
    if (!!properties && !!appBasicInfo) {
      if (
        [SOURCE_VHX, SOURCE_KAJABI].includes(dataSource) &&
        !isMigratedLegacy &&
        properties?.OnboardingWizardCompleted !== '1'
      ) {
        setWizardOpen(true);
      }
    }
  }, [dataSource, appBasicInfo, isMigratedLegacy, properties]);

  const onWizardCancel = useCallback(() => {
    setWizardOpen(false);
  }, [setWizardOpen]);

  return (
    <OnboardingChecklistProvider>
      <PageContainer
        isLoading={basicInfoIsLoading || propertiesIsLoading || accountIsLoading}
        isError={basicInfoIsError || propertiesIsError || accountIsError}
        contentMaxWidth={{ small: PAGE_CONTAINER_NARROW_WIDTH, large: PAGE_CONTAINER_NARROW_WIDTH }}
      >
        <Header>
          <Heading heading={`${welcomeMessage}, ${account?.FirstName ?? ''}`} />
        </Header>
        {showChecklist ? (
          <GuidedTour>
            <OnboardingChecklist />
          </GuidedTour>
        ) : (
          <>
            <KeyAnalytics />
            <TabNavigation />
            {showSimpleChecklist && (
              <GuidedTour>
                <OnboardingChecklistSimple />
              </GuidedTour>
            )}
          </>
        )}
      </PageContainer>
      {wizardOpen && <OnboardingWizard onCancel={onWizardCancel} />}
    </OnboardingChecklistProvider>
  );
};
