import styled from 'styled-components';

import { APP_FONT_10PX_MEDIUM } from 'mockup-font';
import { useLocalAppProperties } from 'providers';
import { useAppTheme } from '../../hooks';

const TagWrapper = styled.div<{ $backgroundColor: string; $color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  padding: 0 5px;
  border-radius: 4px;
  ${APP_FONT_10PX_MEDIUM};
  color: ${({ $color }) => $color};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  flex-shrink: 0;
`;

const TagWrapperAbsolute = styled(TagWrapper)`
  position: absolute;
  top: -11px;
`;

interface ProductTagProps {
  text: string;
  isSelected?: boolean;
  isFullWidth?: boolean;
}

export const ProductTag = ({ text, isSelected, isFullWidth }: ProductTagProps) => {
  const { getDesignProperty } = useAppTheme();
  const { properties } = useLocalAppProperties();
  const PrimaryItemHEX = getDesignProperty('primaryItemHEX') as string;
  const { HighlightHEX, ColorScheme } = properties;

  const labelBackground = ColorScheme === 'Dark' ? '#383838' : '#ECECEC';

  const backgroundColor = isSelected ? `#${HighlightHEX}` : labelBackground;
  const color = isSelected ? '#FEFEFE' : PrimaryItemHEX;

  if (isFullWidth) {
    return (
      <TagWrapper $backgroundColor={backgroundColor} $color={color}>
        {text}
      </TagWrapper>
    );
  }

  return (
    <TagWrapperAbsolute $backgroundColor={backgroundColor} $color={color}>
      {text}
    </TagWrapperAbsolute>
  );
};
