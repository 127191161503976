import { AxiosInstance } from 'axios';

export const BUILDER_EVENT_WIZARD_COMPLETED = 'ONBOARDING_WIZARD_COMPLETED';

export interface BuilderEvent {
  ID: number;
  Type: string;
  EventTs: number;
  UserID: number;
  UserName: string;
  UserEmail: string;
  DeleteTs?: number;
  DeleteUserID?: number;
  DeleteUserName?: string;
  DeleteUserEmail?: string;
}

export const getBuilderEvents = (client: AxiosInstance, appId: string) => {
  return client.get<BuilderEvent[]>(`/event/${appId}`).then((response) => response.data);
};

export const saveBuilderEvent = async (client: AxiosInstance, appId: string, eventType: string) => {
  return client.post(`/event/${appId}`, JSON.stringify({ Type: eventType }));
};

export const deleteBuilderEvent = async (client: AxiosInstance, appId: string, eventId: number) => {
  return client.delete(`/event/${appId}/${eventId}`);
};
