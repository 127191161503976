import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { CollectionToSave, createCollection, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

interface CreateCollectionProps {
  invalidateQuery: boolean;
  refresh: boolean;
  saveActionLog: boolean;
}

export const useCreateCollection = ({
  invalidateQuery = true,
  refresh = true,
  saveActionLog = true,
}: CreateCollectionProps) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse<{ TabId: number }>, AxiosError, CollectionToSave>(
    (collectionToCreate) => {
      return createCollection(client, appId, collectionToCreate, refresh);
    },
    {
      onSuccess: async (_response, payload) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['collections', appId]);
        }
        if (saveActionLog) {
          recordAction.mutate({ action: 'Create Collection', detail: { createCollection: payload } });
        }
      },
    },
  );
};
