import { DefaultContentProvider } from 'providers';
import { CalendarModalContextProvider } from '../CalendarModal/providers/calendar-modal-provider';
import { CalendarInternal } from './CalendarInternal';

export const CalendarPage = () => {
  return (
    <DefaultContentProvider>
      <CalendarModalContextProvider>
        <CalendarInternal />
      </CalendarModalContextProvider>
    </DefaultContentProvider>
  );
};
