import styled from 'styled-components';

import { NEUTRAL_5_COLOUR, NEUTRAL_8_COLOUR, PRIMARY_ORANGE_COLOUR, SUCCESS_COLOUR } from 'theme';

const Container = styled.div`
  color: ${NEUTRAL_8_COLOUR};
  display: flex;
  align-items: center;
`;

const ProgressNumbers = styled.div`
  margin-right: 10px;
`;

const Bar = styled.div`
  width: 108px;
  height: 5px;
  background-color: ${NEUTRAL_5_COLOUR};
  border-radius: 5px;
`;

const Completion = styled(Bar)<{ $width: string; $isAdmin?: boolean }>`
  background-color: ${({ $isAdmin }) => ($isAdmin ? PRIMARY_ORANGE_COLOUR : SUCCESS_COLOUR)};
  width: ${({ $width }) => $width};
  min-width: 7px;
`;

export const ProgressBar = ({ completed, total }: { completed: number; total: number }) => (
  <Container>
    <ProgressNumbers>{`${completed}/${total} completed`}</ProgressNumbers>
    <Bar>
      <Completion $width={`${(completed / total) * 100}%`} />
    </Bar>
  </Container>
);
