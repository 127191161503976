import { useEffect } from 'react';
import styled from 'styled-components';

import {
  CollectionDescriptionPreview,
  CollectionTitle,
  ContentAdvancedSettings,
  ContentSourceTitle,
  SettingsCollectionThumbnail,
} from 'components';
import { useContent, useSaveContext } from 'providers';
import { DEFAULT_COLLECTION, GROUP_LABEL, SOURCE_TYPE_COLLECTION, SOURCE_VIDAPP } from 'api';
import { capitalizeString, getCollectionValuesFromNewCollection, getDataSourceDisplayName } from 'utils';
import { useAppBeingEdited } from 'app-context';
import { useTemplates } from 'hooks';

import { CollectionResources, CollectionTemplateSelect, ContentViewContainer } from './components';
import { ContentCollectionTree } from '../content-tree';
import { useContentNavigationContext } from '../../providers';
import { ModalContextProvider } from '../cms-modal/providers/modal-provider';

const SEGMENT_MARGIN = { $marginTop: '0px', $marginBottom: '40px' };

const PaddedContainer = styled.div`
  // All children of a ContentCollectionView except for the content tree need to be given extra padding, so tree rows appear to bleed into the margins
  padding: 0 4px 0 6px;
`;

interface ContentCollectionViewProps {
  collectionId: string | number;
}

export const ContentCollectionView = ({ collectionId }: ContentCollectionViewProps) => {
  const { tempTabId, setTempTabId } = useContentNavigationContext();
  const { setNewCollection, collections } = useContent();
  const { setCollectionToSave, getTempId } = useSaveContext();
  const appId = useAppBeingEdited();
  const { data: templatesData } = useTemplates();

  const collection = collections[collectionId] ?? collections[tempTabId];

  const newCollectionType = collectionId.toString().startsWith('new_')
    ? capitalizeString(collectionId.toString().replace('new_', ''))
    : undefined;

  useEffect(() => {
    if (newCollectionType) {
      const tempId = `TempTab${getTempId()}`;
      setTempTabId(tempId);
      const newCollection = {
        AppId: appId,
        ...DEFAULT_COLLECTION,
        TabId: tempId,
        TemplateId: 10,
        Position: 0,
        Name: `New ${newCollectionType === SOURCE_TYPE_COLLECTION ? GROUP_LABEL : newCollectionType}`,
        Thumbnails: {},
        SourceType: newCollectionType,
        Items: [],
        Resources: [],
        DisplayInTVApp: 1 as const,
        IncludedInAppData: 0 as const,
      };
      setNewCollection(tempId, newCollection);
      setCollectionToSave(tempId, getCollectionValuesFromNewCollection(newCollection));
    }
  }, [newCollectionType]);

  return (
    <ContentViewContainer collection={collection} key={collectionId} isLoading={!templatesData}>
      {collection && (
        <>
          <PaddedContainer>
            {collection.DataSource !== SOURCE_VIDAPP && (
              <ContentSourceTitle
                size="large"
                DataSource={collection.DataSource}
                SourceName={collection.SourceName}
                showDeepLink
                itemId={collection.TabId}
                itemType="collection"
                {...SEGMENT_MARGIN}
              />
            )}
            <CollectionTitle
              TabId={collection.TabId}
              Name={collection.Name}
              sourceName={collection.SourceName}
              label="VidApp Title"
              size="large"
              {...SEGMENT_MARGIN}
            />
            <CollectionTemplateSelect collection={collection} />
          </PaddedContainer>
          <ModalContextProvider treeCollectionId={collection.TabId}>
            <ContentCollectionTree collection={collection} />
          </ModalContextProvider>
          <PaddedContainer>
            <SettingsCollectionThumbnail collection={collection} {...SEGMENT_MARGIN} />
            <CollectionDescriptionPreview
              collection={collection}
              revertText={`Use description from ${getDataSourceDisplayName(collection.DataSource)}`}
            />
            <CollectionResources collection={collection} {...SEGMENT_MARGIN} />
            <ContentAdvancedSettings collection={collection} />
          </PaddedContainer>
        </>
      )}
    </ContentViewContainer>
  );
};
