import { useCallback } from 'react';
import styled from 'styled-components';

import { CustomButton } from 'components';
import { BuilderProduct, useLocalProducts, useSaveContext } from 'providers';
import { PlusIcon } from 'icons';
import { useAppBeingEdited } from 'app-context';
import { Product, SOURCE_VIDAPP } from 'api';
import { useAppBasicInfo } from 'hooks';

const StyledButton = styled(CustomButton)`
  &&&& {
    margin-right: 12px;
  }
`;

export const AddOfferButton = ({
  setActiveProductId,
}: {
  setActiveProductId: (id: string | number | undefined) => void;
}) => {
  const { getTempId, setProductToSave } = useSaveContext();
  const { products, setProducts } = useLocalProducts();
  const { data: appBasicInfo } = useAppBasicInfo(Infinity);
  const appId = useAppBeingEdited();

  const newProductId = useCallback(() => {
    const prefix = `${appBasicInfo?.AppleBundleId}.Bundle`;

    // First get indexes of all existing products starting with the prefix
    const usedIndexes: number[] = [];

    products?.forEach(({ ProductId }) => {
      if (ProductId.startsWith(prefix)) {
        usedIndexes.push(parseInt(ProductId.replace(prefix, ''))); // Remove prefix to only push indexes to array
      }
    });

    // If usedIndexes is empty, this is the first builder-created product so use 0, otherwise use the highest index + 1
    const nextIndex = usedIndexes.length === 0 ? 0 : Math.max(...usedIndexes) + 1;

    return `${prefix}${nextIndex}`;
  }, [products, appBasicInfo]);

  const handleAddProduct = useCallback(() => {
    const tempId = getTempId();
    const tempProductId = `TempProduct${tempId}`;
    const newProduct: BuilderProduct = {
      Id: tempProductId,
      AppId: appId,
      IsIndividualPurchase: 0,
      ProductType: 'subscription',
      ProductId: newProductId(),
      DataSource: SOURCE_VIDAPP,
      CustomDisplayName: 'New Offer',
      SourceProductId: '',
      SubscriptionGroup: '',
      Items: [],
      DisplayName: 'New Offer',
      ReferenceName: `${appId} - `,
      Description: `${appId} - `,
      ReadyForSale: 'no',
      AvailableForLogin: 'no',
      PriceTier: '-1',
      SubscriptionDuration: '1m',
      SubscriptionTrial: '0',
    };

    const values = Object.entries(newProduct).map(([key, value]) => {
      return { name: key as keyof Product, value };
    });
    setProductToSave(tempProductId, values);

    setProducts((prev) => {
      const newProducts = new Map(prev);
      newProducts.set(tempProductId, {
        ...newProduct,
      });
      return newProducts;
    });

    setActiveProductId(tempProductId);
  }, [setProducts, getTempId, setActiveProductId, newProductId]);

  return (
    <StyledButton secondary icon={<PlusIcon />} onClick={handleAddProduct}>
      Create New Offer
    </StyledButton>
  );
};
