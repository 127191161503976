import styled from 'styled-components';

import { SOURCE_KAJABI, SOURCE_VIDAPP, VIDEO_TYPE_POST } from 'api';
import { getVideoName, isResource, isUserAdmin } from 'utils';
import { ContentSourceTitle, VideoTitle, VideoPurchasePrice, VideoDescriptionPreview, DevTag } from 'components';

import { useContent } from 'app/modules/build-dragdrop/Builder/providers';
import {
  ContentResourceList,
  DrawerHeading,
  DrawerScrollContainer,
  FileDrawer,
  VideoResources,
  VideoThumbnail,
} from 'app/modules/build-dragdrop/Builder/drawer/components';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup';

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const VideoDetailsDrawer = () => {
  const { currentPage } = useMockupContext();
  const { videos } = useContent();

  const video = currentPage?.itemId ? videos[currentPage?.itemId] : undefined;

  const heading = video && isResource(video) ? 'File' : video?.DataSource === SOURCE_KAJABI ? 'Lesson' : 'Video';

  const suffix = isUserAdmin() ? (
    <div>
      <DevTag label="V">{video?.VideoId}</DevTag>
      <DevTag label="S">{video?.SourceId}</DevTag>
    </div>
  ) : null;

  const type = (
    <SpaceBetween>
      <div>{heading}</div>
      {suffix}
    </SpaceBetween>
  );

  if (!video) {
    return null;
  }

  return isResource(video) ? (
    <FileDrawer file={video} type={type} />
  ) : (
    <>
      <DrawerHeading heading={getVideoName(video)} type={type} />
      <DrawerScrollContainer paddingBottom="50px">
        <VideoTitle
          size="middle"
          Title={video.Title}
          sourceTitle={video.SourceTitle}
          VideoId={video.VideoId}
          $marginTop="0"
          showDeepLink
        />
        <VideoThumbnail video={video} />
        <VideoDescriptionPreview video={video} />
        {video.DataSource === SOURCE_VIDAPP && video.Type === VIDEO_TYPE_POST ? (
          <VideoResources video={video} />
        ) : (
          <ContentResourceList Resources={video?.Resources} />
        )}
        <ContentSourceTitle size="middle" DataSource={video.DataSource} SourceName={video.SourceTitle} />
        <VideoPurchasePrice VideoId={video.VideoId} ProductId={video.ProductId} DataSource={video.DataSource} />
      </DrawerScrollContainer>
    </>
  );
};
