import { useState } from 'react';
import styled from 'styled-components';

import { Comment } from 'api';
import { CustomTable, PageContainer, TableColumn, CustomCell } from 'components';
import { useComments } from 'hooks';
import { formatDateToDDMMYYYYHHMM } from 'utils';
import { FONT_12PX_MEDIUM, FONT_14PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';

import { CommentsModeratorModal } from './components/components/CommentsModeratorModal';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

const PAGE_SIZE = 20;

const SmallCell = styled(CustomCell)`
  ${FONT_12PX_MEDIUM};
`;

const Ellipsis = styled.div`
  ${OVERFLOW_ELLIPSIS};
  ${FONT_14PX_MEDIUM};
`;

const ParagraphCell = styled.div`
  padding: 18px 12px;
`;

const EllipsisParagraph = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  ${FONT_14PX_MEDIUM};
`;

const columns: TableColumn<Comment & { id: string }>[] = [
  {
    heading: 'User',
    width: 124,
    render: ({ CommenterName }) => (
      <CustomCell>
        <Ellipsis>{CommenterName}</Ellipsis>
      </CustomCell>
    ),
  },
  {
    heading: 'Item Title',
    width: 260,
    render: ({ ItemTitle }) => (
      <CustomCell>
        <Ellipsis>{ItemTitle}</Ellipsis>
      </CustomCell>
    ),
  },
  {
    heading: 'Comment',
    width: 'grow',
    render: ({ Comment }) => (
      <ParagraphCell>
        <EllipsisParagraph>{Comment}</EllipsisParagraph>
      </ParagraphCell>
    ),
  },
  {
    heading: 'Date',
    width: 150,
    render: (comment) => <SmallCell>{formatDateToDDMMYYYYHHMM(new Date(comment.Date))}</SmallCell>,
  },
];

export const CommentsModerator = () => {
  const [page, setPage] = useState(1);
  const [modalComment, setModalComment] = useState<Comment>();
  const { comments, fetchNextPage, isLoading, isError } = useComments();

  const totalTablePages = comments.length / PAGE_SIZE;

  const onRowClick = (data: Comment) => {
    setModalComment(data);
  };

  return (
    <PageContainer
      heading="Manage Comments"
      subheading="Moderate your users app comments."
      isLoading={isLoading}
      isError={isError}
      contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}
    >
      <CustomTable
        pageSize={PAGE_SIZE}
        columns={columns}
        data={comments.map((comment) => ({ ...comment, id: comment.CommentId.toString() }))}
        emptyTitle="No comments"
        emptyDescription="No one has commented yet."
        overridePage={page}
        onRowClick={onRowClick}
        onPageChange={(page) => {
          setPage(page);
          // If on the second to last or last table page, fetch more data
          if (page >= totalTablePages - 1) {
            fetchNextPage();
          }
        }}
      />
      {modalComment && (
        <CommentsModeratorModal
          data={modalComment}
          open={!!modalComment}
          closeModal={() => setModalComment(undefined)}
        />
      )}
    </PageContainer>
  );
};
