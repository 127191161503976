import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { MetricKey } from 'api';
import { LoadingDots, Tooltip } from 'components';
import { FONT_10PX_MEDIUM, FONT_30PX_SEMIBOLD } from 'font';
import { InfoCircleIcon } from 'icons';
import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_8_COLOUR } from 'theme';
import { getScreenFromPathname } from 'utils';

import { METRIC_PARAM } from '../const';
import { getMetricType } from '../util';
import { useAnalyticsContext } from '../providers/AnalyticsProvider';

const Container = styled(Link)<{ $active?: boolean; $screen: string; $disabled: boolean }>`
  &&&& {
    width: ${({ $screen }) => ($screen === 'dashboard' ? '48.9%' : $screen === 'engagement' ? '33.3%' : '25%')};
    margin-bottom: 16px;
    height: 97px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    border: solid 1px ${NEUTRAL_5_COLOUR};
    outline: solid 1px transparent;
    border-color: ${({ $active }) => $active && HIGHLIGHT_PRIMARY_COLOUR};
    outline-color: ${({ $active }) => $active && HIGHLIGHT_PRIMARY_COLOUR};

    :hover {
      border-color: ${({ $active }) => ($active ? HIGHLIGHT_PRIMARY_COLOUR : NEUTRAL_10_COLOUR)};
    }

    :not(:last-child) {
      ${({ $screen }) => $screen !== 'dashboard' && 'margin-right: 16px'}
    }

    ${({ $disabled }) => $disabled && 'cursor: default'};
    ${({ $disabled }) => $disabled && 'pointer-events: none'};
  }
`;

const Title = styled.div<{ $active?: boolean }>`
  width: fit-content;
  ${FONT_10PX_MEDIUM};
  color: ${({ $active }) => ($active ? NEUTRAL_10_COLOUR : NEUTRAL_8_COLOUR)};
`;

const StyledTooltip = styled(Tooltip)<{ $active?: boolean }>`
  &&&& {
    color: ${({ $active }) => ($active ? NEUTRAL_10_COLOUR : NEUTRAL_8_COLOUR)};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
`;

const ValueRow = styled(Row)`
  height: 31px;
  margin-bottom: 0;
`;

const Value = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_30PX_SEMIBOLD};
`;

const StyledIcon = styled(InfoCircleIcon)`
  font-size: 12px;
  margin-left: 4px;
`;

const LoadingDotsWrapper = styled.div`
  padding-left: 6px;
`;

interface CardProps {
  title: string;
  value?: number | string;
  isPercentage?: boolean;
  metric: MetricKey;
  tooltip?: string;
  active?: boolean;
  isLoading?: boolean;
}

export const Card = ({ title, value, isPercentage, metric, tooltip, active, isLoading, ...props }: CardProps) => {
  const formattedValue = !value ? '--' : isPercentage ? `${Math.round(value as number)}%` : value.toLocaleString();
  const { pathname } = useLocation();
  const screen = getScreenFromPathname(pathname) as string;
  const { disabledKeys } = useAnalyticsContext();

  return (
    <Container
      to={`/analytics/${getMetricType(metric)}?${METRIC_PARAM}=${metric}`}
      replace={screen !== 'dashboard'} // Only update history stack if coming from Dashboard
      $disabled={disabledKeys?.includes(metric)}
      $active={active}
      $screen={screen}
      {...props}
    >
      <Row>
        <Title $active={active}>{title}</Title>
        {tooltip && <StyledTooltip title={tooltip} icon={<StyledIcon />} $active={active} />}
      </Row>
      <ValueRow>
        {isLoading ? (
          <LoadingDotsWrapper>
            <LoadingDots />
          </LoadingDotsWrapper>
        ) : (
          <Value>{formattedValue}</Value>
        )}
      </ValueRow>
    </Container>
  );
};
