import {
  AppProperties,
  Collection,
  CollectionItem,
  ITEM_TYPE_COLLECTION,
  ITEM_TYPE_TAB,
  ITEM_TYPE_VIDEO,
  Resource,
  S3_BUCKET,
  SOURCE_TYPE_USCREEN_COL,
  SOURCE_USCREEN,
  SOURCE_VHX,
  SOURCE_WOOCOMMERCEMEMBERSHIPS,
  SOURCE_WOOCOMMERCESUBSCRIPTIONS,
  ThumbnailSize,
  ThumbnailType,
  Video,
} from 'api';
import {
  AUDIO_EXTENSIONS,
  CollectionNameProps,
  DEFAULT_THUMBNAIL,
  getCollectionName,
  isResource,
  secondsToTime,
  VideoNameProps,
} from './index';
import { getVideoName } from './video-util';
import { BuilderCollection, BuilderVideo } from 'providers';

// Map of expected source types, unexpected types will be grouped under 'other'
const EXPECTED_SOURCETYPES: Record<string, string> = {
  product: 'product',
  category: 'category',
  'sub-category': 'sub-category',
  series: 'series',
  season: 'season',
  playlist: 'playlist',
  movie: 'movie',
  uscreencollection: 'uscreencollection',
  collection: 'collection',
  challenge: 'series',
  course: 'series',
  program: 'series',
  tag: 'tag',
  workout: 'workout',
  other: 'other',
};
const SOURCETYPE_SORT = Object.values(EXPECTED_SOURCETYPES);

const getUrlFromFilename = (prefix: string, url: string) => {
  if (url.toLowerCase().startsWith('http')) {
    return url;
  }
  return `${prefix}/${url}`;
};

export const getImageUrl = (appId: string, filename?: string) => {
  if (!filename) {
    return filename;
  }
  const prefix = `${S3_BUCKET}${appId}/images`;
  return getUrlFromFilename(prefix, filename);
};

export type ThumbnailUrlProps = Partial<
  Pick<
    Video,
    | 'Thumbnails'
    | 'ThumbnailSource'
    | 'SourceThumbnailSource'
    | 'SquareThumbnails'
    | 'PortraitThumbnails'
    | 'SquareThumbnailSource'
    | 'PortraitThumbnailSource'
    | 'SourcePortraitThumbnailSource'
    | 'SourceSquareThumbnailSource'
  >
>;
export const getContentThumbnailUrl = (
  appId: string,
  content: ThumbnailUrlProps,
  size?: ThumbnailSize,
  type?: ThumbnailType,
) => {
  const prefix = `${S3_BUCKET}${appId}/images`;

  if (!type || type === 'landscape') {
    if (content.Thumbnails && size) {
      const sizedThumbnail = content.Thumbnails[size];
      if (sizedThumbnail) {
        return getUrlFromFilename(prefix, sizedThumbnail);
      }
    }

    if (content.ThumbnailSource) {
      return getUrlFromFilename(prefix, content.ThumbnailSource);
    }
    if (content.SourceThumbnailSource) {
      return getUrlFromFilename(prefix, content.SourceThumbnailSource);
    }
  } else if (type === 'square') {
    if (content.SquareThumbnails && size) {
      const sizedThumbnail = content.SquareThumbnails[size];
      if (sizedThumbnail) {
        return getUrlFromFilename(prefix, sizedThumbnail);
      }
    }
    if (content.SquareThumbnailSource) {
      return getUrlFromFilename(prefix, content.SquareThumbnailSource);
    }
    if (content.SourceSquareThumbnailSource) {
      return getUrlFromFilename(prefix, content.SourceSquareThumbnailSource);
    }
  } else if (type === 'portrait') {
    if (content.PortraitThumbnails && size) {
      const sizedThumbnail = content.PortraitThumbnails[size];
      if (sizedThumbnail) {
        return getUrlFromFilename(prefix, sizedThumbnail);
      }
    }
    if (content.PortraitThumbnailSource) {
      return getUrlFromFilename(prefix, content.PortraitThumbnailSource);
    }
    if (content.SourcePortraitThumbnailSource) {
      return getUrlFromFilename(prefix, content.SourcePortraitThumbnailSource);
    }
  }

  return DEFAULT_THUMBNAIL;
};

export const getContentName = (
  props: VideoNameProps | CollectionNameProps | Collection | Video,
  type: CollectionItem['Type'],
) => {
  if (type === ITEM_TYPE_COLLECTION || type === ITEM_TYPE_TAB) {
    return getCollectionName(props as CollectionNameProps);
  }
  return getVideoName(props as VideoNameProps);
};

export const getDataSourceDisplayName = (dataSource: string) => {
  return (
    {
      [SOURCE_VHX]: 'Vimeo OTT',
      [SOURCE_WOOCOMMERCESUBSCRIPTIONS]: 'WooCommerce Subscriptions',
      [SOURCE_WOOCOMMERCEMEMBERSHIPS]: 'WooCommerce Memberships',
    }[dataSource] ?? dataSource
  );
};

/*
  Groups anything that has a SourceType
  Supports both Collections and DragDropCollections
 */
export const groupCollectionsBySourceType = <T extends Pick<Collection, 'SourceType' | 'DataSource'>>(
  collections: T[],
) => {
  const output: Record<string, T[]> = {};

  // Group collections into categories by SourceType
  for (const collection of collections) {
    const sourceType =
      collection.DataSource === SOURCE_USCREEN && collection.SourceType === SOURCE_TYPE_USCREEN_COL
        ? 'uscreencollection' // We don't want uscreen collections to be converted to "Group", so use custom SourceType which will be converted to "Collection" in getSourceTypeDisplayName
        : collection.SourceType?.toLowerCase();
    const key = EXPECTED_SOURCETYPES[sourceType] ?? 'other';
    if (!output[key]) {
      output[key] = [];
    }
    output[key].push(collection);
  }
  return Object.entries(output).sort(([name_1], [name_2]) => {
    return SOURCETYPE_SORT.indexOf(name_1) - SOURCETYPE_SORT.indexOf(name_2);
  });
};

export const getContentType = (items: Pick<CollectionItem, 'Type'>[]) => {
  const types = items.reduce((acc, curr) => {
    acc.add(curr.Type);
    return acc;
  }, new Set<string>());

  if (types.size > 1) {
    return 'Item'; // Mixed items
  } else if (types.has(ITEM_TYPE_COLLECTION)) {
    return 'Collection';
  } else if (types.has(ITEM_TYPE_VIDEO)) {
    return 'Video';
  }
  return 'Item'; // Fallback
};

export const getContentOverlay = (
  props: Pick<Video, 'DurationSeconds'> | Pick<Collection, 'Items'> | Pick<BuilderCollection, 'Items'>,
  options?: Pick<AppProperties, 'HideTimestampOnVideoThumbnails' | 'HideItemCountOnCollectionThumbnails'>,
) => {
  // Input expects one or the other, but we treat it as both so typescript is happy
  const ambiguousProps = props as Pick<Video, 'DurationSeconds'> & Pick<Collection, 'Items'>;
  if (ambiguousProps?.Items) {
    if (options?.HideItemCountOnCollectionThumbnails === '1') {
      return '';
    }
    const { Items } = ambiguousProps;
    const plural = Items.length > 1 ? 's' : '';
    return Items.length > 0 ? `${Items.length} ${getContentType(Items).toLowerCase()}${plural}` : '';
  } else if (ambiguousProps?.DurationSeconds) {
    if (options?.HideTimestampOnVideoThumbnails === '1') {
      return '';
    }
    const { DurationSeconds } = ambiguousProps;
    return secondsToTime(parseInt(DurationSeconds), { hideHours: true });
  }
  return '';
};

export const contentHasPreviewVideo = (content: Video | BuilderVideo | BuilderCollection) => {
  return !!content.PreviewVideoId && content.PreviewVideoId != 0 && content.PreviewVideoId != -1;
};
export const isContentResource = (content: Video | BuilderVideo | BuilderCollection, type: CollectionItem['Type']) => {
  if (type === ITEM_TYPE_VIDEO) {
    return isResource(content as Video);
  }
  return false;
};

export const getContentResourceType = (
  content: Video | BuilderVideo | BuilderCollection,
  type: CollectionItem['Type'],
) => {
  if (type === ITEM_TYPE_VIDEO) {
    const resourceExtensions = [...AUDIO_EXTENSIONS, 'pdf', 'png', 'jpg', 'jpeg'];
    const fileParts = (content as BuilderVideo)?.OriginalFilename?.split('.') ?? [];
    if (fileParts.length < 2) {
      return '';
    }
    const extension = fileParts.pop() || '';
    return resourceExtensions.includes(extension) ? extension : '';
  }
  return '';
};

export const getResourceUrl = (appId: string, resource: Pick<Resource, 'Url'>) => {
  const resourceUrl = resource.Url.toLowerCase();
  if (resourceUrl.startsWith('https' || resourceUrl.startsWith('http'))) {
    return resourceUrl;
  }
  return `https://storage.vidapp.com/${appId}/${resourceUrl}`;
};
