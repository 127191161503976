import { ShepherdOptionsWithType, ShepherdTour, Step, Tour } from 'react-shepherd';
import { ReactNode, useCallback } from 'react';
import ReactDOMServer from 'react-dom/server';
import { RouterChildContext, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import 'shepherd.js/dist/css/shepherd.css';
import './tour.css';
import { CallIcon, DashboardIcon, DesignIcon, PublishIcon, SaveIcon, ToolIcon } from 'icons';
import { useSaveAppProperties } from 'hooks';
import { useOnboardingChecklistContext } from 'app/modules/onboarding-checklist/OnboardingChecklist/providers/OnboardingChecklistProvider';
import { STEP_KEY_EXPLORE } from 'app/modules/onboarding-checklist/OnboardingChecklist/const';

const StepText = styled.span`
  font-weight: 400;
  font-family: Inter, sans-serif;
  font-size: 18px;
  line-height: 26px;
`;
const Semibold = styled.span`
  font-weight: 600;
`;
const TitleWrapper = styled.div`
  display: flex;
`;
const TitleText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const StyledIcon = styled.div`
  height: 24px;
  width: 24px;
  font-size: 24px;
  margin-right: 8px;
  display: flex;
`;

const getTitle = (title: string, icon?: ReactNode) => {
  return () =>
    ReactDOMServer.renderToStaticMarkup(
      <TitleWrapper>
        {icon && <StyledIcon>{icon}</StyledIcon>}
        <TitleText>{title}</TitleText>
      </TitleWrapper>,
    );
};

const getFooter = (step: Step) => {
  const currentStepElement = step.getElement();
  const tour = step.getTour();
  if (currentStepElement) {
    const footer = currentStepElement.querySelector('.shepherd-footer');
    const progress = document.createElement('div');
    progress.className = 'shepherd-progress';
    progress.innerText = `${tour.steps.indexOf(step) + 1}/${tour.steps.length}`;
    if (footer) {
      footer.prepend(progress);
    }
  }
};

const getSteps = (onComplete: () => void, history: RouterChildContext['router']['history']) => {
  const steps: ShepherdOptionsWithType[] = [
    {
      title: getTitle('Your Dashboard', <DashboardIcon />),
      text: () =>
        ReactDOMServer.renderToStaticMarkup(
          <StepText>
            Use the Dashboard to help track your progress towards launching your app. Once launched this dashboard will
            display key information for your app.
          </StepText>,
        ),
      attachTo: {
        element: document.getElementById('NavItem--Dashboard')?.parentNode?.parentNode as HTMLElement,
        on: 'right-start',
      },
      beforeShowPromise: async () => {
        history.push('/');
      },
      popperOptions: {
        modifiers: [
          // Tricky solution to get custom arrow placement
          { name: 'offset', options: { offset: [-5, 17] } },
          { name: 'arrow', options: { padding: 5 } },
        ],
      },
      modalOverlayOpeningPadding: 6,
    },
    {
      title: getTitle('Book In A Call', <CallIcon />),
      text: () =>
        ReactDOMServer.renderToStaticMarkup(
          <StepText>
            {
              "When you're ready, schedule a time with us to finish your onboarding process. We'll discuss the best plan for your business needs."
            }
          </StepText>,
        ),
      beforeShowPromise: async () => {
        history.push('/');
        return new Promise<void>((resolve) => {
          setTimeout(() => resolve(), 300);
        });
      },
      attachTo: { element: '#Checklist__Item--call', on: 'top-start' },
      popperOptions: {
        modifiers: [
          {
            name: 'arrow',
            options: { padding: { right: 28 } },
          },
        ],
      },
    },
    {
      title: getTitle('Color Scheme', <DesignIcon />),
      text: () =>
        ReactDOMServer.renderToStaticMarkup(
          <StepText>Customize the look and feel of your app in the Color Scheme section.</StepText>,
        ),
      attachTo: {
        element: document.getElementById('NavItem--ColorScheme')?.parentNode as HTMLElement,
        on: 'right-start',
      },
      beforeShowPromise: async () => {
        history.push('/design/color-scheme');
        return new Promise<void>((resolve) => {
          setTimeout(() => resolve(), 300);
        });
      },
      popperOptions: {
        modifiers: [
          // Tricky solution to get custom arrow placement
          { name: 'offset', options: { offset: [-12, 98] } },
          { name: 'arrow', options: { padding: 5 } },
        ],
      },
      modalOverlayOpeningPadding: 6,
    },
    {
      title: getTitle('Save', <SaveIcon />),
      text: () =>
        ReactDOMServer.renderToStaticMarkup(
          <StepText>Once you have made any edits, be sure to save your changes.</StepText>,
        ),
      attachTo: { element: '#Heading__Save', on: 'left-start' },
      highlightClass: 'shepherd-active-button',
      modalOverlayOpeningPadding: 12,
      popperOptions: {
        modifiers: [
          { name: 'offset', options: { offset: [-10, 24] } },
          { name: 'arrow', options: { padding: { top: 0 } } },
        ],
      },
    },
    {
      title: getTitle('Publish To Preview', <PublishIcon />),
      text: () =>
        ReactDOMServer.renderToStaticMarkup(
          <StepText>
            To see what your changes look like in your test app, you will need to:
            <br />
            {"1. Click 'Publish To Preview'"}
            <br />
            2. Exit and reopen your test app
          </StepText>,
        ),
      // attachTo: { element: '#NavHeader__Publish', on: 'bottom-end' },
      highlightClass: 'shepherd-active-button',
      modalOverlayOpeningPadding: 16,

      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [12, 28] } }],
      },
    },
    {
      title: getTitle('Build My App', <ToolIcon />),
      text: () =>
        ReactDOMServer.renderToStaticMarkup(
          <StepText>You can edit and customize the tabs of your app in Build My App.</StepText>,
        ),
      beforeShowPromise: async () => {
        history.push('/design/color-scheme');
        return new Promise<void>((resolve) => {
          setTimeout(() => resolve(), 300);
        });
      },
      attachTo: {
        element: document.getElementById('NavItem--BuildMyApp')?.parentNode as HTMLElement,
        on: 'right-start',
      },
      popperOptions: {
        modifiers: [
          // Tricky solution to get custom arrow placement
          { name: 'offset', options: { offset: [-12, 98] } },
          { name: 'arrow', options: { padding: 5 } },
        ],
      },
      modalOverlayOpeningPadding: 6,
    },
    {
      title: getTitle('Build My App', <ToolIcon />),
      text: () =>
        ReactDOMServer.renderToStaticMarkup(
          <StepText>Tap 'Customize' on a tab to edit the layout and its settings.</StepText>,
        ),
      beforeShowPromise: async () => {
        history.push('/build-my-app');
        return new Promise<void>((resolve) => {
          setTimeout(() => resolve(), 500);
        });
      },
      attachTo: { element: '#BuildNavTab--GuidedTour', on: 'right-start' },
      highlightClass: 'shepherd-hover',
      when: {
        show: function () {
          getFooter(this);
          const tour = this.getTour();
          const customizeButton = document.getElementById('CustomizeButton--BuildNavTab');
          const nextStep = () => setTimeout(tour.next, 100);

          customizeButton?.addEventListener('click', nextStep);

          tour.on('cancel', () => {
            customizeButton?.removeEventListener('click', nextStep);
          });
        },
      },
      popperOptions: {
        modifiers: [
          // Tricky solution to get custom arrow placement
          { name: 'offset', options: { offset: [0, 27] } },
          { name: 'arrow', options: { padding: 45 } },
        ],
      },
      modalOverlayOpeningPadding: 12,
    },
    {
      title: getTitle('Add Content or Sections', <ToolIcon />),
      text: () =>
        ReactDOMServer.renderToStaticMarkup(
          <StepText>
            Click <Semibold>+ Add</Semibold> where you want to insert something and then select content synced from your
            website or a new section.
          </StepText>,
        ),
      modalOverlayOpeningPadding: 6,
      popperOptions: {
        modifiers: [
          // Tricky solution to get custom arrow placement
          { name: 'offset', options: { offset: [-5, 17] } },
          { name: 'arrow', options: { padding: 5 } },
        ],
      },
      beforeShowPromise: async () => {
        history.push('/builder/0');
        return new Promise<void>((resolve) => {
          setTimeout(() => resolve(), 500);
        });
      },
    },
    {
      title: getTitle('You’re All Set', <DashboardIcon />),
      text: () =>
        ReactDOMServer.renderToStaticMarkup(
          <StepText>
            Play around and start customising your app! It might take up to 5 minutes for your content to sync. Once
            you're finished click Dashboard to go back to your Dashboard.
          </StepText>,
        ),
    },
  ];
  steps[steps.length - 1].buttons = [
    {
      classes: 'shepherd-button-secondary',
      text: 'Back',
      action(this) {
        this.back();
      },
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Finish',
      action(this) {
        onComplete();
        this.complete();
      },
    },
  ];
  return steps;
};
const tourOptions: Tour.TourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: false,
    },
    scrollTo: true,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        action(this) {
          this.back();
        },
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        action(this) {
          this.next();
        },
      },
    ],
    modalOverlayOpeningRadius: 8,
    popperOptions: {
      modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
    },
    when: {
      show: function () {
        getFooter(this);
      },
    },
  },
  // eslint-disable-next-line
  stepsContainer: document.getElementById('react-app')!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  modalContainer: document.getElementById('react-app')!,
  useModalOverlay: true,
};

export const GuidedTour = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const saveAppProperties = useSaveAppProperties();
  const { completeOnboardingStep } = useOnboardingChecklistContext();

  const onComplete = useCallback(() => {
    saveAppProperties.mutate([{ Name: 'OnboardingChecklistExplore', Value: '1' }]);
    completeOnboardingStep(STEP_KEY_EXPLORE);
  }, [saveAppProperties, completeOnboardingStep]);

  const history = useHistory();

  return (
    <ShepherdTour steps={getSteps(onComplete, history)} tourOptions={tourOptions}>
      {children}
    </ShepherdTour>
  );
};
