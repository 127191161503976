import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { LoadingSpinner } from 'components';
import { useAppBasicInfo } from 'hooks';
import { AppBasicInfo } from 'api';

import { LocalAppBasicInfoProvider } from './local-app-basic-info-provider';

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface DefaultAppBasicInfoProviderProps {
  children: ReactNode | ReactNode[];
}

export const DefaultAppBasicInfoProvider = ({ children }: DefaultAppBasicInfoProviderProps) => {
  const [basicInfo, setBasicInfo] = useState<AppBasicInfo>();
  const history = useHistory();

  const { data: basicInfoData } = useAppBasicInfo();

  const revertLocalChanges = () => {
    setBasicInfo(basicInfoData);
  };

  // When the user navigates to a new page the local changes will be reverted
  history.listen((location, action) => {
    if (action === 'PUSH' || action === 'POP') {
      revertLocalChanges();
    }
  });

  useEffect(() => {
    // Setup state once on initial data load
    if (basicInfo === undefined && !!basicInfoData) {
      setBasicInfo(basicInfoData);
    }
  }, [!!basicInfoData]);

  return !basicInfo ? (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  ) : (
    <LocalAppBasicInfoProvider
      basicInfo={basicInfo}
      setBasicInfo={setBasicInfo as Dispatch<SetStateAction<AppBasicInfo>>}
    >
      {children}
    </LocalAppBasicInfoProvider>
  );
};
