import { useCallback, useState } from 'react';
import { format, fromUnixTime } from 'date-fns';
import styled from 'styled-components';
import { debounce } from 'lodash';

import { NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_7_COLOUR, PAGE_CONTAINER_WIDE_WIDTH } from 'theme';
import { CustomTable, PageContainer, SettingsTextInput, TableColumn } from 'components';
import { useAppUsers } from 'hooks';
import { useUserPurchases } from './hooks/useUserPurchases';

import { LoadingSpinner, SearchIcon } from 'icons';
import { AppPurchase } from 'api';
import { FONT_14PX_MEDIUM, FONT_16PX_SEMIBOLD } from '../../../font';
import { useOffers } from '../offers/hooks/useOffers';
import { PurchaseDetails, SyncPurchaseModal } from './components/SyncPurchaseModal';

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 16px;
  color: ${NEUTRAL_7_COLOUR} !important;
`;
const SearchBox = styled(SettingsTextInput)`
  &&&.ant-input-affix-wrapper {
    margin-bottom: 16px !important;
  }

  color: ${NEUTRAL_7_COLOUR};
  flex-shrink: 0;
`;
const CustomCell = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  ${FONT_14PX_MEDIUM};
`;

const UserID = styled.div`
  width: 500px;
  color: ${NEUTRAL_10_COLOUR};
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 6px;
  ${FONT_16PX_SEMIBOLD};
  padding: 8px;
  margin-top: 24px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`;
const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Heading = styled.div`
  ${FONT_16PX_SEMIBOLD};
`;
const Subtitle = styled.div`
  ${FONT_14PX_MEDIUM};
  margin-bottom: 16px;
`;

export const UserLookup = () => {
  const [email, setEmail] = useState<string>('');
  const [purchase, setPurchase] = useState<PurchaseDetails | undefined>();
  const { data, isLoading } = useUserPurchases(email);
  const { products } = useOffers();
  const { data: appUserData } = useAppUsers(data ? [data.UserID] : undefined);
  const debouncedSetEmail = useCallback(
    debounce((newQuery) => {
      setEmail(newQuery);
    }, 500),
    [setEmail],
  );
  const getOfferByProductId = useCallback(
    (productId: string) => {
      if (!products) {
        return;
      }
      return (
        Array.from(products)
          // eslint-disable-next-line
          .map(([_, p]) => p)
          .find((p) => p.ProductId === productId || p.ProductIdAndroid === productId)
      );
    },
    [products],
  );

  const columns: TableColumn<AppPurchase & { id: string }>[] = [
    {
      heading: 'Store',
      width: 100,
      render: (data) => <CustomCell>{data.Store}</CustomCell>,
    },
    {
      heading: 'Original Purchase Email',
      width: 300,
      render: (data) => <CustomCell>{data.OriginalPurchaseEmail}</CustomCell>,
    },
    {
      heading: 'Original (UTC)',
      width: 160,
      render: ({ OriginalPurchaseTs }) => (
        <CustomCell>{format(fromUnixTime(OriginalPurchaseTs), 'MMMM dd, yyyy')}</CustomCell>
      ),
    },
    {
      heading: 'Latest (UTC)',
      width: 160,
      render: ({ Transaction: { PurchaseTs } }) => (
        <CustomCell>{format(fromUnixTime(PurchaseTs), 'MMMM dd, yyyy')}</CustomCell>
      ),
    },
    {
      heading: 'Transaction Expiry',
      width: 160,
      render: ({ Transaction: { ExpirationTs } }) => (
        <CustomCell>{format(fromUnixTime(ExpirationTs), 'MMMM dd, yyyy')}</CustomCell>
      ),
    },
    {
      heading: 'Expired',
      width: 100,
      render: ({ Transaction: { ExpirationProcessed } }) => (
        <CustomCell>{ExpirationProcessed == 1 ? 'Yes' : 'No'}</CustomCell>
      ),
    },
    {
      heading: 'Trial',
      width: 100,
      render: ({ Transaction: { Trial } }) => <CustomCell>{Trial == 1 ? 'Yes' : 'No'}</CustomCell>,
    },
    {
      heading: 'Product Name',
      width: 300,
      render: ({ Transaction: { StoreProductId } }) => (
        <CustomCell>{getOfferByProductId(StoreProductId)?.DisplayName}</CustomCell>
      ),
    },
  ];

  const lastLogin = appUserData?.users[0]?.LastLoginTs;

  return (
    <PageContainer heading="User Lookup" contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}>
      <SearchBox
        defaultValue={email}
        prefix={isLoading ? <LoadingSpinner /> : <StyledSearchIcon />}
        placeholder="Lookup by Email"
        onChange={(e) => debouncedSetEmail(e.target.value)}
      />
      {data?.UserID && (
        <UserID>
          <SpaceBetween>
            <div>User ID</div>
            <div>{data?.UserID}</div>
          </SpaceBetween>
          <SpaceBetween>
            <div> Last Successful Login</div>
            <div>{lastLogin ? format(fromUnixTime(lastLogin / 1000), 'MMMM dd, yyyy, HH:MM aaa') : 'Unknown'}</div>
          </SpaceBetween>
        </UserID>
      )}
      <Heading>Purchases</Heading>
      <Subtitle>Click a row to attempt to sync a newer transaction for a purchase</Subtitle>
      <CustomTable
        columns={columns}
        isLoading={isLoading}
        data={data?.Purchases.map((p) => ({ ...p, id: p.Id.toString() })) ?? []}
        onRowClick={(row) =>
          setPurchase({
            userID: data?.UserID ?? '',
            purchaseName: getOfferByProductId(row.Transaction.StoreProductId)?.DisplayName ?? '',
            purchaseId: row.Id,
            productId: row.Transaction.StoreProductId,
            email: row.OriginalPurchaseEmail, // Used for invalidating row after syncing a new purchase
          })
        }
        emptyDescription="No purchases found"
      />
      <SyncPurchaseModal key={purchase?.purchaseId} purchase={purchase} setPurchase={setPurchase} />
    </PageContainer>
  );
};
