import styled from 'styled-components';

import { NEUTRAL_8_COLOUR } from 'theme';
import { InfoIcon } from 'icons';
import { FONT_14PX_MEDIUM } from 'font';

const NoFiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 0;
`;

const StyledInfoIcon = styled(InfoIcon)`
  font-size: 50px;
  margin-bottom: 18px;
`;

const Title = styled.div`
  width: 240px;
  text-align: center;
  color: ${NEUTRAL_8_COLOUR};
  ${FONT_14PX_MEDIUM};
`;

export const NoFilters = () => (
  <NoFiltersContainer>
    <StyledInfoIcon />
    <Title>Add a new category by tapping the Add New Category button.</Title>
  </NoFiltersContainer>
);
