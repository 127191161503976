import { addMonths, format, subMonths } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { VIDAPP_HELP_URL, saveCalendarMonthStatus, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { CustomButton, Dropdown, DropdownOption, InfoModal, SegmentTitle } from 'components';
import { FONT_12PX_MEDIUM } from 'font';
import { AppBackIcon, CheckIcon, FileBlankIcon, FilledRightArrow, ForwardIcon, UploadIcon } from 'icons';
import { NEUTRAL_10_COLOUR, NEUTRAL_4_COLOUR, NEUTRAL_8_COLOUR, SECONDARY_SUCCESS_COLOUR, SUCCESS_COLOUR } from 'theme';

import { useCalendarModalContext } from '../../CalendarModal/providers/calendar-modal-provider';
import { useCalendarPageContext } from '../../CalendarModal/providers/calendar-page-provider';

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
`;

const MonthHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  margin-right: 8px;
`;

const MonthControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActiveDate = styled.div`
  width: 100px;
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_12PX_MEDIUM};
  text-align: center;
`;

const DownArrow = styled(FilledRightArrow)`
  &&&& {
    font-size: 14px;
    transform: rotate(90deg);
    margin-right: 0 !important;
  }
`;

const DropdownContent = styled.div`
  padding: 9px 12px 9px 4px;
`;

const DropdownHeader = styled.div`
  ${FONT_12PX_MEDIUM};
  color: ${NEUTRAL_8_COLOUR};
`;

const buttonStylePublished = css`
  background-color: #fff;
  color: ${SUCCESS_COLOUR};
`;
const buttonStyleDraft = css`
  background-color: #fff;
  color: ${NEUTRAL_10_COLOUR};
`;
const buttonStylePublishedActive = css`
  background-color: ${SECONDARY_SUCCESS_COLOUR};
  color: ${SUCCESS_COLOUR};
`;
const buttonStyleDraftActive = css`
  background-color: ${NEUTRAL_4_COLOUR};
  color: ${NEUTRAL_10_COLOUR};
`;

const getButtonStyles = (isPublished?: boolean, isActive?: boolean) => {
  if (isActive) {
    return isPublished ? buttonStylePublishedActive : buttonStyleDraftActive;
  }
  return isPublished ? buttonStylePublished : buttonStyleDraft;
};

const StyledButton = styled(CustomButton)<{ isPublished?: boolean }>`
  &&&& {
    ${({ isPublished }) => getButtonStyles(isPublished, true)};
  }
`;

const StyledDropdownOption = styled(DropdownOption)<{ isPublished?: boolean; isActive?: boolean }>`
  &&&& {
    ${({ isPublished, isActive }) => getButtonStyles(isPublished, isActive)};
    margin-top: 8px;
    width: fit-content;
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  &&&& {
    margin-left: 2px !important;
    margin-right: 0 !important;
  }
`;

export const CalendarHeader = () => {
  const appBeingEdited = useAppBeingEdited();
  const axiosInstance = useAxiosInstance();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { activeDate, setActiveDate, publishedStatus, setPublishedStatus, startDate, endDate } =
    useCalendarPageContext();
  const { setModalPublishedStatus } = useCalendarModalContext();

  const handleMonthChange = useCallback(
    (direction) => {
      direction === 'backwards' ? setActiveDate(subMonths(activeDate, 1)) : setActiveDate(addMonths(activeDate, 1));
    },
    [activeDate, setActiveDate],
  );

  const handleStatusChange = useCallback(
    async (value) => {
      setDropdownOpen(false);
      setIsSaving(true);
      const month = format(activeDate, 'yyyy-MM');
      setPublishedStatus({ ...publishedStatus, [month]: value });
      await saveCalendarMonthStatus(axiosInstance, appBeingEdited, {
        Month: month,
        Published: value,
      }).then((response) => {
        if (response.status === 201) {
          setModalPublishedStatus(value);
          if (value === 1) {
            InfoModal(
              `${format(activeDate, 'MMMM')} Calendar is now published`,
              'Change status to Draft to un-publish.',
              'success',
              'Done',
            );
          } else {
            InfoModal(
              `${format(activeDate, 'MMMM')} Calendar has been set to Draft`,
              'Your scheduled content for this month is no longer visible in the app.',
              'info',
              'Done',
            );
          }
        } else {
          setPublishedStatus(publishedStatus); // Save failed, set status to previous state
          InfoModal(
            `Failed To Publish`,
            <span>
              Your calendar failed to publish. Please try again or click{' '}
              <a href={VIDAPP_HELP_URL} target="_blank" rel="noopener noreferrer">
                here
              </a>{' '}
              to get in contact with the VidApp support team.
            </span>,
            'error',
            'Done',
          );
        }
        setIsSaving(false);
      });
    },
    [publishedStatus, setPublishedStatus, activeDate, saveCalendarMonthStatus, setIsSaving],
  );

  useEffect(() => {
    setModalPublishedStatus(publishedStatus[format(activeDate, 'yyyy-MM')] ?? 0);
  }, [publishedStatus, activeDate]);

  const isPublished = publishedStatus[format(activeDate, 'yyyy-MM')] === 1;

  return (
    <HeaderContainer>
      <MonthHeadingWrapper>
        <TitleWrapper>
          <SegmentTitle title={`${format(activeDate, 'MMMM')} Calendar`} $marginBottom="0" />
        </TitleWrapper>
        <Dropdown
          trigger={
            <StyledButton
              tertiary
              small
              isPublished={isPublished}
              icon={isPublished ? undefined : <FileBlankIcon />}
              disabled={isSaving}
            >
              {isPublished ? 'Published' : 'Draft'}
              <DownArrow />
            </StyledButton>
          }
          menuMinWidth="195px"
          isOpen={dropdownOpen}
          onOpenChange={setDropdownOpen}
          align="start"
          content={
            <DropdownContent>
              <DropdownHeader>{`Set ${format(activeDate, 'MMMM')} Calendar to:`}</DropdownHeader>
              <StyledDropdownOption
                tertiary
                small
                onClick={() => handleStatusChange(0)}
                icon={<FileBlankIcon />}
                isActive={!isPublished}
              >
                Draft
                {!isPublished && <StyledCheckIcon />}
              </StyledDropdownOption>
              <StyledDropdownOption
                tertiary
                small
                onClick={() => handleStatusChange(1)}
                icon={<UploadIcon />}
                isActive={isPublished}
                isPublished
              >
                Published
                {isPublished && <StyledCheckIcon />}
              </StyledDropdownOption>
            </DropdownContent>
          }
        />
      </MonthHeadingWrapper>
      <MonthControls>
        <CustomButton
          small
          tertiary
          onClick={() => handleMonthChange('backwards')}
          disabled={format(activeDate, 'MM-yy') === format(startDate, 'MM-yy')}
          data-testid="left-arrow-button"
          icon={<AppBackIcon />}
        />
        <ActiveDate>{format(activeDate, 'MMMM yyyy')}</ActiveDate>
        <CustomButton
          small
          tertiary
          onClick={() => handleMonthChange('forwards')}
          disabled={format(activeDate, 'MM-yy') === format(endDate, 'MM-yy')}
          data-testid="right-arrow-button"
          icon={<ForwardIcon />}
        />
      </MonthControls>
    </HeaderContainer>
  );
};
