import { Dispatch, SetStateAction, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { SOURCE_TYPE_WORKOUT } from 'api';
import { useAppBeingEdited } from 'app-context';
import { PageContainer } from 'components';
import {
  BuilderCollections,
  BuilderVideos,
  ContentProvider,
  DefaultAppPropertiesProvider,
  DefaultContentProvider,
  SaveProvider,
  useUnsavedChanges,
} from 'providers';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

import { useContentScreen } from '../hooks/useContentScreen';
import { CONTENT_ROOT_PATH, useContentNavigationContext } from '../providers';
import { SyncedContent } from './content-table-screens';
import { ContentHeaderButtons } from './content-table-screens/components';
import { ContentCollectionView, ContentVideoView, ContentWorkoutView } from './content-view-screens';

export const ContentInternal = () => {
  const { collections, videos, setCollections, setVideos, isLoading, isError } = useContentScreen();
  const { activeItem, setActiveItem } = useContentNavigationContext();
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const queryClient = useQueryClient();
  const appId = useAppBeingEdited();
  const { setUnsavedChanges } = useUnsavedChanges();

  const locationState = location.state ? (location.state as Record<string, string>) : undefined;

  useEffect(() => {
    if (locationState?.invalidateQueries) {
      setUnsavedChanges(false);
      queryClient.invalidateQueries(['collections', appId]);
      queryClient.invalidateQueries(['videos', appId]);
    }
  }, [pathname]);

  useEffect(() => {
    if (!!collections && !!videos) {
      const path = pathname.slice(-1) === '/' ? pathname.slice(0, -1) : pathname; // Remove trailing / if there is one
      const itemId = path.substring(path.lastIndexOf('/') + 1);

      const type =
        itemId === 'new_workout'
          ? 'workout'
          : itemId.startsWith('new_')
          ? 'collection'
          : videos[itemId]
          ? 'video'
          : collections[itemId]
          ? collections[itemId].SourceType === SOURCE_TYPE_WORKOUT
            ? 'workout'
            : 'collection'
          : undefined;

      if (type) {
        setActiveItem({ id: itemId, type });
      } else {
        setActiveItem(undefined);

        if (itemId !== 'content') {
          // Reroute to root if invalid path
          history.push(CONTENT_ROOT_PATH);
        }
      }
    }
  }, [pathname, history, isLoading]);

  if (!!collections && !!videos && activeItem) {
    const { id, type } = activeItem;

    return (
      <ContentProvider
        collections={collections}
        setCollections={setCollections as Dispatch<SetStateAction<BuilderCollections>>}
        videos={videos}
        setVideos={setVideos as Dispatch<SetStateAction<BuilderVideos>>}
      >
        <SaveProvider key={id}>
          <DefaultAppPropertiesProvider>
            {type === 'workout' ? (
              <ContentWorkoutView collectionId={id} key={id} />
            ) : type === 'collection' ? (
              <ContentCollectionView collectionId={id} key={id} />
            ) : type === 'video' ? (
              <ContentVideoView videoId={id} key={id} />
            ) : null}
          </DefaultAppPropertiesProvider>
        </SaveProvider>
      </ContentProvider>
    );
  }

  return (
    <PageContainer
      heading="Content"
      subheading="Sync and add content from your existing site or video hosting"
      isLoading={isLoading}
      isError={isError}
      headingButton={<ContentHeaderButtons />}
      contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}
    >
      <DefaultContentProvider updateOnRefetch>
        <SyncedContent />
      </DefaultContentProvider>
    </PageContainer>
  );
};
