import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useAppProperties, useTemplates } from 'hooks';
import {
  DEFAULT_TAB,
  DEFAULT_TAB_ICONS,
  DEFAULT_TAB_NAMES,
  FILTER_TEMPLATE,
  MIXED_TEMPLATE,
  MY_LISTS_TEMPLATE,
  MY_PLAYLISTS_TEMPLATE,
  NEW_TAB_OPTIONS,
  WEBSITE_TEMPLATE,
} from 'api';
import { sortByArray, getCollectionValuesFromNewCollection, getScreenFromPathname } from 'utils';
import { CustomButton, ColumnModal } from 'components';
import { PlusIcon } from 'icons';

import { AddTabOption } from 'app/modules/build-screens/BuildNav/AddTabOption';
import { BuilderCollection, useContent, useSaveContext } from 'providers';
import { useAppBeingEdited } from 'app-context';
import { useHistory, useLocation } from 'react-router-dom';
import { useBuildNav } from '../providers';
import { BMA_TV_SCREEN } from '../const';

const Wrapper = styled.div`
  padding-top: 10px;
  padding-left: 24px;
`;

const CardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const CardSpacer = styled.div`
  margin-right: 16px;
  margin-bottom: 16px;

  :nth-child(3n) {
    margin-right: 0; // Every item at the end of the row has no side margin
  }

  :last-child {
    margin-bottom: 20px; //Last items have bigger bottom spacing
  }
`;

interface AddTabButtonProps {
  existingTabs: BuilderCollection[];
  setForceOpen: Dispatch<SetStateAction<boolean>>;
}

export const AddTabButton = ({ existingTabs, setForceOpen, ...props }: AddTabButtonProps) => {
  const { setNewCollection, setCollectionProperty } = useContent();
  const { getTempId, setCollectionToSave, setCollectionPropertyToSave } = useSaveContext();
  const { saveTimestamp } = useBuildNav();
  const appId = useAppBeingEdited();
  const { data } = useTemplates();
  const { data: properties } = useAppProperties();
  const history = useHistory();
  const [templateId, setTemplateId] = useState<number | undefined>(undefined);
  const [open, setOpen] = useState(false); // Modal is open
  const location = useLocation();
  const screen = getScreenFromPathname(location.pathname);
  const isTV = screen === BMA_TV_SCREEN;

  const isDisabledTemplate = (_id: number) => {
    if (isTV) {
      // TV can only have mixed and 1 filter
      if (_id === FILTER_TEMPLATE) {
        return !!existingTabs.find((c) => c.TemplateId === _id);
      }
      return _id !== MIXED_TEMPLATE;
    }
    if ([MIXED_TEMPLATE, WEBSITE_TEMPLATE].includes(_id)) {
      // Can have multiple of these templates
      return false;
    }
    // Don't already have one
    return !!existingTabs.find((c) => c.TemplateId === _id);
  };

  const availableTemplates = useMemo(() => {
    return (
      data?.templates
        .filter((t) => NEW_TAB_OPTIONS.includes(t.TemplateId))
        .filter((t) => (t.TemplateId === MY_PLAYLISTS_TEMPLATE ? properties?.DisplayMyLibrary === '1' : true))
        .sort((a, b) => sortByArray(a.TemplateId, b.TemplateId, NEW_TAB_OPTIONS)) || []
    );
  }, [data, properties]);

  const createTab = useCallback(() => {
    if (templateId) {
      const tempId = `TempTab${getTempId()}`;

      const newTab = {
        ...DEFAULT_TAB,
        TabId: tempId,
        AppId: appId,
        TemplateId: templateId,
        Icon: DEFAULT_TAB_ICONS[templateId] ?? '',
        Name: DEFAULT_TAB_NAMES[templateId].toUpperCase() ?? 'NEW TAB',
        NavBarTitleText: DEFAULT_TAB_NAMES[templateId] ?? 'New Tab',
        SourceId: '',
        Items: [],
        SourceName: '',
        Thumbnails: {},
        SourceThumbnailSource: '',
        TemplateName: '',
      };
      setNewCollection(tempId, newTab);
      setCollectionToSave(tempId, getCollectionValuesFromNewCollection(newTab));
      if (templateId !== MY_LISTS_TEMPLATE) {
        setCollectionProperty(tempId, 'LockedUntilSignIn', '1');
        setCollectionPropertyToSave(tempId, 'LockedUntilSignIn', '1');
      }

      setOpen(false);
      saveTimestamp(); // Update timestamp to trigger initiateTabs in useBuildNav
      history.replace(`/${screen}/${tempId}`);

      // Force Inactive section of BuildNav to open
      setForceOpen(true);

      // Scroll to bottom of BuildNav's tab lists container so new tab is in view (setTimeout to allow height to update first)
      setTimeout(() => {
        const tabLists = document.getElementById('build-nav-tab-lists');
        tabLists?.scrollTo({
          top: tabLists.scrollHeight,
          behavior: 'smooth',
        });
      }, 100);
    }
  }, [
    templateId,
    screen,
    saveTimestamp,
    getTempId,
    setNewCollection,
    setCollectionProperty,
    setCollectionToSave,
    setCollectionPropertyToSave,
    setForceOpen,
  ]);

  return (
    <Wrapper {...props}>
      <ColumnModal
        title="Create Tab"
        cols={5}
        align="right"
        onConfirm={createTab}
        open={open}
        onOpenChange={setOpen}
        onCancel={() => setOpen(false)}
        confirmDisabled={!templateId}
        primaryText="Create"
        triggerButton={
          <span>
            <CustomButton large tertiaryHighlight icon={<PlusIcon />}>
              Create Tab
            </CustomButton>
          </span>
        }
      >
        <CardGrid>
          {availableTemplates.map((template) => (
            <CardSpacer key={`Add ${template.TemplateId}`}>
              <AddTabOption
                templateId={template.TemplateId}
                onClick={() => setTemplateId(template.TemplateId)}
                selected={templateId == template.TemplateId}
                disabled={isDisabledTemplate(template.TemplateId)}
              />
            </CardSpacer>
          ))}
        </CardGrid>
      </ColumnModal>
    </Wrapper>
  );
};
