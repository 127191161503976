import { useMemo } from 'react';
import styled from 'styled-components';

import {
  CollectionDescriptionPreview,
  CollectionDrip,
  CollectionTitle,
  ContentAdvancedSettings,
  ContentSourceTitle,
  DevTag,
} from 'components';

import { useBuilderContext, useContent } from 'app/modules/build-dragdrop/Builder/providers';
import {
  CollectionCustomItemList,
  CollectionDisplayType,
  CollectionItemList,
  CollectionItemsDrawer,
  CollectionSource,
  CollectionTemplateDrawer,
  CollectionThumbnail,
  ContentResourceList,
  DrawerHeading,
  DrawerScrollContainer,
} from 'app/modules/build-dragdrop/Builder/drawer/components/index';

import { getCollectionName, getSourceTypeDisplayName, isUserAdmin } from 'utils';
import { SOURCE_KAJABI, SOURCE_TYPE_TEXT, SOURCE_TYPE_WEBSITE, SOURCE_TYPE_WORKOUT, SOURCE_VIDAPP } from 'api';
import { WebsiteDrawer } from 'app/modules/build-dragdrop/Builder/drawer/components/Drawers/WebsiteDrawer';
import { HTMLPageDrawer } from 'app/modules/build-dragdrop/Builder/drawer/components/Drawers/HTMLPageDrawer';
import { WorkoutDrawer } from 'app/modules/build-dragdrop/Builder/drawer/components/Drawers/WorkoutDrawer';

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CollectionDetailsDrawer = () => {
  const { visiblePageId, drawerContext } = useBuilderContext();
  const { collections } = useContent();

  const collection = visiblePageId ? collections[visiblePageId] : undefined;

  const heading = useMemo(() => {
    return getSourceTypeDisplayName(collection?.SourceType ?? '', collection?.DataSource, { defaultName: 'Custom' });
  }, [collection]);

  const suffix = isUserAdmin() ? (
    <div>
      <DevTag label="V">{collection?.TabId}</DevTag>
      <DevTag label="S">{collection?.SourceId}</DevTag>
    </div>
  ) : null;

  const type = (
    <SpaceBetween>
      <div>{heading}</div>
      {suffix}
    </SpaceBetween>
  );

  // This collection should always exist
  if (!collection) {
    console.warn('Unexpected error - No collection for CollectionDetailsDrawer');
    return null;
  }

  return drawerContext?.showItemsView && collection ? (
    <CollectionItemsDrawer collection={collection} />
  ) : drawerContext?.showCollectionDisplayView ? (
    <CollectionTemplateDrawer collection={collection} />
  ) : collection.SourceType === SOURCE_TYPE_WEBSITE ? (
    <WebsiteDrawer collection={collection} type={type} />
  ) : collection.SourceType === SOURCE_TYPE_TEXT ? (
    <HTMLPageDrawer collection={collection} type={type} />
  ) : collection.SourceType === SOURCE_TYPE_WORKOUT ? (
    <WorkoutDrawer collection={collection} type={type} />
  ) : (
    <>
      {/* This is a typical collection drawer */}
      <DrawerHeading heading={getCollectionName(collection)} type={type} />
      <DrawerScrollContainer paddingBottom="50px">
        <CollectionTitle
          size="middle"
          TabId={collection.TabId}
          Name={collection.Name}
          sourceName={collection.SourceName}
          $marginTop="0"
          showDeepLink
        />
        <CollectionSource
          SourceId={collection.SourceId}
          DataSource={collection.DataSource}
          SourceType={collection.SourceType}
        />
        <CollectionDisplayType collection={collection} />
        <CollectionThumbnail collection={collection} />
        {collection.DataSource === SOURCE_VIDAPP ? (
          <CollectionCustomItemList collection={collection} />
        ) : (
          <CollectionItemList Items={collection?.Items} />
        )}
        <CollectionDescriptionPreview collection={collection} />
        <ContentResourceList Resources={collection?.Resources} />
        <ContentSourceTitle size="middle" DataSource={collection.DataSource} SourceName={collection.SourceName} />
        {collection.DataSource === SOURCE_KAJABI && <CollectionDrip collection={collection} />}
        <ContentAdvancedSettings collection={collection} isDrawer />
      </DrawerScrollContainer>
    </>
  );
};
