import { useQuery, UseQueryOptions } from 'react-query';
import { getProducts, GetProductsResponse, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useCallback } from 'react';

export const useProducts = (
  staleTime?: UseQueryOptions['staleTime'],
  refetchOnMount?: UseQueryOptions['refetchOnMount'],
) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const query = useQuery<GetProductsResponse>(['products', appId], () => getProducts(client, appId), {
    staleTime,
    refetchOnMount,
    enabled: !!appId, // Don't call fetcher with empty AppId
  });

  const { data } = query;

  const getProductByProductId = useCallback(
    (productId: string) => {
      const indexedProduct = data?.product_by_product_id.get(productId);
      if (indexedProduct) {
        return indexedProduct;
      }

      // Android transactions have extra characters appended to productId, so won't be found in the above Map
      const fallback = data?.products.find((product) => productId.startsWith(product.ProductId.toLowerCase()));

      if (fallback) {
        return fallback;
      }

      console.error(`Can't retrieve product with id ${productId}`);
      return null;
    },
    [data],
  );

  return {
    ...query,
    getProductByProductId,
  };
};
