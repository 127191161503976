import { useCallback, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { CustomCell, CustomTable, FilterDropdown, SearchBar, TableColumn, Tabs } from 'components';
import { FONT_12PX_MEDIUM, FONT_14PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { BuilderCollection, useContent } from 'providers';
import { NEUTRAL_8_COLOUR } from 'theme';
import { getSortedArr, getSortedNumberArr } from '../util';

const PAGE_SIZE = 50;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SearchBarWrapper = styled.div`
  margin-right: 12px;
`;

const Total = styled.div`
  ${FONT_14PX_MEDIUM};
  color: ${NEUTRAL_8_COLOUR};
`;

const StyledCell = styled(CustomCell)`
  height: 42px;
  ${OVERFLOW_ELLIPSIS};
  ${FONT_12PX_MEDIUM};
`;

const Ellipsis = styled.div`
  ${OVERFLOW_ELLIPSIS};
`;

const JSONWrapper = styled.div`
  height: 100%;
  padding: 5px;

  .react-json-view {
    height: 100%;
  }
`;

export const DebugTabs = () => {
  const { collections: CollectionsData } = useContent();
  const [page, setPage] = useState(1);
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get('search') ?? undefined;
  const [filters, setFilters] = useState<Record<string, string[] | undefined>>({});

  const tabs = useMemo(() => Object.values(CollectionsData), [CollectionsData]);
  const tabFilters = useMemo(() => {
    const DataSource = new Set<string>();
    const TemplateId = new Set<number>();
    const SourceType = new Set<string>();
    const IsMainTab = new Set<number>();
    const IncludedInAppData = new Set<number>();
    const DisplayInTVApp = new Set<number>();
    const tvTemplateId = new Set<string>();
    tabs.forEach((t) => {
      if (t.DataSource) {
        DataSource.add(t.DataSource);
      }
      if (t.TemplateId) {
        TemplateId.add(t.TemplateId);
      }
      if (t.SourceType) {
        SourceType.add(t.SourceType);
      }
      if (t.IsMainTab) {
        IsMainTab.add(t.IsMainTab);
      }
      if (t.DisplayInTVApp) {
        DisplayInTVApp.add(t.DisplayInTVApp);
      }
      if (t.Properties?.tvTemplateId) {
        tvTemplateId.add(t.Properties?.tvTemplateId);
      }

      IncludedInAppData.add(t.IncludedInAppData);
    });
    return {
      DataSource: getSortedArr(DataSource),
      TemplateId: getSortedNumberArr(TemplateId),
      SourceType: getSortedArr(SourceType),
      IsMainTab: getSortedNumberArr(IsMainTab),
      DisplayInTVApp: getSortedNumberArr(DisplayInTVApp),
      tvTemplateId: getSortedArr(tvTemplateId),
      IncludedInAppData: getSortedNumberArr(IncludedInAppData),
    };
  }, [tabs]);

  const appliedFilters = useMemo(() => {
    const obj: Record<string, (string | number)[]> = {};
    for (const [key, value] of Object.entries(filters)) {
      if (value) {
        obj[key] = value;
      }
    }
    return obj;
  }, [filters]);

  const filteredData = useMemo(() => {
    const filteredByQuery = !query
      ? tabs
      : tabs?.filter(({ TabId, SourceId, Name, SourceName }) => {
          if (TabId && TabId.toString().toLowerCase().includes(query)) {
            return true;
          }
          if (SourceId && SourceId.toString().toLowerCase().includes(query)) {
            return true;
          }
          if (Name && Name.toLowerCase().includes(query)) {
            return true;
          }
          if (SourceName && SourceName.toLowerCase().includes(query)) {
            return true;
          }
          return false;
        });

    if (Object.keys(appliedFilters).length === 0) {
      return filteredByQuery;
    }

    const filtered = filteredByQuery.filter((row) => {
      let match = true;
      Object.keys(appliedFilters).forEach((key) => {
        const fieldValue =
          key === 'tvTemplateId' && row.Properties
            ? row.Properties[key]
            : row[key as keyof BuilderCollection]?.toString();
        if (!fieldValue || !appliedFilters[key]?.includes(fieldValue)) {
          match = false;
        }
      });
      return match;
    });

    return filtered;
  }, [tabs, query, appliedFilters]);

  const columns: TableColumn<BuilderCollection & { id: string }>[] = [
    {
      heading: 'SourceName',
      width: 200,
      render: ({ SourceName }) => (
        <StyledCell>
          <Ellipsis>{SourceName}</Ellipsis>
        </StyledCell>
      ),
      sorter: (a, b) => a.SourceName.localeCompare(b.SourceName),
      sorterKey: 'SourceName',
      initialSortOrder: 'ascend',
    },
    {
      heading: 'Name',
      width: 200,
      render: ({ Name }) => (
        <StyledCell>
          <Ellipsis>{Name}</Ellipsis>
        </StyledCell>
      ),
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      sorterKey: 'Name',
      initialSortOrder: 'ascend',
    },
    {
      heading: 'SourceType',
      width: 100,
      render: ({ SourceType }) => (
        <StyledCell>
          <Ellipsis>{SourceType}</Ellipsis>
        </StyledCell>
      ),
      sorter: (a, b) => (a.SourceType ?? '').localeCompare(b.SourceType ?? ''),
      sorterKey: 'SourceType',
      initialSortOrder: 'ascend',
    },
    {
      heading: 'SourceId',
      width: 110,
      render: ({ SourceId }) => (
        <StyledCell>
          <Ellipsis>{SourceId}</Ellipsis>
        </StyledCell>
      ),
      sorter: (a, b) => (a.SourceId ?? '').localeCompare(b.SourceId ?? ''),
      sorterKey: 'SourceId',
      initialSortOrder: 'ascend',
    },
    {
      heading: 'TabId',
      width: 90,
      render: ({ TabId }) => (
        <StyledCell>
          <Ellipsis>{TabId}</Ellipsis>
        </StyledCell>
      ),
      sorter: (a, b) => a.TabId.toString().localeCompare(b.TabId.toString()),
      sorterKey: 'TabId',
      initialSortOrder: 'ascend',
    },
    {
      heading: 'DataSource',
      width: 100,
      render: ({ DataSource }) => (
        <StyledCell>
          <Ellipsis>{DataSource}</Ellipsis>
        </StyledCell>
      ),
      sorter: (a, b) => a.DataSource.localeCompare(b.DataSource),
      sorterKey: 'DataSource',
      initialSortOrder: 'ascend',
    },
    {
      heading: 'Tab',
      width: 'grow',
      render: function LogDetails(video) {
        return (
          <JSONWrapper>
            <ReactJson
              name=""
              collapsed
              src={video}
              theme="paraiso"
              indentWidth={2}
              iconStyle="square"
              style={{ padding: '6px', fontSize: '12px', lineHeight: '14px' }}
              displayDataTypes={false}
              quotesOnKeys={false}
              displayObjectSize={false}
            />
          </JSONWrapper>
        );
      },
    },
  ];

  const availableFilters = useMemo(
    () =>
      Object.entries(tabFilters).map(([key, value]) => ({
        key,
        title: key,
        options: value.map((name) => ({ name: name.toString() })),
        multiSelect: true,
      })),
    [tabFilters],
  );

  const handleSearch = useCallback(
    (value: string) => {
      const searchInput = value.toLowerCase();
      const searchParam = searchInput !== '' ? `&search=${searchInput}` : '';

      history.replace({
        search: searchParam,
      });

      setPage(1);
    },
    [history],
  );

  return (
    <Tabs.Content value="tabs">
      <TableHeader>
        <SearchWrapper>
          <SearchBarWrapper>
            <SearchBar value={query} onSearch={handleSearch} width="293px" />
          </SearchBarWrapper>
          <FilterDropdown
            availableFilters={availableFilters}
            handleChange={(value) => {
              setFilters(value);
              setPage(1);
            }}
            total={Object.keys(appliedFilters).length}
          />
        </SearchWrapper>
        <Total>{`${filteredData.length} Tabs`}</Total>
      </TableHeader>
      <CustomTable
        isLoading={!tabs}
        pageSize={PAGE_SIZE}
        columns={columns}
        data={filteredData.map((v) => ({ ...v, id: v.TabId.toString() }))}
        emptyTitle=""
        emptyDescription="No results"
        overridePage={page}
        onPageChange={setPage}
        defaultSortKey="TabId"
        defaultSortOrder="ascend"
      />
    </Tabs.Content>
  );
};
