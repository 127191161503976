import { useCallback } from 'react';

import { useSaveContext, BuilderCollection, useContent } from 'providers';
import { ITEM_TYPE_COLLECTION } from 'api';
import { SegmentProps, ContentTitle } from 'components';
import { InputSize } from 'theme';

interface CollectionTitleProps extends SegmentProps {
  placeholder?: string;
  label?: string;
  size?: InputSize;
  showDeepLink?: boolean;
  disabled?: boolean;
  sourceName?: BuilderCollection['SourceName'];
}

export const CollectionTitle = ({
  TabId,
  Name,
  sourceName,
  size = 'small',
  ...props
}: Pick<BuilderCollection, 'TabId' | 'Name'> & CollectionTitleProps) => {
  const { setCollectionValue } = useContent();
  const { setCollectionValueToSave } = useSaveContext();

  const handleNameChange = useCallback(
    (name: string) => {
      // Update state
      setCollectionValue(TabId, 'Name', name);
      // Track change to be saved
      setCollectionValueToSave(TabId, 'Name', name === '' && sourceName ? sourceName : name);
    },
    [setCollectionValueToSave, setCollectionValue, TabId, sourceName],
  );

  return (
    <ContentTitle
      size={size}
      onChange={handleNameChange}
      itemId={TabId}
      itemType={ITEM_TYPE_COLLECTION}
      name={Name}
      placeholder={sourceName}
      onBlur={() => {
        if (Name === '' && sourceName) {
          handleNameChange(sourceName);
        }
      }}
      {...props}
    />
  );
};
