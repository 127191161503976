import { AxiosInstance } from 'axios';
import { builderAPI } from 'api/axios';
import { getUserEmail } from 'utils';

export type BuildPlatform = 'iOS' | 'tvOS' | 'Android' | 'Roku' | 'AndroidTV';
export type BuildStage = 'Preview' | 'Live' | 'Notification';

export interface BuildOrSubmitOptions {
  platform: BuildPlatform;
  stage: BuildStage;
  branch?: string;
  repository?: string;
  metadata?: string;
  manual?: string;
}

export const buildOrSubmitApp = (
  client: AxiosInstance,
  appId: string,
  { platform, stage, branch, repository, metadata, manual }: BuildOrSubmitOptions,
) => {
  return client
    .post(`${builderAPI.appstoreApiURL}/app-build`, {
      AppId: appId,
      Platform: platform,
      Stage: stage,
      Branch: branch ?? 'main',
      Repository: repository ?? '',
      Metadata: metadata ?? '',
      Manual: manual ?? '',
      EmailToUpdate: getUserEmail(),
    })
    .then(() => {
      console.log(`Successfully initiated ${stage === 'Preview' ? 'building' : 'submission'} of ${platform} app`);
    })
    .catch((err) => {
      throw err;
    });
};
