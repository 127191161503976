import { useQuery } from 'react-query';

import { BuilderEvent, getBuilderEvents, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useBuilderEvents = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  return useQuery<BuilderEvent[]>(['builderEvents', appId], () => getBuilderEvents(client, appId), {
    enabled: !!appId,
  });
};
