import styled from 'styled-components';

import { StepDivider } from './OnboardingStep';
import { SubmissionStatus } from './SubmissionStatus';

const Container = styled.div`
  width: 100%;
  margin-bottom: 28px;
`;
export const SubmissionStatuses = (props: { isLocked: boolean }) => (
  <Container>
    <StepDivider />
    <SubmissionStatus title="iOS" platform="iOS" marginBottom="52px" {...props} />
    <SubmissionStatus title="Android" platform="android" {...props} />
  </Container>
);
