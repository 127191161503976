import styled from 'styled-components';

import { FONT_10PX_MEDIUM } from 'font';
import {
  DANGER_COLOUR,
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_SECONDARY_COLOUR,
  HIGHLIGHT_SECONDARY_ORANGE_HOVER_COLOUR,
  NEUTRAL_4_COLOUR,
  NEUTRAL_8_COLOUR,
  PRIMARY_ORANGE_COLOUR,
  SECONDARY_DANGER_COLOUR,
  SECONDARY_SUCCESS_COLOUR,
  SUCCESS_COLOUR,
} from 'theme';

interface TypeLabelProps {
  $neutral?: boolean;
  $warning?: boolean;
  $danger?: boolean;
  $disabled?: boolean;
  $upperCase?: boolean;
}

const getColor = (props: TypeLabelProps) => {
  if (props.$neutral) {
    return HIGHLIGHT_PRIMARY_COLOUR;
  } else if (props.$warning) {
    return PRIMARY_ORANGE_COLOUR;
  } else if (props.$danger) {
    return DANGER_COLOUR;
  } else if (props.$disabled) {
    return NEUTRAL_8_COLOUR;
  }
  return SUCCESS_COLOUR;
};

const getBackgroundColor = (props: TypeLabelProps) => {
  if (props.$neutral) {
    return HIGHLIGHT_SECONDARY_COLOUR;
  } else if (props.$warning) {
    return HIGHLIGHT_SECONDARY_ORANGE_HOVER_COLOUR;
  } else if (props.$danger) {
    return SECONDARY_DANGER_COLOUR;
  } else if (props.$disabled) {
    return NEUTRAL_4_COLOUR;
  }
  return SECONDARY_SUCCESS_COLOUR;
};

export const TypeLabel = styled.div<TypeLabelProps>`
  ${FONT_10PX_MEDIUM};
  padding: 2px 4px;
  border-radius: 3px;
  white-space: nowrap;
  ${({ $upperCase }) => $upperCase && 'text-transform: uppercase'};
  color: ${(props) => getColor(props)};
  background-color: ${(props) => getBackgroundColor(props)};
`;
