import { SelectOption } from 'components';

const ANDROID_CURRENCIES = [
  'AED',
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'COP',
  'CRC',
  'CZK',
  'DKK',
  'EGP',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'JPY',
  'KRW',
  'LBP',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'RON',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'UAH',
  'USD',
  'UYU',
  'VND',
  'ZAR',
];

type PropertyType = 'appProperty' | 'secureAppProperty';

export interface PropertyDetails {
  type: PropertyType;
  isFile?: boolean;
  stripWhitespace?: boolean;
}

export interface Field {
  name: string;
  label: string;
  type: 'text' | 'select';
  propertyType: PropertyType;
  placeholder?: string;
  tooltip?: string;
  width?: string;
  options?: SelectOption[];
  defaultVal?: string;
}

// Map of all properties editable in the AppStoreSettings form
export const PROPERTIES: Record<string, PropertyDetails> = {
  // iOS
  ITunesTeamId: { type: 'secureAppProperty', stripWhitespace: true },
  ITunesCompanyName: { type: 'secureAppProperty' },
  ITunesDeveloperTitle: { type: 'secureAppProperty' },
  SharedSecret: { type: 'appProperty', stripWhitespace: true },
  AppleKeyId: { type: 'secureAppProperty', stripWhitespace: true },
  AppleIssuerId: { type: 'secureAppProperty', stripWhitespace: true },
  AppleAuthKey: { type: 'secureAppProperty', isFile: true },
  // Android
  AndroidDeveloperName: { type: 'appProperty' },
  AndroidDefaultCurrency: { type: 'appProperty' },
  AndroidJsonFilename: { type: 'secureAppProperty', isFile: true },
};

export const IOS_FIELDS: Field[] = [
  {
    name: 'ITunesTeamId',
    label: 'Team ID',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
  {
    name: 'ITunesCompanyName',
    label: 'Team Name (or Developer Title)',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
  {
    name: 'ITunesDeveloperTitle',
    label: 'Developer Title - App Store',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
  {
    name: 'SharedSecret',
    label: 'Apple Shared Secret',
    type: 'text',
    propertyType: 'appProperty',
  },
  {
    name: 'AppleKeyId',
    label: 'Apple Key ID',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
  {
    name: 'AppleIssuerId',
    label: 'Apple Issuer ID',
    type: 'text',
    propertyType: 'secureAppProperty',
  },
];

export const ANDROID_FIELDS: Field[] = [
  {
    name: 'AndroidDeveloperName',
    label: 'Android Developer Name',
    type: 'text',
    propertyType: 'appProperty',
  },
  {
    name: 'AndroidDefaultCurrency',
    label: 'Android Default Currency',
    type: 'select',
    propertyType: 'appProperty',
    options: ANDROID_CURRENCIES.map((o) => ({ name: o, value: o })),
    defaultVal: 'USD',
  },
];
