import { getMetricType, getMetricUrl } from 'app/modules/analytics/util';
import { AxiosInstance, AxiosResponse } from 'axios';
import { format } from 'date-fns'; // Video Analytics

// Video Analytics
export const METRIC_KEY_UNIQUE_PLAYS = 'unique_plays';
export const METRIC_KEY_UNIQUE_COMPLETIONS = 'unique_completions';
// Viewership/Video Analytics
export const METRIC_KEY_TOTAL_PLAYS = 'plays';
export const METRIC_KEY_COMPLETIONS = 'completions';
export const METRIC_KEY_AVERAGE_WATCHED = 'average_completion';
export const METRIC_KEY_MINUTES = 'minutes';
// Engagement
export const METRIC_KEY_SCHEDULED = 'scheduled';
export const METRIC_KEY_COMMENTS = 'add_comment';
export const METRIC_KEY_SAVED_PLAYLIST = 'saved_to_playlist';
export const METRIC_KEY_ENGAGED_USERS = 'engagement';

// Video Analytics
export const VIDEO_METRIC_KEYS = [
  METRIC_KEY_TOTAL_PLAYS,
  METRIC_KEY_COMPLETIONS,
  METRIC_KEY_AVERAGE_WATCHED,
  METRIC_KEY_UNIQUE_PLAYS,
  METRIC_KEY_UNIQUE_COMPLETIONS,
] as const;

// Viewership
export const VIEWERSHIP_METRIC_KEYS = [
  METRIC_KEY_TOTAL_PLAYS,
  METRIC_KEY_COMPLETIONS,
  METRIC_KEY_AVERAGE_WATCHED,
  METRIC_KEY_MINUTES,
] as const;

// Other
export const OTHER_METRIC_KEYS = [METRIC_KEY_ENGAGED_USERS] as const;

// Engagement
export const ENGAGEMENT_METRIC_KEYS = [METRIC_KEY_SCHEDULED, METRIC_KEY_COMMENTS, METRIC_KEY_SAVED_PLAYLIST] as const;

// Dashboard
export const KEY_METRIC_KEYS = [
  METRIC_KEY_TOTAL_PLAYS,
  METRIC_KEY_MINUTES,
  METRIC_KEY_COMMENTS,
  METRIC_KEY_SAVED_PLAYLIST,
] as const;

export type MetricGroup = 'keyMetrics' | 'viewershipMetrics' | 'engagementMetrics' | 'otherMetrics';

const GROUP_KEYS = {
  keyMetrics: KEY_METRIC_KEYS,
  viewershipMetrics: VIEWERSHIP_METRIC_KEYS,
  engagementMetrics: ENGAGEMENT_METRIC_KEYS,
  otherMetrics: OTHER_METRIC_KEYS,
};

export type VideoMetricKey = (typeof VIDEO_METRIC_KEYS)[number];
export type EngagementMetricKey = (typeof ENGAGEMENT_METRIC_KEYS)[number];
export type ViewershipMetricKey = (typeof VIEWERSHIP_METRIC_KEYS)[number];
export type OtherMetricKey = (typeof OTHER_METRIC_KEYS)[number];
export type KeyMetricKey = (typeof KEY_METRIC_KEYS)[number];
export type MetricKey = ViewershipMetricKey | EngagementMetricKey | OtherMetricKey;

export interface VideoByMetric {
  ItemId?: string;
  SourceId?: string;
  Plays?: number;
  Completions?: number;
  AverageCompletion?: string;
}

interface VideoPlays {
  SourceId: string;
  Plays: number;
}

interface VideoCompletions {
  ItemId: string;
  Completions: number;
}

interface AverageCompletions {
  SourceId: string;
  AverageCompletion: string; // Unrounded percentage string, e.g. "92.192307"
}

interface UniqueVideoPlays {
  SourceId: string;
  Plays: number;
}

interface UniqueVideoCompletions {
  ItemId: string;
  Completions: number;
}

export interface VideoAnalytics {
  [METRIC_KEY_TOTAL_PLAYS]: VideoPlays[];
  [METRIC_KEY_COMPLETIONS]: VideoCompletions[];
  [METRIC_KEY_AVERAGE_WATCHED]: AverageCompletions[];
  [METRIC_KEY_UNIQUE_PLAYS]: UniqueVideoPlays[];
  [METRIC_KEY_UNIQUE_COMPLETIONS]: UniqueVideoCompletions[];
}

// Map the response keys to relevant MetricKey
const KEY_MAP: Record<string, VideoMetricKey> = {
  VideoPlays: METRIC_KEY_TOTAL_PLAYS,
  VideoCompletions: METRIC_KEY_COMPLETIONS,
  AverageCompletions: METRIC_KEY_AVERAGE_WATCHED,
  UniqueVideoPlays: METRIC_KEY_UNIQUE_PLAYS,
  UniqueVideoCompletions: METRIC_KEY_UNIQUE_COMPLETIONS,
};

export const getVideoAnalytics = async (
  client: AxiosInstance,
  appBeingEdited: string,
  startDate: Date,
  endDate: Date,
) => {
  const formattedStart = format(startDate, 'yyyy-MM-dd HH:mm:ss');
  const formattedEnd = format(endDate, 'yyyy-MM-dd HH:mm:ss');
  const requests: Promise<AxiosResponse>[] = [];

  VIDEO_METRIC_KEYS.forEach((metric: string) => {
    requests.push(
      client
        .get(`/video_analytics/${appBeingEdited}/${metric}?start_date=${formattedStart}&end_date=${formattedEnd}`)
        .then(({ data }) => data),
    );
  });

  const responses = await Promise.all(requests);
  const analytics: VideoAnalytics = {
    [METRIC_KEY_TOTAL_PLAYS]: [],
    [METRIC_KEY_COMPLETIONS]: [],
    [METRIC_KEY_AVERAGE_WATCHED]: [],
    [METRIC_KEY_UNIQUE_PLAYS]: [],
    [METRIC_KEY_UNIQUE_COMPLETIONS]: [],
  };
  responses.forEach((response) => {
    for (const [key, value] of Object.entries(response)) {
      const mappedKey = KEY_MAP[key];
      analytics[mappedKey] = value;
    }
  });
  return analytics;
};

export interface ProgressTracking {
  SourceUserId: string;
  SourceId: string;
  MaxPercentage: string;
  StartDate: string;
}

interface ProgressTrackingResponse {
  ProgressTracking: ProgressTracking[];
}

export const getProgressTrackingAnalytics = (
  client: AxiosInstance,
  appBeingEdited: string,
  startDate: Date,
  endDate: Date,
) => {
  return client
    .get<ProgressTrackingResponse>(`/video_analytics/${appBeingEdited}/progresstracking`, {
      params: {
        start_date: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
        end_date: format(endDate, 'yyyy-MM-dd HH:mm:ss'),
      },
    })
    .then(({ data }) => data.ProgressTracking);
};

interface AverageCompletionsTotal {
  SourceId: 'all' | null;
  AverageCompletion: string; // Unrounded percentage string, e.g. "92.192307"
  Date: Date | null;
}

interface VideoPlaysTotal {
  SourceId: 'all' | null;
  Plays: number;
  Date: Date | null;
}

interface VideoCompletionsTotal {
  ItemId: 'all' | null;
  Completions: number;
  Date: Date | null;
}

interface VideoMinutesTotal {
  Minutes: number;
  Date: Date | null;
}

// Return value for all engagement metrics
interface EngagementTotal {
  Count: number;
  Date: Date | null;
}

export interface AnalyticsTotals {
  [METRIC_KEY_AVERAGE_WATCHED]: AverageCompletionsTotal | undefined; // Unrounded percentage string, e.g. "92.192307"
  [METRIC_KEY_TOTAL_PLAYS]: VideoPlaysTotal | undefined;
  [METRIC_KEY_COMPLETIONS]: VideoCompletionsTotal | undefined;
  [METRIC_KEY_MINUTES]: VideoMinutesTotal | undefined;
  [METRIC_KEY_SCHEDULED]: EngagementTotal | undefined;
  [METRIC_KEY_COMMENTS]: EngagementTotal | undefined;
  [METRIC_KEY_SAVED_PLAYLIST]: EngagementTotal | undefined;
  [METRIC_KEY_ENGAGED_USERS]: EngagementTotal | undefined;
}

export type DateIncrement = 'daily' | 'weekly' | 'monthly';

export const getAnalyticsTotals = async (
  type: 'keyMetrics' | 'viewershipMetrics' | 'engagementMetrics' | 'otherMetrics',
  client: AxiosInstance,
  appBeingEdited: string,
  startDate: Date,
  endDate: Date,
) => {
  const formattedStart = format(startDate, 'yyyy-MM-dd HH:mm:ss');
  const formattedEnd = format(endDate, 'yyyy-MM-dd HH:mm:ss');
  const requests: Promise<AxiosResponse>[] = [];

  const params = `start_date=${formattedStart}&end_date=${formattedEnd}&total_only=true`;

  GROUP_KEYS[type].forEach((metric) => {
    const type = getMetricType(metric);
    const URL = getMetricUrl(appBeingEdited, type, metric, params);

    requests.push(
      client.get(URL).then(({ data }) => {
        // Replace returned keys with definied MetricKey
        for (const key of Object.keys(data)) {
          data[metric] = data[key];
          delete data[key];
        }

        return data;
      }),
    );
  });

  const responses = await Promise.all(requests);
  const analytics: AnalyticsTotals = {
    [METRIC_KEY_AVERAGE_WATCHED]: undefined,
    [METRIC_KEY_TOTAL_PLAYS]: undefined,
    [METRIC_KEY_COMPLETIONS]: undefined,
    [METRIC_KEY_MINUTES]: undefined,
    [METRIC_KEY_SCHEDULED]: undefined,
    [METRIC_KEY_COMMENTS]: undefined,
    [METRIC_KEY_SAVED_PLAYLIST]: undefined,
    [METRIC_KEY_ENGAGED_USERS]: undefined,
  };
  responses.forEach((response) => {
    for (const [key, value] of Object.entries(response)) {
      analytics[key as MetricKey] = value[0];
    }
  });
  return analytics;
};

export const getAnalyticsMetricByDate = async (
  client: AxiosInstance,
  appBeingEdited: string,
  metric: MetricKey,
  startDate: Date,
  endDate: Date,
  byDate: DateIncrement,
) => {
  const formattedStart = format(startDate, 'yyyy-MM-dd HH:mm:ss');
  const formattedEnd = format(endDate, 'yyyy-MM-dd HH:mm:ss');

  const params = `start_date=${formattedStart}&end_date=${formattedEnd}&by_date=${byDate}`;
  const type = getMetricType(metric);
  const URL = getMetricUrl(appBeingEdited, type, metric, params);

  return client.get(URL).then(({ data }) => {
    if (metric === METRIC_KEY_COMPLETIONS) {
      const arr = data.VideoCompletions as VideoCompletionsTotal[];
      return arr.map(({ Date, Completions }) => ({ date: Date as Date, value: Completions }));
    } else if (metric === METRIC_KEY_TOTAL_PLAYS) {
      const arr = data.VideoPlays as VideoPlaysTotal[];
      return arr.map(({ Date, Plays }) => ({ date: Date as Date, value: Plays }));
    } else if (metric === METRIC_KEY_MINUTES) {
      const arr = data.WatchMinutes as VideoMinutesTotal[];
      return arr.map(({ Date, Minutes }) => ({ date: Date as Date, value: Minutes }));
    } else if (metric === METRIC_KEY_AVERAGE_WATCHED) {
      const arr = data.AverageCompletions as AverageCompletionsTotal[];
      return arr.map(({ Date, AverageCompletion }) => ({ date: Date as Date, value: parseInt(AverageCompletion) }));
    } else {
      const arr = data.Counts as EngagementTotal[];
      return arr.map(({ Date, Count }) => ({ date: Date as Date, value: Count }));
    }
  });
};
