import styled from 'styled-components';

import { VIDAPP_HELP_URL } from 'api';
import { FONT_14PX_REGULAR, FONT_24PX_REGULAR } from 'font';
import { ErrorIcon } from 'icons';
import { NEUTRAL_10_COLOUR, NEUTRAL_8_COLOUR } from 'theme';

const ErrorContainer = styled.div`
  width: 1048px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 240px);
  justify-content: center;
  align-items: center;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  font-size: 80px;
`;

const Text = styled.div`
  width: 500px;
  text-align: center;
`;

const Title = styled(Text)`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_24PX_REGULAR};
  margin: 24px 0 8px;
`;

const Description = styled(Text)`
  color: ${NEUTRAL_8_COLOUR};
  ${FONT_14PX_REGULAR};
`;

export const PageLoadError = () => (
  <ErrorContainer>
    <StyledErrorIcon />
    <Title>Something went wrong</Title>
    <Description>
      Looks like your page failed to load. Please try again or click{' '}
      <a href={VIDAPP_HELP_URL} target="_blank" rel="noopener noreferrer">
        here
      </a>{' '}
      to get in contact with the VidApp support team.
    </Description>
  </ErrorContainer>
);
