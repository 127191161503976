import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { Collection, deleteCollection, useAxiosInstance } from 'api';
import { useRecordAction } from 'hooks';

interface DeleteCollectionProperties {
  invalidateQuery?: boolean;
  refresh?: boolean;
  saveActionLog?: boolean;
}

export const useDeleteCollection = ({
  invalidateQuery = true,
  refresh = true,
  saveActionLog = true,
}: DeleteCollectionProperties) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse, AxiosError, number>(
    (tabId: Collection['TabId']) => {
      return deleteCollection(client, appId, tabId, refresh);
    },
    {
      onSuccess: async (_response, tabId) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['collections', appId]);
        }
        if (saveActionLog) {
          recordAction.mutate({ action: 'Delete Collection', detail: { TabId: tabId } });
        }
      },
    },
  );
};
