import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useProducts } from 'hooks';
import { BuilderProducts } from 'providers';

export const useOffers = () => {
  const { data: productData, isError: productsIsError } = useProducts();
  const [products, setProducts] = useState<BuilderProducts>();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { offerId: offerIdParam } = useParams<{ offerId?: string }>();
  const [productId, setProductId] = useState<number | string | undefined>(offerIdParam);

  useEffect(() => {
    // Setup state once on initial data load
    if (!!productData) {
      // Sort products by DisplayName, with ReadyForSale products at the top of the table
      const sorted = productData.products
        .filter(({ ReadyForSale }) => ReadyForSale !== 'never')
        .sort((a, b) => {
          if (a.ReadyForSale === b.ReadyForSale) {
            return (a.DisplayName ?? '').localeCompare(b.DisplayName ?? '');
          }
          return (b.ReadyForSale ?? '').localeCompare(a.ReadyForSale ?? '');
        });

      const productMap = new Map(sorted.map((obj) => [obj.Id, obj]));

      setProducts(productMap);
    }
  }, [!!productData]);

  useEffect(() => {
    document.getElementById('ScrollContainer--PageContainer')?.scrollTo(0, 0);
  }, [productId]);

  const setActiveProductId = useCallback(
    (id: string | number | undefined) => {
      history.push(id ? `/offers/${id}` : '/offers');
    },
    [history],
  );

  useEffect(() => {
    if (!offerIdParam) {
      setProductId(offerIdParam);
    } else {
      setProductId(offerIdParam.startsWith('Temp') ? offerIdParam : Number.parseInt(offerIdParam));
    }
  }, [offerIdParam]);

  const handleSearch = useCallback(
    (value: string) => {
      const searchInput = value.toLowerCase();
      const searchParam = searchInput !== '' ? `&search=${searchInput}` : '';

      history.replace({
        search: searchParam,
      });
    },
    [history, query],
  );

  return {
    products,
    setProducts,
    activeProductId: productId,
    setActiveProductId,
    searchValue: query.get('search') ?? '',
    handleSearch,
    isLoading: !products,
    isError: productsIsError,
  };
};
