import { useHistory, useLocation } from 'react-router-dom';

import { CustomButton } from 'components';

import { useSaveContext, useUnsavedChanges } from 'providers';
import { useBuildNav } from 'app/modules/build-screens/providers';
import { getScreenFromPathname } from 'utils';

export const BuildMyAppSaveButton = () => {
  const { isSaving, saveChanges } = useSaveContext();
  const { unsavedChanges } = useUnsavedChanges();
  const { setActiveTabs, setInactiveTabs } = useBuildNav();
  const location = useLocation();
  const history = useHistory();
  const screen = getScreenFromPathname(location.pathname);

  const handleSave = async () => {
    const { getPermanentCollectionId } = await saveChanges();
    setActiveTabs((existing) => {
      const output = [...(existing || [])];
      for (const tab of output) {
        if (tab.TabId.toString().startsWith('TempTab')) {
          tab.TabId = getPermanentCollectionId(tab.TabId as string);
        }
      }
      return output;
    });
    setInactiveTabs((existing) => {
      const output = [...(existing || [])];

      for (const tab of output) {
        if (tab.TabId.toString().startsWith('TempTab')) {
          tab.TabId = getPermanentCollectionId(tab.TabId as string);
        }
      }
      return output;
    });

    const endOfPathname = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    if (endOfPathname.includes('TempTab')) {
      const permanentId = getPermanentCollectionId(endOfPathname);
      history.replace(`/${screen}/${permanentId}`);
    }
  };

  return (
    <CustomButton medium onClick={handleSave} loading={isSaving} disabled={!unsavedChanges}>
      Save
    </CustomButton>
  );
};
