import { ChangeEvent, useCallback } from 'react';
import styled from 'styled-components';
import { LinkIcon } from 'icons';
import { CustomButton, Segment, SegmentProps, SegmentTitle, SettingsTextInput } from 'components';
import { InputSize } from 'theme';
import { useDeepLink } from 'hooks/useDeepLink';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface ContentTitleProps extends SegmentProps {
  itemId?: string | number;
  itemType?: 'collection' | 'video';
  name: string;
  onChange: (name: string) => void;
  placeholder?: string;
  label?: string;
  size?: InputSize;
  showDeepLink?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
}

export const ContentTitle = ({
  onChange,
  itemType,
  itemId,
  name,
  placeholder,
  label,
  size = 'small',
  showDeepLink,
  disabled,
  onBlur,
  ...props
}: ContentTitleProps) => {
  const handleNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const { domain, copyDomainToClipboard } = useDeepLink({ itemType, itemId });

  return (
    <Segment
      header={
        <Row>
          <SegmentTitle title={label || 'Title'} />
          {!!showDeepLink && domain && (
            <CustomButton
              medium
              tertiaryHighlight
              icon={<LinkIcon />}
              iconPosition="right"
              onClick={copyDomainToClipboard}
            >
              Copy Deep Link
            </CustomButton>
          )}
        </Row>
      }
      $marginTop="0"
      $marginBottom="40px"
      {...props}
    >
      <SettingsTextInput
        size={size}
        value={name}
        placeholder={placeholder}
        onChange={handleNameChange}
        $marginTop={size === 'large' ? '0' : undefined}
        disabled={disabled}
        onBlur={onBlur}
      />
    </Segment>
  );
};
