// Filter down the Datasources and their respective Collection/Videos available to be added into a specific collection
import { BuilderCollection, BuilderVideo, useContent, useDataSource } from 'providers';
import { useCallback, useMemo } from 'react';

import {
  SOURCE_CUSTOMAPI,
  SOURCE_JWPLAYER,
  SOURCE_KAJABI,
  SOURCE_TYPE_WORKOUT,
  SOURCE_USCREEN,
  SOURCE_VHX,
  SOURCE_VIDAPP,
  SOURCE_VIMEO,
  SOURCE_WORDPRESS,
  SOURCE_YOUTUBE,
} from 'api';
import { useAppProperties } from 'hooks';
import { DumbbellIcon, VideoIcon } from 'icons';
import { checkVideoType } from 'utils';

import { NavigationOption } from '../CalendarModal';

const WORKOUT_PROPERTY = 'DisplayCircuitWorkouts';

const DEFAULT_VIDEOS_VIEW = {
  Title: 'Videos',
  ViewType: 'videos' as const,
  Icon: <VideoIcon />,
};
const EXCLUDED_CIRCUIT_TEMPLATES = [24, 25, 32, 35];

export const useModalOptions = () => {
  const datasource = useDataSource();
  const { videos, collections } = useContent();
  const { data } = useAppProperties();

  const showWorkouts = data?.[WORKOUT_PROPERTY] === '1';

  const isAddableVideo = (v: BuilderVideo) => {
    if (!['video', 'post'].includes(v.Type)) {
      return false;
    }
    if (v.IncludedInAppData !== 1) {
      return false;
    }
    return true;
  };

  // Can currently only add SOURCE_TYPE_WORKOUT to calendar (if DisplayCircuitWorkouts)
  const isAddableCollection = useCallback(
    (c: BuilderCollection) => {
      if (c.IncludedInAppData !== 1) {
        return false;
      }
      if (c.SourceType !== SOURCE_TYPE_WORKOUT || !showWorkouts) {
        return false;
      }
      if ([...EXCLUDED_CIRCUIT_TEMPLATES].includes(c.TemplateId)) {
        return false;
      }
      return true;
    },
    [data, showWorkouts],
  );

  const videosBySource = useMemo(() => {
    const obj: Map<string, BuilderVideo[]> = new Map(
      Object.entries({
        [datasource]: [],
        [SOURCE_WORDPRESS]: [],
        [SOURCE_JWPLAYER]: [],
        [SOURCE_VIMEO]: [],
        [SOURCE_YOUTUBE]: [],
      }),
    );
    obj.set(SOURCE_CUSTOMAPI, []); // Sometimes its also the app datasource

    Object.values(videos).forEach((v) => {
      if (!isAddableVideo(v)) {
        return; // skip some content
      }

      if (obj.has(v.DataSource) && checkVideoType(v, { audio: true, text: true })) {
        obj.get(v.DataSource)?.push(v);
      }
    });
    return obj;
  }, [videos, datasource]);

  const collectionsBySource = useMemo(() => {
    const obj: Map<string, BuilderCollection[]> = new Map(Object.entries({}));

    if (showWorkouts) {
      obj.set(SOURCE_VIDAPP, []);

      Object.values(collections).forEach((c) => {
        if (!isAddableCollection(c)) {
          return; // skip some content
        }

        if (obj.has(c.DataSource)) {
          obj?.get(c.DataSource)?.push(c);
        }
      });
    }
    return obj;
  }, [collections, showWorkouts, isAddableCollection]);

  // The following determines the full list of navigation options in the Navigation Panel
  // All conditional logic on what collections/videos are available is contained here
  const navigationOptions = useMemo(() => {
    if (!collectionsBySource || !videosBySource) {
      return {};
    }

    const outputOptions: Record<string, NavigationOption[]> = {
      [datasource]: [],
      [SOURCE_WORDPRESS]: [
        {
          ...DEFAULT_VIDEOS_VIEW,
          Title: 'Posts',
          VideoOptions: videosBySource.get(SOURCE_WORDPRESS),
        },
      ],
      [SOURCE_JWPLAYER]: [{ ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(SOURCE_JWPLAYER) }],
      [SOURCE_VIMEO]: [{ ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(SOURCE_VIMEO) }],
      [SOURCE_YOUTUBE]: [{ ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(SOURCE_YOUTUBE) }],
      [SOURCE_CUSTOMAPI]: [{ ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(SOURCE_CUSTOMAPI) }],
      [SOURCE_VIDAPP]: [],
    };
    if (showWorkouts) {
      outputOptions[SOURCE_VIDAPP].push({
        ViewType: 'collections',
        Title: 'Workouts',
        Icon: <DumbbellIcon />,
        CollectionOptions: collectionsBySource
          .get(SOURCE_VIDAPP)
          ?.filter(({ SourceType }) => [SOURCE_TYPE_WORKOUT].includes(SourceType)),
      });
    }

    if (datasource == SOURCE_KAJABI) {
      /**** KAJABI SPECIFIC LOGIC ****/
      outputOptions[datasource] = [
        {
          ...DEFAULT_VIDEOS_VIEW,
          Title: 'Lessons',
          VideoOptions: videosBySource.get(datasource),
        },
      ];
    } else if (datasource === SOURCE_VHX) {
      /**** VHX SPECIFIC LOGIC ****/
      outputOptions[datasource] = [{ ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(datasource) }];
    } else if (datasource == SOURCE_USCREEN) {
      /**** USCREEN SPECIFIC LOGIC ***/
      outputOptions[datasource] = [{ ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(datasource) }];
    } else {
      /*** DEFAULT LOGIC (WordPress, Shopify) ***/
      outputOptions[datasource] = [{ ...DEFAULT_VIDEOS_VIEW, VideoOptions: videosBySource.get(datasource) }];
    }

    // Exclude any video/collections page with no items
    for (const key of Object.keys(outputOptions)) {
      outputOptions[key] = outputOptions[key].filter((opt) => {
        if (opt.ViewType === 'collections') {
          return opt.CollectionOptions && opt.CollectionOptions.length > 0;
        } else if (opt.ViewType === 'videos') {
          return opt.VideoOptions && opt.VideoOptions.length > 0;
        }
        return true;
      });
      if (outputOptions[key]?.length === 0) {
        delete outputOptions[key];
      }
    }

    return outputOptions;
  }, [collectionsBySource, videosBySource, datasource]);

  return { navigationOptions };
};
