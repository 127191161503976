import styled from 'styled-components';

import { PageContainer } from 'components';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

import { Calendar, CalendarHeader, DisplayPanel } from 'app/modules/calendar/CalendarPage/components';
import { CalendarPageProvider } from '../CalendarModal/providers/calendar-page-provider';
import { useCalendar } from './hooks/useCalendar';

const CalendarContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const CalendarInternal = () => {
  const { isError, isLoading } = useCalendar();

  return (
    <CalendarPageProvider>
      <PageContainer
        heading="Calendar Scheduler"
        subheading="Use this section to schedule videos and quotes to your users calendar."
        isError={isError}
        isLoading={isLoading}
        contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}
      >
        <CalendarContainer>
          <div>
            <CalendarHeader />
            <Calendar />
          </div>
          <DisplayPanel />
        </CalendarContainer>
      </PageContainer>
    </CalendarPageProvider>
  );
};
