import { format, isSameMonth } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CalendarDay } from 'api';
import { FONT_10PX_MEDIUM, FONT_12PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { NEUTRAL_8_COLOUR } from 'theme';

import { useCalendarModalContext } from '../../CalendarModal/providers/calendar-modal-provider';
import { ContentIcon } from 'components';
import { PenIcon } from 'icons';
import { useContent } from 'providers';
import { useCalendarPageContext } from '../../CalendarModal/providers/calendar-page-provider';
import { useCalendar } from '../hooks/useCalendar';

const ITEMS_TO_DISPLAY = 3;

const CalendarCellContainer = styled.div`
  height: calc((100vh - 273px) / 6);
  min-height: 106px;
  max-height: 158px;
  padding: 4px;
  text-align: left;
`;

const DateLabel = styled.div`
  ${FONT_12PX_MEDIUM};
  color: ${NEUTRAL_8_COLOUR};
`;

const CellItemsList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const CellItem = styled.div`
  height: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
`;

const CellItemIcon = styled.span`
  margin-right: 3px;
  font-size: 12px;
`;

const CellItemTitle = styled.span`
  ${FONT_10PX_MEDIUM};
  ${OVERFLOW_ELLIPSIS};
`;

const MoreLabel = styled.div`
  ${FONT_10PX_MEDIUM};
  color: ${NEUTRAL_8_COLOUR};
  height: 19px;
  display: flex;
  align-items: center;
`;

interface CellItem {
  title: string;
  icon: JSX.Element;
}

interface CalendarCellProps {
  date: Date;
}

export const CalendarCell = ({ date }: CalendarCellProps) => {
  const { displayedItems, calendarData, activeDate } = useCalendarPageContext();
  const { setModalDate } = useCalendarModalContext();
  const [cellItems, setCellItems] = useState<CellItem[]>();
  const [calendarDay, setCalendarDay] = useState<CalendarDay>();
  const [remaining, setRemaining] = useState<number>(0);
  const { videos, collections } = useContent();
  const { getCalendarDay } = useCalendar();

  const isInView = useMemo(() => isSameMonth(activeDate, date), [activeDate, date]);

  useEffect(() => {
    setCalendarDay(undefined); // Reset the calendarDay state for the cell
  }, [activeDate]); // When the calendar month changes

  useEffect(() => {
    const day = getCalendarDay(date);
    if (day) {
      setCalendarDay(day); // Set the calendarDay state for the cell with the API data
    }
  }, [calendarData, getCalendarDay(date)]); // When the calendar data is updated from the API

  useEffect(() => {
    const items: CellItem[] = [];
    if (calendarDay && videos) {
      calendarDay.Items.forEach((item) => {
        if (item.Type === 'video' && displayedItems.includes('Videos / Posts')) {
          const video = videos[item.Id];
          video &&
            items.push({
              title: video.Title ?? 'video',
              icon: (
                <ContentIcon
                  itemType={video.Type ?? 'video'}
                  contentType={video.Type ?? 'video'}
                  dataSource={video.DataSource}
                />
              ),
            });
        } else if (item.Type === 'collection' && displayedItems.includes('Workouts')) {
          const collection = collections[item.Id];
          collection &&
            items.push({
              title: collection.Name ?? 'workout',
              icon: (
                <ContentIcon
                  itemType={collection.Type}
                  contentType={collection.SourceType}
                  dataSource={collection.DataSource}
                />
              ),
            });
        }
      });
      calendarDay.Quote &&
        displayedItems.includes('Quotes') &&
        items.push({
          title: calendarDay.Quote,
          icon: <PenIcon />,
        });
    }
    setCellItems(items.slice(0, ITEMS_TO_DISPLAY));
    setRemaining(items.length - ITEMS_TO_DISPLAY);
  }, [calendarDay, videos, displayedItems]); // When calendarDay state is updated, or displayedItems is changed

  return (
    <>
      <CalendarCellContainer onClick={() => isInView && setModalDate(date)}>
        <DateLabel className="date-label">{format(date, 'dd')}</DateLabel>
        <CellItemsList>
          {cellItems?.map((item, idx) => (
            <CellItem key={idx}>
              <CellItemIcon>{item.icon}</CellItemIcon>
              <CellItemTitle>{item.title}</CellItemTitle>
            </CellItem>
          ))}
        </CellItemsList>
        {remaining > 0 && <MoreLabel>{remaining} more...</MoreLabel>}
      </CalendarCellContainer>
    </>
  );
};
