import { Tooltip as AntdTooltip } from 'antd';
import styled from 'styled-components';
import * as amplitude from '@amplitude/analytics-browser';

import { CustomButton } from 'components';
import { PenIcon } from 'icons';
import { BREAKPOINT_1, BREAKPOINT_2, BREAKPOINT_3, BREAKPOINT_4 } from 'theme';
import { useAppBeingEdited } from 'app-context';
import { useContent } from 'providers';
import { isContent1Tab } from 'utils';

import { getPageName } from '../../utils';

const EditButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;

  /* Offset mockup scaling on button */
  transition-property: transform, top;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transform: scale(1.5);
  top: -57px;
  @media (min-width: ${BREAKPOINT_1.minWidth}) and (min-height: ${BREAKPOINT_1.minHeight}) {
    transform: scale(1.31);
    top: -54px;
  }
  @media (min-width: ${BREAKPOINT_2.minWidth}) and (min-height: ${BREAKPOINT_2.minHeight}) {
    transform: scale(1.15);
    top: -51px;
  }
  @media (min-width: ${BREAKPOINT_3.minWidth}) and (min-height: ${BREAKPOINT_3.minHeight}) {
    transform: scale(1.08);
    top: -50px;
  }
  @media (min-width: ${BREAKPOINT_4.minWidth}) and (min-height: ${BREAKPOINT_4.minHeight}) {
    transform: scale(1);
    top: -49px;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

interface PhoneMockupContainerProps {
  tabId?: string | number;
  children?: JSX.Element;
}

export const PhoneMockUpContainerV2 = ({ tabId, children }: PhoneMockupContainerProps) => {
  const appId = useAppBeingEdited();
  const { collections } = useContent();

  const handleEditClick = () => {
    if (tabId) {
      const tab = collections[tabId];
      const tabType = isContent1Tab(tab)
        ? 'Content1'
        : getPageName(tab) === 'Content'
        ? 'ContentOther'
        : getPageName(tab);
      amplitude.track('Tab opened via BMA Simulator', { appId, tabType });
    }
  };

  const buttonDisabled = !tabId || isNaN(tabId as number);

  return (
    <Wrapper>
      {children}
      <EditButtonWrapper>
        {buttonDisabled ? (
          <AntdTooltip
            getPopupContainer={() => document.getElementById('react-app') as HTMLElement}
            arrowPointAtCenter
            title="You need to click save before you customize this Tab"
            trigger="hover"
          >
            <div>
              <CustomButton icon={<PenIcon />} medium disabled>
                Customize
              </CustomButton>
            </div>
          </AntdTooltip>
        ) : (
          <CustomButton
            icon={<PenIcon />}
            medium
            link={{ to: { pathname: `/builder/${tabId}`, state: { showUnsavedChangesPrompt: true } } }}
            onClick={handleEditClick}
          >
            Customize
          </CustomButton>
        )}
      </EditButtonWrapper>
    </Wrapper>
  );
};
