import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { DataSource, SOURCE_KAJABI, SOURCE_TYPE_KAJABI_PRODUCT, SOURCE_VIDAPP, Video } from 'api';
import { useAppBeingEdited } from 'app-context';
import {
  ClickToCopy,
  CustomButton,
  CustomTable,
  HighlightText,
  InfoModal,
  SettingsCheckboxInput,
  TableColumn,
  Thumbnail,
} from 'components';
import { FONT_12PX_MEDIUM, FONT_12PX_SEMIBOLD, FONT_14PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { useSyncKajabiProduct } from 'hooks';
import { UISyncIcon } from 'icons';
import { BuilderCollection } from 'providers';
import { NEUTRAL_10_COLOUR, NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import {
  DEFAULT_THUMBNAIL,
  collectionIsProtected,
  formatNiceDate,
  getCollectionSourceName,
  getContentThumbnailUrl,
  getDataSourceDisplayName,
  getVideoSourceTitle,
} from 'utils';

import { useBanner } from 'providers/banner-provider';
import { useContentNavigationContext } from '../../../providers';
import { DeleteCollectionButton } from '../../components';
import { FALLBACK_CREATE_DATE } from '../SyncedContent';

const TableContainer = styled.div`
  position: relative;
  margin-top: 20px;
`;

const Cell = styled.div`
  padding: 5px 2px 5px 12px;
  height: 55px;
  display: flex;
  align-items: center;
  width: 100%;
  color: ${NEUTRAL_9_COLOUR};
`;

const TitleWrapper = styled.div`
  span {
    ${FONT_14PX_MEDIUM};
  }

  ${OVERFLOW_ELLIPSIS};
`;

const ButtonCell = styled(Cell)`
  justify-content: center;
`;

const DateWrapper = styled.div`
  ${FONT_12PX_MEDIUM};
  ${OVERFLOW_ELLIPSIS};
`;

const SourceIdWrapper = styled.div`
  span {
    ${FONT_12PX_MEDIUM};
  }

  ${OVERFLOW_ELLIPSIS};

  :hover {
    color: ${NEUTRAL_7_COLOUR};
  }
`;

const ItemThumbnail = styled(Thumbnail)`
  margin-right: 8px;
`;

const SyncButton = styled(CustomButton)`
  &&&& {
    margin-right: 10px;
  }
`;

const AdminFilters = styled.div`
  display: flex;
  position: absolute;
  top: -118px;
  right: 36px;
`;

const HeaderWrapper = styled.div`
  user-select: none;
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_12PX_SEMIBOLD};
`;

export interface ContentItem {
  sourceTitle: string;
  title: string;
  sourceId: string;
  itemId: string | number;
  key: string | number;
  thumbnail: string | null;
  dataSource: DataSource;
  sourceType?: string;
  kajabiDataThemeId?: number;
  type: 'collection' | 'video';
  includedInAppData?: boolean;
  createdAt: string;
}

interface ContentScreenTableProps {
  searchValue: string;
  collections?: BuilderCollection[];
  videos?: Video[];
  showSync?: boolean;
  showAdminFilters?: boolean;
}

export const ContentScreenTable = ({
  searchValue,
  collections,
  videos,
  showSync,
  showAdminFilters,
}: ContentScreenTableProps) => {
  const { page, pageSize, visibleColumns, setPage, setPageSize, navigate } = useContentNavigationContext();
  const syncKajabiProduct = useSyncKajabiProduct({ isOnboarding: false, syncToApp: true });
  const appId = useAppBeingEdited();
  const { setRestartBanner } = useBanner();
  const [showIncludedInApp, setShowIncludedInApp] = useState(true);
  const [showNotIncludedInApp, setShowNotIncludedInApp] = useState(true);

  const tableDataSource = collections ? collections[0]?.DataSource : videos ? videos[0]?.DataSource : undefined;
  const showDeleteColumn = !!collections && (tableDataSource === SOURCE_KAJABI || tableDataSource === SOURCE_VIDAPP);

  const columns = useMemo(() => {
    const sourceLabel = tableDataSource ? getDataSourceDisplayName(tableDataSource) : 'Source';
    const arr: TableColumn<ContentItem & { id: string }>[] = [];
    if (tableDataSource !== SOURCE_VIDAPP) {
      arr.push({
        heading: `${sourceLabel} Title`,
        headingElement: <HeaderWrapper>{`${sourceLabel} Title`}</HeaderWrapper>,
        width: 'grow',
        sorter: (a, b) => a.sourceTitle.localeCompare(b.sourceTitle),
        sorterKey: 'sourceTitle',
        render: ({ sourceTitle, thumbnail }) => (
          <Cell>
            <ItemThumbnail
              url={thumbnail}
              width="43px"
              height="24px"
              border={thumbnail && thumbnail !== DEFAULT_THUMBNAIL ? 'none' : undefined}
              borderRadius="2px"
            />
            <TitleWrapper>
              <HighlightText text={sourceTitle} highlight={searchValue} />
            </TitleWrapper>
          </Cell>
        ),
      });
    }

    if (tableDataSource === SOURCE_VIDAPP || visibleColumns.vidAppTitle) {
      arr.push({
        heading: 'VidApp Title',
        headingElement: <HeaderWrapper>VidApp Title</HeaderWrapper>,
        width: 'grow',
        sorter: (a, b) => a.title.localeCompare(b.title),
        sorterKey: 'title',
        render: ({ title, sourceTitle, thumbnail }) => (
          <Cell>
            {tableDataSource === SOURCE_VIDAPP && (
              <ItemThumbnail
                url={thumbnail}
                width="43px"
                height="24px"
                border={thumbnail && thumbnail !== DEFAULT_THUMBNAIL ? 'none' : undefined}
                borderRadius="2px"
              />
            )}
            <TitleWrapper>
              <HighlightText text={title !== '' ? title : sourceTitle} highlight={searchValue} />
            </TitleWrapper>
          </Cell>
        ),
      });
    }

    if (visibleColumns.createdAt) {
      arr.push({
        heading: tableDataSource === SOURCE_VIDAPP ? 'Created At' : 'First Synced',
        headingElement: (
          <HeaderWrapper>{tableDataSource === SOURCE_VIDAPP ? 'Created At' : 'First Synced'}</HeaderWrapper>
        ),
        width: 125,
        sorter: (a, b) => (b.createdAt ?? FALLBACK_CREATE_DATE).localeCompare(a.createdAt ?? FALLBACK_CREATE_DATE),
        sorterKey: 'createdAt',
        render: ({ createdAt }) => (
          <Cell>
            <DateWrapper>{formatNiceDate(new Date(createdAt))}</DateWrapper>
          </Cell>
        ),
      });
    }

    if (visibleColumns.sourceId) {
      arr.push({
        heading: 'Source ID',
        headingElement: <HeaderWrapper>Source ID</HeaderWrapper>,
        width: 110,
        sorter: (a, b) => a.sourceId.localeCompare(b.sourceId),
        sorterKey: 'sourceId',
        render: ({ sourceId }) => (
          <Cell>
            <ClickToCopy copyValue={sourceId}>
              <SourceIdWrapper>
                <HighlightText text={sourceId} highlight={searchValue} />
              </SourceIdWrapper>
            </ClickToCopy>
          </Cell>
        ),
      });
    }

    if (tableDataSource !== SOURCE_VIDAPP && visibleColumns.vidAppId) {
      arr.push({
        heading: 'VidApp ID',
        headingElement: <HeaderWrapper>VidApp ID</HeaderWrapper>,
        width: 110,
        sorter: (a, b) => a.itemId.toString().localeCompare(b.itemId.toString()),
        sorterKey: 'itemId',
        render: ({ itemId }) => (
          <Cell>
            <ClickToCopy copyValue={itemId.toString()}>
              <SourceIdWrapper>
                <HighlightText text={itemId.toString()} highlight={searchValue} />
              </SourceIdWrapper>
            </ClickToCopy>
          </Cell>
        ),
      });
    }

    if (showDeleteColumn) {
      arr.push({
        heading: '',
        id: 'delete',
        width: 30,
        render: ({ itemId, dataSource, sourceType, sourceId }) => {
          const showDeleteButton =
            collections &&
            !collectionIsProtected(sourceId) &&
            ((dataSource === SOURCE_KAJABI && sourceType === 'Product') || dataSource === SOURCE_VIDAPP);
          return (
            <ButtonCell onClick={(e) => e.stopPropagation()}>
              {showDeleteButton && (
                <DeleteCollectionButton
                  tabId={itemId as number}
                  sourceType={sourceType}
                  dataSource={dataSource}
                  tableStyle
                />
              )}
            </ButtonCell>
          );
        },
      });
    }

    if (showSync) {
      arr.push({
        heading: '',
        id: 'sync',
        width: 79,
        render: ({ sourceType, sourceId, kajabiDataThemeId }) =>
          sourceType === SOURCE_TYPE_KAJABI_PRODUCT ? (
            <ButtonCell>
              <SyncButton
                tertiaryHighlight
                small
                icon={<UISyncIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  syncKajabiProduct.mutate({ productId: sourceId, themeId: kajabiDataThemeId });
                  setTimeout(() => {
                    setRestartBanner(true);
                  }, 3000);

                  InfoModal(
                    `Your ${SOURCE_KAJABI} course is being synced`,
                    "You'll receive an email once this is complete",
                    'success',
                  );
                }}
              >
                Sync
              </SyncButton>
            </ButtonCell>
          ) : null,
      });
    }

    return arr;
  }, [videos, collections, showSync, showDeleteColumn, tableDataSource, visibleColumns, collectionIsProtected]);

  const dataSource: ContentItem[] = useMemo(() => {
    const collectionsArr: ContentItem[] = collections
      ? collections.map((item) => ({
          sourceTitle: getCollectionSourceName(item),
          title: item.Name,
          sourceId: item.SourceId,
          itemId: item.TabId,
          key: item.TabId,
          thumbnail: getContentThumbnailUrl(appId, item, 'small'),
          dataSource: item.DataSource,
          sourceType: item.SourceType,
          kajabiDataThemeId: item.KajabiDataThemeId,
          type: 'collection',
          includedInAppData: item.IncludedInAppData === 1,
          createdAt: item.CreatedAt ?? '2000-00-00T00:00:00',
        }))
      : [];

    const videosArr: ContentItem[] = videos
      ? videos.map((item) => {
          return {
            sourceTitle: getVideoSourceTitle(item),
            title: item.Title,
            sourceId: item.SourceId,
            itemId: item.VideoId,
            key: item.VideoId,
            thumbnail: getContentThumbnailUrl(appId, item, 'small'),
            dataSource: item.DataSource,
            type: 'video',
            createdAt: item.CreatedAt ?? '2000-00-00T00:00:00',
          };
        })
      : [];

    if (collections && videos) {
      // This is an 'All' tab, so combine collections and videos then sort them alphabetically by sourceTitle
      const joinedArr = collectionsArr.concat(videosArr).sort((a, b) => b.createdAt.localeCompare(a.createdAt));

      // Kajabi 'All' tab displays products first
      if (tableDataSource === SOURCE_KAJABI) {
        const products: ContentItem[] = [];
        const others: ContentItem[] = [];
        joinedArr.forEach((item) => {
          if (item.sourceType === SOURCE_TYPE_KAJABI_PRODUCT) {
            products.push(item);
          } else {
            others.push(item);
          }
        });

        return [...products, ...others];
      }

      return joinedArr;
    }

    return collections ? collectionsArr : videosArr;
  }, [
    collections,
    videos,
    appId,
    tableDataSource,
    getCollectionSourceName,
    getVideoSourceTitle,
    getContentThumbnailUrl,
  ]);

  const inAppData = useMemo(() => {
    const included: ContentItem[] = [];
    const notIncluded: ContentItem[] = [];

    if (showAdminFilters) {
      dataSource.forEach((item) => {
        if (item.includedInAppData) {
          included.push(item);
        } else {
          notIncluded.push(item);
        }
      });
    }

    return { included, notIncluded };
  }, [dataSource]);

  return (
    <TableContainer>
      {showAdminFilters && (
        <AdminFilters>
          <SettingsCheckboxInput
            label={`Included in app (${inAppData.included.length})`}
            checked={showIncludedInApp}
            onChange={(e) => setShowIncludedInApp(e.target.checked)}
            width="fit-content"
            reverseOrder
            margin="0 10px 0 0"
          />
          <SettingsCheckboxInput
            label={`Not included in app (${inAppData.notIncluded.length})`}
            checked={showNotIncludedInApp}
            onChange={(e) => setShowNotIncludedInApp(e.target.checked)}
            width="fit-content"
            reverseOrder
          />
        </AdminFilters>
      )}
      <CustomTable
        data={
          !showAdminFilters || (showIncludedInApp && showNotIncludedInApp)
            ? dataSource.map((item) => ({ ...item, id: item.itemId.toString() }))
            : showIncludedInApp
            ? inAppData.included.map((item) => ({ ...item, id: item.itemId.toString() }))
            : showNotIncludedInApp
            ? inAppData.notIncluded.map((item) => ({ ...item, id: item.itemId.toString() }))
            : []
        }
        query={searchValue}
        onRowClick={({ itemId }) => navigate(itemId)}
        columns={columns}
        emptyTitle="No content"
        emptyDescription="You haven't added any content yet"
        overridePage={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        defaultSortKey={
          tableDataSource === SOURCE_KAJABI && collections && videos // Kajabi 'All' tab has a custom sort order with products displayed first
            ? undefined
            : 'createdAt'
        }
      />
    </TableContainer>
  );
};
