import { useMemo } from 'react';
import styled from 'styled-components';

import { CustomButton, Segment } from 'components';
import { ForwardIcon } from 'icons';
import { FONT_10PX_REGULAR } from 'font';
import { NEUTRAL_9_COLOUR } from 'theme';

import { CollectionItemDisplay } from 'app/modules/build-dragdrop/Builder/drawer/components';
import { BuilderCollection, useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';

const CenterRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const SectionSmallText = styled.div`
  ${FONT_10PX_REGULAR};
  color: ${NEUTRAL_9_COLOUR};
  margin-top: 8px;
`;

interface CollectionItemListProps {
  overrideTitle?: string;
  showAllItems?: boolean;
}

export const CollectionItemList = ({
  Items = [],
  showAllItems,
  overrideTitle,
}: Pick<BuilderCollection, 'Items'> & CollectionItemListProps) => {
  const { setDrawerContext } = useBuilderContext();

  const visibleItems = useMemo(() => {
    return showAllItems ? Items.slice(0, 200) : Items.slice(0, 3);
  }, [Items, showAllItems]);

  return (
    <Segment header={overrideTitle ?? 'Content'}>
      {Items?.length > 0 ? (
        <>
          {/* Up to the first 3 items */}
          {visibleItems.map((i) => {
            return <CollectionItemDisplay item={i} key={i.Position} />;
          })}
          {/* Show the View all button only when there are more than 3 items */}
          {!showAllItems && Items.length > 3 && (
            <CenterRow>
              <CustomButton
                small
                secondary
                icon={<ForwardIcon />}
                iconPosition="right"
                onClick={() =>
                  setDrawerContext((prevContext) => ({
                    ...prevContext,
                    showItemsView: true,
                  }))
                }
              >
                View all
              </CustomButton>
            </CenterRow>
          )}
        </>
      ) : (
        <SectionSmallText>No items in this collection</SectionSmallText>
      )}
    </Segment>
  );
};
