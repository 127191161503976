import { ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import { COMMUNITY_TEMPLATE, Collection, SOURCE_KAJABI, SOURCE_VIDAPP, Video } from 'api';
import {
  Collapse,
  CollectionDrip,
  CollectionPreviewVideo,
  CollectionTVSettings,
  ContentSourceId,
  GroupTitle,
  Segment,
  SettingsCheckboxInput,
  VideoPreviewVideo,
  VideoPurchasePrice,
} from 'components';
import { useAppProperties } from 'hooks';
import { BuilderCollection, BuilderVideo, useContent, useSaveContext } from 'providers';

const TITLE = 'Advanced Settings';

const DrawerContainer = styled.div`
  margin-top: 20px;
`;

const Spacer = styled.div`
  height: 20px;
`;

const Container = ({ isDrawer, children }: { isDrawer?: boolean; children: ReactNode }) =>
  isDrawer ? (
    <DrawerContainer>
      <GroupTitle title={TITLE} />
      {children}
    </DrawerContainer>
  ) : (
    <Collapse triggerContent={<GroupTitle title={TITLE} $marginBottom="0" />} iconAtEnd>
      {children}
    </Collapse>
  );

interface ContentAdvancedSettingsProps {
  collection?: BuilderCollection;
  video?: BuilderVideo;
  isDrawer?: boolean; // Is in a drawer in either BMA or D&D
  isTab?: boolean;
  isSubTab?: boolean;
  isSection?: boolean;
  isWorkout?: boolean;
}

export const ContentAdvancedSettings = ({
  collection,
  video,
  isDrawer,
  isTab,
  isSubTab,
  isSection,
  isWorkout,
}: ContentAdvancedSettingsProps) => {
  const { setCollectionValue, setCollectionProperty, setVideoValue } = useContent();
  const { setCollectionValueToSave, setCollectionPropertyToSave, setVideoValueToSave } = useSaveContext();
  const { data: appProperties } = useAppProperties();

  const dataSource = collection ? collection.DataSource : video ? video.DataSource : undefined;
  const sourceId = collection ? collection.SourceId : video ? video.SourceId : undefined;
  const isCollection = collection && !isTab && !isSubTab && !isSection && !isWorkout;
  const isBMASection = isSection && collection?.DataSource === SOURCE_VIDAPP;

  const displayTV =
    (isCollection || (collection && (isTab || isSection))) &&
    (appProperties?.DisplayAppleTV === 'Yes' ||
      appProperties?.DisplayRoku === 'Yes' ||
      appProperties?.DisplayAndroidTV === '1');

  const handleCheckboxChange = useCallback(
    (type: 'collectionValue' | 'collectionProperty' | 'videoValue', checked: boolean, property: string) => {
      if (collection) {
        if (type === 'collectionValue') {
          setCollectionValue(collection.TabId, property as keyof Collection, checked ? 1 : 0);
          setCollectionValueToSave(collection.TabId, property as keyof Collection, checked ? 1 : 0);
        } else {
          setCollectionProperty(collection.TabId, property, checked ? '1' : '0');
          setCollectionPropertyToSave(collection.TabId, property, checked ? '1' : '0');
        }
      } else if (video) {
        if (type === 'videoValue') {
          setVideoValue(video.VideoId, property as keyof Video, checked ? 1 : 0);
          setVideoValueToSave(video.VideoId, property as keyof Video, checked ? 1 : 0);
        }
      }
    },
    [
      video,
      setCollectionValue,
      setCollectionProperty,
      setVideoValue,
      setCollectionValueToSave,
      setCollectionPropertyToSave,
      setVideoValueToSave,
    ],
  );

  return (
    <>
      {isCollection ? (
        <Segment header="Trailer or Preview Video" $marginBottom={isDrawer ? '20px' : '40px'}>
          {collection && <CollectionPreviewVideo TabId={collection.TabId} />}
          {video && <VideoPreviewVideo VideoId={video.VideoId} />}
        </Segment>
      ) : !isDrawer ? (
        <Spacer />
      ) : null}
      <Container isDrawer={isDrawer}>
        <Segment header="Content Behavior">
          {collection && (
            <>
              {!isTab && (
                <SettingsCheckboxInput
                  label="Only display purchased content"
                  checked={collection.Properties?.OnlyDisplayPurchasedContent === '1'}
                  onChange={(e) =>
                    handleCheckboxChange('collectionProperty', e.target.checked, 'OnlyDisplayPurchasedContent')
                  }
                />
              )}
              {!isBMASection && !isSubTab && (
                <>
                  <SettingsCheckboxInput
                    label="Locked until user is signed in"
                    checked={collection.Properties?.LockedUntilSignIn === '1'}
                    onChange={(e) => handleCheckboxChange('collectionProperty', e.target.checked, 'LockedUntilSignIn')}
                    disabled={collection.TemplateId === COMMUNITY_TEMPLATE}
                  />
                  {!isWorkout && !isTab && (
                    <SettingsCheckboxInput
                      label="Hide from search results"
                      checked={collection.HideFromSearchResults === 1}
                      onChange={(e) =>
                        handleCheckboxChange('collectionValue', e.target.checked, 'HideFromSearchResults')
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
          {video && (
            <SettingsCheckboxInput
              label="Hide from search results"
              checked={video.HideFromSearchResults === 1}
              onChange={(e) => handleCheckboxChange('videoValue', e.target.checked, 'HideFromSearchResults')}
            />
          )}
        </Segment>
        {!isDrawer && dataSource && sourceId && (
          <ContentSourceId DataSource={dataSource} SourceId={sourceId} size="middle" />
        )}
        {collection?.DataSource === SOURCE_KAJABI && <CollectionDrip collection={collection} />}
        {displayTV && <CollectionTVSettings collection={collection} $marginBottom="0" />}
        {video && (
          <VideoPurchasePrice VideoId={video.VideoId} ProductId={video.ProductId} DataSource={video.DataSource} />
        )}
      </Container>
    </>
  );
};
