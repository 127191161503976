import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { CustomCheckbox, LoadingSpinner, TableColumn } from 'components';
import { ChevronLeftIcon, ChevronRightIcon } from 'icons';

import {
  LoadingContainer,
  PageSizeSelector,
  PaginationButton,
  PaginationRow,
  StyledTable,
  StyledTableHeader,
  StyledTableRow,
  TableCell,
  TableCheckboxCell,
  TableHeadCell,
  TableRow,
  TableSortIcon,
} from './components/CustomTableComponents';

import { Empty } from 'app/modules/community/Shared/Empty';

const CHECKBOX_COLUMN_WIDTH = 32;

const Container = styled.div`
  width: fit-content;
  max-width: 100%;
`;

const TableWrapper = styled.div`
  display: flex;
`;

const ScrollWrapper = styled.div`
  max-width: fit-content;
  overflow-x: scroll;
`;

interface TableProps<T> {
  data?: T[];
  columns: TableColumn<T>[];
  pageSize?: number;
  onPageSizeChange?: (value: number) => void;
  query?: string;
  emptyTitle?: string;
  emptyDescription?: string;
  onRowClick?: (data: T) => void;
  overridePage?: number;
  onPageChange?: (page: number) => void;
  tableWidth?: string;
  tableMinWidth?: string;
  rowSelection?: RowSelection;
  defaultSortKey?: string;
  defaultSortOrder?: 'ascend' | 'descend';
  showColumnBorders?: boolean;
  isLoading?: boolean;
}

interface RowSelection {
  selectedRowKeys: string[];
  onChange: (selectedKeys: string[]) => void;
}

// Data can be anything, but must have an id value
interface RowData {
  id: string;
}

const MAX_VISIBLE_PAGES = 10;

/**
 * This is an alternate version of CustomTable
 * First column (and checkbox column if applicable) are fixed in position
 * Remaining columns will scroll horizontally next to the fixed column(s)
 */

export const CustomTableFixedColumn = <T extends RowData>({
  data,
  columns,
  pageSize = 15,
  onPageSizeChange,
  query,
  emptyTitle,
  emptyDescription,
  onRowClick,
  overridePage,
  onPageChange,
  tableMinWidth,
  rowSelection,
  defaultSortKey,
  defaultSortOrder,
  showColumnBorders,
  isLoading,
}: TableProps<T>) => {
  const [page, setPage] = useState(1);
  const [headerCheckboxValue, setHeaderCheckboxValue] = useState<boolean | 'indeterminate'>(false);
  const [sortedData, setSortedData] = useState<T[] | undefined>(data);
  const [sortOrder, setSortOrder] = useState<{ key: string; order: 'ascend' | 'descend' } | undefined>(
    defaultSortKey ? { key: defaultSortKey, order: defaultSortOrder ?? 'ascend' } : undefined,
  );

  const activePageNumber = overridePage ?? page;

  const totalPages = sortedData ? Math.ceil(sortedData.length / pageSize) : 0;
  const startIndex = (activePageNumber - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const [lastCheckedIdx, setLastCheckedIdx] = useState(-1);
  const [shifPressed, setShiftPressed] = useState(false);

  const currentPage = sortedData?.slice(startIndex, endIndex);

  const isEmpty = !currentPage || currentPage?.length === 0;

  // Keep row heights in sync between fixed column and other columns
  const [rowHeights, setRowHeights] = useState<number[]>([]);
  const rowRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const heights = rowRefs.current.map((row) => (row ? row.clientHeight + 1 : 0));
      setRowHeights(heights);
    });

    rowRefs.current.forEach((row) => {
      if (row) {
        observer.observe(row);
      }
    });

    return () => {
      rowRefs.current.forEach((row) => {
        if (row) {
          observer.unobserve(row);
        }
      });
    };
  }, [sortedData]);

  const getRowHeight = (idx: number) => (rowHeights.length > 0 ? rowHeights[idx] ?? 'auto' : 'auto');

  const clearSelections = () => {
    if (rowSelection) {
      rowSelection.onChange([]);
      setLastCheckedIdx(-1);
    }
  };

  useEffect(() => {
    if (data) {
      if (sortOrder) {
        const column = columns.find((col) => col.sorterKey === sortOrder.key);
        if (column && column.sorter) {
          const sorted = sortOrder.order === 'ascend' ? data.sort(column.sorter) : data.sort(column.sorter).reverse();
          setSortedData(sorted);
        } else {
          setSortedData(data);
        }
      }
      setSortedData(data);
    }
  }, [data]);

  useEffect(() => {
    clearSelections();
  }, [activePageNumber, query]);

  useEffect(() => {
    setPage(1);
  }, [query]);

  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        clearSelections();
      } else if (e.shiftKey) {
        setShiftPressed(true);
      }
    },
    [clearSelections, setShiftPressed],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    document.addEventListener('keyup', () => {
      setShiftPressed(false);
    });

    return () => {
      document.removeEventListener('keyup', keyDownHandler);
      document.removeEventListener('keyup', () => {
        setShiftPressed(false);
      });
    };
  }, []);

  const generatePageNumbers = () => {
    const visiblePages = [];
    const halfMaxVisiblePages = Math.floor(MAX_VISIBLE_PAGES / 2);

    let startPage = activePageNumber - halfMaxVisiblePages;
    if (startPage < 1) {
      startPage = 1;
    }

    let endPage = startPage + MAX_VISIBLE_PAGES - 1;
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - MAX_VISIBLE_PAGES + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(i);
    }

    return visiblePages;
  };

  const handlePageChange = (pageNumber: number) => {
    onPageChange ? onPageChange(pageNumber) : setPage(pageNumber);
  };

  const handleSort = useCallback(
    (column: TableColumn<T>) => {
      const { sorter, sorterKey, initialSortOrder } = column;
      if (data && sorter && sorterKey) {
        if (sortOrder?.key === sorterKey) {
          const newOrder = sortOrder.order === 'ascend' ? 'descend' : 'ascend';
          setSortedData(newOrder === 'descend' ? data.sort(sorter).reverse() : data.sort(sorter));
          setSortOrder({
            key: sorterKey,
            order: newOrder,
          });
        } else {
          const newOrder = initialSortOrder ?? 'ascend';
          setSortedData(newOrder === 'descend' ? data.sort(sorter).reverse() : data.sort(sorter));
          setSortOrder({ key: sorterKey, order: newOrder });
        }
      }
    },
    [data, sortOrder],
  );

  return (
    <Container>
      <TableWrapper>
        <StyledTable
          $width={`${(columns[0].width as number) + (rowSelection ? CHECKBOX_COLUMN_WIDTH : 0)}px`}
          $minWidth={`${(columns[0].minWidth as number) + (rowSelection ? CHECKBOX_COLUMN_WIDTH : 0)}px`}
          $borderRadius="8px 0 0 0"
        >
          <StyledTableHeader>
            <TableRow>
              {rowSelection && (
                <TableCheckboxCell key={`checkbox-cell-header`} $width={CHECKBOX_COLUMN_WIDTH}>
                  <CustomCheckbox
                    name="checkbox-header"
                    checked={headerCheckboxValue}
                    onCheckedChange={(checked) => {
                      setLastCheckedIdx(-1);
                      setHeaderCheckboxValue(checked);
                      const keys = !checked || !currentPage ? [] : currentPage.map((item) => item.id);
                      rowSelection.onChange(keys);
                    }}
                  />
                </TableCheckboxCell>
              )}
              <TableHeadCell
                key={columns[0].id ?? columns[0].heading}
                $width={columns[0].width}
                $minWidth={columns[0].minWidth}
                $centered={columns[0].centered}
                $cursor={columns[0].sorter ? 'pointer' : 'unset'}
                onClick={() => handleSort(columns[0])}
              >
                {columns[0].headingElement ?? columns[0].heading}
                {columns[0].sorterKey && sortOrder && (
                  <TableSortIcon active={sortOrder.key === columns[0].sorterKey} order={sortOrder.order} />
                )}
              </TableHeadCell>
            </TableRow>
          </StyledTableHeader>
          {!isLoading && sortedData ? (
            <div>
              {isEmpty && (
                <Empty
                  title={!!query ? `No results found for "${query}"` : emptyTitle}
                  description={!!query ? 'Check the spelling or try a different search term.' : emptyDescription}
                  $width="50%!"
                />
              )}
              {currentPage?.map((row, idx) => (
                <StyledTableRow
                  key={`fixed--${row.id}`}
                  onClick={onRowClick ? () => onRowClick(row) : undefined}
                  style={{ height: getRowHeight(idx) }}
                >
                  {rowSelection && (
                    <TableCheckboxCell key={`checkbox-cell-${row.id}`} $width={CHECKBOX_COLUMN_WIDTH}>
                      <CustomCheckbox
                        name={`checkbox-${row.id}`}
                        checked={rowSelection.selectedRowKeys.includes(row.id)}
                        onCheckedChange={(checked) => {
                          const newState = [...rowSelection.selectedRowKeys];
                          if (checked) {
                            if (shifPressed && lastCheckedIdx !== -1) {
                              const startIdx = lastCheckedIdx > idx - 1 ? idx : lastCheckedIdx;
                              const endIdx = lastCheckedIdx > idx - 1 ? lastCheckedIdx : idx;

                              for (let i = startIdx; i <= endIdx; i++) {
                                const id = currentPage[i].id;
                                if (!newState.includes(id)) {
                                  newState.push(id);
                                }
                              }
                            } else {
                              newState.push(row.id);
                            }
                            rowSelection.onChange(newState);
                            setLastCheckedIdx(idx);
                          } else {
                            const keys = newState.filter((key) => key !== row.id);
                            rowSelection.onChange(keys);
                            setLastCheckedIdx(-1);
                          }
                        }}
                      />
                    </TableCheckboxCell>
                  )}
                  <TableCell
                    key={row.id + (columns[0].id ?? columns[0].heading)}
                    $width={columns[0].width}
                    $minWidth={columns[0].minWidth}
                    $showColumnBorders={showColumnBorders}
                  >
                    {columns[0].render(row)}
                  </TableCell>
                </StyledTableRow>
              ))}
            </div>
          ) : (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          )}
        </StyledTable>
        <ScrollWrapper>
          <StyledTable $width="fit-content" $minWidth={tableMinWidth} $borderRadius="0 8px 0 0 ">
            <StyledTableHeader>
              <TableRow>
                {columns.map((column, idx) =>
                  idx === 0 ? null : (
                    <TableHeadCell
                      key={column.id ?? column.heading}
                      $width={column.width}
                      $minWidth={column.minWidth}
                      $centered={column.centered}
                      $cursor={column.sorter ? 'pointer' : 'unset'}
                      onClick={() => handleSort(column)}
                    >
                      {column.headingElement ?? column.heading}
                      {column.sorterKey && sortOrder && (
                        <TableSortIcon active={sortOrder.key === column.sorterKey} order={sortOrder.order} />
                      )}
                    </TableHeadCell>
                  ),
                )}
              </TableRow>
            </StyledTableHeader>
            {!isLoading && sortedData && (
              <div>
                {currentPage?.map((row, idx) => (
                  <StyledTableRow
                    key={row.id}
                    onClick={onRowClick ? () => onRowClick(row) : undefined}
                    className="StyledRow"
                    ref={(el) => {
                      rowRefs.current[idx] = el;
                    }}
                  >
                    {columns.map((column, idx) =>
                      idx === 0 ? null : (
                        <TableCell
                          key={row.id + (column.id ?? column.heading)}
                          $width={column.width}
                          $minWidth={column.minWidth}
                          $showColumnBorders={showColumnBorders && idx !== columns.length - 1}
                        >
                          {column.render(row)}
                        </TableCell>
                      ),
                    )}
                  </StyledTableRow>
                ))}
              </div>
            )}
          </StyledTable>
        </ScrollWrapper>
      </TableWrapper>
      {(totalPages > 1 || onPageSizeChange) && (
        <PaginationRow>
          {totalPages > 1 && (
            <>
              <PaginationButton
                $selected={false}
                $disabled={activePageNumber === 1}
                onClick={() => activePageNumber > 1 && handlePageChange(activePageNumber - 1)}
              >
                <ChevronLeftIcon />
              </PaginationButton>
              {generatePageNumbers().map((pageNumber) => (
                <PaginationButton
                  key={pageNumber}
                  $disabled={false}
                  $selected={pageNumber === activePageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </PaginationButton>
              ))}
              <PaginationButton
                $selected={false}
                $disabled={activePageNumber === totalPages}
                onClick={() => activePageNumber < totalPages && handlePageChange(activePageNumber + 1)}
              >
                <ChevronRightIcon />
              </PaginationButton>
            </>
          )}
          {onPageSizeChange && (
            <PageSizeSelector
              width="100px"
              wrapperWidth="fit-content"
              size="small"
              options={[
                { name: '25 / page', value: 25 },
                { name: '50 / page', value: 50 },
                { name: '100 / page', value: 100 },
              ]}
              defaultVal={25}
              onChange={(value) => onPageSizeChange(value as number)}
            />
          )}
        </PaginationRow>
      )}
    </Container>
  );
};
