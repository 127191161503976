import { ChangeEvent, useCallback } from 'react';
import styled from 'styled-components';

import { BuilderProduct, useLocalProducts, useSaveContext } from 'providers';
import { ClickToCopy, SettingsSegment, SettingsTextInput } from 'components';
import { SOURCE_KAJABI } from 'api';

const StyledTextInput = styled(SettingsTextInput)`
  // Allow ClickToCopy to work with disabled input
  pointer-events: none;
`;

type OfferIdFieldName = 'SourceProductId' | 'ProductId' | 'ProductIdAndroid' | 'ProductIdWeb';

export const EditableOfferId = ({
  Id,
  DataSource,
  title,
  value,
  fieldName,
}: Pick<BuilderProduct, 'Id' | 'DataSource'> & {
  title?: string;
  fieldName: OfferIdFieldName;
  value?: string;
}) => {
  const { setProductValue } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setProductValue(Id, fieldName, value);
      setProductValueToSave(Id, fieldName, value);
    },
    [setProductValue, setProductValueToSave, Id],
  );

  return (
    <SettingsSegment title={title ?? 'Source Offer ID'}>
      {DataSource === SOURCE_KAJABI ? (
        <ClickToCopy copyValue={value ?? ''}>
          <StyledTextInput value={value} onChange={handleChange} $marginTop="0" disabled />
        </ClickToCopy>
      ) : (
        <SettingsTextInput value={value} onChange={handleChange} $marginTop="0" />
      )}
    </SettingsSegment>
  );
};
