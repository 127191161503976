import { ReactNode } from 'react';
import { CheckboxProps, Indicator, Root } from '@radix-ui/react-checkbox';
import styled from 'styled-components';

import { FONT_12PX_REGULAR, FONT_14PX_REGULAR } from 'font';
import { CheckIcon, IndeterminateIcon } from 'icons';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_PRIMARY_HOVER_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_4_COLOUR,
  NEUTRAL_6_COLOUR,
  NEUTRAL_7_COLOUR,
} from 'theme';

const Container = styled.div<{ $marginTop?: string }>`
  display: flex;
  ${({ $marginTop }) => $marginTop && `margin-top: ${$marginTop}`};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

const StyledRoot = styled(Root)`
  width: 16px;
  height: 16px;
  border: 2px solid ${NEUTRAL_7_COLOUR};
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .check-indicator svg {
    color: #fff;
  }

  :disabled {
    border-color: ${NEUTRAL_6_COLOUR};
    background-color: ${NEUTRAL_4_COLOUR};
    cursor: not-allowed;

    .check-indicator svg {
      color: ${NEUTRAL_6_COLOUR};
    }
  }

  :hover:not(:disabled) {
    border-color: ${HIGHLIGHT_PRIMARY_COLOUR};

    &[data-state='checked'],
    &[data-state='indeterminate'] {
      background-color: ${HIGHLIGHT_PRIMARY_HOVER_COLOUR};
      border-color: ${HIGHLIGHT_PRIMARY_HOVER_COLOUR};
    }
  }

  &[data-state='checked']:not(:disabled),
  &[data-state='indeterminate']:not(:disabled) {
    background-color: ${HIGHLIGHT_PRIMARY_COLOUR};
    border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
  }
`;

const StyledLabel = styled.label<{ $disabled?: boolean; $small?: boolean }>`
  display: flex;
  align-items: center;
  color: ${NEUTRAL_10_COLOUR};
  ${({ $small }) => ($small ? FONT_12PX_REGULAR : FONT_14PX_REGULAR)};
  margin-left: ${({ $small }) => ($small ? '4px' : '8px')};
  ${({ $disabled }) => !$disabled && 'cursor: pointer'};
`;

const StyledIndeterminateIcon = styled(IndeterminateIcon)`
  font-size: 9px;
  margin-bottom: 7px;
`;

interface CustomCheckboxProps extends CheckboxProps {
  name: string;
  label?: string;
  labelElement?: ReactNode;
  small?: boolean;
  $marginTop?: string;
  $marginBottom?: string;
}

export const CustomCheckbox = ({
  name,
  label,
  labelElement,
  $marginTop,
  checked,
  small,
  ...props
}: CustomCheckboxProps) => (
  <Container $marginTop={$marginTop}>
    <CheckboxWrapper>
      <StyledRoot id={name} checked={checked} {...props}>
        <Indicator className="check-indicator">
          {checked === 'indeterminate' && <StyledIndeterminateIcon />}
          {checked === true && <CheckIcon />}
        </Indicator>
      </StyledRoot>
    </CheckboxWrapper>
    {labelElement ? (
      labelElement
    ) : label ? (
      <StyledLabel $disabled={props.disabled} $small={small} htmlFor={name}>
        {label}
      </StyledLabel>
    ) : null}
  </Container>
);
