import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { AppPurchase, getAppPurchases } from 'api';
import { useAppBeingEdited } from 'app-context';

export interface useAppPurchasesOptions {
  query?: string;
  platform?: string;
  store_product_id?: string;
  status?: string;
}

export const useAppPurchases = ({ query, platform, store_product_id, status }: useAppPurchasesOptions) => {
  const appId = useAppBeingEdited();

  const { data, fetchNextPage, isLoading, isError } = useInfiniteQuery(
    ['app_purchases', query, platform, store_product_id, status],
    ({ pageParam }) => getAppPurchases(appId, { pageParam, query, platform, store_product_id, status }),
    {
      getNextPageParam: (lastPage) => lastPage.Page + 1,
    },
  );

  const purchases = useMemo(() => {
    const purchasesArr: AppPurchase[] = [];
    if (data && !isLoading) {
      data.pages.forEach((page) => purchasesArr.push(...page.Purchases));
    }
    return purchasesArr;
  }, [data, isLoading]);

  return { purchases, total: data?.pages[0]?.Total ?? 0, fetchNextPage, isLoading, isError };
};
