import { useState } from 'react';

import { PageContainer, Tabs } from 'components';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

import { DebugAppBasicInfo } from './components/DebugAppBasicInfo';
import { DebugAppProperties } from './components/DebugAppProperties';
import { DebugApps } from './components/DebugApps';
import { DebugTabs } from './components/DebugTabs';
import { DebugVideos } from './components/DebugVideos';

export const DebugScreenInternal = () => {
  const [activeTab, setActiveTab] = useState('videos');

  return (
    <PageContainer heading="Debug" contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}>
      <Tabs.Root value={activeTab} onValueChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Trigger value="videos">Videos</Tabs.Trigger>
          <Tabs.Trigger value="tabs">Tabs</Tabs.Trigger>
          <Tabs.Trigger value="properties">App Properties</Tabs.Trigger>
          <Tabs.Trigger value="basicInfo">App Basic Info</Tabs.Trigger>
          <Tabs.Trigger value="apps">All Apps</Tabs.Trigger>
        </Tabs.List>
        <DebugVideos />
        <DebugTabs />
        <DebugAppProperties />
        <DebugAppBasicInfo />
        <DebugApps />
      </Tabs.Root>
    </PageContainer>
  );
};
