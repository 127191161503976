import styled from 'styled-components';

import { ProgressTracking } from 'api';
import { CustomTable, TableColumn } from 'components';
import { OVERFLOW_ELLIPSIS } from 'font';
import { NEUTRAL_9_COLOUR } from 'theme';

const Cell = styled.div`
  padding: 5px 2px 5px 12px;
  height: 55px;
  display: flex;
  align-items: center;
  width: 100%;
  color: ${NEUTRAL_9_COLOUR};
`;

const Ellipsis = styled.span`
  ${OVERFLOW_ELLIPSIS};
`;

export interface TableRow extends ProgressTracking {
  key: unknown;
  VideoId: number;
  Title: string;
  Email: string;
}

interface ProgressTrackingTableProps {
  data?: TableRow[];
  isLoading?: boolean;
  query: string;
}

export const ProgressTrackingTable = ({ data, isLoading, query }: ProgressTrackingTableProps) => {
  const columns: TableColumn<TableRow & { id: string }>[] = [
    {
      heading: 'Email',
      width: 'grow',
      sorter: (a, b) => a.Email.toLowerCase().localeCompare(b.Email.toLowerCase()),
      sorterKey: 'SourceUserId',
      render: ({ Email }) => (
        <Cell>
          <Ellipsis>{Email}</Ellipsis>
        </Cell>
      ),
    },
    {
      heading: 'User Id',
      width: 200,
      sorter: (a, b) => a.SourceUserId.toLowerCase().localeCompare(b.SourceUserId.toLowerCase()),
      sorterKey: 'SourceUserId',
      render: ({ SourceUserId }) => (
        <Cell>
          <Ellipsis>{SourceUserId}</Ellipsis>
        </Cell>
      ),
    },
    {
      heading: 'Title',
      width: 'grow',
      sorter: (a, b) => a.Title.toLowerCase().localeCompare(b.Title.toLowerCase()),
      sorterKey: 'Title',
      render: ({ Title }) => <Cell>{Title}</Cell>,
    },
    {
      heading: 'Source Id',
      width: 200,
      sorter: (a, b) => a.SourceId.toLowerCase().localeCompare(b.SourceId.toLowerCase()),
      sorterKey: 'SourceId',
      render: ({ SourceId }) => (
        <Cell>
          <Ellipsis>{SourceId}</Ellipsis>
        </Cell>
      ),
    },
    {
      heading: 'Date',
      width: 'grow',
      sorter: (a, b) => a.StartDate.toLowerCase().localeCompare(b.StartDate.toLowerCase()),
      sorterKey: 'StartDate',
      initialSortOrder: 'descend',
      render: ({ StartDate }) => (
        <Cell>
          <Ellipsis>{StartDate}</Ellipsis>
        </Cell>
      ),
    },
    {
      heading: 'Percent Complete',
      width: 150,
      sorter: (a, b) => Number.parseFloat(a.MaxPercentage) - Number.parseFloat(b.MaxPercentage),
      initialSortOrder: 'descend',
      sorterKey: 'MaxPercentage',
      render: ({ MaxPercentage }) => (
        <Cell>
          <Ellipsis>{MaxPercentage}</Ellipsis>
        </Cell>
      ),
    },
  ];

  return (
    <CustomTable
      data={data?.map((item) => ({ ...item, id: `${item.SourceUserId}|${item.SourceId}` }))}
      columns={columns}
      pageSize={20}
      emptyTitle=""
      emptyDescription="No content"
      defaultSortKey="Title"
      defaultSortOrder="ascend"
      isLoading={isLoading || !data}
      query={query}
    />
  );
};
