import { syncProducts, useAxiosInstance } from '../api';
import { useAppBeingEdited } from '../app-context';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useDataSource } from 'providers';

export const useSyncProducts = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const dataSource = useDataSource();

  return useMutation<unknown, AxiosError, { overrideDataSource?: string }>(async ({ overrideDataSource }) => {
    await syncProducts(client, appId, overrideDataSource ?? dataSource);
  });
};
