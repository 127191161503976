import { axiosReact } from './axios';

interface GetAppPurchasesResponse {
  Page: number;
  Purchases: AppPurchase[];
  Total: number;
}

interface Transaction {
  Store: string;
  AppPurchaseId: number;
  CreatedTs: number;
  ExpirationProcessed: 0 | 1;
  CancellationReason: string | null;
  PurchaseTs: number;
  StoreProductId: string;
  AppId: string;
  Id: number;
  LastUpdatedTs: number;
  Trial: 0 | 1;
  ExpirationTs: number;
  CancellationTs: number | null;
  OrderId: string;
}

export interface AppPurchase {
  Id: number;
  Store: string;
  OriginalTransactionId: string;
  PurchaseType: string;
  IsTest: boolean;
  DevicePurchasedOn: string;
  OriginalPurchaseEmail: string;
  OriginalStoreToken: string;
  OriginalPurchaseTs: number;
  CreatedTs: number;
  LastUpdatedTs: number;
  Transaction: Transaction;
}

interface GetAppPurchasesOptions {
  pageParam?: number;
  query?: string;
  platform?: string;
  store_product_id?: string;
  status?: string;
}

export const getAppPurchases = (appId: string, options: GetAppPurchasesOptions) => {
  const params: Record<string, unknown> = {};

  if (options?.pageParam) {
    params['page'] = options.pageParam;
  }
  if (options?.query) {
    params['query'] = options.query;
  }
  if (options?.platform) {
    params['platform'] = options.platform;
  }
  if (options?.store_product_id) {
    params['store_product_id'] = options.store_product_id;
  }
  if (options?.status) {
    params['status'] = options.status;
  }

  return axiosReact
    .get<GetAppPurchasesResponse>(`/purchases/${appId}?page_size=100`, { params })
    .then(({ data }) => data);
};

interface UserPurchases {
  UserID: string;
  Purchases: AppPurchase[];
}

export const getUserPurchases = (appId: string, email: string) => {
  return axiosReact.get<UserPurchases>(`/purchases/${appId}/user`, { params: { email } }).then(({ data }) => data);
};

export interface SyncPurchasePayload {
  PurchaseID: number;
  UserID: string;
  DataSource: string;
  StoreProductID: string;
}

export const syncPurchase = (appId: string, data: SyncPurchasePayload) => {
  return axiosReact.post(`/purchases/${appId}/sync_purchase`, data);
};
