import { format, parse } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { CustomButton, LineChart, PageContainer } from 'components';
import { FileIcon } from 'icons';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';
import { METRIC_KEY_AVERAGE_WATCHED } from 'api';

import { KeyAnalytics } from '../KeyAnalytics/KeyAnalytics';
import { AnalyticsTable } from '../components';
import { METRIC_LABELS } from '../const';
import { useAnalyticsContext } from '../providers/AnalyticsProvider';
import { useLocation } from 'react-router-dom';
import { capitalizeString, getScreenFromPathname } from 'utils';

export const AnalyticsPage = () => {
  const { keyAnalytics, activeMetric, chartData, isError, activeIncrement, activeRange } = useAnalyticsContext();
  const { pathname } = useLocation();
  const screen = getScreenFromPathname(pathname);

  const isPercentage = activeMetric === METRIC_KEY_AVERAGE_WATCHED;

  // Return an array of labels to display on the x-axis based on the selected date range and increment
  const xLabelsToShow = useMemo(() => {
    if (!chartData) {
      return undefined;
    }

    // Only display the 1st of each month
    if (activeRange === 365 && activeIncrement === 'daily') {
      return chartData[0].data.map((v) => {
        const label = v.x as string;
        if (label.includes('-01 ')) {
          return label;
        }
        return '';
      });
    }

    // Display every nth label
    if (
      (activeRange === 365 && activeIncrement === 'weekly') ||
      ([30, 90].includes(activeRange) && activeIncrement === 'daily')
    ) {
      const n = activeIncrement === 'daily' ? 7 : 4;
      return chartData[0].data.map((v, i) => (i % n === 0 ? (v.x as string) : ''));
    }

    // For everything else, return undefined which will display all labels
    return undefined;
  }, [chartData, activeIncrement, activeRange]);

  const useMMMyy = useMemo(
    () =>
      (activeRange === 365 && activeIncrement !== 'weekly') || (activeRange === 90 && activeIncrement === 'monthly'),
    [activeIncrement, activeRange],
  );

  const getFormattedDate = useCallback(
    (value: string, fullDate?: boolean) => {
      const parsedDate = parse(value, 'yyyy-MM-dd HH:mm:ss', new Date());
      const dateFormat = fullDate ? "d MMM ''yy" : useMMMyy ? "MMM ''yy" : 'dd MMM';
      const formattedDate = format(parsedDate, dateFormat);
      return formattedDate;
    },
    [useMMMyy],
  );

  return (
    <PageContainer
      heading={screen ? capitalizeString(screen) : undefined}
      headingButton={
        screen === 'viewership' ? (
          <CustomButton tertiary icon={<FileIcon />} link={{ to: '/analytics/video-analytics' }}>
            View Full Report
          </CustomButton>
        ) : undefined
      }
      isBeta={screen === 'engagement'}
      isError={isError}
      contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}
    >
      <KeyAnalytics />
      {activeMetric && (
        <>
          <LineChart
            key={`${activeMetric}${activeRange}${activeIncrement}`}
            header={METRIC_LABELS[activeMetric]}
            data={chartData}
            yMax={isPercentage ? 100 : undefined}
            axisLeftFormat={(name) => (isPercentage ? `${name}%` : name.toLocaleString())}
            xLabelsToShow={xLabelsToShow}
            getFormattedDate={getFormattedDate}
          />
          <AnalyticsTable
            data={chartData ? chartData[0].data : undefined}
            total={keyAnalytics ? keyAnalytics[activeMetric] : undefined}
            getFormattedDate={getFormattedDate}
            isPercentage={isPercentage}
          />
        </>
      )}
    </PageContainer>
  );
};
