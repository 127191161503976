import { ChangeToSave, LogDetails } from './save-types';
import { BuilderCollection, BuilderCollections, BuilderVideo } from '../content-provider';
import { AppPropertyToSave } from '../../api';
import { BuilderProduct } from '../local-products-provider';

const initializeVideo = (logDetails: LogDetails, videoId: BuilderVideo['VideoId']) => {
  if (!logDetails.videos) {
    logDetails.videos = {};
  }
  if (!logDetails.videos[videoId]) {
    logDetails.videos[videoId] = {};
  }
};
const initializeCollection = (logDetails: LogDetails, collectionId: BuilderCollection['TabId']) => {
  if (!logDetails.collections) {
    logDetails.collections = {};
  }
  if (!logDetails.collections[collectionId]) {
    logDetails.collections[collectionId] = {};
  }
};
const initializeProduct = (logDetails: LogDetails, productId: BuilderProduct['Id']) => {
  // Offers is the UI facing term
  if (!logDetails.offers) {
    logDetails.offers = {};
  }
  if (!logDetails.offers[productId]) {
    logDetails.offers[productId] = {};
  }
};
export const addVideoLogDetails = (logDetails: LogDetails, videoChanges: ChangeToSave[]) => {
  // Handle changes of type "video" "video-properties"
  for (const change of videoChanges) {
    initializeVideo(logDetails, change.id);
    const videos = logDetails.videos;

    if (change.type === 'video') {
      videos[change.id] = { ...videos[change.id], ...change.properties };
    } else if (change.type === 'video-properties') {
      videos[change.id] = { ...videos[change.id], properties: change.properties };
    }
  }
};

export const addAppPropertiesLogDetails = (logDetails: LogDetails, properties: AppPropertyToSave[]) => {
  const propertyDetails: Record<string, unknown> = {};
  for (const property of properties) {
    propertyDetails[property.Name] = property.Value;
  }
  logDetails['appProperties'] = propertyDetails;
};

export const addCollectionLogDetails = (
  logDetails: LogDetails,
  collectionChanges: ChangeToSave[],
  collections: BuilderCollections,
) => {
  for (const change of collectionChanges) {
    initializeCollection(logDetails, change.id);
    const collectionDetails = logDetails.collections;
    if (change.type === 'collection') {
      collectionDetails[change.id] = { ...collectionDetails[change.id], ...change.properties };
    } else if (change.type === 'collection-properties') {
      collectionDetails[change.id] = { ...collectionDetails[change.id], properties: change.properties };
    } else if (change.type === 'collection-items') {
      const localId = change.id.startsWith('Temp') ? change.id : parseInt(change.id);
      const items = collections[localId]?.Items;
      collectionDetails[change.id] = { ...collectionDetails[change.id], items: items };
    } else if (change.type === 'delete-collection') {
      collectionDetails[change.id] = 'deleted';
    }
  }
};

export const addProductLogDetails = (logDetails: LogDetails, productChanges: ChangeToSave[]) => {
  for (const change of productChanges) {
    initializeProduct(logDetails, change.id);
    const productDetails = logDetails.offers;
    if (change.type === 'product') {
      productDetails[change.id] = { ...productDetails[change.id], ...change.properties };
    } else if (change.type === 'delete-product') {
      productDetails[change.id] = 'deleted';
    }
  }
};

const convertRouteToReadable = (route: string) => {
  // Remove the leading slash and split the route string on both slashes and dashes
  const words: string[] = route.replace(/^\//, '').split(/[-\/]/);
  // Filters numerical segments
  const filteredWords = words.filter((word) => !/^\d+$/.test(word));
  // Capitalize the first letter of each word and join them with a space
  return filteredWords.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};
export const getSaveLogName = (pathname: string) => {
  // Specific names for paths
  const pages: Record<string, string> = {
    '/content': 'Content Screen',
    '/builder': 'Tab Builder',
  };

  let page = convertRouteToReadable(pathname.split('?')[0]);
  for (const key in pages) {
    if (pathname.startsWith(key)) {
      page = pages[key];
      break;
    }
  }

  return `Saved on ${page}`;
};
