import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { AppBasicInfoToSave, AppBasicInfo, saveAppBasicInfo, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

interface SaveAppInfoProps {
  saveActionLog?: boolean;
}

export const useSaveAppBasicInfo = (props?: SaveAppInfoProps) => {
  const { saveActionLog = true } = props ?? {};
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, AppBasicInfoToSave>(
    (appBasicInfo) => {
      return saveAppBasicInfo(client, appId, appBasicInfo);
    },
    {
      onSuccess: async (response, payload) => {
        await queryClient.invalidateQueries(['appBasicInfo', appId]);
        if (payload.PaymentStatus) {
          // Update the local cache for this app in the search box
          const availableApps = queryClient.getQueryData('availableApps') as AppBasicInfo[];
          if (availableApps) {
            const app = availableApps.find((app) => app.AppId === appId);
            if (app) {
              app.PaymentStatus = payload.PaymentStatus as number;
            }
            queryClient.setQueryData('availableApps', availableApps);
          }
        }
        if (saveActionLog) {
          recordAction.mutate({ action: 'Save App Basic Info', detail: { infoToSave: payload } });
        }
      },
    },
  );
};
