import { Form } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  CustomButton,
  PageContainer,
  SettingsFileInput,
  SettingsFormItem,
  SettingsGroup,
  SettingsSelectInput,
  SettingsTextInput,
  Tabs,
} from 'components';
import { SETTINGS_ITEM_MARGIN_BOTTOM } from 'theme';
import { useAppBeingEdited } from 'app-context';

import { useAppStoreSettings } from '../hooks/useAppStoreSettings';
import { ANDROID_FIELDS, Field, IOS_FIELDS, PROPERTIES } from './const';

const ANDROID_URL =
  'https://wearevidapp.zendesk.com/hc/en-nz/articles/6650734541209-How-do-I-create-my-Google-Service-Account';
const IOS_URL =
  'https://wearevidapp.zendesk.com/hc/en-nz/articles/4407780414105-How-do-I-generate-my-App-Store-Connect-API-key';

const { Root, List, Trigger, Content } = Tabs;

const TAB_PARAM = 'tab';

const StyledRoot = styled(Root)`
  margin-top: 24px;
`;

const getFields = (fieldArr: Field[]) =>
  fieldArr.map(({ type, name, width, label, placeholder, tooltip, options, defaultVal }) => {
    if (type === 'text') {
      return (
        <SettingsFormItem key={name} name={name} width={width || '49%'} $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}>
          <SettingsTextInput label={label} tooltip={tooltip} placeholder={placeholder} autoComplete="off" />
        </SettingsFormItem>
      );
    }
    return (
      <SettingsFormItem key={name} name={name} width={width || '49%'}>
        <SettingsSelectInput label={label} tooltip={tooltip} options={options ?? []} defaultVal={defaultVal} />
      </SettingsFormItem>
    );
  });

export const AppStoreSettings = () => {
  const {
    form,
    unsavedChanges,
    isSaving,
    isLoading,
    isError,
    initialValues,
    filenames,
    onFilenameChange,
    onValuesChange,
    saveForm,
  } = useAppStoreSettings(PROPERTIES);
  const history = useHistory();
  const { search, state } = useLocation();
  const appId = useAppBeingEdited();

  const { AppleAuthKey, AndroidJsonFilename } = filenames;

  const locationState = state ? (state as Record<string, string>) : undefined;

  const query = new URLSearchParams(search);
  const tabParam = locationState?.tab ?? query.get(TAB_PARAM) ?? 'ios';

  return (
    <PageContainer
      heading="App Store Settings"
      subheading={
        tabParam === 'android' ? (
          <>
            Upload the Android Developer Account JSON by{' '}
            <a target="_blank" rel="noreferrer" href={ANDROID_URL}>
              following these steps.
            </a>{' '}
            Then enter your Android Developer Account Name and select your Default Currency.
          </>
        ) : (
          <>
            Create App Store Connect credentials (Shared Secret, Key ID, Issuer ID and API Key File) by{' '}
            <a target="_blank" rel="noreferrer" href={IOS_URL}>
              following these steps.
            </a>{' '}
            Your Team ID, Team Name and Developer Title can be accessed from your App Store Connect's profile.
          </>
        )
      }
      isLoading={isLoading}
      isError={isError}
      headingButton={
        <CustomButton medium onClick={() => form?.submit()} loading={isSaving} disabled={!unsavedChanges}>
          Save
        </CustomButton>
      }
    >
      <>
        <Form
          name="settings-form"
          onValuesChange={onValuesChange}
          onFinish={saveForm}
          autoComplete="off"
          form={form}
          initialValues={initialValues}
        >
          <StyledRoot
            defaultValue="ios"
            value={tabParam}
            onValueChange={(key) => {
              history.push({
                search: `?${TAB_PARAM}=${key}`,
              });
            }}
          >
            <List>
              <Trigger value="ios">iOS</Trigger>
              <Trigger value="android">Android</Trigger>
            </List>
            {/* iOS Tab */}
            <Content value="ios">
              <SettingsGroup title="iOS Settings" wrapRow $marginBottom="0">
                {getFields(IOS_FIELDS)}
              </SettingsGroup>
              <SettingsGroup>
                <SettingsFileInput
                  heading="API Key File"
                  filename={AppleAuthKey?.value}
                  useOriginalFilename
                  downloadable
                  filePath={`${appId}/certs/`}
                  acceptedFileTypes=".p8"
                  onFilenameChange={(filename) => onFilenameChange('AppleAuthKey', filename)}
                />
              </SettingsGroup>
            </Content>
            {/* Android Tab */}
            <Content value="android">
              <SettingsGroup title="Android Settings" wrapRow $marginBottom="0">
                {getFields(ANDROID_FIELDS)}
              </SettingsGroup>
              <SettingsGroup>
                <SettingsFileInput
                  heading="Android Developer Account JSON"
                  filename={AndroidJsonFilename?.value}
                  useOriginalFilename
                  downloadable
                  acceptedFileTypes="application/json"
                  onFilenameChange={(filename) => onFilenameChange('AndroidJsonFilename', filename)}
                />
              </SettingsGroup>
            </Content>
          </StyledRoot>
        </Form>
      </>
    </PageContainer>
  );
};
