import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { APP_ID_PARAM } from 'api';
import { useAppBeingEdited } from 'app-context';
import { getUserApps, isUserAdmin } from 'utils';
import { DataSourceProvider } from 'providers';

import { SideNav } from 'app/modules/side-nav';
import { SyncBanner } from 'app/modules/sync-tracking/SyncBanner/SyncBanner';
import { BannerProvider } from 'providers/banner-provider';

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const PageWrapper = styled.div`
  min-width: 960px;
  height: 100%;
  overflow: auto;
  flex: 1 1 auto;
`;

interface MainContainerProps {
  children: ReactNode;
}

export const MainContainer = ({ children }: MainContainerProps) => {
  const appId = useAppBeingEdited();
  const { pathname, state, search } = useLocation();
  const history = useHistory();
  const locationState = state ? (state as Record<string, string>) : {};

  const [appIdReady, setAppIdReady] = useState(false);

  const query = new URLSearchParams(search);
  const appIdParam = query.get(APP_ID_PARAM);

  useEffect(() => {
    // If there is no appid query param, add it
    if (!appIdParam) {
      history.replace({
        search: search ? `${search}&${APP_ID_PARAM}=${appId}` : `?${APP_ID_PARAM}=${appId}`,
        state: { ...locationState },
      });
      setAppIdReady(true);
    }
    // If the appid query param is different to the app currently being edited
    else if (appIdParam !== appId) {
      const availableApps = getUserApps();
      if (isUserAdmin({ overrideImpersonation: true }) || availableApps[appIdParam]) {
        // If the user has access to the app from the appid param, change app
        localStorage.setItem('appBeingEdited', appIdParam);
        window.location.reload();
      } else {
        // Otherwise, update the query param to match the app currently being edited
        history.replace({
          search: search.replace(`${APP_ID_PARAM}=${appIdParam}`, `${APP_ID_PARAM}=${appId}`),
          state: { ...locationState },
        });
        setAppIdReady(true);
      }
    } else {
      setAppIdReady(true);
    }
  }, [appIdParam]);

  useEffect(() => {
    document.getElementById('ScrollContainer--PageContainer')?.scrollTo(0, 0); // Reset scroll when new page is navigated to

    const pathSegments = pathname.split('/');
    if (window.hj && pathSegments.length >= 2) {
      // Send the first segment of the pathname as a hotjar event
      // Avoid any dynamic parameters in the path
      // Allows segmentation of recordings
      const output = pathSegments.filter((s) => isNaN(s as unknown as number)).join('/');
      const event = output ? output : 'legacy';
      console.debug(`Sending event:${event} to hotjar`);
      window.hj('event', event);
    }
  }, [pathname]);

  const isErrorScreen = locationState.isError;

  const isSideNavHidden = useMemo(() => {
    return isErrorScreen || ['/builder'].some((prefix) => pathname.startsWith(prefix));
  }, [isErrorScreen, pathname]);

  if (!appIdReady) {
    return null;
  }

  return (
    <Container>
      <DataSourceProvider>
        <SideNav visible={!isSideNavHidden} />
      </DataSourceProvider>
      <BannerProvider>
        <PageWrapper>{children}</PageWrapper>
        <SyncBanner />
      </BannerProvider>
      <div id="modal-portal" />
    </Container>
  );
};
