import styled from 'styled-components';

import { PropertyDiff } from 'api';
import { FONT_12PX_MEDIUM, FONT_12PX_SEMIBOLD, FONT_14PX_SEMIBOLD, OVERFLOW_ELLIPSIS } from 'font';
import { CustomTable, TableColumn } from 'components';

import { usePropertyDiff } from 'app/modules/publish-diffs/PublishDiff/hooks/usePublishDiff';
import { Heading } from './Heading';
import { NEUTRAL_9_COLOUR } from 'theme';

const Cell = styled.div`
  padding: 5px 2px 5px 12px;
  height: 36px;
  display: flex;
  align-items: center;
  width: 100%;
  color: ${NEUTRAL_9_COLOUR};
`;

const TruncatedText = styled.div`
  ${OVERFLOW_ELLIPSIS};
  max-width: 400px;
  ${FONT_12PX_MEDIUM};
`;

const PropertyTitle = styled.div`
  ${FONT_14PX_SEMIBOLD};
`;

const Semibold = styled.div`
  ${FONT_12PX_SEMIBOLD};
`;
const KeyDiff = styled.div`
  margin-bottom: 4px;
`;

const renderValue = (value: string | undefined | null) => {
  if (typeof value === 'undefined') {
    return 'UNDEFINED';
  } else if (typeof value === 'string') {
    if (value.length === 0) {
      return 'EMPTY_STRING';
    }
    return `"${value}"`;
  }
  return '';
};

const getColumns = (stage: string) => {
  const afterStage = stage === 'Preview' ? 'Builder' : 'Preview';
  const columns: TableColumn<PropertyDiff & { id: string }>[] = [
    {
      heading: 'Property',
      width: 200,
      render: ({ Name }) => (
        <Cell>
          <PropertyTitle>{Name}</PropertyTitle>
        </Cell>
      ),
    },
    {
      heading: `Current (${stage})`,
      width: 'grow',
      minWidth: 200,
      render: ({ Before }) => (
        <Cell>
          <TruncatedText>{renderValue(Before)}</TruncatedText>
        </Cell>
      ),
    },
    {
      heading: `New (${afterStage})`,
      width: 'grow',
      minWidth: 200,
      render: ({ After }) => (
        <Cell>
          <TruncatedText>{renderValue(After)}</TruncatedText>
        </Cell>
      ),
    },
    {
      heading: 'Differences on Keys inside the property',
      width: 'grow',
      minWidth: 200,
      render: ({ KeyDiffs }) => (
        <Cell>
          <TruncatedText>
            {KeyDiffs
              ? KeyDiffs?.map((diff) => (
                  <KeyDiff>
                    <Semibold>{diff.Name}</Semibold>
                    <div> {`${renderValue(diff.Before)} -> ${renderValue(diff.After)}`}</div>
                  </KeyDiff>
                ))
              : 'N/A'}
          </TruncatedText>
        </Cell>
      ),
    },
  ];

  return columns;
};

interface PropertyDiffTableProps {
  stage: 'Preview' | 'Live';
}

export const PropertyDiffTable = ({ stage }: PropertyDiffTableProps) => {
  const { data } = usePropertyDiff(stage);

  return (
    <>
      <Heading>App Property Changes</Heading>
      <CustomTable
        data={data?.PropertyDifferences.map((item) => ({ ...item, id: item.Name }))}
        columns={getColumns(stage)}
        emptyTitle=""
        emptyDescription="No Data"
      />
    </>
  );
};
