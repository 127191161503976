import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { DateRangePicker, PageContainer, SearchBar } from 'components';
import { useAppUsers, useVideos } from 'hooks';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

import { ExportIcon } from '../../../../icons';
import { CsvButton } from '../components';
import { ProgressTrackingTable, TableRow } from './components/ProgressTrackingTable';
import { useProgressTracking } from './hooks/useProgressTracking';

const SEARCH_PARAM = 'search';

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ProgressTrackingPage = () => {
  const today: Date = new Date();
  const [startDate, setStartDate] = useState<Date>(startOfDay(subDays(today, 7)));
  const [endDate, setEndDate] = useState<Date>(endOfDay(subDays(today, 1)));
  const { data: videos, isError: isVideosError, isLoading: isVideosLoading } = useVideos();
  const {
    data: analytics,
    isError: isAnalyticsError,
    isLoading: isAnalyticsLoading,
  } = useProgressTracking(startDate, endDate);

  const userIds = analytics?.map(({ SourceUserId }) => SourceUserId);
  const { data: appUserData, isError: appUserDataIsError, isLoading: appUserDataIsLoading } = useAppUsers(userIds);

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get(SEARCH_PARAM) ?? '';

  const isError = isAnalyticsError || isVideosError || appUserDataIsError;
  const isLoading = isVideosLoading || isAnalyticsLoading || appUserDataIsLoading;

  // Combine the analytics data and the video data
  const data = useMemo(() => {
    if (analytics && videos) {
      const tableData: TableRow[] = [];
      for (const analytic of analytics) {
        const video = videos.video_by_source_id[analytic.SourceId];
        const user = appUserData?.user_by_user_id[analytic.SourceUserId];
        if (video) {
          tableData.push({
            ...analytic,
            key: analytic.SourceId,
            Title: video.Title,
            VideoId: video.VideoId,
            Email: user?.Email ?? '',
          });
        }
      }
      return tableData;
    }
    return undefined;
  }, [analytics, appUserData, videos]);

  const filteredData = useMemo(() => {
    if (query === '') {
      return data;
    }

    const lowerQuery = query.toLowerCase();

    return data?.filter(
      ({ Email, Title }) => Email.toLowerCase().includes(lowerQuery) || Title.toLowerCase().includes(lowerQuery),
    );
  }, [data, query]);

  const handleDateChange = useCallback(
    (dates) => {
      const returnedStartDate = startOfDay(dates[0]);
      const returnedEndDate = endOfDay(dates[1]);
      if (
        startDate.toString() !== returnedStartDate.toString() ||
        endDate.toString() !== returnedStartDate.toString()
      ) {
        setStartDate(returnedStartDate);
        setEndDate(returnedEndDate);
      }
    },
    [startDate, endDate, setStartDate, setEndDate],
  );

  const handleSearch = useCallback(
    (value: string) => {
      const searchInput = value.toLowerCase();
      const searchParam = searchInput !== '' ? `&${SEARCH_PARAM}=${searchInput}` : '';

      history.replace({
        search: searchParam,
      });
    },
    [history],
  );

  return (
    <PageContainer
      heading="Progress Tracking"
      subheading="View a summary of viewing progress for users within your app."
      isBeta
      isError={isError}
      headingButton={
        data && (
          <CsvButton
            filename={`ProgressTracking ${format(startDate, 'yyyy-MM-dd')} - ${format(endDate, 'yyyy-MM-dd')}`}
            headers={[
              { label: 'Email', key: 'Email' },
              { label: 'User Id', key: 'SourceUserId' },
              { label: 'Title', key: 'Title' },
              { label: 'Source Id', key: 'SourceId' },
              { label: 'Percent Complete', key: 'MaxPercentage' },
              { label: 'Date', key: 'StartDate' },
            ]}
            data={data}
          >
            <ExportIcon />
            Export CSV File
          </CsvButton>
        )
      }
      contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}
    >
      <HeaderRow>
        <SearchBar value={query} onSearch={handleSearch} />
        <div>
          <DateRangePicker
            today={today}
            onChange={handleDateChange}
            value={[startDate, endDate]}
            disabled={isLoading}
            allowClear={false}
          />
        </div>
      </HeaderRow>
      <ProgressTrackingTable data={filteredData} isLoading={isLoading} query={query} />
    </PageContainer>
  );
};
