import * as amplitude from '@amplitude/analytics-browser';
import { Tooltip as AntdTooltip } from 'antd';
import { useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { CONTENT_1_SOURCE_ID, KAJABI_LIBRARY_SOURCE_ID, TAB_ICON_URL } from 'api';
import { useAppBeingEdited } from 'app-context';
import { CustomButton, DragHandleDots, DragHandleLocked, Dropdown, TypeLabel } from 'components';
import { FONT_18PX_MEDIUM } from 'font';
import { PenIcon, TrashIcon, VericalEllipsisIcon } from 'icons';
import { BuilderCollection, useContent, useDataSource, useLocalAppProperties, useSaveContext } from 'providers';
import { NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR, PRIMARY_ORANGE_COLOUR } from 'theme';
import { BUILDER_IMAGES_DOMAIN, TintImage, getScreenFromPathname, isUserAdmin } from 'utils';

import { useBuildNav } from '../../providers';
import { getPageName, tabIsProtected } from '../../utils';
import { BMA_TV_SCREEN } from '../../const';
import { useFeatureFlags } from 'app/modules/feature-flagged/FeatureFlagged/hooks/useFeatureFlags';

interface TabProps {
  $isActive: boolean;
  $isDraggingOver?: boolean;
  $isDragging?: boolean;
  $padding?: string;
}

const Tab = styled.div<TabProps>`
  position: relative;
  width: 100%;
  padding: ${({ $padding }) => $padding || '12px'};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ $isActive, $isDragging }) =>
    $isActive ? PRIMARY_ORANGE_COLOUR : $isDragging ? NEUTRAL_10_COLOUR : NEUTRAL_5_COLOUR};
  border-radius: 8px;
  background-color: #fff;
  color: ${NEUTRAL_10_COLOUR};
  user-select: none;
  ${FONT_18PX_MEDIUM};
  cursor: pointer;

  .anticon {
    opacity: ${({ $isDragging }) => ($isDragging ? 1 : 0)};
  }

  .customize-button-wrapper {
    display: none;
  }

  :hover {
    border-color: ${({ $isActive, $isDraggingOver }) =>
      $isActive ? PRIMARY_ORANGE_COLOUR : $isDraggingOver ? NEUTRAL_5_COLOUR : NEUTRAL_10_COLOUR};

    .customize-button-wrapper {
      display: ${({ $isDraggingOver }) => ($isDraggingOver ? 'none' : 'block')};
    }

    .anticon {
      opacity: ${({ $isDraggingOver }) => ($isDraggingOver ? 0 : 1)};
    }
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const TopRow = styled(Row)`
  height: 30px;
  margin-bottom: 12px;
`;

const BottomRow = styled(Row)`
  height: 18px;
`;

const StatusLabel = styled(TypeLabel)`
  margin-left: 8px;
`;

const StyledDragHandleDots = styled(DragHandleDots)`
  position: absolute;
  top: -1px;
  left: -28px;
`;

const StyledDragHandleLocked = styled(DragHandleLocked)`
  position: absolute;
  top: -1px;
  left: -28px;
`;

const TabIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  margin-right: 8px;
`;
const TabName = styled.div`
  width: 100%;
  flex-grow: 1;
`;

const Spacer = styled.div`
  height: 20px;
`;

const EllipsisButton = styled(CustomButton)`
  &&&& {
    background: none;
    color: ${NEUTRAL_7_COLOUR};

    .anticon {
      font-size: 24px;
    }
    :hover:not(:active) {
      color: ${NEUTRAL_9_COLOUR};
    }
  }
`;

const CustomizeButton = styled(CustomButton)`
  &&&& {
    margin-bottom: 2px;
  }
`;

const StatusLabels = ({ tab }: { tab: BuilderCollection }) => {
  const location = useLocation();
  const screen = getScreenFromPathname(location.pathname);
  const isTV = screen === BMA_TV_SCREEN;
  const { hasAccess } = useFeatureFlags();
  if (!isUserAdmin() || !hasAccess('tv')) {
    return null;
  }

  const { IsMainTab, IsMainTVTab } = tab;
  const isActive = isTV ? IsMainTVTab === 1 : IsMainTab === 1;

  return (
    <>
      {isActive && <StatusLabel>ACTIVE</StatusLabel>}
      {IsMainTab === 1 && <StatusLabel $neutral>MOBILE</StatusLabel>}
      {IsMainTVTab === 1 && <StatusLabel $neutral>TV</StatusLabel>}
    </>
  );
};

interface BuildNavTabProps {
  tabId: string | number;
  idx: number;
  isActive: boolean;
  isGuidedTourTab: boolean;
  isDraggingOver?: boolean;
}

export const BuildNavTab = ({ tabId, idx, isActive, isGuidedTourTab, isDraggingOver }: BuildNavTabProps) => {
  const { collections, deleteCollection } = useContent();
  const { setCollectionToDelete } = useSaveContext();
  const { properties } = useLocalAppProperties();
  const { saveTimestamp } = useBuildNav();
  const appId = useAppBeingEdited();
  const dataSource = useDataSource();
  const history = useHistory();
  const location = useLocation();
  const screen = getScreenFromPathname(location.pathname);
  const isTV = screen === BMA_TV_SCREEN;

  const handleDelete = useCallback(() => {
    setCollectionToDelete(tabId);
    deleteCollection(tabId);
    saveTimestamp();
    history.replace(`/${screen}/`);
  }, [tabId, screen, setCollectionToDelete, deleteCollection, saveTimestamp]);

  const tab = collections[tabId];

  const showEllipsisButton = tab && !tabIsProtected(tab, dataSource, properties.OptionalProfileTab !== '1');

  return tab ? (
    <Draggable draggableId={tabId.toString()} index={idx} key={tabId}>
      {(provided, { isDragging }) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Tab
            onClick={() => history.replace(`/${screen}/${tabId}`)}
            $isActive={isActive}
            $isDraggingOver={isDraggingOver}
            $isDragging={isDragging}
            $padding={showEllipsisButton ? '12px 4px 12px 12px' : undefined}
            id={isGuidedTourTab ? 'BuildNavTab--GuidedTour' : undefined}
            data-testid={`build-nav-tab-${idx}`}
          >
            <StyledDragHandleDots $color={NEUTRAL_7_COLOUR} {...provided.dragHandleProps} />
            <TopRow>
              <TabIcon>
                <TintImage
                  src={tab.Icon ? `${TAB_ICON_URL}${tab.Icon}` : `${BUILDER_IMAGES_DOMAIN}/icons/blocked.png`}
                  color={NEUTRAL_10_COLOUR}
                  dimensions={{ width: 20, height: 20 }}
                />
              </TabIcon>
              <TabName>{tab.Name}</TabName>
              <div className="customize-button-wrapper">
                {isNaN(tabId as number) ? (
                  <AntdTooltip
                    getPopupContainer={() => document.getElementById('react-app') as HTMLElement}
                    arrowPointAtCenter
                    title="You need to click save before you customize this Tab"
                    trigger="hover"
                  >
                    <div>
                      <CustomizeButton tertiaryHighlight medium icon={<PenIcon />} disabled>
                        Customize
                      </CustomizeButton>
                    </div>
                  </AntdTooltip>
                ) : (
                  <CustomizeButton
                    id={isGuidedTourTab ? 'CustomizeButton--BuildNavTab' : undefined}
                    tertiaryHighlight
                    medium
                    icon={<PenIcon />}
                    link={{
                      to: { pathname: `/builder/${tabId}`, state: { showUnsavedChangesPrompt: true, viaTV: isTV } },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      const isContent1Tab = [CONTENT_1_SOURCE_ID, KAJABI_LIBRARY_SOURCE_ID].includes(tab.SourceId);
                      const tabType = isContent1Tab
                        ? 'Content1'
                        : getPageName(tab) === 'Content'
                        ? 'ContentOther'
                        : getPageName(tab);
                      amplitude.track('Tab opened via BMA Side Menu', { appId, tabType });
                    }}
                  >
                    Customize
                  </CustomizeButton>
                )}
              </div>
              {showEllipsisButton && (
                <Dropdown
                  trigger={<EllipsisButton icon={<VericalEllipsisIcon />} tertiary />}
                  options={[
                    {
                      label: 'Delete Tab',
                      icon: <TrashIcon />,
                      onClick: handleDelete,
                      danger: true,
                    },
                  ]}
                />
              )}
            </TopRow>
            <BottomRow>
              <TypeLabel $upperCase $warning>
                {getPageName(tab, undefined, 'Content')}
              </TypeLabel>
              <StatusLabels tab={tab} />
            </BottomRow>
          </Tab>
          <Spacer />
        </div>
      )}
    </Draggable>
  ) : null;
};

interface BuildNavProfileTabProps {
  tab: BuilderCollection;
  isActive: boolean;
}

export const BuildNavProfileTab = ({ tab, isActive }: BuildNavProfileTabProps) => {
  const history = useHistory();
  const location = useLocation();
  const screen = getScreenFromPathname(location.pathname);

  return (
    <>
      <Tab onClick={() => history.replace(`/${screen}/${tab.TabId}`)} $isActive={isActive}>
        <StyledDragHandleLocked />
        <TopRow>
          <TabIcon>
            <TintImage
              src={tab.Icon ? `${TAB_ICON_URL}${tab.Icon}` : `${BUILDER_IMAGES_DOMAIN}/icons/blocked.png`}
              color={NEUTRAL_10_COLOUR}
              dimensions={{ width: 20, height: 20 }}
            />
          </TabIcon>
          <TabName>{tab.Name}</TabName>
          <div className="customize-button-wrapper">
            <CustomizeButton
              tertiaryHighlight
              medium
              icon={<PenIcon />}
              link={{ to: { pathname: `/builder/${tab.TabId}`, state: { showUnsavedChangesPrompt: true } } }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Customize
            </CustomizeButton>
          </div>
        </TopRow>
        <BottomRow>
          <TypeLabel $upperCase $warning>
            Profile
          </TypeLabel>
          <StatusLabels tab={tab} />
        </BottomRow>
      </Tab>
      <Spacer />
    </>
  );
};
