import styled from 'styled-components';

import { TypeLabel } from 'components';
import { FONT_10PX_SEMIBOLD, FONT_12PX_SEMIBOLD } from 'font';
import { useState } from 'react';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_PRIMARY_HOVER_COLOUR,
  HIGHLIGHT_SECONDARY_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_13_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_8_COLOUR,
  NEUTRAL_9_COLOUR,
  SECONDARY_SUCCESS_COLOUR,
  SUCCESS_COLOUR,
} from 'theme';
import { isUserAdmin } from 'utils';
import { CheckIcon, PadlockIcon } from 'icons';

const StageContainer = styled.div`
  display: flex;

  cursor: pointer;
`;

interface PositionProps {
  $isCompleted: boolean;
  $isActive: boolean;
  $isCurrent: boolean;
  $isHovering: boolean;
}

const getColor = ({ $isCompleted, $isActive, $isCurrent, $isHovering }: PositionProps) => {
  if ($isActive) {
    return '#fff';
  } else if ($isCompleted) {
    return SUCCESS_COLOUR;
  } else if ($isCurrent) {
    return $isHovering ? HIGHLIGHT_PRIMARY_HOVER_COLOUR : HIGHLIGHT_PRIMARY_COLOUR;
  }
  return NEUTRAL_9_COLOUR;
};

const getBgColor = ({ $isCompleted, $isActive, $isCurrent, $isHovering }: PositionProps) => {
  if ($isActive) {
    if ($isCompleted) {
      return SUCCESS_COLOUR;
    } else if ($isCurrent) {
      return $isHovering ? HIGHLIGHT_PRIMARY_HOVER_COLOUR : HIGHLIGHT_PRIMARY_COLOUR;
    }
    return $isHovering ? NEUTRAL_13_COLOUR : NEUTRAL_10_COLOUR;
  }
  return '#fff';
};

const getBorderColor = (props: PositionProps) => {
  const { $isActive, $isCompleted, $isCurrent, $isHovering } = props;

  if ($isActive) {
    return getBgColor(props);
  } else if ($isCompleted) {
    return SUCCESS_COLOUR;
  } else if ($isCurrent) {
    return $isHovering ? HIGHLIGHT_PRIMARY_HOVER_COLOUR : HIGHLIGHT_PRIMARY_COLOUR;
  }
  return $isHovering ? NEUTRAL_10_COLOUR : NEUTRAL_5_COLOUR;
};

const getOutlineColor = ({ $isCompleted }: PositionProps) => {
  if ($isCompleted) {
    return SECONDARY_SUCCESS_COLOUR;
  }
  return HIGHLIGHT_SECONDARY_COLOUR;
};

const Position = styled.div<PositionProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  ${FONT_12PX_SEMIBOLD};
  color: ${(props) => getColor(props)};
  background-color: ${(props) => getBgColor(props)};
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => getBorderColor(props)};
  outline-width: ${({ $isActive, $isCurrent, $isCompleted }) =>
    $isActive && ($isCurrent || $isCompleted) ? '2px' : 0};
  outline-style: solid;
  outline-color: ${(props) => getOutlineColor(props)};
`;

const StyledCheckIcon = styled(CheckIcon)`
  font-size: 18px;
`;
const StyledPadlockIcon = styled(PadlockIcon)`
  font-size: 18px;
`;

const Title = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;

  margin-left: 12px;
  ${FONT_12PX_SEMIBOLD};
  ${({ $isActive }) => $isActive && 'font-weight: 700'};
  color: ${({ $isActive }) => ($isActive ? NEUTRAL_10_COLOUR : NEUTRAL_9_COLOUR)};
`;

const StageDetails = styled.div`
  display: flex;

  margin: 0px 0 7px 12px;
`;

const DetailsWrapper = styled.div`
  display: flex;
  cursor: pointer;
  height: 18px;
  padding-top: 2px;
`;

const CompletionWrapper = styled.div`
  display: flex;
  align-items: center;

  ${FONT_10PX_SEMIBOLD};
  color: ${NEUTRAL_8_COLOUR};
`;

const StyledTypeLabel = styled(TypeLabel)`
  margin: 0 12px 0 4px;
`;

const Divider = styled.div<{ $isVisible: boolean }>`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  width: 1px;
  height: 40px;
  margin-top: 4px;
  background-color: ${NEUTRAL_5_COLOUR};
  border-radius: 1px;
  margin-right: 24px;
`;

interface OnboardingStageProps {
  title: string;
  position: number;
  isActive: boolean;
  isCurrent: boolean;
  showDivider: boolean;
  handleClick: () => void;
  isCompleted: boolean;
  isLocked?: boolean; // Stage cannot be navigated to
  clientTotal: number;
  clientCompleted: number;
  adminTotal: number;
  adminCompleted: number;
}

export const OnboardingStage = ({
  title,
  position,
  isActive,
  isCurrent,
  showDivider,
  handleClick,
  isCompleted,
  isLocked,
  clientTotal,
  clientCompleted,
  adminTotal,
  adminCompleted,
}: OnboardingStageProps) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <>
      <StageContainer
        onClick={() => !isLocked && handleClick()}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        <Position $isCompleted={isCompleted} $isActive={isActive} $isCurrent={isCurrent} $isHovering={isHovering}>
          {isCompleted && isHovering ? <StyledCheckIcon /> : isLocked && isHovering ? <StyledPadlockIcon /> : position}
        </Position>
        <Title $isActive={isActive}>{title}</Title>
      </StageContainer>
      <StageDetails>
        <Divider $isVisible={showDivider} />
        {isUserAdmin() && (
          <DetailsWrapper
            onClick={() => !isLocked && handleClick()}
            onMouseEnter={() => {
              setIsHovering(true);
            }}
            onMouseLeave={() => {
              setIsHovering(false);
            }}
          >
            <CompletionWrapper>
              CLIENT
              <StyledTypeLabel $disabled={clientTotal === 0 || clientCompleted < clientTotal}>
                {clientTotal > 0 ? `${clientCompleted}/${clientTotal}` : 'N/A'}
              </StyledTypeLabel>
            </CompletionWrapper>
            <CompletionWrapper>
              ADMIN
              <StyledTypeLabel
                $disabled={adminCompleted < adminTotal}
                $danger={clientTotal > 0 && clientCompleted === clientTotal && adminCompleted < adminTotal}
              >
                {adminCompleted}/{adminTotal}
              </StyledTypeLabel>
            </CompletionWrapper>
          </DetailsWrapper>
        )}
      </StageDetails>
    </>
  );
};
