import { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { OnboardingScreen } from 'api';
import { CustomCell, CustomTable, SearchBar, TableColumn, Tabs } from 'components';
import { FONT_12PX_MEDIUM, FONT_14PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { useLocalAppProperties } from 'providers';
import { NEUTRAL_8_COLOUR } from 'theme';

const PAGE_SIZE = 50;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SearchBarWrapper = styled.div`
  margin-right: 12px;
`;

const Total = styled.div`
  ${FONT_14PX_MEDIUM};
  color: ${NEUTRAL_8_COLOUR};
`;

const StyledCell = styled(CustomCell)`
  height: 42px;
  ${OVERFLOW_ELLIPSIS};
  ${FONT_12PX_MEDIUM};
`;

const Ellipsis = styled.div`
  ${OVERFLOW_ELLIPSIS};
`;

export const DebugAppProperties = () => {
  const { properties: propertiesData } = useLocalAppProperties();
  const [page, setPage] = useState(1);
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get('search') ?? undefined;

  const properties = useMemo(
    () =>
      Object.entries(propertiesData)
        .map(([key, value]) => ({
          id: key,
          value: value ? value.toString() : '',
        }))
        .sort((a, b) => a.id.localeCompare(b.id)),
    [propertiesData],
  );

  const filteredData = useMemo(
    () =>
      !query
        ? properties
        : properties?.filter(
            ({ id, value }) => id.toLowerCase().includes(query) || value.toLowerCase().includes(query),
          ),
    [properties, query],
  );

  const columns: TableColumn<{ id: string; value: string | OnboardingScreen[] }>[] = [
    {
      heading: 'Property',
      width: 300,
      render: ({ id }) => (
        <StyledCell>
          <Ellipsis>{id}</Ellipsis>
        </StyledCell>
      ),
      sorter: (a, b) => a.id.localeCompare(b.id),
      sorterKey: 'id',
      initialSortOrder: 'ascend',
    },
    {
      heading: 'Value',
      width: 'grow',
      render: ({ value }) => (
        <StyledCell>
          <code>{value}</code>
        </StyledCell>
      ),
    },
  ];

  const handleSearch = useCallback(
    (value: string) => {
      const searchInput = value.toLowerCase();
      const searchParam = searchInput !== '' ? `&search=${searchInput}` : '';

      history.replace({
        search: searchParam,
      });

      setPage(1);
    },
    [history],
  );

  return (
    <Tabs.Content value="properties">
      <TableHeader>
        <SearchWrapper>
          <SearchBarWrapper>
            <SearchBar value={query} onSearch={handleSearch} width="293px" />
          </SearchBarWrapper>
        </SearchWrapper>
        <Total>{`${filteredData.length} Properties`}</Total>
      </TableHeader>
      <CustomTable
        isLoading={!properties}
        pageSize={PAGE_SIZE}
        columns={columns}
        data={filteredData}
        emptyTitle=""
        emptyDescription="No results"
        overridePage={page}
        onPageChange={setPage}
        defaultSortKey="id"
        defaultSortOrder="ascend"
      />
    </Tabs.Content>
  );
};
