import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { updateVideo, useAxiosInstance, VideoToUpdate } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

interface SaveVideoProps {
  invalidateQuery: boolean;
  refresh: boolean;
  saveActionLog: boolean;
}

export const useSaveVideo = ({ invalidateQuery = true, refresh = true, saveActionLog = true }: SaveVideoProps) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, VideoToUpdate>(
    (videoToUpdate) => {
      return updateVideo(client, appId, videoToUpdate, refresh);
    },
    {
      onSuccess: async (_response, payload) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['videos', appId]);
        }
        if (saveActionLog) {
          recordAction.mutate({ action: 'Save Video', detail: { videoToUpdate: payload } });
        }
      },
    },
  );
};
