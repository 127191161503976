import styled from 'styled-components';

import { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { VIDAPP_HELP_URL } from 'api';
import { CustomButton } from 'components';
import { FONT_16PX_SEMIBOLD, FONT_20PX_REGULAR, FONT_45PX_BOLD } from 'font';
import { NEUTRAL_10_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { BUILDER_IMAGES_DOMAIN } from 'utils';
import { AppSelector } from 'app/modules/nav-header';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px;
  min-height: 100vh;
`;

const Logo = styled(Link)`
  align-self: flex-start;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 523px;
  flex-grow: 1;
`;

const ErrorCode = styled.div`
  ${FONT_16PX_SEMIBOLD};
  color: ${NEUTRAL_7_COLOUR};
`;

const Heading = styled.div`
  ${FONT_45PX_BOLD}
  color: ${NEUTRAL_10_COLOUR};
  margin: 40px 0 12px;
`;

const Description = styled.div`
  ${FONT_20PX_REGULAR};
  color: ${NEUTRAL_10_COLOUR};
  max-width: 519px;
  margin-bottom: 36px;
  line-height: 28px;
`;

export const StyledLink = styled.a`
  &&&& {
    text-decoration: underline;
    color: inherit;
    text-underline-offset: 4px;
    text-decoration-skip-ink: none;

    :hover {
      color: ${NEUTRAL_7_COLOUR};
    }
  }
`;

const AppSelectorWrapper = styled.div`
  padding: 20px 0 0 20px;
`;

const getBody = (code: ErrorPageProps['code']) => {
  switch (code) {
    case 403:
      return (
        <>
          <Heading>
            You don't have <br />
            access to this page.
          </Heading>
          <Description>
            Head over to your Dashboard or{' '}
            <StyledLink href={VIDAPP_HELP_URL} target="_blank" rel="noopener noreferrer">
              get in contact
            </StyledLink>
            <br />
            with our customer success team.
          </Description>
        </>
      );
    case 404:
      return (
        <>
          <Heading>We can't find this page.</Heading>
          <Description>
            Check you're using the correct URL or head back over to your Dashboard to continue exploring the Builder.
          </Description>
        </>
      );
  }
};

interface ErrorPageProps {
  code: 403 | 404;
  heading?: string;
  showAppSelector?: boolean;
  hideDashboardButton?: boolean;
}

export const ErrorPage = ({ code, heading, showAppSelector, hideDashboardButton }: ErrorPageProps) => {
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    history.replace(pathname, { isError: true });
  }, []);

  return (
    <Wrapper>
      <Logo to={'/'} replace>
        <img src={`${BUILDER_IMAGES_DOMAIN}/branding/vidapp_logo.webp`} width="92px" alt="VidApp logo" />
      </Logo>
      <Body>
        <ErrorCode>{code}</ErrorCode>
        {heading ? <Heading>{heading}</Heading> : getBody(code)}
        {!hideDashboardButton && <CustomButton link={{ to: '/' }}>Go To Dashboard</CustomButton>}
        {showAppSelector && (
          <AppSelectorWrapper>
            <AppSelector isInvalidApp />
          </AppSelectorWrapper>
        )}
      </Body>
    </Wrapper>
  );
};
