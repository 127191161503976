import { DefaultAppBasicInfoProvider, DefaultAppPropertiesProvider, DefaultContentProvider } from 'providers';
import { DebugScreenInternal } from './DebugScreenInternal';

export const DebugScreen = () => (
  <DefaultAppBasicInfoProvider>
    <DefaultAppPropertiesProvider>
      <DefaultContentProvider>
        <DebugScreenInternal />
      </DefaultContentProvider>
    </DefaultAppPropertiesProvider>
  </DefaultAppBasicInfoProvider>
);
