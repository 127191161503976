import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { syncPurchase, SyncPurchasePayload } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useDataSource } from 'providers';

export const useSyncPurchases = () => {
  const appId = useAppBeingEdited();
  const dataSource = useDataSource();
  const queryClient = useQueryClient();
  return useMutation<unknown, AxiosError, Omit<SyncPurchasePayload & { Email: string }, 'DataSource'>>(
    ({ Email, ...data }) => {
      console.info('Syncing purchase', { ...data, DataSource: dataSource, Email });
      return syncPurchase(appId, { ...data, DataSource: dataSource });
    },
    {
      onSuccess: (_, { Email }) => {
        queryClient.invalidateQueries(['purchases', appId, Email]);
      },
    },
  );
};
