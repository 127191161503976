import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PageContainer } from 'components';
import { useContent, useLocalAppProperties } from 'providers';
import { getScreenFromPathname } from 'utils';

import { BuildNav } from '..';
import { BuildMyAppMockup, BuildMyAppSaveButton } from '../components';
import { useBuildNav } from '../providers';
import { BMA_TV_SCREEN } from '../const';

export const BuildMyAppInternal = () => {
  const { activeTabs, inactiveTabs } = useBuildNav();
  const { collections, videos } = useContent();
  const { properties } = useLocalAppProperties();
  const history = useHistory();
  const location = useLocation();
  const [tabId, setTabId] = useState<string | number>();
  const screen = getScreenFromPathname(location.pathname);
  const isTV = screen === BMA_TV_SCREEN;

  useEffect(() => {
    const endOfPathname = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    if (endOfPathname.includes('TempTab')) {
      setTabId(endOfPathname);
    } else {
      const clickedTabId = parseInt(endOfPathname);

      if (isNaN(clickedTabId) || !collections[clickedTabId]) {
        // Build My App has been navigated to, but no tab has been clicked yet - Navigate to the first available tab
        if (activeTabs && activeTabs.length > 0) {
          history.replace(`/${screen}/${activeTabs[0].TabId}`);
        } else if (inactiveTabs && inactiveTabs.length > 0) {
          history.replace(`/${screen}/${inactiveTabs[0].TabId}`);
        }
      } else {
        setTabId(clickedTabId);
      }
    }
  }, [screen, location, activeTabs, inactiveTabs]);

  return (
    <PageContainer
      heading={`Build My${isTV ? ' TV ' : ' '}App`}
      headingButton={<BuildMyAppSaveButton />}
      isLoading={!collections || !videos || !properties}
      contentMaxWidth={{ small: '458px', large: '556px' }}
      $headingPadding="32px 32px 0"
      $contentPadding="0"
      mockup={
        isTV ? (
          <div></div> // Just display an empty space for TV for now
        ) : (
          <BuildMyAppMockup tabId={tabId} />
        )
      }
    >
      <BuildNav />
    </PageContainer>
  );
};
