import styled from 'styled-components';
import generateCalendar from 'antd/es/calendar/generateCalendar';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { CalendarCell } from 'app/modules/calendar/CalendarPage/components';

import {
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_SECONDARY_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_4_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_8_COLOUR,
} from 'theme';
import { FONT_12PX_SEMIBOLD } from 'font';

import { CalendarModal } from '../../CalendarModal';
import { useCalendarPageContext } from '../../CalendarModal/providers/calendar-page-provider';

const CustomCalendar = styled(generateCalendar<Date>(dateFnsGenerateConfig))`
  #react-app && {
    background: none;
    width: 100%;

    .ant-picker-panel {
      background: none;
    }

    table {
      margin-bottom: 0;
    }

    th {
      display: none;
    }

    tr,
    td {
      border: none; // Reset default
    }

    tr:not(:last-child) {
      border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
    }

    td {
      transition: 0.3s background-color;
      background-color: #fff;
      color: ${NEUTRAL_10_COLOUR};

      :not(.ant-picker-cell-in-view) {
        background-color: ${NEUTRAL_3_COLOUR};
      }

      &.ant-picker-cell-today {
        background-color: ${HIGHLIGHT_SECONDARY_COLOUR};
        color: ${HIGHLIGHT_PRIMARY_COLOUR};
        .date-label {
          color: ${HIGHLIGHT_PRIMARY_COLOUR};
        }
      }

      :hover {
        background-color: ${NEUTRAL_4_COLOUR};
      }

      :not(:last-child) {
        border-right: 1px solid ${NEUTRAL_5_COLOUR};
      }
    }

    .ant-picker-body {
      border: 1px solid ${NEUTRAL_5_COLOUR};
      border-radius: 8px;
      padding: 0;
      overflow: hidden;
    }
  }
`;

const CustomHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 4px;
`;

const HeaderDay = styled.div`
  width: calc(100% / 7);
  color: ${NEUTRAL_8_COLOUR};
  ${FONT_12PX_SEMIBOLD};
  margin-left: 1px;
`;

export const Calendar = () => {
  const { activeDate, setActiveDate, startDate, endDate } = useCalendarPageContext();

  return (
    <>
      <CustomHeader>
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <HeaderDay key={day}>{day}</HeaderDay>
        ))}
      </CustomHeader>
      <CustomCalendar
        mode="month"
        validRange={[startDate, endDate]}
        value={activeDate}
        onPanelChange={setActiveDate}
        dateFullCellRender={(date) => <CalendarCell date={date} />}
        headerRender={() => null}
      />
      <CalendarModal />
    </>
  );
};
