import { useEffect } from 'react';
import styled from 'styled-components';

import { ContentAdvancedSettings, CollectionTitle, SettingsCollectionThumbnail, WorkoutDescription } from 'components';
import { useContent, useSaveContext } from 'providers';
import { DEFAULT_COLLECTION, SOURCE_TYPE_WORKOUT, WORKOUT_TEMPLATE_ID } from 'api';
import { getCollectionValuesFromNewCollection } from 'utils';
import { useAppBeingEdited } from 'app-context';
import { useFilters } from 'hooks';

import { ContentViewContainer, WorkoutSettings } from './components';
import { ContentCollectionTree } from '../content-tree';
import { useContentNavigationContext } from '../../providers';
import { ModalContextProvider } from '../cms-modal/providers/modal-provider';

const SEGMENT_MARGIN = { $marginTop: '0px', $marginBottom: '40px' };

const PaddedContainer = styled.div`
  // All children of a ContentCollectionView except for the content tree need to be given extra padding, so tree rows appear to bleed into the margins
  padding: 0 4px 0 6px;
`;

interface ContentWorkoutViewProps {
  collectionId: string | number;
}

export const ContentWorkoutView = ({ collectionId }: ContentWorkoutViewProps) => {
  const { tempTabId, setTempTabId } = useContentNavigationContext();
  const { setNewCollection, setCollectionProperty, collections } = useContent();
  const { setCollectionToSave, setCollectionPropertyToSave, getTempId } = useSaveContext();
  const appId = useAppBeingEdited();
  const { data: filtersData } = useFilters();

  const collection = collections[collectionId] ?? collections[tempTabId];

  useEffect(() => {
    if (collectionId.toString().startsWith('new_')) {
      const tempId = `TempTab${getTempId()}`;
      setTempTabId(tempId);
      const newCollection = {
        AppId: appId,
        ...DEFAULT_COLLECTION,
        TabId: tempId,
        TemplateId: WORKOUT_TEMPLATE_ID,
        Position: 0,
        Name: `New Workout`,
        Thumbnails: {},
        SourceType: SOURCE_TYPE_WORKOUT,
        Items: [],
        Resources: [],
        DisplayInTVApp: 1 as const,
        IncludedInAppData: 0 as const,
      };
      setNewCollection(tempId, newCollection);
      setCollectionToSave(tempId, getCollectionValuesFromNewCollection(newCollection));
      setCollectionProperty(tempId, 'LockedUntilSignIn', '1');
      setCollectionPropertyToSave(tempId, 'LockedUntilSignIn', '1');
    }
  }, [collectionId]);

  return (
    <ContentViewContainer collection={collection} key={collectionId} isLoading={!filtersData}>
      {collection && (
        <>
          <PaddedContainer>
            <CollectionTitle
              TabId={collection.TabId}
              Name={collection.Name}
              placeholder={collection.SourceName}
              label="VidApp Title"
              size="large"
              {...SEGMENT_MARGIN}
            />
            <WorkoutSettings collection={collection} />
          </PaddedContainer>
          <ModalContextProvider treeCollectionId={collection.TabId}>
            <ContentCollectionTree collection={collection} />
          </ModalContextProvider>
          <PaddedContainer>
            <SettingsCollectionThumbnail collection={collection} collapsible {...SEGMENT_MARGIN} />
            <WorkoutDescription TabId={collection.TabId} Description={collection.Description} />
            <ContentAdvancedSettings collection={collection} isWorkout />
          </PaddedContainer>
        </>
      )}
    </ContentViewContainer>
  );
};
