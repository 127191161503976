import { ReactNode, useState } from 'react';

import { Dropdown } from 'components';

export const HoverCard = ({ ...props }: { trigger: ReactNode; content: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [delayHandler, setDelayHandler] = useState<NodeJS.Timeout>();

  const handleMouseEnter = () => {
    setDelayHandler(
      setTimeout(() => {
        setIsOpen(true);
      }, 500),
    );
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
    if (delayHandler) {
      clearTimeout(delayHandler);
    }
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Dropdown align="start" isOpen={isOpen} {...props} menuMarginTop="0" />
    </div>
  );
};
