import styled from 'styled-components';

import { ProductCard } from './ProductCard';
import { BuilderProductWithPrice } from './ContentGateView';
import { useEffect, useState } from 'react';

const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  padding-top: 24px;
  flex-direction: column;
`;

const Row = styled(Column)`
  flex-direction: row;
  gap: 12px;
`;

interface ProductListProps {
  products: BuilderProductWithPrice[];
  isBto?: boolean;
}

export const ProductList = ({ products, isBto }: ProductListProps) => {
  const [selectedId, setSelectedId] = useState<string | number>();
  const productForComparison = products[0]; // Lowest priced product will be used to calculate save % on other subscriptions

  const isVerticalStackView = isBto || products.length > 3;

  // Start with the first product selected if vertical stack view, otherwise last product
  useEffect(() => {
    if (products.length > 0) {
      const defaultSelectedIdx = isVerticalStackView ? 0 : products.length - 1;

      setSelectedId(products[defaultSelectedIdx].Id);
    }
  }, []);

  if (isVerticalStackView) {
    return (
      <Column>
        {products.map((p) => (
          <ProductCard
            key={p.Id}
            product={p}
            productForComparison={productForComparison}
            isSelected={selectedId === p.Id}
            isBto={isBto}
            isFullWidth
            handleSelect={setSelectedId}
          />
        ))}
      </Column>
    );
  }

  return (
    <Row>
      {products.map((p) => (
        <ProductCard
          key={p.Id}
          product={p}
          productForComparison={productForComparison}
          isSelected={selectedId === p.Id}
          isBto={isBto}
          handleSelect={setSelectedId}
        />
      ))}
    </Row>
  );
};
